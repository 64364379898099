
import { PdfpopupComponent } from './pdfpopup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxLinkPipeModule } from '../box-link-pipe/box-link-pipe.module';


@NgModule({
  declarations:[PdfpopupComponent],
  imports: [
    CommonModule,
    BoxLinkPipeModule
  ],
  exports:[
    PdfpopupComponent
  ]
})
export class PdfpopupModule { }
