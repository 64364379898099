import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-accenture',
  templateUrl: './accenture.component.html',
  styleUrls: ['./accenture.component.css']
})
export class AccentureComponent implements OnInit {

  constructor(private route: Router, private authS: MsalService) {
    
    
    if(localStorage.getItem('sso') == 'accenture') {
      this.authS.logout().toPromise()
        .then( ()=> {
        this.route.navigateByUrl('');
      })
      .catch( ()=> {
        this.route.navigateByUrl('');
        setTimeout(() => {
          window.location.reload();
        }, 200);
      });
    } else {
      localStorage.setItem('sso', 'accenture');
      window.location.reload();
    }

  }

  ngOnInit(): void {
  }

}
