import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conheca-mais',
  templateUrl: './conheca-mais.component.html',
  styleUrls: ['./conheca-mais.component.css']
})
export class ConhecaMaisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
