export const empresas_lancamento = [
  {
    nome:''
  },
  {
    nome: 'Agrichem'
  },
  {
    nome: 'Agrosema'
  },
  {
    nome: 'TecAgro (SGL)'
  },
  {
    nome: 'TecAgro (FCR)'
  },
  {
    nome: 'Nutrien'
  }
]
