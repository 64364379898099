export const quantidadeDeProdutos = [
  {
    quantidade:'1'
  },
  {
    quantidade:'2'
  },
  {
    quantidade:'3'
  },
  {
    quantidade:'4'
  },
  {
    quantidade:'5'
  },
  {
    quantidade:'6'
  },
  {
    quantidade:'7'
  },
  {
    quantidade:'8'
  },
  {
    quantidade:'9'
  },
  {
    quantidade:'10'
  },
  {
    quantidade:'11'
  },
  {
    quantidade:'12'
  },
  {
    quantidade:'13'
  },
  {
    quantidade:'14'
  },
  {
    quantidade:'15'
  },
  {
    quantidade:'16'
  },
  {
    quantidade:'17'
  },
  {
    quantidade:'18'
  },
  {
    quantidade:'19'
  },
  {
    quantidade:'20'
  }
]
