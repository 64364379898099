<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Solicitação de Pagamento IOF - Contratos de Mútuos RH"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome_responsavel_nutrien" class="col-sm-4 col-form-label">*Nome Responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_responsavel_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_responsavel_nutrien')?.errors?.required && formulario.get('nome_responsavel_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="email_responsavel_nutrien" class="col-sm-4 col-form-label">*E-mail do responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_responsavel_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_responsavel_nutrien')?.errors?.required && formulario.get('email_responsavel_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_responsavel_nutrien')?.errors?.email && formulario.get('email_responsavel_nutrien')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_do_inicio_do_decendio" class="col-sm-4 col-form-label">*Data do início do decêndio</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_do_inicio_do_decendio">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('data_do_inicio_do_decendio')?.errors?.required && formulario.get('data_do_inicio_do_decendio')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_final_do_decendio" class="col-sm-4 col-form-label">Data final do decêndio</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_final_do_decendio">
      </div>
    </div>

    <div class="form-group row">
      <label for="descricao_da_solicitacao" class="col-sm-4 col-form-label">*Descrição da solicitação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descricao_da_solicitacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao_da_solicitacao')?.errors?.required && formulario.get('descricao_da_solicitacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
