<div class="row box">
  <div class="col-lg-3 box-item">
    <h4>Folha de Pagamento</h4>
  </div>

  <div class="col-lg-3 box-item">
    <h4>Nova Solicitação de Cálculo</h4>
  </div>

  <div class="col-lg-3 box-item">
    <h4>Acompanhamento</h4>
  </div>
</div>
