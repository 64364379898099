import { NbsBannerModule } from './../componentes/nbs-banner/nbs-banner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './../componentes/header/header.module';
import { NgxSpinnerModule } from 'ngx-spinner';





@NgModule({
  declarations: [
    HomeComponent,


  ],
  imports: [
  CommonModule,
  NbsBannerModule,
  HomeRoutingModule,
  RouterModule,
  HeaderModule,
  NgxSpinnerModule,

  ]
})
export class HomeModule { }
