export const categorias = [
  {
    nome:''
  },
  {
    nome: 'PO'
  },
  {
    nome: 'NPO'
  }
]
