import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancelamento-solicitacao',
  templateUrl: './cancelamento-solicitacao.component.html',
  styleUrls: ['./cancelamento-solicitacao.component.css']
})
export class CancelamentoSolicitacaoComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  openForm(){
    this.router.navigate(['/forms/cancelamento-de-solicitacao'])
    // window.open('https://app.pipefy.com/public/form/WNenDdOI',"_blank")

  }
}
