export const empresas =[
  {
    nome:""
  },
  {
    nome: "Nutrien Útilfértil"
  },
  {
    nome: "Nutrien Participações"
  },
  {
    nome: "Agrichem"
  },
  {
    nome: "APMS"
  },
  {
    nome: "FAG"
  },
  {
    nome: "FCR"
  },
  {
    nome: "SGL"
  }

]
