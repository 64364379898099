import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.css']
})
export class FeriasComponent implements OnInit {

  fields: FormField[] = [];
  error_counter: number = 0;
  erro: boolean = false

  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        validators: Validators.required
      },
      {
        "label": "E-mail do Solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        validators: Validators.required
      },
      {

        "label": "Empresa",

        "name": "empresa",

        "select": [

          {

            "type": "option",

            "value": "AGRICHEM DO BRASIL SA",

            "name": "AGRICHEM DO BRASIL SA"
          },

          {

            "type": "option",

            "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",

            "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
          },

          {

            "type": "option",

            "value": "CONFIANÇA AGRÍCOLA LTDA",

            "name": "CONFIANÇA AGRÍCOLA LTDA"
          },

          {

            "type": "option",
            "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
            "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
          },
          // {
          //   "type": "option",
          //   "name": "MARCA AGRO MERCANTIL LTDA",
          //   "value": "MARCA AGRO MERCANTIL LTDA",
          // },
          {

            "type": "option",

            "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",

            "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
          },
          {
            type: "option",
            name: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
            value: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
          },
          {

            "type": "option",

            "value": "SEMENTES GOIÁS LTDA",
            "name": "SEMENTES GOIÁS LTDA"
          },
          {

            "type": "option",

            "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",

            "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
          }

        ],

        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Nome do Colaborador",
        "name": "gestor",
        "type": "text",
        validators: Validators.required
      },
      {
        "label": "Email do Colaborador",
        "name": "email_colaborador",
        "type": "email",
        validators: Validators.required
      },
      {
        "label": "Motivo da exceção de férias",
        "name": "motivo_da_exce_o_de_f_rias",
        "select": [
          {
            "type": "option",
            "value": "Problemas de saúde",
            "name": "Problemas de saúde"
          },
          {
            "type": "option",
            "value": "Férias compulsórias",
            "name": "Férias compulsórias"
          },
          {
            "type": "option",
            "value": "Agendamento fora do prazo",
            "name": "Agendamento fora do prazo"
          },
          {
            "type": "option",
            "value": "Cancelamento fora do prazo",
            "name": "Cancelamento fora do prazo"
          },
          {
            "type": "option",
            "value": "Recesso Estagiário",
            "name": "Recesso Estagiário"
          },
          {
            "type": "option",
            "value": "Outros",
            "name": "Outros",
            "fields": [
              {
                "label": "Descrição do motivo",
                "name": "descri_o_do_motivo",
                "type": "textArea",
                "validators": Validators.required
              }
            ]
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Início do gozo de férias",
        "name": "in_cio_do_gozo_de_f_rias",
        "type": "date",
        validators: Validators.required
      },
      {
        "label": "Dias de gozo",
        "name": "dias_de_gozo",
        "type": "number",
        validators: Validators.required
      },
      {
        type: "radioHorizontal",
        label: "Deseja solicitar o abono pecuniário?",
        name: "deseja_solicitar_o_abono_pecuni_rio",
        validators: Validators.required,
        select: [
          {
            type: "option",
            name: "Sim",
            value: "Sim",
            fields: [
              {
                "label": "Número de dias de abono de férias",
                "name": "n_mero_de_dias_de_abono_de_f_rias",
                "type": "number",
                validators: Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Não",
            value: "Não"
          }
        ]
      },
      {
        type: "radioHorizontal",
        label: "NBS",
        name: "nbs",
        value: "NBS",
        _disabled: true,
        select: [
          {
            type: "option",
            name: "NBS",
            value: "NBS"
          },
          {
            type: "option",
            name: "LG",
            value: "LG"
          }
        ]
      },
      {
        "label": "Anexo",
        "name": "anexo",
        "type": "attachment",
        validators: Validators.required
      },
      {
        "label": "Comentários",
        "type": "textArea",
        "name": "coment_rios"
      },
      {
        type: 'buttonSubmit',
        name: 'Enviar'
      }
    ];

  }

}
