export const pipe_fields_ids =[
  'nome',
  'e_mail',
  'risco_sacado',
  'banco_de_registro_da_opera_o_do_risco_sacado',
  'tipo_de_nota',
  'qual_a_quantidade_de_cte',
  'conta_cont_bil',
  'documento_do_fornecedor',
  'cpf_do_fornecedor',
  'cnpj_fornecedor',
  'nome_do_fornecedor',
  'cnpj_da_empresa_nutrien',
  'nome_empresa_nutrien',
  'nro_nota',
  'data_de_envio_de_nf',
  'valor_total_da_nf_1',
  'nota_com_rateio_de_valores',
  'arquivo_de_rateio', //anexo
  'data_de_vencimento_da_nota',
  'departamento',
  'numero_po',
  'n_mero_de_itens_da_po',
  'n_mero_do_item_da_po',
  'lista_de_itens_da_po', //anexo
  'centro_de_custo',
  'anexo_nota_ou_boleto', //anexo
  'certeza_que_incluiu_o_arquivo_conforme_orienta_o',
  'anexo_documenta_o_suporte',//anexo
  'nota_para_pagamento_fora_da_pol_tica',
  'nota_para_pagamento_urgente',
  'motivo_da_exce_o',
  'observa_o_sobre_o_documento',
  'informa_es_complementares_da_po',
  'anexo_boleto', //anexo
  'anexo_xml', //anexo
  'nf_do_produto_original', //anexo
  'tipo_de_custo_planejado',
  'n_mero_de_cte',
  'opex_e_capex',
  'tipo_de_importa_o',
  'tipo_de_pagamento',
  'n_mero_afe',

  // 'arquivo_rateio_long_text',
  // 'lista_de_itens_da_po_long_text',
  // 'anexo_nota_long_text',
  // 'anexo_documenta_o_suporte_long_text',
  // 'anexo_xml_long_text',
  // 'anexo_boleto_long_text',
  // 'nf_do_produto_original_long_text',


  'id_card_di_ipe',
  'estado_de_origem',
  'estado_de_destino',
  'grc',
  // 'parcela_do_aluguel',
  'm_s_do_aluguel',
  'ano_do_aluguel',
  'confirma_a_entrega_ou_presta_o_de_servi_os',
  'descreva_o_motivo',
  'hora_de_selecao_da_empresa',
  'hora_abertura_de_card',
  'tempo_gasto_no_preenchimento_do_formulario',
  'anexo_para_pagamento_de_aut_nomo',
  'cont_m_registro_na_prefeitura',
  'anexar_registro_do_pagamento_de_aut_nomo',
  'anexar_nota_emitida_na_prefeitura',
  'preenchimento_manual_do_formul_rio',
  'ocr_leitura',
  'ocr_quantidade_de_campos_ocerizados',
  'ocr_valor_total_da_nf',
  'ocr_nome_do_fornecedor',
  'ocr_cnpj_empresa_nutrien',
  'ocr_cnpj_fornecedor',
  'ocr_data_de_emiss_o_da_nf',
  'ocr_n_mero_da_nota',
  'tempo_ocr',




  // campos IPE - Novos
'natureza_da_opera_o',
'cfop',
'nr_de_serie',
// 'nome_empresa_nutrien_ocr',
'chave_de_acesso_portal',
'n_mero_da_linha_do_pedido_1',
'n_mero_da_linha_do_pedido_2',
'n_mero_da_linha_do_pedido_3',
'n_mero_da_linha_do_pedido_4',
'n_mero_da_linha_do_pedido_5',
'n_mero_da_linha_do_pedido_6',
'n_mero_da_linha_do_pedido_7',
'n_mero_da_linha_do_pedido_8',
'n_mero_da_linha_do_pedido_9',
'n_mero_da_linha_do_pedido_10',
'lista_de_pedidos',
'quantidade_de_pedidos',
'categoria',
'subcategoria_po',
'tipo_de_opera_o',
'migo_1',
'origem_da_cria_o_do_card',
'json_most',
'tomador_ipe',
'fornecedor_ipe',
'n_mero_do_pedido_1_1',
'n_mero_do_pedido_2_1',
'n_mero_do_pedido_3',
'n_mero_do_pedido_4',
'n_mero_do_pedido_5',
'n_mero_do_pedido_6',
'n_mero_do_pedido_7',
'n_mero_do_pedido_8',
'n_mero_do_pedido_9',
'n_mero_do_pedido_10',
'hora_inicio_chamada_most',
'hora_fim_chamada_most',
'c_digo_do_servi_o',
'ambiente_ipe',
'n_mero_da_parcela',
'select',
'solicita_o_referente_a_presta_o_de_contas_adiantamento',
'n_do_card_de_adiantamento',
'situa_o_da_presta_o_de_contas'

]
