import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommomServicesService } from './../services/commom-services.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';
import { TokenServiceService } from './../services/token-service.service';

@Component({
  selector: 'app-teste-backend',
  templateUrl: './teste-backend.component.html',
  styleUrls: ['./teste-backend.component.css']
})
export class TesteBackendComponent implements OnInit {

  resposta:any
  formulario!: FormGroup;
  token:string = ''
  token_completo:string =''

  constructor(private formBuilder: FormBuilder,
              private commomService: CommomServicesService,
              private msalService: MsalService,
              private tokenService: TokenServiceService
            ) { }



  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      url: ['', []],
      payload: ['', []],
      authorization: ['',[]]
    })
  }

  enviarFormulario(){
    let url = this.formulario.getRawValue().url
    let payload = this.formulario.getRawValue().payload
    let authorization = this.formulario.getRawValue().authorization

    this.commomService.testeApi(url, payload, authorization).subscribe((res)=>{
      console.log(res)
      this.resposta = JSON.stringify(res)

    },
    (error:any)=>{
      console.log("erro:",error)
      console.log("error.status:",error.status)
      console.log(typeof(error.status))
      if(error.status == 401){
        console.log("entrei em error.status")
        Swal.fire({
          title: 'Ops, sua sessão expirou!',
          text: 'Por favor, saia e logue novamente.',
          icon: 'error',
          confirmButtonText: 'Sair',
          confirmButtonColor: '#ff6961',
          allowOutsideClick:false,
          allowEscapeKey:false
        }).then(()=>{
          // this.msalService.logoutRedirect()
        })
      }else{
        console.log("entrei em outro erro")
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
          allowOutsideClick:false,
          allowEscapeKey:false
        })
      }
    })

    console.log('url =>', url)
    console.log('payload =>', payload)
  }

  gerarToken(){
    this.tokenService.getTokenFromGgp().then((res)=>{
      this.token = res.body.data.Authorization
      this.formulario.get('authorization')?.setValue('Bearer '+  this.token)
      console.log('tokeeen',this.token)
      console.log('tokeee222n',this.token_completo)
    }).catch((error:any)=>{
      console.log(error)
      this.token = error.message
    })
  }

}
