export const tratativas = [
  {
    nome:''
  },
  {
    nome:'Criação'
  },
  {
    nome:'Baixa'
  },
  {
    nome:'Movimentação'
  },
]
