<app-header></app-header>

<app-nbs-banner url="../../../assets/img/background.png" descricao="teste" titulo="Compras"></app-nbs-banner>

<div class="col-md-6">

    <app-box-link-pipe url="../../../assets/img/Compras/compras1.png" descricao="Compras" titulo="Requisição de Compras" link="https://app.pipefy.com/public/form/8CMl776i"></app-box-link-pipe>

    <app-box-link-pipe url="../../../assets/img/Compras/compras1.png" descricao="Compras" titulo="Suporte de Compras" link="https://app.pipefy.com/public/form/6wliaNG2"> </app-box-link-pipe>

</div>



