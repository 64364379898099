export const moedas = [
  {
    nome:''
  },
  {
    nome: 'BRL'
  },
  {
    nome: 'USD'
  }
]
