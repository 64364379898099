<div class="nbs_box_link_menu">
    <img class="nbs_box_link_menu-image img-responsive" [src]="url" [alt]="descricao" />
    <div class="">
        <div *ngIf="!target">
          <p class="nbs_box_link_menu-title p-2 box-titulo"  routerLink="link">{{ titulo }}</p>
        </div>
        <div *ngIf="target">
          <p class="nbs_box_link_menu-title p-2 box-titulo"><a class="ancora" href="{{linkTarget}}" target="_blank">{{ titulo }}</a></p>
        </div>
        <span class="complemento">{{complemento}}</span>
        <p class = "subtitulo">{{subtitulo}} </p>
        <p class = "nbs_box_link_menu-title p-2 subtitulo2">{{subtitulo2}} </p>

    </div>
</div>
