export const pipe_fields_reembolso_beneficios =[
    "nome",
    "cpf",
    "e_mail",
    "empresa",
    "centro_de_custo",
    "tipo",
    "valor_integral_documenta_o",
    "ipva",
    "seguro_carro",
    "valor_integral_documenta_o_revisado",
    "ipva_r_revisado",
    "seguro_carro_r_revisado",
    "total_a_reembolsar",
    "anexo_documenta_o",
    "anexo_ipva",
    "anexo_seguro"
]