export const FamiliaProdutos =[
  {
    familia:''
  },
  {
    familia:'Defensivo'
  },
  {
    familia:'Nutricionais'
  },
  {
    familia:'Fertilizantes'
  },
  {
    familia:'Sementes'
  },
  {
    familia:'Outros'
  }

]
