export const pipe_fields_id = [
  "digite_seu_nome",
  "digite_seu_e_mail",
  "nome_da_empresa",
  "tipo_de_tratativa",
  "c_digo_sap",
  "data_da_baixa",
  //"n_mero_do_bem",
  "n_mero_do_bem_item", //codigo de ativo no sistema
  "tipo_de_transa_o",
  "pdf_da_nota_fiscal_de_origem_est_em_anexo",
  "formul_rio_de_solicita_o_de_emiss_o_de_nota_fiscal_est_anexado_e_preenchido",
  //"formul_rio_de_autoriza_o_da_contabilidade_para_emiss_o_da_nota_fiscal_est_em_anexo_e_assinado_pela_gest_o",
  "incluir_anexos",
  "n_mero_de_nota_fiscal",
  "recebimento_financeiro_atrav_s_de_leil_o",
  "comprovante_de_pagamento",
  "valor_do_faturamento_valor_total_da_venda_dos_itens",
  "motivo_da_baixa",
  "formul_rio_assinado",
  // "id_card_origem",
  "observa_o"
]
