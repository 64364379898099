export const empresas = [
  {
    nome:''
  },
  {
    nome:'Agrichem'
  },
  // {
  //   nome:'Agrosema'
  // },
  {
    nome:'Nutrien'
  },
  // {
  //   nome:'Tec Agro'
  // },
  {
    nome:'Tec Agro-SGL'
  },
  {
    nome:'Tec Agro-FRC'
  }
  //,
  // {
  //   nome:'Tec Agro'
  // }

]
