export const quantidade_parcelas = [
  {
    parcela: '01'
  },
  {
    parcela: '02'
  },
  {
    parcela: '03'
  },
  {
    parcela: '04'
  },
  {
    parcela: '05'
  },
  {
    parcela: '06'
  },
  {
    parcela: '07'
  },
  {
    parcela: '08'
  },
  {
    parcela: '09'
  },
  {
    parcela: '10'
  },
  {
    parcela: '11'
  },
  {
    parcela: '12'
  }
]
