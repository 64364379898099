<!-- <button (click)="login()" *ngIf="!isLoggedIn()">login teste</button>
<button (click)="logout()" *ngIf="isLoggedIn()">logout</button>
<div *ngIf="!isLoggedIn()"> To Deslogado</div>
<div *ngIf="isLoggedIn()"> To logado</div> -->
<router-outlet></router-outlet>
<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  
</ngx-spinner> -->

<ngx-spinner  bdColor = "rgba(0, 0, 0, 0)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>
