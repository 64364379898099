import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-home-rh',
  templateUrl: './home-rh.component.html',
  styleUrls: ['./home-rh.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
                    )
          ]
        ),
        transition(
          ':leave',
          [
            // style({ height: 0, opacity: 1 }),
            animate('1s ease-in',
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class HomeRhComponent implements OnInit {

  isSideNavCollapsed = false;
  screenWidth = 0;
  perfil:string = 'funcionario';
  email: string = '';

  showTicketOptions = false
  showAutoAtendimentoOptions = false
  showCargosSalariosOptions = false
  showDesligamentos = false

  hideDesligamento:boolean = true
  hideBeneficios: boolean = true
  hideRecibos: boolean = true
  hidePonto: boolean = true
  hideRescisao: boolean = true
  hideMovimentacao: boolean = true
  hideAlteracaoBancarias: boolean = true

  urlsInforme: any[] = []

  showInforme: boolean = false;

  // perfis = ['49a57e6a-1ea6-4d49-90a0-80daf0a7b7fe']
  // ,'remuneracao','orcamentario','recrutamento'

  constructor(
    private router: Router,
    private userInfo: UserInfosService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.email = this.userInfo.getUserEmail();
    window.scroll({top:0, left:0})
    this.popUpInformativo()
  }

  onToggleSideNav(data: SideNavToggle){
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed
  }

  showOptions(option:string){
    if(option == 'Abertura de Ticket'){
      this.showTicketOptions = !this.showTicketOptions
      if(this.showAutoAtendimentoOptions){
        this.showAutoAtendimentoOptions  = !this.showAutoAtendimentoOptions
      }
      if(this.showCargosSalariosOptions){
        this.showCargosSalariosOptions  = !this.showCargosSalariosOptions
      }
    }
    if(option == 'Autoatendimento'){
      this.showAutoAtendimentoOptions  = !this.showAutoAtendimentoOptions
      if(this.showTicketOptions){
        this.showTicketOptions  = !this.showTicketOptions
      }
      if(this.showCargosSalariosOptions){
        this.showCargosSalariosOptions  = !this.showCargosSalariosOptions
      }
    }

    if(option == 'Cargos e Salários'){
      this.showCargosSalariosOptions  = !this.showCargosSalariosOptions
      if(this.showAutoAtendimentoOptions){
        this.showAutoAtendimentoOptions  = !this.showAutoAtendimentoOptions
      }
      if(this.showTicketOptions){
        this.showTicketOptions  = !this.showTicketOptions
      }
    }
  }

  navegar(){
    this.router.navigate(['/forms/ajuda'])
  }

  faq(){
    this.router.navigate(['/forms/ajuda'])
  }

  goHome(){
    this.router.navigate([''])
  }

  goToLg(){
    window.open('https://login.lg.com.br/saml/auto/nutrien', '_blank')
    // window.open('https://login.lg.com.br/login/nutrien', '_blank')
  }

  goToLgCargosSalarios(){
    window.open('https://login.lg.com.br/saml/gente/nutrien', '_blank')
    // window.open('https://login.lg.com.br/login', '_blank')
  }



  goToNutrienGupy(){
    window.open('https://nutrien.gupy.io/companies/signin', '_blank')
  }

  goToConectaNutrien(){
    window.open('https://conectanutrien.com.br/gallery/37/sub_gallery/167', '_blank')
  }

  goToMub(){
    window.open('https://nutrien-ag.mub.one/app/portals/solicitante', '_blank')
  }

  verificaPerfil(group:any){
    if(document.location.host.search('nbs.dev.accenture.com') >= 0 || document.location.host.search('localhost') >= 0) {
      return true;
    }
    // console.log('perfil',this.userInfo.getUserGroups().includes(group))
    var perfil = this.userInfo.getUserGroups().includes(group)
    // console.log("group:", group, perfil)
    return perfil
  }

  showDesligamento(event:any){
    this.hideDesligamento = !this.hideDesligamento
  }
  showMovimentacao(event:any){
    this.hideMovimentacao = !this.hideMovimentacao
  }

  showRecibos(event:any){
    this.hideRecibos = !this.hideRecibos
  }

  showAlteracaoBancarias(event:any){
    this.hideAlteracaoBancarias = !this.hideAlteracaoBancarias
  }

  // verificaPerfilTeste(perfil:any){
  //   console.log(this.perfis.includes(perfil))
  //   return this.perfis.includes(perfil)
  // }

  buscarInformes(){
    this.spinner.show();
    this.urlsInforme = [];
    this.pipefy.buscaInformeRendimentos().subscribe((res: any)=>{
      this.spinner.hide();
      // console.log(JSON.stringify(res))
      if (res.data.infos_informe) {
        this.urlsInforme = res.data.infos_informe;
        this.showInforme = true;
      } else {
        this.urlsInforme = [];
        this.showInforme = false;
        Swal.fire({
          title: 'Informe de Rendimentos indisponível',
          text: 'Qualquer dúvida, favor consultar o RH.',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600'
        })
      }
    },((error)=>{
      this.spinner.hide()
      console.log(error);
      this.showInforme = false;
      Swal.fire({
        title: 'Desculpe!',
        text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600'
      })
    }))
  }

  downloadInforme(informe: any)  {
    if (informe.pdf)
      window.open(informe.pdf,'_blank')
  }

  popUpInformativo(){
    Swal.fire({
      title: 'Atenção!',
      text: 'Os links para alteração de dados pessoais, alteração de dados bancários, rescisão e movimentação (criação de UO - Unidade Organizacional, cargo e posição) foram desabilitados. Estas atividades estão temporariamente suspensas e serão liberadas novamente na plataforma myHR, no início de 2024.',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600'
    })
  }
}
