export const pipe_fields_ids =[
  'nome',
  'e_mail',
  // 'risco_sacado',
  'tipo_de_nota',
  'qual_a_quantidade_de_cte',
  'conta_cont_bil',
  'documento_do_fornecedor',
  'cpf_do_fornecedor',
  'cnpj_fornecedor',
  'nome_do_fornecedor',
  'cnpj_da_empresa_nutrien',
  'nome_empresa_nutrien',
  'nro_nota',
  'data_de_envio_de_nf',
  'valor_total_da_nf_1',
  'nota_com_rateio_de_valores',
  'arquivo_de_rateio', //anexo
  'data_de_vencimento_da_nota',
  'departamento',
  'numero_po',
  'n_mero_de_itens_da_po',
  'n_mero_do_item_da_po',
  'lista_de_itens_da_po', //anexo
  'centro_de_custo',
  'anexo_nota_ou_boleto', //anexo
  'certeza_que_incluiu_o_arquivo_conforme_orienta_o',
  'anexo_documenta_o_suporte',//anexo
  'nota_para_pagamento_fora_da_pol_tica',
  'nota_para_pagamento_urgente',
  'motivo_da_exce_o',
  'observa_o_sobre_o_documento',
  'informa_es_complementares_da_po',
  'anexo_boleto', //anexo
  'anexo_xml', //anexo
  'nf_do_produto_original', //anexo
  'tipo_de_custo_planejado',
  'n_mero_de_cte',
  'opex_e_capex',
  'tipo_de_importa_o',
  'tipo_de_pagamento',
  'n_mero_afe',
  
  // 'arquivo_rateio_long_text',
  // 'lista_de_itens_da_po_long_text',
  // 'anexo_nota_long_text',
  // 'anexo_documenta_o_suporte_long_text',
  // 'anexo_xml_long_text',
  // 'anexo_boleto_long_text',
  // 'nf_do_produto_original_long_text',

  
  'id_card_di_ipe',
  'estado_de_origem',
  'estado_de_destino',
  'grc',
  // 'parcela_do_aluguel',
  'm_s_do_aluguel',
  'ano_do_aluguel',
  'confirma_a_entrega_ou_presta_o_de_servi_os',
  'descreva_o_motivo',
  'hora_de_selecao_da_empresa',
  'hora_abertura_de_card',
  'tempo_gasto_no_preenchimento_do_formulario',
  'anexo_para_pagamento_de_aut_nomo',
  'cont_m_registro_na_prefeitura',
  'anexar_registro_do_pagamento_de_aut_nomo',
  'anexar_nota_emitida_na_prefeitura'
  
 
 

]
