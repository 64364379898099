import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-formularios-titulo',
  templateUrl: './formularios-titulo.component.html',
  styleUrls: ['./formularios-titulo.component.css']
})
export class FormulariosTituloComponent implements OnInit {

  @Input() url:string = ''
  @Input() descricao:string = ''
  @Input() titulo: string = ''
  @Input() link: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
