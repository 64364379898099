<div class="container">
    <div class='conteudo'>
        <div class="row text-center">
            <div class="col-12 text-center">
                <h2 class="text-center titulo">Portal NBS</h2>
                <app-login></app-login>
            </div>
        </div>
    </div>
</div>
