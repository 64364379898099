import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-link-menu',
  templateUrl: './box-link-menu.component.html',
  styleUrls: ['./box-link-menu.component.css']
})
export class BoxLinkMenuComponent implements OnInit {

  @Input() url:string = ''
  @Input() descricao:string = ''
  @Input() titulo: string = ''
  @Input() link: string = ''
  @Input() linkTarget: string = ''
  @Input() target: boolean = false
  @Input() subtitulo:string =''
  @Input() subtitulo2:string =''
  @Input() complemento: string= ''


  constructor() { }

  ngOnInit(): void {
  }

}
