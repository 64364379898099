import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-criacao-conta-contabil',
  templateUrl: './criacao-conta-contabil.component.html',
  styleUrls: ['./criacao-conta-contabil.component.css']
})
export class CriacaoContaContabilComponent implements OnInit {
  values: { [key: string]: any | any[] } = {} as any;
  fields: FormField[] = [];

  constructor() {}

  ngOnInit(): void {
    this.fields = [
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        "validators": Validators.required,
      },
      {
        "label": "E-mail do solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        "validators": [Validators.required, Validators.email],
      },
      {
        type: "select",
        name: "nome_da_empresa",
        label: "Nome da Empresa",
        validators: Validators.required,
        select: [
          {
            type: "option",
            name: "Agrichem",
            value: "Agrichem",
            fields: [
              {
                "label": "Qual a necessidade para abertura de Conta Contábil?",
                "name": "qual_a_necessidade_para_abertura_de_conta_cont_bil",
                "type": "textArea",
                "validators": Validators.required,
              },
              {
                "label": "Número da conta espelho",
                "name": "n_mero_da_conta_espelho",
                "type": "number",
                "validators": Validators.required,
              },
              {
                type: "select",
                name: "tipo_de_conta",
                label: "Tipo de conta:",
                validators: Validators.required,
                select: [
                  {
                    type: "option",
                    name: "Despesa",
                    value: "Despesa",
                  },
                  {
                    type: "option",
                    name: "Custo",
                    value: "Custo",
                  },
                  {
                    type: "option",
                    name: "Receita",
                    value: "Receita",
                  },
                  {
                    type: "option",
                    name: "Patrimonial",
                    value: "Patrimonial",
                  },
                ]
              },
              {
                "label": "Anexo excel:",
                "name": "anexo_excel",
                "type": "attachment",
              },
              {
                "label": "Comentários:",
                "name": "coment_rios",
                "type": "text",
                "validators": Validators.required,
              },
              {
                type: "buttonSubmit",
                name: "Enviar"
              }
            ]
          },
          {
            type: "option",
            name: "Nutrien",
            value: "Nutrien"
          },
          {
            type: "option",
            name: "Fronteira",
            value: "Fronteira",
            fields: [
              {
                "label": "Qual a necessidade para abertura de Conta Contábil?",
                "name": "qual_a_necessidade_para_abertura_de_conta_cont_bil",
                "type": "textArea",
                "validators": Validators.required,
              },
              {
                "label": "Número da conta espelho",
                "name": "n_mero_da_conta_espelho",
                "type": "number",
                "validators": Validators.required,
              },
              {
                type: "select",
                name: "tipo_de_conta",
                label: "Tipo de conta:",
                validators: Validators.required,
                select: [
                  {
                    type: "option",
                    name: "Despesa",
                    value: "Despesa",
                  },
                  {
                    type: "option",
                    name: "Custo",
                    value: "Custo",
                  },
                  {
                    type: "option",
                    name: "Receita",
                    value: "Receita",
                  },
                  {
                    type: "option",
                    name: "Patrimonial",
                    value: "Patrimonial",
                  },
                ]
              },
              {
                "label": "Anexo excel:",
                "name": "anexo_excel",
                "type": "attachment",
              },
              {
                "label": "Comentários:",
                "name": "coment_rios",
                "type": "text",
                "validators": Validators.required,
              },
              {
                type: "buttonSubmit",
                name: "Enviar"
              }
            ]
          },
          {
            type: "option",
            name: "Sementes",
            value: "Sementes",
            fields: [
              {
                "label": "Qual a necessidade para abertura de Conta Contábil?",
                "name": "qual_a_necessidade_para_abertura_de_conta_cont_bil",
                "type": "textArea",
                "validators": Validators.required,
              },
              {
                "label": "Número da conta espelho",
                "name": "n_mero_da_conta_espelho",
                "type": "number",
                "validators": Validators.required,
              },
              {
                type: "select",
                name: "tipo_de_conta",
                label: "Tipo de conta:",
                validators: Validators.required,
                select: [
                  {
                    type: "option",
                    name: "Despesa",
                    value: "Despesa",
                  },
                  {
                    type: "option",
                    name: "Custo",
                    value: "Custo",
                  },
                  {
                    type: "option",
                    name: "Receita",
                    value: "Receita",
                  },
                  {
                    type: "option",
                    name: "Patrimonial",
                    value: "Patrimonial",
                  },
                ]
              },
              {
                "label": "Anexo excel:",
                "name": "anexo_excel",
                "type": "attachment",
              },
              {
                "label": "Comentários:",
                "name": "coment_rios",
                "type": "text",
                "validators": Validators.required,
              },
              {
                type: "buttonSubmit",
                name: "Enviar"
              }
            ]
          },
          {
            type: "option",
            name: "APMS",
            value: "APMS",
            fields: [
              {
                "label": "Qual a necessidade para abertura de Conta Contábil?",
                "name": "qual_a_necessidade_para_abertura_de_conta_cont_bil",
                "type": "textArea",
                "validators": Validators.required,
              },
              {
                "label": "Número da conta espelho",
                "name": "n_mero_da_conta_espelho",
                "type": "number",
                "validators": Validators.required,
              },
              {
                type: "select",
                name: "tipo_de_conta",
                label: "Tipo de conta:",
                validators: Validators.required,
                select: [
                  {
                    type: "option",
                    name: "Despesa",
                    value: "Despesa",
                  },
                  {
                    type: "option",
                    name: "Custo",
                    value: "Custo",
                  },
                  {
                    type: "option",
                    name: "Receita",
                    value: "Receita",
                  },
                  {
                    type: "option",
                    name: "Patrimonial",
                    value: "Patrimonial",
                  },
                ]
              },
              {
                "label": "Anexo excel:",
                "name": "anexo_excel",
                "type": "attachment",
              },
              {
                "label": "Comentários:",
                "name": "coment_rios",
                "type": "text",
                "validators": Validators.required,
              },
              {
                type: "buttonSubmit",
                name: "Enviar"
              }
            ]
          },
        ]
      },
        {
          "label": "Qual a necessidade para abertura de Conta Contábil?",
          "name": "qual_a_necessidade_para_abertura_de_conta_cont_bil",
          "type": "textArea",
          "validators": Validators.required,
        },
        {
          "label": "Número da conta espelho",
          "name": "n_mero_da_conta_espelho",
          "type": "number",
          "validators": Validators.required,
        },
        {
          type: "select",
          name: "tipo_de_conta",
          label: "Tipo de conta:",
          validators: Validators.required,
          select: [
            {
              type: "option",
              name: "Despesa",
              value: "Despesa",
            },
            {
              type: "option",
              name: "Custo",
              value: "Custo",
            },
            {
              type: "option",
              name: "Receita",
              value: "Receita",
            },
            {
              type: "option",
              name: "Patrimonial",
              value: "Patrimonial",
            },
          ]
        },
        {
          "label": "Anexo excel:",
          "name": "anexo_excel",
          "type": "attachment",
        },
        {
          "label": "Comentários:",
          "name": "coment_rios",
          "type": "text",
          "validators": Validators.required,
        },
        {
          type: "buttonSubmit",
          name: "Enviar"
        }
    ];
  }

  valueChangeForm(event: any) {
    this.values = event;
  }
}