export const idLabels =
[
    { id: 'nome_empresa_nutrien', label: 'Nome empresa Nutrien' },
    { id: 'tipo_de_nota', label: 'Tipo de Nota' },
    { id: 'anexo_nota_ou_boleto', label: 'Anexo Nota' },
    { id: 'nome', label: 'Nome do Solicitante' },
    { id: 'email', label: 'E-mail do Solicitante' },
    { id: 'cnpj_da_empresa_nutrien', label: 'CNPJ da empresa Nutrien' },
    { id: 'grc', label: 'GRC' },
    { id: 'estado_de_origem', label: 'Estado de origem' },
    { id: 'estado_de_destino', label: 'Estado de destino' },
    { id: 'qual_a_quantidade_de_cte', label: 'Qual a quantidade de CTE' },
    { id: 'n_mero_afe', label: 'Número AFE' },
    { id: 'conta_contabil', label: 'Conta Contábil' },
    { id: 'documento_do_fornecedor', label: 'Documento do Fornecedor' },
    { id: 'cpf_do_fornecedor', label: 'CPF Fornecedor' },
    { id: 'cnpj_fornecedor', label: 'CNPJ Fornecedor' },
    { id: 'nome_do_fornecedor', label: 'Nome do Fornecedor' },
    { id: 'nro_nota', label: 'Número da Nota' },
    { id: 'm_s_do_aluguel', label: 'Mês do Aluguel' },
    { id: 'ano_do_aluguel', label: 'Ano do aluguel' },
    { id: 'data_de_envio_de_nf', label: 'Data de emissão da NF' },
    { id: 'valor_total_da_nf', label: 'Valor Total da NF' },
    { id: 'nf_do_produto_original', label: 'NF Do Produto Original' },
    { id: 'n_mero_de_cte', label: 'Número de CTE' },
    { id: 'opex_e_capex', label: 'OPEX ou Capex' },
    { id: 'tipo_de_importa_o', label: 'Tipo de Importação' },
    { id: 'tipo_de_pagamento', label: 'Tipo de Pagamento é Boleto' },
    { id: 'informacoes_complementares_da_po', label: 'Informações Complementares da PO' },
    { id: 'tipo_de_custo_planejado', label: 'Tipo de Custo Planejado' },
    { id: 'nota_com_rasteio_de_valores', label: 'Nota com rateio de valores' },
    { id: 'arquivo_de_rateio', label: 'Arquivo de Rateio' },
    { id: 'data_de_vencimento_da_nota', label: 'Data de Vencimento da Nota' },
    { id: 'departamento', label: 'Departamento' },
    { id: 'numero_po', label: 'Número de pedido' },
    { id: 'quantidade_de_itens_do_pedido', label: 'Quantidade de itens do pedido' },
    { id: 'numero_do_item_da_po', label: 'Número do item da PO' },
    { id: 'lista_de_itens_da_po', label: 'Lista de itens da PO' },
    { id: 'centro_de_custo', label: 'Centro de custo' },
    { id: 'confirma_entrega_ou_prestacao', label: 'Confirma entrega ou prestação de serviço' },
    { id: 'motivo_negacao', label: 'Motivo da Negação' },
    { id: 'certeza_que_incluiu_o_arquivo_conforme_orienta_o', label: 'Arquivo Incluído conforme orientação?' },
    { id: 'anexo_boleto', label: 'Anexo Boleto' },
    { id: 'anexo_xml', label: 'Anexo XML' },
    { id: 'anexo_documentacao_suporte', label: 'Anexo Documentação Suporte' },
    { id: 'observacao', label: 'Observação sobre o documento' },
    { id: 'anexo_para_pagamento_de_aut_nomo', label: 'Anexo para pagamento de autônomo' },
    { id: 'numero_linha_pedido_1', label: 'Linha do Pedido 1'},
    { id: 'numero_linha_pedido_2', label: 'Linha do Pedido 2'},
    { id: 'numero_linha_pedido_3', label: 'Linha do Pedido 3'},
    { id: 'numero_linha_pedido_4', label: 'Linha do Pedido 4'},
    { id: 'numero_linha_pedido_5', label: 'Linha do Pedido 5'},
    { id: 'numero_linha_pedido_6', label: 'Linha do Pedido 6'},
    { id: 'numero_linha_pedido_7', label: 'Linha do Pedido 7'},
    { id: 'numero_linha_pedido_8', label: 'Linha do Pedido 8'},
    { id: 'numero_linha_pedido_9', label: 'Linha do Pedido 9'},
    { id: 'numero_linha_pedido_10', label: 'Linha do Pedido 10'},
    { id: 'lista_pedidos', label: 'Lista de Itens'},
    { id: 'qtde_pedidos', label: 'Quantidade de Itens'}



    
]