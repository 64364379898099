export const condicoes_de_parcelamento = [
    {
      nome:''
    },
    {
      nome:'BR: PARCELADO 2X D10'
    },
    {
      nome:'BR: PARCELADO 2X D20'
    },
    {
      nome:'BR: PARCELADO 3X D10'
    },
    {
      nome:'BR: PARCELADO 4X D20'
    },
    {
      nome:'BR: PARCELADO 5X D10'
    },
    {
      nome:'BR: PARCELADO 5X D20'
    },
    {
      nome:'BR: PARCELADO 6X D10'
    },
    {
      nome:'BR: PARCELADO 6X D20'
    },
    {
      nome:'BR: PARCELADO 7X D20'
    },
    {
      nome:'BR: PARCELADO 8X D10'
    },
    {
      nome:'BR: PARCELADO 8X D20'
    },
    {
      nome:'BR: PARCELADO 9X D10'
    },
    {
      nome:'BR: PARCELADO 9X D20'
    },
    {
      nome:'BR: PARCELADO 10X D10'
    },
    {
      nome:'BR: PARCELADO 10X D20'
    }
]
