export const categorias = [
    {
      nome:''
    },
    {
      nome:'Defensivos'
    },
    {
      nome:'Fertilizantes'
    },
    {
      nome:'Nutricionais'
    },
    {
      nome:'Sementes'
    }
]