<div class="container">
  <h6>Testes Api</h6>
  <br><br>
<form [formGroup]="formulario" (submit)="enviarFormulario()">

  <a class = 'btn botao'(click)="gerarToken()">Gerar Token Jwt</a>

  <div class="form-group row">
    <label for="url" class="col-sm-4 col-form-label">Token jwt: </label>
  <textarea class="form-control" cols="30" rows="10">{{token}}</textarea>
  </div>

  <div class="form-group row">
    <label for="url" class="col-sm-4 col-form-label">*URL DA API: </label>
    <div class="col-sm-8">
      <input type="text"  class="form-control" formControlName="url">
    </div>
  </div>

  <div class="form-group row">
    <label for="payload" class="col-sm-4 col-form-label">Payload</label>
    <div class="col-sm-8">
      <textarea class="form-control" aria-label="With textarea" formControlName = "payload"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label for="authorization" class="col-sm-4 col-form-label">Authorization</label>
    <div class="col-sm-8">
      <textarea class="form-control"  cols="30" rows="10" formControlName = "authorization"></textarea>
    </div>
  </div>

  <div class="form-group pt-1 col-lg-3 box-botao">
    <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
  </div>

</form>
</div>

<h6>A resposta aparecerá abaixo:</h6>
<br><br>
{{resposta}}


