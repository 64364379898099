<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Solicitação de Lançamento de Notas"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome" class="col-sm-4 col-form-label">*Nome do Solicitante: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*E-mail do Solicitante: </label>
      <div class="col-sm-8 position-relative">
        <div class="dominioEmail">
          <input type="email" class="form-control" formControlName="email" (keyup)="validaCampoEmail()">
          <span>@nutrien.com</span>
        </div>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cnpj_da_empresa_nutrien" mask="00.000.000/0000-00">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.required && formulario.get('cnpj_da_empresa_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.cnpj && formulario.get('cnpj_da_empresa_nutrien')?.touched"
          mensagem="CNPJ inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="nome_empresa_nutrien" (change)="onEmpresaChange($event)">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_empresa_nutrien')?.errors?.required && formulario.get('nome_empresa_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.get('nome_empresa_nutrien')?.value == 'Agrichem'">
      <label for="grc" class="col-sm-4 col-form-label">GRC?</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='grc' value="Sim" class='radio-btn'
            (change)="onGrcChange($event)">&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='grc' value='Não' class='radio-btn'
            (change)="onGrcChange($event)">&nbsp;Não
        </label>
        <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('grc')?.errors?.required && formulario.get('grc')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem> -->
      </div>
    </div>
    <div class="form-group row" *ngIf="formulario.get('grc')?.value === 'Sim'">
      <label for="estado_de_origem" class="col-sm-4 col-form-label">*Estado de origem?</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="estado_de_origem" (change)="onEstadoOrigemChange($event)">
          <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('estado_de_origem')?.errors?.required && formulario.get('estado_de_origem')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>
    <div class="form-group row" *ngIf="formulario.get('grc')?.value === 'Sim'">
      <label for="estado_de_destino" class="col-sm-4 col-form-label">*Estado de destino?</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="estado_de_destino" (change)="onEstadoDestinoChange($event)">
          <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('estado_de_destino')?.errors?.required && formulario.get('estado_de_destino')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="cnpj_da_empresa_nutrien" (change)="onCnpjEmpresaNutrienChange($event)">
          <option *ngFor="let cnpj of listaEmpresasCnpjs" [value]="cnpj.cnpj">{{cnpj.cnpj}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.required && formulario.get('cnpj_da_empresa_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_empresa_nutrien" [attr.disabled]="true" placeholder="Selecione o CNPJ da empresa acima">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_empresa_nutrien')?.errors?.required && formulario.get('nome_empresa_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <!-- <div class="form-group row">
      <label for="risco_sacado" class="col-sm-4 col-form-label">Risco Sacado </label>
      <div class="col-sm-8">
        <input type="checkbox" class="form-check-input" formControlName="risco_sacado" value="Sim"
          (change)='riscoSacado=!riscoSacado' (change)="riscoSacadoValue($event)">
        <small class="form-check-label">Selecionar apenas se NF for relacionada a Risco Sacado. Antecipação de valores a
          fornecedores</small>
      </div>
    </div> -->

    <div class="form-group row">
      <!-- <div class="form-group row"  *ngIf="this.formulario.getRawValue().nome_empresa_nutrien!=''"> -->

      <label for="tipo_de_nota" class="col-sm-4 col-form-label">*Tipo de Nota </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tipo_de_nota" (change)="onTipoDeNotaChange($event)">
          <!-- <option *ngFor="let tipo of tiposDeNotas" [value]="tipo.tipo">{{tipo.tipo}}</option> -->
          <option value="">Selecione...</option>
          <option value="Aluguel">Aluguel</option>
          <option value="Ativo fixo (AFE)">Ativo fixo (AFE)</option>
          <option value="Benefícios">Benefícios</option>
          <option value="Cartório">Cartório</option>
          <option value="Contas de consumo (Luz, água, telefone, internet, gás)">Contas de consumo (Luz, água, telefone,
            internet, gás)</option>
          <option value="Correio">Correio</option>
          <option value="Devolução de Cliente">Devolução a Cliente</option>
          <option value="Escrituração - Não gera pagamento">Escrituração - Não gera pagamento</option>
          <option value="Frete/CTE">Frete/CTE</option>
          <option value="Guias/Imposto/Taxas">Guias/Imposto/Taxas</option>
          <option value="Importação">Importação</option>
          <option value="Incentivo - Doação">Incentivo - Doação</option>
          <option value="Industrialização">Industrialização</option>
          <option value="Jurídico">Jurídico</option>
          <option value="Material (DANFE)">Material (DANFE)</option>
          <option value="Nota de Crédito">Nota de Crédito</option>
          <option value="Outras NFs sem PO">Outras NFs sem PO</option>
          <option value="Pagamento de Autônomo">RPA</option>
          <option value="Sem valor fiscal">Sem valor fiscal</option>
          <option value="Serviço">Serviço</option>
        </select>

        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_nota')?.errors?.required && formulario.get('tipo_de_nota')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>

        <small class="col-sm-12" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">O fechamento do recolhimento do INSS ocorre até o dia 15,
          caso seja postada após esta data o pagamento e recolhimento do imposto será feito
          somente o mês subsequente.</small>

        <small class="col-sm-12" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
          Caso não tenha o template Formulário Cadastro Autônomo_E-Social, 
            <a href="./assets/modelode-formulrio-cadastro-autnomo-esocial.xlsx" target="_blank"> clique aqui</a>.
        </small>

        <!-- <small class="col-sm-12" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">Para fazer o download do modelo para preenchimento do pagamento de autônomo, <a href="">clique aqui</a>.</small> -->
      </div>
    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
      <!-- *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete' || this.formulario.getRawValue().tipo_de_nota=='CTE'"> -->

      <label for="qual_a_quantidade_de_cte" class="col-sm-4 col-form-label">*Qual a quantidade de CTE </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="qual_a_quantidade_de_cte">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('qual_a_quantidade_de_cte')?.errors?.required && formulario.get('qual_a_quantidade_de_cte')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Ativo fixo (AFE)'">
      <label for="n_mero_afe" class="col-sm-4 col-form-label">*Número AFE </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="n_mero_afe">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_afe')?.errors?.required && formulario.get('n_mero_afe')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'
      ||formulario.getRawValue().tipo_de_nota=='Aluguel' 
      ||formulario.getRawValue().tipo_de_nota=='Jurídico' 
      ||formulario.getRawValue().tipo_de_nota=='Devolução de Cliente'
      ||formulario.getRawValue().tipo_de_nota=='Nota de Crédito'&&formulario.getRawValue().nome_empresa_nutrien=='Agrichem'
      ||formulario.getRawValue().tipo_de_nota=='Nota de Crédito'&&formulario.getRawValue().nome_empresa_nutrien=='Nutrien'">
      <!-- *ngIf="formulario.getRawValue().tipo_de_nota ==='Reembolso' || formulario.getRawValue().tipo_de_nota =='Aluguel' || formulario.getRawValue().tipo_de_nota =='Guias'"> -->
      <label for="conta_contabil" class="col-sm-4 col-form-label">*Conta Contábil</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="conta_contabil">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('conta_contabil')?.errors?.required && formulario.get('conta_contabil')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="documento_do_fornecedor" class="col-sm-4 col-form-label">*Documento do Fornecedor</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='documento_do_fornecedor' value="CPF" class='radio-btn'
            (change)="onDocumentoChange($event)"
       >&nbsp;CPF
        </label>
        <label for="tipo" class='radio-inline' *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
          <input type="radio" formControlName='documento_do_fornecedor' value='CNPJ' class='radio-btn'
            (change)="onDocumentoChange($event)">&nbsp;CNPJ
        </label>
        <!-- <small *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">Ao selecionar CPF você será
          redirecionado para outro formulário.</small> -->
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('documento_do_fornecedor')?.errors?.required && formulario.get('documento_do_fornecedor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="formulario.getRawValue().documento_do_fornecedor=='CPF' || formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
      <label for="cpf_do_fornecedor" class="col-sm-4 col-form-label">*CPF Fornecedor</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cpf_do_fornecedor" mask='000.000.000-99'>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.required && formulario.get('cpf_do_fornecedor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.cpf && formulario.get('cpf_do_fornecedor')?.touched"
          mensagem="CPF inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().documento_do_fornecedor=='CNPJ'">
      <label for="cnpj_fornecedor" class="col-sm-4 col-form-label">*CNPJ Fornecedor</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="cnpj_fornecedor" mask="00.000.000/0000-00">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_fornecedor')?.errors?.required && formulario.get('cnpj_fornecedor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cnpj_fornecedor')?.errors?.cnpj && formulario.get('cnpj_fornecedor')?.touched"
          mensagem="CNPJ inválido"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="nome_do_fornecedor" class="col-sm-4 col-form-label">*Nome do Fornecedor</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_fornecedor">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_fornecedor')?.errors?.required && formulario.get('nome_do_fornecedor')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota != 'Aluguel'">
      <label for="nro_nota" class="col-sm-4 col-form-label">*Número da Nota</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nro_nota">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nro_nota')?.errors?.required && formulario.get('nro_nota')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
      <label for="parcela_do_aluguel" class="col-sm-4 col-form-label">*Parcela do aluguel</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="parcela_do_aluguel">
          <option *ngFor="let parcelas of parcelaAluguel" [value]="parcelas">{{parcelas}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('parcela_do_aluguel')?.errors?.required && formulario.get('parcela_do_aluguel')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
      <label for="m_s_do_aluguel" class="col-sm-4 col-form-label">*Mês do Aluguel</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="m_s_do_aluguel">
          <option *ngFor="let mes of mesAluguel" [value]="mes">{{mes}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('m_s_do_aluguel')?.errors?.required && formulario.get('m_s_do_aluguel')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
      <label for="ano_do_aluguel" class="col-sm-4 col-form-label">*Ano do aluguel</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="ano_do_aluguel">
          <option *ngFor="let ano of anoAluguel" [value]="ano">{{ano}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('ano_do_aluguel')?.errors?.required && formulario.get('ano_do_aluguel')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row">
      <label for="data_de_envio_de_nf" class="col-sm-4 col-form-label">*Data de emissão da NF</label>
      <div class="col-sm-8">
        <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_envio_de_nf">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.getRawValue().data_de_envio_de_nf > dataAtual"
          mensagem="Não é permitido data futura"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_envio_de_nf')?.errors?.required && formulario.get('data_de_envio_de_nf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="valor_total_da_nf" class="col-sm-4 col-form-label">*Valor Total da NF</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_total_da_nf" (paste)="pasteValorDecimal($event)"
          (keyup)="formataValorDecimal($event.target.value)" (click)="focusValorDecimal($event)"
          (contextmenu)="focusValorDecimal($event)" [value]="valorTotalNfAlterado">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_total_da_nf')?.errors?.required && formulario.get('valor_total_da_nf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="valor_total_da_nf" class="col-sm-4 col-form-label">*Valor Total da NF</label>
      <div class="col-sm-8">

        <input type="text" class="form-control" formControlName="valor_total_da_nf"
            (keyup)="formataValorDecimal($event.target.value)" [value]="valorTotalNfAlterado"> -->
    <!-- <small>Digite o valor da nota fiscal com as casas decimais. Não é necessário o uso de vírgula e não copie e cole utilizando o mouse.</small> -->

    <!-- <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_total_da_nf')?.errors?.required && formulario.get('valor_total_da_nf')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Industrialização'">

      <!-- *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete'"> -->
      <label for="nf_do_produto_original" class="col-sm-4 col-form-label">NF Do Produto Original </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="nf_do_produto_original" id="formFileMultiple" (change)="saveAnexoNfProdutoOriginal($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="nfProdutoOriginal.length!=0">
      <div class="card-header">Anexo - Nf Produto Original</div>
      <ul class="list-group list-group-flush" *ngFor="let file of nfProdutoOriginal">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNfProdutoOriginal(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row"
      *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
      <!-- *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete'"> -->

      <label for="n_mero_de_cte" class="col-sm-4 col-form-label">Número de CTE</label>
      <div class="col-sm-8 ">
        <input type="text" class="form-control mb-1" formControlName="n_mero_de_cte">
      </div>
    </div>

    <div class="form-group row" *ngIf="(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' || this.formulario.getRawValue().nome_empresa_nutrien=='Agrichem')
      && this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'">
      <!-- *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias'"> -->


      <label for="opex_e_capex" class="col-sm-4 col-form-label">OPEX ou Capex?</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='opex_e_capex' value="Opex" class='radio-btn'>&nbsp;OPEX
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='opex_e_capex' value='Capex' class='radio-btn'>&nbsp;CAPEX
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('opex_e_capex')?.errors?.required && formulario.get('opex_e_capex')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota=='Importação'">
      <label for="tipo_de_importa_o" class="col-sm-4 col-form-label">Tipo de Importação</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='tipo_de_importa_o' value="Serviço" class='radio-btn'>&nbsp;Serviço
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='tipo_de_importa_o' value='Material' class='radio-btn'>&nbsp;Material
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_importa_o')?.errors?.required && formulario.get('tipo_de_importa_o')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="tipo_de_pagamento" class="col-sm-4 col-form-label">Tipo de Pagamento é Boleto? </label>
      <div class="col-sm-8">
        <input type="radio" class="form-check-input" formControlName="tipo_de_pagamento" value="Boleto">
        <small class="form-check-label"> Marcar se tipo de pagamento for boleto</small>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="formulario.getRawValue().tipo_de_nota=='Frete/CTE'|| formulario.getRawValue().tipo_de_nota=='Serviço'">
      <label for="informacoes_complementares_da_po" class="col-sm-4 col-form-label">Informações Complementares da PO
      </label>
      <div class="col-sm-8">
        <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
            class="form-check-input" formControlName="informacoes_complementares_da_po"
            (change)="custoComplementar=!custoComplementar" (change)="inserirNoArray($event)"
            value='Custo planejado?'>&nbsp;&nbsp;&nbsp;&nbsp; Custo Planejado? </label>

        <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
            class="form-check-input" formControlName="informacoes_complementares_da_po"
            (change)="debitoPosterior=!debitoPosterior" (change)="inserirNoArray($event)"
            value='Debito posterior?'>&nbsp;&nbsp;&nbsp;&nbsp; Débito Posterior</label>
      </div>
    </div>

    <div class="form-group row"
      *ngIf="(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete/CTE' && custoComplementar)||
    (this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Serviço' && custoComplementar)">
      <label for="tipo_de_custo_planejado" class="col-sm-4 col-form-label">Tipo de Custo Planejado</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='tipo_de_custo_planejado' value="Armazenagem"
            class='radio-btn'>&nbsp;Armazenagem
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='tipo_de_custo_planejado' value='Capatazia'
            class='radio-btn'>&nbsp;Capatazia
        </label>

        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='tipo_de_custo_planejado' value='Frete' class='radio-btn'>&nbsp;Frete
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_custo_planejado')?.errors?.required && formulario.get('tipo_de_custo_planejado')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <div class="form-group row">
        <label for="nota_com_rasteio_de_valores" class="col-sm-4 col-form-label">Nota com rateio de valores? </label>
        <div class="col-sm-8">
            <label class='radio-inline'>
              <input type="radio" formControlName='nota_com_rasteio_de_valores' value="Sim" class='radio-btn'
                (change)="notaComRateiosValue($event)">&nbsp;Sim
            </label>

            <label class='radio-inline'>
              <input type="radio" formControlName='nota_com_rasteio_de_valores' value="Não" class='radio-btn'
                (change)="notaComRateiosValue($event)">&nbsp;Não
            </label>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().nota_com_rasteio_de_valores=='Sim'">
        <label for="arquivo_de_rateio" class="col-sm-4 col-form-label">Arquivo de Rateio </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="arquivo_de_rateio" id="formFileMultiple" (change)="saveArquivoRateio($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosDeRateio.length!=0">
        <div class="card-header">Anexo - Arquivo de Rateio</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosDeRateio">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoRateio(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_de_vencimento_da_nota" class="col-sm-4 col-form-label">*Data de Vencimento da Nota </label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento_da_nota"
          (blur)='onDataDeVencimentoChange($event)'>

        <small class="col-sm-12">Os pagamentos devem ser conforme a Política de Pagamentos da Nutrien</small>
        <!-- <small class="col-sm-12 atencao">Atenção<br>
          Aproveitamos para reforçar a política de pagamentos da Nutrien.</small>
        <small class="col-sm-12">Pagamentos são executados apenas nos dias 15 e 25 de cada mês, seguindo as regras
          abaixo:</small>
        <small class="col-sm-12">
          <ul class="col-sm-12">
            <li>• Para pagamentos no dia 15, as notas devem ser postadas no portal NBS até no máximo dia 05. Se postadas
              depois do dia 05, a nota será paga apenas no dia 25</li>
            <li>• Para pagamentos no dia 25, as notas devem ser postadas no portal NBS até no máximo dia 15. Se postadas
              depois do dia 15, a nota será paga apenas no dia 15 do mês seguinte.</li>
          </ul>
        </small> -->

        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_vencimento_da_nota')?.errors?.required && formulario.get('data_de_vencimento_da_nota')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>

      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().nome_empresa_nutrien =='Agrichem'">
      <label for="departamento" class="col-sm-4 col-form-label">*Departamento </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="departamento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('departamento')?.errors?.required && formulario.get('departamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div
      *ngIf="!(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas')">
      <!-- *ngIf="!(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias')"> -->





      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Devolução de Cliente'||
      this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'||
      this.formulario.getRawValue().tipo_de_nota=='Jurídico'||
      this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito'||
      this.formulario.getRawValue().tipo_de_nota=='Outras NFs sem PO'">
        <label for="numero_po" class="col-sm-4 col-form-label">Número de pedido</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_po">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('numero_po')?.errors?.required && formulario.get('numero_po')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Aluguel'||
      this.formulario.getRawValue().tipo_de_nota=='Ativo fixo (AFE)'||
      this.formulario.getRawValue().tipo_de_nota=='Benefícios'||
      this.formulario.getRawValue().tipo_de_nota=='Contas de consumo (Luz, água, telefone, internet, gás)'||
      this.formulario.getRawValue().tipo_de_nota=='Correio'||
      this.formulario.getRawValue().tipo_de_nota=='Escrituração - Não gera pagamento'||
      this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'||
      this.formulario.getRawValue().tipo_de_nota=='Importação'||
      this.formulario.getRawValue().tipo_de_nota=='Incentivo - Doação'||
      this.formulario.getRawValue().tipo_de_nota=='Industrialização'||
      this.formulario.getRawValue().tipo_de_nota=='Material (DANFE)'||
      this.formulario.getRawValue().tipo_de_nota=='Sem valor fiscal'||
      this.formulario.getRawValue().tipo_de_nota=='Serviço'">

        <label for="numero_po" class="col-sm-4 col-form-label">*Número de pedido</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_po">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('numero_po')?.errors?.required && formulario.get('numero_po')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>






    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="quantidade_de_itens_do_pedido" class="col-sm-4 col-form-label">Quantidade de itens do pedido</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="quantidade_de_itens_do_pedido">
          <option *ngFor="let quantidade of quantidadeDeItens" [value]="quantidade.quantidade">{{quantidade.quantidade}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quantidade_de_itens_do_pedido')?.errors?.required && formulario.get('quantidade_de_itens_do_pedido')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="formulario.getRawValue().quantidade_de_itens_do_pedido =='De 1 a 10 Itens'">
      <label for="numero_do_item_da_po" class="col-sm-4 col-form-label">Número do item da PO </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="numero_do_item_da_po"></textarea>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().quantidade_de_itens_do_pedido =='Acima de 10 Itens'">
      <div class="form-group row">
        <label for="lista_de_itens_da_po" class="col-sm-4 col-form-label">Lista de itens da PO </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="lista_de_itens_da_po" id="formFileMultiple" (change)="saveListaDeItensDaPO($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="listaDeItensDaPo.length!=0">
        <div class="card-header">Anexo - Lista de Itens da PO</div>
        <ul class="list-group list-group-flush" *ngFor="let file of listaDeItensDaPo">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeListaDeItensDaPO(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="centro_de_custo" class="col-sm-4 col-form-label">Centro de custo</label>
      <div class="col-sm-8 ">
        <input type="text" class="form-control mb-1" formControlName="centro_de_custo">
        <small class="text-observacao-centro-de-custo">*Sempre preencher se a nota selecionada não tiver ordem de
          compra/serviço.</small>
      </div>
    </div>


    <div class="form-group row">
      <label for="confirma_entrega_ou_prestacao" class="col-sm-4 col-form-label prestacao_servico">*Confirma entrega ou
        prestação de serviço?</label>
      <div class="col-sm-8">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='confirma_entrega_ou_prestacao' value="Sim" class='radio-btn'
            (change)="onConfirmaEntregaChange($event)">&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='confirma_entrega_ou_prestacao' value="Não se aplica" class='radio-btn'
            (change)="onConfirmaEntregaChange($event)">&nbsp;Não se aplica
        </label>
        <small class="obs-confirma-entrega">*Declaro que ao solicitar o pagamento desta nota, confirmo o recebimento do
          produto e/ou a prestação do serviço.</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('confirma_entrega_ou_prestacao')?.errors?.required && formulario.get('confirma_entrega_ou_prestacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="formulario.getRawValue().confirma_entrega_ou_prestacao=='Não se aplica'">
      <label for="motivo_negacao" class="col-sm-4 col-form-label">*Descreva o motivo:</label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_negacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_negacao')?.errors?.required && formulario.get('motivo_negacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_negacao')?.errors?.minlength && formulario.get('motivo_negacao')?.touched"
          mensagem="Obrigatório no mínimo 5 caracteres"></app-mensagem>
      </div>
    </div>




    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="anexo_nota_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota</label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_nota_ou_boleto" id="formFileMultiple" (change)="saveAnexoNota($event)"
          multiple="multiple">
        <small class="text-observacao-centro-de-custo"
          *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">*Obrigatório incluir somente os Dactes (CT-Es)
          em um único arquivo PDF..</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexo_nota_ou_boleto')?.errors?.required && formulario.get('anexo_nota_ou_boleto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="notas.length!=0">
      <div class="card-header">Anexo - Nota ou Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of notas">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNota(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
      <label for="certeza_que_incluiu_o_arquivo_conforme_orienta_o" class="col-sm-4 col-form-label">Arquivo Incluído
        conforme orientação?</label>
      <div class="col-sm-8">
        <input type="checkbox" class="form-check-input"
          formControlName="certeza_que_incluiu_o_arquivo_conforme_orienta_o" value="Sim"
          (change)='dacteCorreto=!dacteCorreto' (change)="dacteCorretoValue($event)">
      </div>
    </div>


    <div class="form-group row"
    *ngIf="formulario.getRawValue().tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)'
    ||formulario.getRawValue().tipo_de_nota == 'Guias/Imposto/Taxas' 
    || formulario.getRawValue().tipo_de_nota == 'Correio' 
    || formulario.getRawValue().tipo_de_nota == 'Cartório'
    || formulario.getRawValue().tipo_de_nota == 'Jurídico'
    || formulario.getRawValue().tipo_de_nota == 'Aluguel'
    || formulario.getRawValue().tipo_de_nota == 'Benefícios'">
      <label for="anexo_boleto" class="col-sm-4 col-form-label">Anexo Boleto </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_boleto"
          id="formFileMultiple" (change)="saveAnexoBoleto($event)" multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="boletos.length!=0">
      <div class="card-header">Anexo - Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of boletos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileBoleto(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo XML </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_xml"
          id="formFileMultiple" (change)="saveAnexoXML($event)" multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="xml.length!=0">
      <div class="card-header">Anexo - Nota ou Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of xml">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileXML(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
      <label for="anexo_documentacao_suporte" class="col-sm-4 col-form-label">Anexo Documentação Suporte </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_documentacao_suporte" id="formFileMultiple"
          (change)="saveAnexoDocumentacaoSuporte($event)" multiple="multiple">
        <small *ngIf="this.formulario.getRawValue().tipo_de_nota=='Aluguel'">*Para o tipo de nota "ALUGUEL" O
          preenchimento desse campo é obrigatório.</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexo_documentacao_suporte')?.errors?.required && formulario.get('anexo_documentacao_suporte')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentacaoSuporte.length!=0">
      <div class="card-header">Anexo - Documentação Suporte</div>
      <ul class="list-group list-group-flush" *ngFor="let file of documentacaoSuporte">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFilDocumentacaoSuporte(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <!-- As linhas abaixo foram comentadas de acordo com a demanda 597461824 -->

    <!-- <div class="form-group row">
      <label for="nota_para_pagamento_fora_da_politica" class="col-sm-4 col-form-label">*Nota para pagamento fora da
        política</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value="Sim"
            class='radio-btn'>&nbsp;SIM
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value='Não'
            class='radio-btn'>&nbsp;NÃO
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('nota_para_pagamento_fora_da_politica')?.errors?.required && formulario.get('nota_para_pagamento_fora_da_politica')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->



    <!-- <div class="form-group row">
      <label for="nota_para_pagamento_urgente" class="col-sm-4 col-form-label">*Nota para pagamento urgente</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='nota_para_pagamento_urgente' value="Sim" class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;SIM
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='nota_para_pagamento_urgente' value='Não' class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;NÃO
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('nota_para_pagamento_urgente')?.errors?.required && formulario.get('nota_para_pagamento_urgente')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->


    <!-- <div class="form-group row" *ngIf="formulario.getRawValue().nota_para_pagamento_urgente=='Sim'">
      <label for="motivo_da_excecao" class="col-sm-4 col-form-label">*Motivo de Exceção </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_excecao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('motivo_da_excecao')?.errors?.required && formulario.get('motivo_da_excecao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div> -->



    <!-- *************** CAMPOS NOVOS LANC - IPE *********** -->


    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação sobre o documento </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>

    <div *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
      <div class="form-group row">
        <label for="anexo_para_pagamento_de_aut_nomo" class="col-sm-4 col-form-label">*Anexo para pagamento de
          autônomo</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexo_para_pagamento_de_aut_nomo" id="anexo_para_pagamento_de_aut_nomo"
            (change)="save_anexo_para_pagamento_de_aut_nomo($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('anexo_para_pagamento_de_aut_nomo')?.errors?.required && formulario.get('anexo_para_pagamento_de_aut_nomo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="list_anexo_para_pagamento_de_aut_nomo.length!=0">
        <div class="card-header">Anexo - Pagamento de Autônomo</div>
        <ul class="list-group list-group-flush" *ngFor="let file of list_anexo_para_pagamento_de_aut_nomo">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile'
                (click)="remove_anexo_para_pagamento_de_aut_nomo(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>


    <!-- <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
      <label for="registroPrefeitura" class="col-sm-4 col-form-label">Contém registro na prefeitura?</label>
      <div class="col-sm-8">
        <label for="cont_m_registro_na_prefeitura" class='radio-inline'>
          <input type="radio" formControlName='cont_m_registro_na_prefeitura' value="Sim" class='radio-btn'
            (change)="onContemRegistroPrefeituraChange($event)">&nbsp;Sim
        </label>
        <label for="cont_m_registro_na_prefeitura" class='radio-inline'>
          <input type="radio" formControlName='cont_m_registro_na_prefeitura' value='Não' class='radio-btn'
            (change)="onContemRegistroPrefeituraChange($event)">&nbsp;Não
        </label>
      </div>
    </div> -->


    <!-- <div *ngIf="formulario.getRawValue().cont_m_registro_na_prefeitura == 'Sim'">
      <div class="form-group row">
        <label for="anexar_nota_emitida_na_prefeitura" class="col-sm-4 col-form-label">Anexo nota emitido
          prefeitura</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexar_nota_emitida_na_prefeitura" id="anexar_nota_emitida_na_prefeitura"
            (change)="save_anexar_nota_emitida_na_prefeitura($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('anexar_nota_emitida_na_prefeitura')?.errors?.required && formulario.get('anexar_nota_emitida_na_prefeitura')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="list_anexar_nota_emitida_na_prefeitura.length!=0">
        <div class="card-header">Anexo - Nota Prefeitura</div>
        <ul class="list-group list-group-flush" *ngFor="let file of list_anexar_nota_emitida_na_prefeitura">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile'
                (click)="remove_anexar_nota_emitida_na_prefeitura(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div> -->

    <!-- <div *ngIf="formulario.getRawValue().cont_m_registro_na_prefeitura == 'Não'">
      <div class="form-group row">
        <label for="anexar_registro_do_pagamento_de_aut_nomo" class="col-sm-4 col-form-label">Anexo registro de
          pagamento de autônomo</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexar_registro_do_pagamento_de_aut_nomo" id="anexar_registro_do_pagamento_de_aut_nomo"
            (change)="saveRegistro($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.errors?.required && formulario.get('anexar_registro_do_pagamento_de_aut_nomo')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="registros.length!=0">
        <div class="card-header">Anexo registro de pagamento de autônomo</div>
        <ul class="list-group list-group-flush" *ngFor="let file of registros">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile'
                (click)="removeFileRegistro(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div> -->
<!-- 
    <div class="mensagem-bottom" style="width: 700px; text-align: left;">
      <h6 class="text-center">Reforçamos que o  prazo final para o envio de notas fiscais foi em <span class="bottom-destaque">15/12/23</span>. Exceções serão tratadas pontualmente pelo Time de Posting.
        O fechamento financeiro ocorrerá de 22/12/23 a 01/01/24, durante este período, não serão escrituradas/lançadas NFs nos ERPs. <br>Para efeito de provisão de NFs, cards que apresentarem alguma pendência e não puderem ser lançados até 21/12/23, mas que contenham informações mandatórias como: pedido aprovado, centro de custo, conta contábil, AFE e NF – poderão ser provisionados, desde que todas as informações necessárias estejam corretas e disponíveis na abertura do card.</h6>
    </div> -->

   



    <div class="form-group pt-1 col-lg-3 box-botao">
      <!-- <button type="submit" class="btn botao" [disabled]="formulario.invalid || this.formulario.getRawValue().documento_do_fornecedor=='CPF'">Enviar</button> -->
      <button type="submit" class="btn botao"
      [disabled]="formulario.invalid || formulario.getRawValue().data_de_envio_de_nf > dataAtual">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="payment"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>