export const impostos = [
  {
    nome:''
  },
  {
    nome:'1-ICMS - Operação própria '
  },
  {
    nome:'2-ICMS-ST - Substituição Tributária'
  },
  {
    nome:'3-DIFAL -Diferencial de alíquota '
  },
  {
    nome:'4- EC87 -Consumidor final, não Contribuinte do ICMS '
  },
  {
    nome:'5- IPI - Imposto sobre Produtos Industrializados'
  },
  {
    nome:'6- PIS e a COFINS'
  },
  {
    nome:'7- ISSQ -Imposto Sobre Serviços de Qualquer Natureza'
  },
  {
    nome:'8- IRRF - Imposto de Renda Retido na Fonte'
  },
  {
    nome:'9- CSRF - Contribuições sociais retidas na fonte'
  },
  {
    nome:'10-INSS - Instituto Nacional do Seguro Social '
  },
  {
    nome:'11- IRPJ - Imposto de Renda Pessoa Jurídica'
  },
  {
    nome:'12- CSLL - Contribuição Social sobre Lucro Líquido'
  },
  {
    nome:'13-CIDE - Contribuição de Intervenção no Domínio Econômico'
  },
  {
    nome:'14- ICMS De Frete (Autônomos) '
  },
  {
    nome:'15- Funrural- Fundo de Assistência ao Trabalhador Rural'
  },
  {
    nome:'16- ICMS-ST Transportadora Não Credenciada '
  },
  {
    nome:'17- PROTEGE/FET – TO'
  },
  {
    nome:'18- DARF - IOF - Imposto sobre Operações Financeiras'
  },
  {
    nome:'19- Redarf'
  },
  {
    nome:'20- FASE MT'
  },
  {
    nome:'21- Lançamentos regulares de assuntos no LTO'
  },
  {
    nome:'22- Taxa'
  },
  {
    nome:'23- PERDCOMP (comum)'
  },
  {
    nome:'24-PERDCOMP (Web)'
  },
  {
    nome:'25- Outros impostos e taxas que não estão discriminados na lista'
  }
]
