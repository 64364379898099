export const emailNbs = [
    {
      nome:''
    },
    {
      nome:'agrichem.fiscal@accenture.com'
    },
    {
      nome:'agrosema.fiscal@accenture.com'
    },
    {
      nome:'nutrien.fiscal@accenture.com'
    },
    {
      nome:'tecagro.fiscal@accenture.com'
    }
  ]
  