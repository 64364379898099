export const formas_pagamento = [
  {
    nome:''
  },
  {
    nome: 'Depósito'
  },
  {
    nome: 'Boleto'
  },
  {
    nome: 'Guia de Imposto'
  }
]
