<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="FAQ">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Tire suas dúvidas"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="seu_nome" class="col-sm-4 col-form-label">*Seu Nome</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="seu_nome">
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('seu_nome')?.errors?.required && formulario.get('seu_nome')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>

      </div>


    </div>

    <div class="form-group row">
      <label for="tel" class="col-sm-4 col-form-label">Telefone de contato</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="tel" mask="(00)0000-0000||(00)00000-0000" [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('tel')?.errors?.required && formulario.get('tel')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*E-mail</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="melhor_forma_de_contato" class="col-sm-4 col-form-label">*Melhor forma de contato:</label>
      <div class="col-sm-8">
        <label for="tipo" class=' radio-inline'>
          <input type="radio" formControlName='melhor_forma_de_contato' value="Telefone"

            class='radio-btn'>&nbsp;Telefone
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='melhor_forma_de_contato' value='Email'
            class='radio-btn'>&nbsp;E-mail
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('melhor_forma_de_contato')?.errors?.required && formulario.get('melhor_forma_de_contato')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="area" class="col-sm-4 col-form-label" >*Area</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="area" (change)="onAreaChange($event)">
          <option *ngFor="let area of areas" [value]="area.nome">{{area.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('area')?.errors?.required && formulario.get('area')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().area =='Contabilidade'">
      <label for="fluxo_contabilidade" class="col-sm-4 col-form-label">Fluxo Contabilidade</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="fluxo_contabilidade">
          <option *ngFor="let fluxo of fluxoContabilidade" [value]="fluxo.nome">{{fluxo.nome}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().area =='Fiscal'">
      <label for="fluxo_fiscal" class="col-sm-4 col-form-label">Fluxo Fiscal</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="fluxo_fiscal">
          <option *ngFor="let fluxo of fluxoFiscal" [value]="fluxo.nome">{{fluxo.nome}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().area =='Lojas Centros de Experiência'">
      <label for="fluxo_lojas" class="col-sm-4 col-form-label">Fluxo Lojas Centro de Experiência</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="fluxo_lojas">
          <option *ngFor="let fluxo of fluxoLojas" [value]="fluxo.nome">{{fluxo.nome}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().area =='Pagamentos'">
      <label for="fluxo_pagamentos" class="col-sm-4 col-form-label">Fluxo Pagamentos</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="fluxo_pagamentos">
          <option *ngFor="let fluxo of fluxoPagamento" [value]="fluxo.nome">{{fluxo.nome}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().area =='Compras de Serviços e Materiais Indiretos'">
      <label for="fluxo_compras" class="col-sm-4 col-form-label">Fluxo Compras de Serviços e Materiais Indiretos</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="fluxo_compras">
          <option *ngFor="let fluxo of fluxoCompras" [value]="fluxo.nome">{{fluxo.nome}}</option>
        </select>
      </div>
    </div>


    <div class="form-group row">
      <label for="duvida" class="col-sm-4 col-form-label">*Dúvida / Info: </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="duvida"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('duvida')?.errors?.required && formulario.get('duvida')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>







    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
