export const pipe_fields_id = [
  "e_mail_do_solicitante",
  "nome_da_empresa",
  "c_d_sap",
  "torre",
  "per_odo_da_documenta_o",
  "qual_documenta_o_solicitada",
  "anexar_solicita_o_da_auditoria",
  "observa_o"
]
