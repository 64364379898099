import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-atendimento-de-duvidas',
  templateUrl: './atendimento-de-duvidas.component.html',
  styleUrls: ['./atendimento-de-duvidas.component.css']
})
export class AtendimentoDeDuvidasComponent implements OnInit {

  fields: FormField[] = [];

  constructor() {

  }

  ngOnInit(): void { // atendimento de duvidas com AD feat-737500512

  this.fields = [
    {
      "type": "text",
      "name": "nome_do_solicitante",
      "label": "Nome do solicitante"
    },
    {
      "type": "email",
      "name": "e_mail_do_solicitante",
      "label": "E-mail do solicitante"
    },
    {
      "type": "cpf",
      "name": "cpf",
      "label": "CPF",
      "validators": Validators.required
    },
    {
      "type": "select",
      "name": "empresa",
      "label": "Empresa",
      "validators": Validators.required,
      "select": [
        {
          "type": "option",
          "name": "AGRICHEM DO BRASIL SA",
          "value": "AGRICHEM DO BRASIL SA"
        },
        {
          "type": "option",
          "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
          "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
        },
        {
          "type": "option",
          "name": "CONFIANÇA AGRÍCOLA LTDA",
          "value": "CONFIANÇA AGRÍCOLA LTDA"
        },
        {
          "type": "option",
          "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
          "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
        },
        // {
        //   "type": "option",
        //   "name": "MARCA AGRO MERCANTIL LTDA",
        //   "value": "MARCA AGRO MERCANTIL LTDA",
        // },
        {
          "type": "option",
          "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
          "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
        },
        {
          type: "option",
          name: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
          value: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
        },
        {
          "type": "option",
          "name": "SEMENTES GOIÁS LTDA",
          "value": "SEMENTES GOIÁS LTDA"
        },
        {
          "type": "option",
          "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
          "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
        }
      ]
    },
    {
      "type": "select",
      "name": "processo",
      "label": "Processo",
      "validators": Validators.required,
      "select": [
        // {
        //   "type": "option",
        //   "name": "Admissão",
        //   "value": "Admissão",
        //   "fields": [
        //     {
        //       "type": "select",
        //       "name": "admiss_o",
        //       "label": "Tipos de dúvidas admissão",
        //       "validators": Validators.required,
        //       "select": [
        //         {
        //           "type": "option",
        //           "name": "Alteração de dados de admissão",
        //           "value": "Alteração de dados de admissão",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Cancelamento",
        //           "value": "Cancelamento",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Nova admissão",
        //           "value": "Nova admissão",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   "type": "option",
        //   "name": "Alteração cadastral",
        //   "value": "Alteração cadastral",
        //   "fields": [
        //     {
        //       "type": "select",
        //       "name": "altera_o_cadastral",
        //       "label": "Tipos de dúvidas alteração cadastral",
        //       "validators": Validators.required,
        //       "select": [
        //         {
        //           "type": "option",
        //           "name": "Alteração de centro de custo",
        //           "value": "Alteração de centro de custo",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Medidas disciplinares",
        //           "value": "Medidas disciplinares",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          "type": "option",
          "name": "Benefícios",
          "value": "Benefícios",
          "fields": [
            {
              "type": "select",
              "name": "benef_cios",
              "label": "Tipos de dúvidas benefícios",
              "validators": Validators.required,
              "select": [
                {
                  "type": "option",
                  "name": "Assistência médica",
                  "value": "Assistência médica",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Assistência odontológica",
                  "value": "Assistência odontológica",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "PAE - Programa de assistência ao empregado",
                  "value": "PAE - Programa de assistência ao empregado",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Previdência privada",
                  "value": "Previdência privada",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Seguro de vida",
                  "value": "Seguro de vida",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Veículo executivo",
                  "value": "Veículo executivo",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "VR/VA",
                  "value": "VR/VA",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "VT",
                  "value": "VT",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Outros",
                  "value": "Outros",
                  "fields": [
                    {
                      "type": "text",
                      "name": "tipos_de_d_vidas_benef_cios_outros",
                      "label": "Tipo de dúvida benefícios outros",
                      "validators": Validators.required,
                    },
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "type": "option",
          "name": "Cadastro",
          "value": "Cadastro",
          "fields": [
            {
              "type": "select",
              "name": "cadastro",
              "label": "Tipos de dúvidas cadastro",
              "validators": Validators.required,
              "select": [
                {
                  "type": "option",
                  "name": "Declarações",
                  "value": "Declarações",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Dados cadastrais",
                  "value": "Dados cadastrais",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                }
              ]
            }
          ]
        },
        // {
        //   "type": "option",
        //   "name": "Rescisão",
        //   "value": "Rescisão",
        //   "fields": [
        //     {
        //       "type": "text",
        //       "name": "tipo_de_d_vida_rescis_o",
        //       "label": "Tipo de dúvida rescisão",
        //       "validators": Validators.required
        //     }
        //   ]
        // },
        {
          "type": "option",
          "name": "Férias",
          "value": "Férias",
          "fields": [
            {
              "type": "select",
              "name": "f_rias",
              "label": "Tipos de dúvidas férias",
              "validators": Validators.required,
              "select": [
                // {
                //   "type": "option",
                //   "name": "Agendamento de férias fora do prazo",
                //   "value": "Agendamento de férias fora do prazo",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                {
                  "type": "option",
                  "name": "Alteração do período de Férias",
                  "value": "Alteração do período de Férias",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                // {
                //   "type": "option",
                //   "name": "Cancelamento do período de Férias",
                //   "value": "Cancelamento do período de Férias",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                {
                  "type": "option",
                  "name": "Como programar férias",
                  "value": "Como programar férias",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Consulta de férias programadas",
                  "value": "Consulta de férias programadas",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Documentação e recibo de pagamento",
                  "value": "Documentação e recibo de pagamento",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "type": "option",
          "name": "Folha de pagamento",
          "value": "Folha de pagamento",
          "fields": [
            {
              "type": "select",
              "name": "folha_de_pagamento",
              "label": "Tipos de dúvidas folha de pagamento",
              "validators": Validators.required,
              "select": [
                {
                  "type": "option",
                  "name": "13º Salário",
                  "value": "13º Salário",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                // {
                //   "type": "option",
                //   "name": "Acordo coletivo / dissidio/ contribuição sindical",
                //   "value": "Acordo coletivo / dissidio/ contribuição sindical",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                {
                  "type": "option",
                  "name": "Adiantamento quinzenal",
                  "value": "Adiantamento quinzenal",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                // {
                //   "type": "option",
                //   "name": "Auditoria externa",
                //   "value": "Auditoria externa",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                // {
                //   "type": "option",
                //   "name": "Demonstrativo de pagamento",
                //   "value": "Demonstrativo de pagamento",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                // {
                //   "type": "option",
                //   "name": "Documentação auditoria interna / fiscalizações",
                //   "value": "Documentação auditoria interna / fiscalizações",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                {
                  "type": "option",
                  "name": "Documentação para cadastro de aposentadoria",
                  "value": "Documentação para cadastro de aposentadoria",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                // {
                //   "type": "option",
                //   "name": "Documentação para processo jurídico",
                //   "value": "Documentação para processo jurídico",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                {
                  "type": "option",
                  "name": "Cálculo de pagamento",
                  "value": "Cálculo de pagamento",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Informações controladoria / tesouraria",
                  "value": "Informações controladoria / tesouraria",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Notificação do cadastro de aposentadoria",
                  "value": "Notificação do cadastro de aposentadoria",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Ofício de pensão alimentícia",
                  "value": "Ofício de pensão alimentícia",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Pagamento de RPA (Autônomos)",
                  "value": "Pagamento de RPA (Autônomos)",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Pagamento não recebido",
                  "value": "Pagamento não recebido",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "PLR / Premiação",
                  "value": "PLR / Premiação",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Recolhimento sindical",
                  "value": "Recolhimento sindical",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                // {
                //   "type": "option",
                //   "name": "Relatórios",
                //   "value": "Relatórios",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // },
                // {
                //   "type": "option",
                //   "name": "Solicitação de registro funcional",
                //   "value": "Solicitação de registro funcional",
                //   "fields": [
                //     {
                //       "type": "attachment",
                //       "name": "documenta_o_e_ou_anexo",
                //       "label": "Documentação e/ou Anexo"
                //     }
                //   ]
                // }
              ]
            }
          ]
        },
        {
          "type": "option",
          "name": "Informe de Rendimentos",
          "value": "Informe de Rendimentos",
          "fields": [
            {
              "type": "attachment",
              "name": "documenta_o_e_ou_anexo",
              "label": "Documentação e/ou Anexo"
            }
          ]
        },
        // {
        //   "type": "option",
        //   "name": "Instrumentos coletivos",
        //   "value": "Instrumentos coletivos",
        //   "fields": [
        //     {
        //       "type": "select",
        //       "name": "instrumentos_coletivos",
        //       "label": "Tipos de dúvidas instrumentos coletivos",
        //       "validators": Validators.required,
        //       "select": [
        //         {
        //           "type": "option",
        //           "name": "Ajustes de benefícios CCT",
        //           "value": "Ajustes de benefícios CCT",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Alteração salarial",
        //           "value": "Alteração salarial",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Cálculo de reajuste salarial",
        //           "value": "Cálculo de reajuste salarial",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Cálculo de reajuste salarial - desligados",
        //           "value": "Cálculo de reajuste salarial - desligados",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Criação de rubricas para manutenção de sindicatos",
        //           "value": "Criação de rubricas para manutenção de sindicatos",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Outros",
        //           "value": "Outros",
        //           "fields": [
        //             {
        //               "type": "text",
        //               "name": "outros",
        //               "label": "Outros",
        //               "validators": Validators.required
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          "type": "option",
          "name": "Medicina do trabalho",
          "value": "Medicina do trabalho",
          "fields": [
            {
              "type": "select",
              "name": "medicina_do_trabalho",
              "label": "Tipos de dúvidas medicina do trabalho",
              "validators": Validators.required,
              "select": [
                {
                  "type": "option",
                  "name": "Afastamento INSS > 15 dias",
                  "value": "Afastamento INSS > 15 dias",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Atestado médico",
                  "value": "Atestado médico",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "CIPA",
                  "value": "CIPA",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Licença maternidade",
                  "value": "Licença maternidade",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Licença médica < 15 dias",
                  "value": "Licença médica < 15 dias",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Licença paternidade",
                  "value": "Licença paternidade",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Outras licenças",
                  "value": "Outras licenças",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "PPP - Perfil profissiográfico previdenciário",
                  "value": "PPP - Perfil profissiográfico previdenciário",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Retorno do colaborador",
                  "value": "Retorno do colaborador",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                }
              ]
            }
          ]
        },
        // {
        //   "type": "option",
        //   "name": "Movimentações",
        //   "value": "Movimentações",
        //   "fields": [
        //     {
        //       "type": "select",
        //       "name": "movimenta_es",
        //       "label": "Tipos de dúvidas movimentações",
        //       "validators": Validators.required,
        //       "select": [
        //         {
        //           "type": "option",
        //           "name": "Ajuste de faixa salarial ",
        //           "value": "Ajuste de faixa salarial ",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Promoção",
        //           "value": "Promoção",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         },
        //         {
        //           "type": "option",
        //           "name": "Transferência",
        //           "value": "Transferência",
        //           "fields": [
        //             {
        //               "type": "attachment",
        //               "name": "documenta_o_e_ou_anexo",
        //               "label": "Documentação e/ou Anexo"
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          "type": "option",
          "name": "Ponto eletrônico",
          "value": "Ponto eletrônico",
          "fields": [
            {
              "type": "select",
              "name": "ponto",
              "label": "Tipos de dúvidas ponto eletrônico",
              "validators": Validators.required,
              "select": [
                {
                  "type": "option",
                  "name": "Ajuste no ponto / Tratamento ponto fora do prazo",
                  "value": "Ajuste no ponto / Tratamento ponto fora do prazo",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "evid_ncia",
                      "label": "Evidência",
                      "validators": Validators.required
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Alteração / Criação de jornada de trabalho",
                  "value": "Alteração / Criação de jornada de trabalho",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Declaração de comparecimento / Acompanhamento",
                  "value": "Declaração de comparecimento / Acompanhamento",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Erro na leitura facial / Biometria",
                  "value": "Erro na leitura facial / Biometria",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "evid_ncia",
                      "label": "Evidência",
                      "validators": Validators.required
                    }
                  ]
                },
                {
                  "type": "option",
                  "name": "Lançamento de atestados e declarações",
                  "value": "Lançamento de atestados e declarações",
                  "fields": [
                    {
                      "type": "attachment",
                      "name": "documenta_o_e_ou_anexo",
                      "label": "Documentação e/ou Anexo"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "textArea",
      "name": "descri_o",
      "label": "Descrição",
      "validators": Validators.required
    },
    {
      "type": "buttonSubmit",
      "name": "Enviar"
    }
  ]
  }

}
