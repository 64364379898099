import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormField } from 'src/app/componentes/formMat/form.component';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-rescisao',
  templateUrl: './rescisao.component.html',
  styleUrls: ['./rescisao.component.css']
})
export class RescisaoComponent implements OnInit {


  fields: FormField[] = [];

  error_counter: number = 0;
  erro: boolean = false

  constructor(
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService) { }

  ngOnInit(): void {
    this.fields = [
      {
        type: "select",
        label: "Parametrizador",
        name: "entrada",
        value: "NBS",
        hide: true,
        select: [
          {
            type:"option",
            name: "NBS",
            value: "NBS"
          },
          {
            type:"option",
            name: "LG",
            value: "LG"
          }
        ]
      },
      {
        type: "select",
        label: "Tipo de rescisão",
        name: "tipo_de_rescis_o",
        validators: Validators.required,
        _disabled: true, // ocultar campo
        value:"Rescisão Voluntária", // setar valor
        select: [
          {
            type: "option",
            name: "Rescisão Voluntária",
            value: "Rescisão Voluntária"
          },
          {
            type: "option",
            name: "Rescisão sem justa causa",
            value: "Rescisão sem justa causa"
          },
          {
            type: "option",
            name: "Rescisão com justa causa",
            value: "Rescisão com justa causa"
          },
          {
            type: "option",
            name: "Rescisão por abandono de emprego",
            value: "Rescisão por abandono de emprego"
          },
          {
            type: "option",
            name: "Rescisão por falecimento",
            value: "Rescisão por falecimento"
          }
        ]
      },
      {
        name: "nome_do_colaborador",
        type: "text",
        label: "Nome do colaborador",
        validators: Validators.required
      },
      {
        name: "matr_cula",
        type: "number",
        label: "Matrícula",
        validators: Validators.required
      },
      {
        name: "cpf_colaborador",
        type: "cpf",
        label: "CPF Colaborador",
        validators: Validators.required
      },
      {
        name: "rg_co",
        type: "rg",
        label: "RG Colaborador",
        validators: Validators.required
      },
      {
        name: "data_de_nascimento",
        type: "date",
        label: "Data de nascimento",
        validators: Validators.required
      },
      // {
      //   type: "connector",
      //   connector: 'Data Admissão',
      //   name: "data_de_admiss_o",
      //   label: "Data de admissão",
      //   validators: Validators.required
      // },
      {
        type: "date",
        name: "data_de_admiss_o_nova",
        label: "Data de admissão nova",
        validators: Validators.required
      },
      {
        type: "connector",
        connector: 'CBO',
        name: "cargo",
        label: "Cargo",
        validators: Validators.required
      },
      {
        name: "setor_departamento",
        type: "text",
        label: "Setor/Departamento",
        validators: Validators.required
      },
      {
        name: "e_mail_pessoal_colaborador",
        type: "email",
        label: "Email pessoal colaborador",
        validators: [Validators.required, Validators.email]
      },
      {
        name: "telefone_pessoal_colaborador",
        type: "tel",
        label: "Telefone pessoal colaborador",
        validators: [Validators.required]
      },
      {
        name: "data_de_desligamento",
        type: "date",
        label: "Data de desligamento",
        validators: Validators.required
      },
      {
        type: "radioHorizontal",
        label: "Vai cumprir o aviso prévio?",
        name: "vai_cumprir_o_aviso_pr_vio",
        validators: Validators.required,
        select: [
          {
            type: "option",
            name: "Sim",
            value: "Sim",
            fields: [
              {
                label: "Período de aviso prévio",
                name: "per_odo_de_aviso_pr_vio",
                type: "date",
                validators: Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Não",
            value: "Não"
          }
        ]
      },
      {
        type: "select",
        name: "nome_da_empresa",
        label: "Nome da Empresa",
        validators: [Validators.required],
        select: [
          {
            type: "option",
            name: "AGRICHEM DO BRASIL SA",
            value: "AGRICHEM DO BRASIL SA",
          },
          {
            type: "option",
            name: "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
            value: "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
          },
          {
            type: "option",
            name: "CONFIANÇA AGRÍCOLA LTDA",
            value: "CONFIANÇA AGRÍCOLA LTDA",
          },
          {
            type: "option",
            name: "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
            value: "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
          },
          // {
          //   type: "option",
          //   name: "MARCA AGRO MERCANTIL LTDA",
          //   value: "MARCA AGRO MERCANTIL LTDA",
          // },
          {
            type: "option",
            name: "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
            value: "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
          },
          {
            type: "option",
            name: "SEMENTES GOIÁS LTDA",
            value: "SEMENTES GOIÁS LTDA",
          },
          {
            type: "option",
            name: "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
            value: "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
          },
        ]
      },
      {
        name: "cnpj_da_empresa",
        type: "cnpj",
        label: "CNPJ da Empresa",
        validators: [Validators.required]
      },
      {
        name: "unidade_de_trabalho",
        type: "text",
        label: "Unidade de trabalho",
        validators: Validators.required
      },
      {
        name: "local_de_trabalho",
        type: "text",
        label: "Local de trabalho",
        validators: Validators.required
      },
      {
        type: 'attachment',
        name: 'carta_de_demiss_o',
        label: 'Carta de demissão',
        validators: Validators.required
      },
      {
        name: "gestor_demandante",
        type: "text",
        label: "Gestor Demandante",
        validators: Validators.required
      },
      // {
      //   type: "radioHorizontal",
      //   label: "Existe prazo de extensão do plano de saúde/odonto?",
      //   name: "existe_prazo_de_extens_o_do_plano_de_sa_de_odonto",
      //   validators: Validators.required,
      //   value: 'Não',
      //   select: [
      //     {
      //       type: "option",
      //       name: "Sim",
      //       value: "Sim",
      //       fields: [
      //         {
      //           "label": "Qual prazo de extensão do plano de saúde/odonto?",
      //           "name": "qual_prazo_de_extens_o_do_plano_de_sa_de_odonto",
      //           type: "date",
      //           validators: Validators.required
      //         }
      //       ]
      //     },
      //     {
      //       type: "option",
      //       name: "Não",
      //       value: "Não"
      //     }
      //   ]
      // },
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        validators: Validators.required
      },
      {
        "label": "E-mail do solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        validators: Validators.required
      },
      {
        type: "buttonSubmit",
        name: "Enviar"
      }
    ]
  }

  enviarFormulario(event: any) {
    console.log(event);
    // debugger
  }


}
