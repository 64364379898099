export const tipos_de_notas_ipe =
[
    {
        tipo:''
    },
    {
        tipo:"Aluguel"
    },
    // {
    //     tipo:"Ativo Fixo (AFE)"
    // },
    {
        tipo:"Beneficios"
    },
    {
        tipo:"Cartório"
    },
    {
        tipo:"Contas de consumo (Luz, água, telefone, internet, gás)"
    },
    {
        tipo:"Correio"
    },
    {
        tipo:"Devolução cliente"
    },
    {
        tipo:"Escrituração - Não gera pagamento"
    },
    {
        tipo:"Frete/CTE"
    },
    {
        tipo:"Guias/impostos/ taxas"
    },
    {
        tipo:"Importação"
    },
    {
        tipo:"Incentivo - Doação"
    },
    {
        tipo:"Jurídico"
    },
    {
        tipo:"Material (DANFE)"
    },
    {
        tipo:"Material Compras e aquisição"
    },
    {
        tipo:"Nota de Crédito"
    },
    {
        tipo:"Notas de Débito"
    },
    {
        tipo:"Remessa"
    },
    {
        tipo:"Sem valor fiscal"
    },
    {
        tipo:"Serviço"
    },
    // {
    //     tipo:"Reembolso"
    // }
]