import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConhecaMaisComponent } from './conheca-mais.component';



@NgModule({
  declarations: [
    ConhecaMaisComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ConhecaMaisModule { }
