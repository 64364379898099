export const tipos_de_notas_lancamento = [
  {
    tipo: ''
  },
  {
    tipo :'Pagamento serviço PO'
  },
  {
    tipo: 'Pagamento material PO com NFE e XML'
  },
  {
    tipo: 'Pagamento material PO com NFE'
  },
  {
    tipo: 'Pagamento material sem PO'
  },
  {
    tipo: 'Boleto'
  },
  {
    tipo: 'Guias'
  },
  {
    tipo: 'Frete'
  },
  {
    tipo: 'Materia Prima'
  },
  {
    tipo: 'Sem valor fiscal'
  },
  {
    tipo: 'Uso e consumo'
  },
  {
    tipo: 'Importação'
  },
  {
    tipo: 'Ativo fixo (AFE)'
  },
  {
    tipo: 'Retorno Armazem'
  },
  {
    tipo: 'Outras NFs sem PO'
  },
  {
    tipo: 'DANFE'
  },
  {
    tipo: 'NFPS'
  },
  {
    tipo: 'Reembolso'
  },
  {
    tipo: 'DANFE (DIFAL)'
  },
  {
    tipo: 'Fatura'
  },
  {
    tipo:'Imposto'
  },
  {
    tipo:'Aluguel'
  },
  {
    tipo:'Cartório'
  },
  {
    tipo:'CTE'
  },
  {
    tipo:'Conta de consumo'
  },
  {
    tipo:'Incentivo - Doação'
  },
  {
    tipo:'Jurídico'
  },
  {
    tipo:'Industrialização'
  },
  {
    tipo:'Benefícios'
  },
  {
    tipo:'Correio'
  },
  {
    tipo: 'Devolução de Cliente'
  }
]
