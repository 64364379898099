<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Solicitação de Lançamento de Notas Ocr"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">
    <div class="form-group row">
      <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="nome_empresa_nutrien" (change)="onEmpresaChange($event)">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_empresa_nutrien')?.errors?.required && formulario.get('nome_empresa_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="tipo_de_nota" class="col-sm-4 col-form-label">*Tipo de Nota</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tipo_de_nota" (change)="changeTipoDeNota($event)" (change)="onTipoDeNotaChange($event)">
          <option *ngFor="let tipo of tipos_de_notas" [value]="tipo.tipo">{{tipo.label}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_nota')?.errors?.required && formulario.get('tipo_de_nota')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <small class="col-sm-12" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">O fechamento do recolhimento do INSS ocorre até o dia 15,
            caso seja postada após esta data o pagamento e recolhimento do imposto será feito
            somente o mês subsequente.</small>
  
          <small class="col-sm-12" *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
            Caso não tenha o template Formulário Cadastro Autônomo_E-Social, 
              <a href="./assets/modelode-formulrio-cadastro-autnomo-esocial.xlsx" target="_blank"> clique aqui</a>.
          </small>
      </div>
    </div>

    <!-- CAMPO DE ANEXO PO - OCR - NOTA FISCAL -->
    <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota!=='' && formulario.getRawValue().tipo_de_nota!=='Pagamento de Autônomo'" >
      <!-- <div class="form-group row" *ngIf="formulario.getRawValue().categoria == 'PO'"> -->

      <label for="anexo_nota_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota</label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_nota_ou_boleto" id="formFileMultiple" (change)="saveNotaFiscalOCR($event)"
          (change)="saveAnexoNota($event)" multiple="multiple">
        <small class="text-observacao-centro-de-custo"
          *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">*Obrigatório incluir somente os Dactes (CT-Es)
          em um único arquivo PDF..</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexo_nota_ou_boleto')?.errors?.required && formulario.get('anexo_nota_ou_boleto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="notas.length != 0">
      <div class="card-header">Anexo - Nota ou Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of notas">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNota(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    

    <!-- CAMPO DE ANEXO NPO - TEMPLATE EXCEL -->
    <!-- <div *ngIf="formulario.getRawValue().categoria == 'NPO'">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Template Excel</label>
        <div class="col-sm-8">
          <a class="mt-2 d-block"
            href="https://app-storage-service.pipefy.com/v1/signed/uploads/4218e504-eee7-4467-9b85-58e402f1eb04/IPECoreFile-NPOCODINGTEMPLATEv2.xlsx?signature=rPAD3sJwE%2FwRtgkd0e4Gsk1qfVfT%2BGTxOMbx8eETtQc%3D"
            target="_blank" rel="noopener noreferrer">
            Rateio, Centro de Custos e Contas Contábeis
          </a>
        </div>
      </div>

      <div class="form-group row">
        <label for="anexo_npo_modelo_excel" class="col-sm-4 col-form-label">*Anexo (NPO) - Modelo Excel</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".xlsx"
            formControlName="anexo_npo_modelo_excel" id="formFileMultiple" (change)="saveArquivoNpo($event)"
            multiple="multiple">
        </div>
      </div>

      <div *ngIf="arquivosNpo.length != 0">
        <div class="shadow p-3 mb-5 bg-white rounded">
          <div class="card-header">Anexo - Arquivo NPO</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosNpo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNpo(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <!-- CAMPOS PO -->
    <div *ngIf="notas?.length != 0 || this.formulario.getRawValue().tipo_de_nota=='Pagamento de Autônomo'">

      <div class="form-group row">
        <label for="nome" class="col-sm-4 col-form-label">*Nome do Solicitante: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome" [attr.disabled]="true">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">*E-mail do Solicitante: </label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="email" (keyup)="validaCampoEmail()">
            <span>@nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota!='Pagamento de Autônomo'">
        <label for="natureza_da_opera_o" class="col-sm-4 col-form-label">Natureza da Operação: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="natureza_da_opera_o" >
        </div>
      </div> -->

      <!-- <div class="form-group row"  *ngIf="this.formulario.getRawValue().tipo_de_nota!='Pagamento de Autônomo'">
        <label for="cfop" class="col-sm-4 col-form-label">CFOP: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cfop" >
        </div>
      </div> -->

      <div class="form-group row">
        <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cnpj_da_empresa_nutrien" mask="00.000.000/0000-00">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.required && formulario.get('cnpj_da_empresa_nutrien')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.cnpj && formulario.get('cnpj_da_empresa_nutrien')?.touched"
            mensagem="CNPJ inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota!='Pagamento de Autônomo'">
        <label for="nome_empresa_nutrien_ocr" class="col-sm-4 col-form-label">Nome empresa Nutrien OCR </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_empresa_nutrien_ocr">
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="nome_empresa_nutrien" (change)="onEmpresaChange($event)">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome_empresa_nutrien')?.errors?.required && formulario.get('nome_empresa_nutrien')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row" *ngIf="formulario.getRawValue().documento_do_fornecedor=='CPF'">
        <label for="cpf_do_fornecedor" class="col-sm-4 col-form-label">*CPF Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf_do_fornecedor" mask='000.000.000-99'>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.required && formulario.get('cpf_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.cpf && formulario.get('cpf_do_fornecedor')?.touched"
            mensagem="CPF inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().documento_do_fornecedor=='CNPJ'">
        <label for="cnpj_fornecedor" class="col-sm-4 col-form-label">*CNPJ Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cnpj_fornecedor" mask="00.000.000/0000-00">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_fornecedor')?.errors?.required && formulario.get('cnpj_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_fornecedor')?.errors?.cnpj && formulario.get('cnpj_fornecedor')?.touched"
            mensagem="CNPJ inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="nome_do_fornecedor" class="col-sm-4 col-form-label">*Nome do Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_do_fornecedor">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome_do_fornecedor')?.errors?.required && formulario.get('nome_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="documento_do_fornecedor" class="col-sm-4 col-form-label">*Documento do Fornecedor:</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='documento_do_fornecedor' value="CPF" class='radio-btn'
              (change)="onDocumentoChange($event)">&nbsp;CPF
          </label>
          <label for="tipo" class='radio-inline' *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
            <input type="radio" formControlName='documento_do_fornecedor' value='CNPJ' class='radio-btn'
              (change)="onDocumentoChange($event)">&nbsp;CNPJ
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('documento_do_fornecedor')?.errors?.required && formulario.get('documento_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      
      <div class="form-group row" *ngIf="formulario.get('nome_empresa_nutrien')?.value == 'Agrichem'">
        <label for="grc" class="col-sm-4 col-form-label">GRC?</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='grc' value="Sim" class='radio-btn'
              (change)="onGrcChange($event)">&nbsp;Sim
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='grc' value='Não' class='radio-btn'
              (change)="onGrcChange($event)">&nbsp;Não
          </label>
          <!-- <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('grc')?.errors?.required && formulario.get('grc')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem> -->
        </div>
      </div>
    
   <div class="form-group row" *ngIf="formulario.get('grc')?.value === 'Sim'">
        <label for="estado_de_origem" class="col-sm-4 col-form-label">*Estado de origem?</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="estado_de_origem" (change)="onEstadoOrigemChange($event)">
            <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('estado_de_origem')?.errors?.required && formulario.get('estado_de_origem')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
  
   <div class="form-group row" *ngIf="formulario.get('grc')?.value === 'Sim'">
        <label for="estado_de_destino" class="col-sm-4 col-form-label">*Estado de destino?</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="estado_de_destino" (change)="onEstadoDestinoChange($event)">
            <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('estado_de_destino')?.errors?.required && formulario.get('estado_de_destino')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
        <label for="parcela_do_aluguel" class="col-sm-4 col-form-label">*Parcela do aluguel</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="parcela_do_aluguel">
            <option *ngFor="let parcelas of parcelaAluguel" [value]="parcelas">{{parcelas}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('parcela_do_aluguel')?.errors?.required && formulario.get('parcela_do_aluguel')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->
    
    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
        <label for="m_s_do_aluguel" class="col-sm-4 col-form-label">*Mês do Aluguel</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="m_s_do_aluguel">
            <option *ngFor="let mes of mesAluguel" [value]="mes">{{mes}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('m_s_do_aluguel')?.errors?.required && formulario.get('m_s_do_aluguel')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
    
     <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota == 'Aluguel'">
        <label for="ano_do_aluguel" class="col-sm-4 col-form-label">*Ano do aluguel</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="ano_do_aluguel">
            <option *ngFor="let ano of anoAluguel" [value]="ano">{{ano}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('ano_do_aluguel')?.errors?.required && formulario.get('ano_do_aluguel')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      

      <!-- <div class="form-group row">
        <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="cnpj_da_empresa_nutrien" (change)="onCnpjEmpresaNutrienChange($event)">
            <option *ngFor="let cnpj of listaEmpresasCnpjs" [value]="cnpj.cnpj">{{cnpj.cnpj}}</option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.required && formulario.get('cnpj_da_empresa_nutrien')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_empresa_nutrien" [attr.disabled]="true" placeholder="Selecione o CNPJ da empresa acima">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome_empresa_nutrien')?.errors?.required && formulario.get('nome_empresa_nutrien')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <!-- <div class="form-group row">
        <label for="risco_sacado" class="col-sm-4 col-form-label">Risco Sacado </label>
        <div class="col-sm-8">
          <input type="checkbox" class="form-check-input" formControlName="risco_sacado" value="Sim"
            (change)='riscoSacado=!riscoSacado' (change)="riscoSacadoValue($event)">
          <small class="form-check-label">Selecionar apenas se NF for relacionada a Risco Sacado. Antecipação de valores a
            fornecedores</small>
        </div>
      </div> -->


      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
        <!-- *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete' || this.formulario.getRawValue().tipo_de_nota=='CTE'"> -->

        <label for="qual_a_quantidade_de_cte" class="col-sm-4 col-form-label">Qual a quantidade de CTE </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="qual_a_quantidade_de_cte">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('qual_a_quantidade_de_cte')?.errors?.required && formulario.get('qual_a_quantidade_de_cte')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Ativo fixo (AFE)'">
        <label for="n_mero_afe" class="col-sm-4 col-form-label">*Número AFE </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="n_mero_afe">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('n_mero_afe')?.errors?.required && formulario.get('n_mero_afe')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'
      ||formulario.getRawValue().tipo_de_nota=='Aluguel' 
      ||formulario.getRawValue().tipo_de_nota=='Jurídico' 
      ||formulario.getRawValue().tipo_de_nota=='Devolução de Cliente'
      ||formulario.getRawValue().tipo_de_nota=='Nota de Crédito'&&formulario.getRawValue().nome_empresa_nutrien=='Agrichem'
      ||formulario.getRawValue().tipo_de_nota=='Nota de Crédito'&&formulario.getRawValue().nome_empresa_nutrien=='Nutrien'">
      <!-- *ngIf="formulario.getRawValue().tipo_de_nota ==='Reembolso' || formulario.getRawValue().tipo_de_nota =='Aluguel' || formulario.getRawValue().tipo_de_nota =='Guias'"> -->
      <label for="conta_contabil" class="col-sm-4 col-form-label">*Conta Contábil</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="conta_contabil">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('conta_contabil')?.errors?.required && formulario.get('conta_contabil')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota != 'Aluguel'">
      <label for="nro_nota" class="col-sm-4 col-form-label">*Número da Nota</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nro_nota">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nro_nota')?.errors?.required && formulario.get('nro_nota')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div>


      <div class="form-group row">
      <label for="nr_de_serie" class="col-sm-4 col-form-label">Número de Série</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nr_de_serie">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nr_de_serie')?.errors?.required && formulario.get('nr_de_serie')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
        </div>

      <div class="form-group row">
        <label for="data_de_envio_de_nf" class="col-sm-4 col-form-label">*Data de emissão da NF</label>
        <div class="col-sm-8">
          <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_envio_de_nf">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.getRawValue().data_de_envio_de_nf > dataAtual"
            mensagem="Não é permitido data futura"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_envio_de_nf')?.errors?.required && formulario.get('data_de_envio_de_nf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="valor_total_da_nf" class="col-sm-4 col-form-label">*Valor Total da NF</label>
        <div class="col-sm-8">
          <!-- <input type="text" class="form-control" formControlName="valor_total_da_nf" mask="separator.2"
            thousandSeparator="." [validation]="false">
          <small>Informar apenas números. Ex: 1.111.111</small> -->

          <input type="text" class="form-control" formControlName="valor_total_da_nf"
            (keyup)="formataValorDecimal($event.target.value)" [value]="valorTotalNfAlterado">
          <small>Digite o valor da nota fiscal com as casas decimais. Não é necessário o uso de vírgula e não copie e
            cole utilizando o mouse.</small>

          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_total_da_nf')?.errors?.required && formulario.get('valor_total_da_nf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Industrialização'">

        <label for="nf_do_produto_original" class="col-sm-4 col-form-label">NF Do Produto Original </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="nf_do_produto_original" id="formFileMultiple" (change)="saveAnexoNfProdutoOriginal($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="nfProdutoOriginal.length!=0">
        <div class="card-header">Anexo - Nf Produto Original</div>
        <ul class="list-group list-group-flush" *ngFor="let file of nfProdutoOriginal">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNfProdutoOriginal(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row"
        *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
        <!-- *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete'"> -->

        <label for="n_mero_de_cte" class="col-sm-4 col-form-label">Número de CTE</label>
        <div class="col-sm-8 ">
          <input type="text" class="form-control mb-1" formControlName="n_mero_de_cte">
        </div>
      </div>

      <div class="form-group row" *ngIf="(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' || this.formulario.getRawValue().nome_empresa_nutrien=='Agrichem')
        && this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'">
        <!-- *ngIf="this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias'"> -->


        <label for="opex_e_capex" class="col-sm-4 col-form-label">OPEX ou Capex?</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='opex_e_capex' value="Opex" class='radio-btn'>&nbsp;OPEX
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='opex_e_capex' value='Capex' class='radio-btn'>&nbsp;CAPEX
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('opex_e_capex')?.errors?.required && formulario.get('opex_e_capex')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota=='Importação'">
        <label for="tipo_de_importa_o" class="col-sm-4 col-form-label">Tipo de Importação</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='tipo_de_importa_o' value="Serviço" class='radio-btn'>&nbsp;Serviço
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='tipo_de_importa_o' value='Material' class='radio-btn'>&nbsp;Material
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_importa_o')?.errors?.required && formulario.get('tipo_de_importa_o')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
        <label for="tipo_de_pagamento" class="col-sm-4 col-form-label">Tipo de Pagamento é Boleto? </label>
        <div class="col-sm-8">
          <input type="radio" class="form-check-input" formControlName="tipo_de_pagamento" value="Boleto">
          <small class="form-check-label"> Marcar se tipo de pagamento for boleto</small>
        </div>
      </div>

      <div class="form-group row"
        *ngIf="formulario.getRawValue().tipo_de_nota=='Frete/CTE'|| formulario.getRawValue().tipo_de_nota=='Serviço'">
        <label for="informacoes_complementares_da_po" class="col-sm-4 col-form-label">Informações Complementares da PO
        </label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
              class="form-check-input" formControlName="informacoes_complementares_da_po"
              (change)="custoComplementar=!custoComplementar" (change)="inserirNoArray($event)"
              value='Custo planejado?'>&nbsp;&nbsp;&nbsp;&nbsp; Custo Planejado? </label>

          <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
              class="form-check-input" formControlName="informacoes_complementares_da_po"
              (change)="debitoPosterior=!debitoPosterior" (change)="inserirNoArray($event)"
              value='Debito posterior?'>&nbsp;&nbsp;&nbsp;&nbsp; Débito Posterior</label>
        </div>
      </div>

      <div class="form-group row"
        *ngIf="(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Frete/CTE' && custoComplementar)||
      (this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Serviço' && custoComplementar)">
        <label for="tipo_de_custo_planejado" class="col-sm-4 col-form-label">Tipo de Custo Planejado</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='tipo_de_custo_planejado' value="Armazenagem"
              class='radio-btn'>&nbsp;Armazenagem
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='tipo_de_custo_planejado' value='Capatazia'
              class='radio-btn'>&nbsp;Capatazia
          </label>

          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='tipo_de_custo_planejado' value='Frete' class='radio-btn'>&nbsp;Frete
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_custo_planejado')?.errors?.required && formulario.get('tipo_de_custo_planejado')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
        <div class="form-group row">
          <label for="nota_com_rasteio_de_valores" class="col-sm-4 col-form-label">Nota com rateio de valores? </label>
          <div class="col-sm-8">
              <label class='radio-inline'>
                <input type="radio" formControlName='nota_com_rasteio_de_valores' value="Sim" class='radio-btn'
                  (change)="notaComRateiosValue($event)">&nbsp;Sim
              </label>
  
              <label class='radio-inline'>
                <input type="radio" formControlName='nota_com_rasteio_de_valores' value="Não" class='radio-btn'
                  (change)="notaComRateiosValue($event)">&nbsp;Não
              </label>
          </div>
        </div>
  
        <div class="form-group row" *ngIf="formulario.getRawValue().nota_com_rasteio_de_valores=='Sim'">
          <label for="arquivo_de_rateio" class="col-sm-4 col-form-label">Arquivo de Rateio </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="arquivo_de_rateio" id="formFileMultiple" (change)="saveArquivoRateio($event)"
              multiple="multiple">
          </div>
        </div>
  
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosDeRateio.length!=0">
          <div class="card-header">Anexo - Arquivo de Rateio</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosDeRateio">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoRateio(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_de_vencimento_da_nota" class="col-sm-4 col-form-label">*Data de Vencimento da Nota </label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_vencimento_da_nota"
            (change)='onDataDeVencimentoChange($event)'>

          <small class="col-sm-12">Os pagamentos devem ser conforme a Política de Pagamentos da Nutrien</small>
          <!-- <small class="col-sm-12 atencao">Atenção<br>
            Aproveitamos para reforçar a política de pagamentos da Nutrien.</small>
          <small class="col-sm-12">Pagamentos são executados apenas nos dias 15 e 25 de cada mês, seguindo as regras
            abaixo:</small>
          <small class="col-sm-12">
            <ul class="col-sm-12">
              <li>• Para pagamentos no dia 15, as notas devem ser postadas no portal NBS até no máximo dia 05. Se postadas
                depois do dia 05, a nota será paga apenas no dia 25</li>
              <li>• Para pagamentos no dia 25, as notas devem ser postadas no portal NBS até no máximo dia 15. Se postadas
                depois do dia 15, a nota será paga apenas no dia 15 do mês seguinte.</li>
            </ul>
          </small> -->

          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_vencimento_da_nota')?.errors?.required && formulario.get('data_de_vencimento_da_nota')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>

        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().nome_empresa_nutrien =='Agrichem'">
        <label for="departamento" class="col-sm-4 col-form-label">*Departamento </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="departamento">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('departamento')?.errors?.required && formulario.get('departamento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div
        *ngIf="!(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas')">
        <!-- *ngIf="!(this.formulario.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formulario.getRawValue().tipo_de_nota=='Guias')"> -->

        <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Devolução de Cliente'||
        this.formulario.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'||
        this.formulario.getRawValue().tipo_de_nota=='Jurídico'||
        this.formulario.getRawValue().tipo_de_nota=='Nota de Crédito'||
        this.formulario.getRawValue().tipo_de_nota=='Outras NFs sem PO'">
          <label for="numero_de_pedido" class="col-sm-4 col-form-label">Número de pedido</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_de_pedido">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_de_pedido')?.errors?.required && formulario.get('numero_de_pedido')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>


        <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Aluguel'||
        this.formulario.getRawValue().tipo_de_nota=='Ativo fixo (AFE)'||
        this.formulario.getRawValue().tipo_de_nota=='Benefícios'||
        this.formulario.getRawValue().tipo_de_nota=='Contas de consumo (Luz, água, telefone, internet, gás)'||
        this.formulario.getRawValue().tipo_de_nota=='Correio'||
        this.formulario.getRawValue().tipo_de_nota=='Escrituração - Não gera pagamento'||
        this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'||
        this.formulario.getRawValue().tipo_de_nota=='Importação'||
        this.formulario.getRawValue().tipo_de_nota=='Incentivo - Doação'||
        this.formulario.getRawValue().tipo_de_nota=='Industrialização'||
        this.formulario.getRawValue().tipo_de_nota=='Material (DANFE)'||
        this.formulario.getRawValue().tipo_de_nota=='Sem valor fiscal'||
        this.formulario.getRawValue().tipo_de_nota=='Serviço'">

          <label for="numero_de_pedido" class="col-sm-4 col-form-label">*Número de pedido</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="numero_de_pedido">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('numero_de_pedido')?.errors?.required && formulario.get('numero_de_pedido')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="(formulario.getRawValue().tipo_de_nota==='Ativo fixo (AFE)'||formulario.getRawValue().tipo_de_nota == 'Material (DANFE)' || formulario.getRawValue().tipo_de_nota == 'Serviço' || formulario.getRawValue().tipo_de_nota == 'Escrituração - Não gera pagamento') && formulario.getRawValue().nome_empresa_nutrien == 'Nutrien'"> <!-- Quantidade de pedidos -->
        <div class="form-group row">
          <label for="qtde_pedidos" class="col-sm-4 col-form-label">*Quantidade de Itens</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="qtde_pedidos" (change)="onTipoChange()" (change)="preencheNumeroDoPedido($event)">
              <option *ngFor="let qtde of quantidade_pedidos" [value]="qtde.nome">{{qtde.nome}}
              </option>
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('qtde_pedidos')?.errors?.required && formulario.get('qtde_pedidos')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 1">
          <div class="form-group row">
            <label for="numero_linha_pedido_1" class="col-sm-4 col-form-label">*Linha do Item 1</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_1">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_1')?.errors?.required && formulario.get('numero_linha_pedido_1')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 2">
          <div class="form-group row">
            <label for="numero_linha_pedido_2" class="col-sm-4 col-form-label">*Linha do Item 2</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_2">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_2')?.errors?.required && formulario.get('numero_linha_pedido_2')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 3">
          <div class="form-group row">
            <label for="numero_linha_pedido_3" class="col-sm-4 col-form-label">*Linha do Item 3</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_3">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_3')?.errors?.required && formulario.get('numero_linha_pedido_3')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 4">
          <div class="form-group row">
            <label for="numero_linha_pedido_4" class="col-sm-4 col-form-label">*Linha do Item 4</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_4">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_4')?.errors?.required && formulario.get('numero_linha_pedido_4')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 5">
          <div class="form-group row">
            <label for="numero_linha_pedido_5" class="col-sm-4 col-form-label">*Linha do Item 5</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_5">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_5')?.errors?.required && formulario.get('numero_linha_pedido_5')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 6">
          <div class="form-group row">
            <label for="numero_linha_pedido_6" class="col-sm-4 col-form-label">*Linha do Item 6</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_6">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_6')?.errors?.required && formulario.get('numero_linha_pedido_6')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 7">
          <div class="form-group row">
            <label for="numero_linha_pedido_7" class="col-sm-4 col-form-label">*Linha do Item 7</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_7">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_7')?.errors?.required && formulario.get('numero_linha_pedido_7')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 8">
          <div class="form-group row">
            <label for="numero_linha_pedido_8" class="col-sm-4 col-form-label">*Linha do Item 8</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_8">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_8')?.errors?.required && formulario.get('numero_linha_pedido_8')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 9">
          <div class="form-group row">
            <label for="numero_linha_pedido_9" class="col-sm-4 col-form-label">*Linha do Item 9</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_9">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_9')?.errors?.required && formulario.get('numero_linha_pedido_9')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 10">
          <div class="form-group row">
            <label for="numero_linha_pedido_10" class="col-sm-4 col-form-label">*Linha do Item 10</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_10">
              <!-- <small>e.g. xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_10')?.errors?.required && formulario.get('numero_linha_pedido_10')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos == 'Mais que 10'">
          <div class="form-group row">
            <label for="lista_pedidos" class="col-sm-4 col-form-label">*Lista de Itens</label>
            <div class="col-sm-8">
              <textarea class="form-control" aria-label="With textarea" formControlName="lista_pedidos"></textarea>
              <!-- <small>e.g. <br />
                xxxx : xxx,xxx-xxx<br />
                xxxx : xxx,xxx-xxx</small> -->
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('lista_pedidos')?.errors?.required && formulario.get('lista_pedidos')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'&&formulario.getRawValue().tipo_de_nota !== 'Ativo fixo (AFE)'&&formulario.getRawValue().tipo_de_nota !== 'Material (DANFE)'&&formulario.getRawValue().tipo_de_nota !== 'Serviço'&&formulario.getRawValue().tipo_de_nota !== 'Escrituração - Não gera pagamento'">
        <label for="" class="col-sm-4 col-form-label">Quantidade de itens do pedido</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="quantidade_de_itens_do_pedido">
            <option *ngFor="let quantidade of quantidadeDeItens" [value]="quantidade.quantidade">
              {{quantidade.quantidade}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('quantidade_de_itens_do_pedido')?.errors?.required && formulario.get('quantidade_de_itens_do_pedido')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row" *ngIf="formulario.getRawValue().quantidade_de_itens_do_pedido =='De 1 a 10 Itens'">
        <label for="numero_do_item_da_po" class="col-sm-4 col-form-label">Número do item da PO </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="numero_do_item_da_po"></textarea>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().quantidade_de_itens_do_pedido =='Acima de 10 Itens'">
        <div class="form-group row">
          <label for="lista_de_itens_da_po" class="col-sm-4 col-form-label">Lista de itens da PO </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="lista_de_itens_da_po" id="formFileMultiple" (change)="saveListaDeItensDaPO($event)"
              multiple="multiple">
          </div>
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="listaDeItensDaPo.length!=0">
          <div class="card-header">Anexo - Lista de Itens da PO</div>
          <ul class="list-group list-group-flush" *ngFor="let file of listaDeItensDaPo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeListaDeItensDaPO(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
        <label for="centro_de_custo" class="col-sm-4 col-form-label">Centro de custo</label>
        <div class="col-sm-8 ">
          <input type="text" class="form-control mb-1" formControlName="centro_de_custo">
          <small class="text-observacao-centro-de-custo">*Sempre preencher se a nota selecionada não tiver ordem de
            compra/serviço.</small>
        </div>
      </div>

      
    <div class="form-group row">
      <label for="confirma_entrega_ou_prestacao" class="col-sm-4 col-form-label prestacao_servico">*Confirma entrega ou
        prestação de serviço?</label>
      <div class="col-sm-8">
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='confirma_entrega_ou_prestacao' value="Sim" class='radio-btn'
            (change)="onConfirmaEntregaChange($event)">&nbsp;Sim
        </label>
        <label for="tipo" class='radio-inline'>
          <input type="radio" formControlName='confirma_entrega_ou_prestacao' value="Não se aplica" class='radio-btn'
            (change)="onConfirmaEntregaChange($event)">&nbsp;Não se aplica
        </label>
        <small class="obs-confirma-entrega">*Declaro que ao solicitar o pagamento desta nota, confirmo o recebimento do
          produto e/ou a prestação do serviço.</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('confirma_entrega_ou_prestacao')?.errors?.required && formulario.get('confirma_entrega_ou_prestacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="formulario.getRawValue().confirma_entrega_ou_prestacao=='Não se aplica'">
      <label for="motivo_negacao" class="col-sm-4 col-form-label">*Descreva o motivo:</label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_negacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_negacao')?.errors?.required && formulario.get('motivo_negacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_negacao')?.errors?.minlength && formulario.get('motivo_negacao')?.touched"
          mensagem="Obrigatório no mínimo 5 caracteres"></app-mensagem>
      </div>
    </div>

      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota=='Frete/CTE'">
        <label for="certeza_que_incluiu_o_arquivo_conforme_orienta_o" class="col-sm-4 col-form-label">Arquivo Incluído
          conforme orientação?</label>
        <div class="col-sm-8">
          <input type="checkbox" class="form-check-input"
            formControlName="certeza_que_incluiu_o_arquivo_conforme_orienta_o" value="Sim"
            (change)='dacteCorreto=!dacteCorreto' (change)="dacteCorretoValue($event)">
        </div>
      </div>


      <div class="form-group row" *ngIf="this.formulario.getRawValue().tipo_de_nota!='Pagamento de Autônomo'">
        <label for="anexo_boleto" class="col-sm-4 col-form-label">Anexo Boleto </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_boleto"
            id="formFileMultiple" (change)="saveBoletoOCR($event)" (change)="saveAnexoBoleto($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="boletos.length!=0">
        <div class="card-header">Anexo - Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of boletos">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileBoleto(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
        <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo XML </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_xml"
            id="formFileMultiple" (change)="saveAnexoXML($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="xml.length!=0">
        <div class="card-header">Anexo - Nota ou Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of xml">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileXML(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>





      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota !== 'Pagamento de Autônomo'">
        <label for="anexo_documentacao_suporte" class="col-sm-4 col-form-label">Anexo Documentação Suporte </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea"
            formControlName="anexo_documentacao_suporte" id="formFileMultiple"
            (change)="saveAnexoDocumentacaoSuporte($event)" multiple="multiple">
          <small *ngIf="this.formulario.getRawValue().tipo_de_nota=='Aluguel'">*Para o tipo de nota "ALUGUEL" O
            preenchimento desse campo é obrigatório.</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('anexo_documentacao_suporte')?.errors?.required && formulario.get('anexo_documentacao_suporte')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentacaoSuporte.length!=0">
        <div class="card-header">Anexo - Documentação Suporte</div>
        <ul class="list-group list-group-flush" *ngFor="let file of documentacaoSuporte">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFilDocumentacaoSuporte(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div *ngIf="formulario.getRawValue().tipo_de_nota == 'Pagamento de Autônomo'">
        <div class="form-group row">
          <label for="anexo_para_pagamento_de_aut_nomo" class="col-sm-4 col-form-label">*Anexo para pagamento de
            autônomo</label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_para_pagamento_de_aut_nomo" id="anexo_para_pagamento_de_aut_nomo"
              (change)="save_anexo_para_pagamento_de_aut_nomo($event)" multiple="multiple">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('anexo_para_pagamento_de_aut_nomo')?.errors?.required && formulario.get('anexo_para_pagamento_de_aut_nomo')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="list_anexo_para_pagamento_de_aut_nomo.length!=0">
          <div class="card-header">Anexo - Pagamento de Autônomo</div>
          <ul class="list-group list-group-flush" *ngFor="let file of list_anexo_para_pagamento_de_aut_nomo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile'
                  (click)="remove_anexo_para_pagamento_de_aut_nomo(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>

      

      <!-- As linhas abaixo foram comentadas de acordo com a demanda 597461824 -->

      <!-- <div class="form-group row">
        <label for="nota_para_pagamento_fora_da_politica" class="col-sm-4 col-form-label">*Nota para pagamento fora da
          política</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value="Sim"
              class='radio-btn'>&nbsp;SIM
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value='Não'
              class='radio-btn'>&nbsp;NÃO
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nota_para_pagamento_fora_da_politica')?.errors?.required && formulario.get('nota_para_pagamento_fora_da_politica')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->



      <!-- <div class="form-group row">
        <label for="nota_para_pagamento_urgente" class="col-sm-4 col-form-label">*Nota para pagamento urgente</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='nota_para_pagamento_urgente' value="Sim" class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;SIM
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='nota_para_pagamento_urgente' value='Não' class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;NÃO
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nota_para_pagamento_urgente')?.errors?.required && formulario.get('nota_para_pagamento_urgente')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->


      <!-- <div class="form-group row" *ngIf="formulario.getRawValue().nota_para_pagamento_urgente=='Sim'">
        <label for="motivo_da_excecao" class="col-sm-4 col-form-label">*Motivo de Exceção </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_excecao"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_da_excecao')?.errors?.required && formulario.get('motivo_da_excecao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        </div>

      </div> -->



      <!-- *************** CAMPOS NOVOS *********** -->

      <!-- <div>
        <div class="form-group row">
          <label for="migo" class="col-sm-4 col-form-label">*MIGO</label> 
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="migo">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('migo')?.errors?.required && formulario.get('migo')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>
  
      <div class="form-group row"> 
        
        <label for="folha_servicos" class="col-sm-4 col-form-label">Folha de Serviços</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="folha_servicos">
        </div>
      </div> -->


      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação sobre o documento </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        </div>
      </div>

      <div class="form-group row">
        <label for="ambiente" class="col-sm-4 col-form-label">*Ambiente:</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='ambiente' value="QA" class='radio-btn'>&nbsp;QA
          </label>
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='ambiente' value="PRE-PROD" class='radio-btn'>&nbsp;PRE-PROD
          </label>
          <!-- <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='ambiente' value="PROD" class='radio-btn'>&nbsp;PROD
          </label> -->

        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="baixa_de_adiantamento" class="col-sm-4 col-form-label">Baixa de Adiantamento</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="baixa_de_adiantamento" (change)="onEmpresaChange($event)">
            <option value=""></option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
          </select>
        </div>
      </div> -->
    </div>

    <!-- CAMPOS NPO -->

    <div *ngIf="arquivosNpo?.length != 0">

      
      <div class="form-group row">
        <label for="documento_do_fornecedor" class="col-sm-4 col-form-label">*Documento do Fornecedor:</label>
        <div class="col-sm-8">
          <label for="tipo" class=' radio-inline'>
            <input type="radio" formControlName='documento_do_fornecedor' value="CPF" class='radio-btn'
              (change)="onDocumentoChange($event)">&nbsp;CPF
          </label>
          <label for="tipo" class='radio-inline'>
            <input type="radio" formControlName='documento_do_fornecedor' value='CNPJ' class='radio-btn'
              (change)="onDocumentoChange($event)">&nbsp;CNPJ
          </label>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('documento_do_fornecedor')?.errors?.required && formulario.get('documento_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().documento_do_fornecedor=='CPF'">
        <label for="cpf_do_fornecedor" class="col-sm-4 col-form-label">*CPF Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf_do_fornecedor" mask='000.000.000-99'>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.required && formulario.get('cpf_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cpf_do_fornecedor')?.errors?.cpf && formulario.get('cpf_do_fornecedor')?.touched"
            mensagem="CPF inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().documento_do_fornecedor=='CNPJ'">
        <label for="cnpj_fornecedor" class="col-sm-4 col-form-label">*CNPJ Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cnpj_fornecedor" mask="00.000.000/0000-00">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_fornecedor')?.errors?.required && formulario.get('cnpj_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_fornecedor')?.errors?.cnpj && formulario.get('cnpj_fornecedor')?.touched"
            mensagem="CNPJ inválido"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="nome_do_fornecedor" class="col-sm-4 col-form-label">*Nome do Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome_do_fornecedor">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome_do_fornecedor')?.errors?.required && formulario.get('nome_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cnpj_da_empresa_nutrien" mask="00.000.000/0000-00">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.required && formulario.get('cnpj_da_empresa_nutrien')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('cnpj_da_empresa_nutrien')?.errors?.cnpj && formulario.get('cnpj_da_empresa_nutrien')?.touched"
            mensagem="CNPJ inválido"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="nome" class="col-sm-4 col-form-label">*Nome do Solicitante: </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nome" [attr.disabled]="true">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">*E-mail do Solicitante: </label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="email" (keyup)="validaCampoEmail()">
            <span>@nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>



      <div class="form-group row">
        <label for="data_de_envio_de_nf" class="col-sm-4 col-form-label">*Data de emissão da NF</label>
        <div class="col-sm-8">
          <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_envio_de_nf">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.getRawValue().data_de_envio_de_nf > dataAtual"
            mensagem="Não é permitido data futura"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_envio_de_nf')?.errors?.required && formulario.get('data_de_envio_de_nf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="data_de_vencimento_da_nota" class="col-sm-4 col-form-label">*Data de Vencimento da Nota </label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_vencimento_da_nota"
            (change)='onDataDeVencimentoChange($event)'>

          <small class="col-sm-12">Os pagamentos devem ser conforme a Política de Pagamentos da Nutrien</small>

          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_vencimento_da_nota')?.errors?.required && formulario.get('data_de_vencimento_da_nota')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>

        </div>
      </div>

      <div class="form-group row">
        <label for="valor_total_da_nf" class="col-sm-4 col-form-label">*Valor Total da NF</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_total_da_nf"
            (keyup)="formataValorDecimal($event.target.value)" [value]="valorTotalNfAlterado">
          <small>Digite o valor da nota fiscal com as casas decimais. Não é necessário o uso de vírgula e não copie e
            cole utilizando o mouse.</small>

          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_total_da_nf')?.errors?.required && formulario.get('valor_total_da_nf')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().nome_empresa_nutrien =='Agrichem'">
        <label for="departamento" class="col-sm-4 col-form-label">*Departamento </label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="departamento">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('departamento')?.errors?.required && formulario.get('departamento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>


      <div class="form-group row">
        <label for="tipo_de_pagamento" class="col-sm-4 col-form-label">Tipo de Pagamento é Boleto? </label>
        <div class="col-sm-8">
          <input type="radio" class="form-check-input" formControlName="tipo_de_pagamento" value="Boleto">
          <small class="form-check-label"> Marcar se tipo de pagamento for boleto</small>
        </div>
      </div>


      <!-- CONFIRMAR SE BOLETO ESTÁ NO IPE -->
      <div class="form-group row">
        <label for="anexo_boleto" class="col-sm-4 col-form-label">Anexo Boleto </label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_boleto"
            id="formFileMultiple" (change)="saveBoletoOCR($event)" (change)="saveAnexoBoleto($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="boletos.length!=0">
        <div class="card-header">Anexo - Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of boletos">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileBoleto(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
     
      NO IPE ESTÁ COMO DESCRIÇÃO BREVE
      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação sobre o documentoooo </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        </div>
      </div>
      EM RELAÇÃO AO IPE FALTAM CAMPOS - BOLETO PARA PAGAMENTO E FORMA DE PAGAMENTO(LISTA)

    </div>

  
    <div class="form-group pt-1 col-lg-3 box-botao">
      <!-- <button type="submit" class="btn botao" [disabled]="formulario.invalid || this.formulario.getRawValue().documento_do_fornecedor=='CPF'">Enviar</button> -->
      <button type="submit" class="btn botao"
        [disabled]="formulario.invalid || formulario.getRawValue().data_de_envio_de_nf > dataAtual">Enviar</button>
    </div>



  </form>

</div>


<div class="footer">
  <app-footer link="payment"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>