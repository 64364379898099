export const comprasMateriaisIndiretos = [
  {
    nome:''
  },
  {
    nome:'Requisição de Compras'
  },
  {
    nome:'Suporte para Compras'
  }
]
