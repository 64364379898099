export const empresas =[
  {
    nome:""
  },
  {
    nome:"Tec Agro"
  },
  {
    nome:'Nutrien'
  },
  {
    nome:'Agrichem'
  },
  {
    nome:'Agrosema'
  }

]
