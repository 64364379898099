import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'


@Injectable({
  providedIn: 'root'
})

export class AzureService {
  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient) { }


}
