export const tiposDeConta =[
  {
    nome:""
  },
  {
    nome:"Conta corrente"
  },
  {
    nome:'Conta salário'
  }
]
