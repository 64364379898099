<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Compras">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Suporte para Compras - Serviços e Materiais Indiretos"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="sua_empresa" class="col-sm-4 col-form-label">*Sua empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="sua_empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('sua_empresa')?.errors?.required && formulario.get('sua_empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="seu_nome" class="col-sm-4 col-form-label">*Seu nome</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="seu_nome">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('seu_nome')?.errors?.required && formulario.get('seu_nome')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="seu_email" class="col-sm-4 col-form-label">*Seu e-mail </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="seu_email">
        <small>Seu endereço de e-mail corporativo</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('seu_email')?.errors?.required && formulario.get('seu_email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('seu_email')?.errors?.email && formulario.get('seu_email')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="motivo_do_contato" class="col-sm-4 col-form-label">Motivo do contato</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="motivo_do_contato">
          <option *ngFor="let motivo of motivos" [value]="motivo.nome">{{motivo.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('motivo_do_contato')?.errors?.required && formulario.get('motivo_do_contato')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="descreva_sua_solicitacao" class="col-sm-4 col-form-label">*Descreva sua solicitação </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="descreva_sua_solicitacao">
        <small>Dê um título para sua solicitação</small>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('descreva_sua_solicitacao')?.errors?.required && formulario.get('descreva_sua_solicitacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="mais_detalhes" class="col-sm-4 col-form-label">*Mais detalhes </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="mais_detalhes"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('mais_detalhes')?.errors?.required && formulario.get('mais_detalhes')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="anexos" class="col-sm-4 col-form-label">Anexar Solicitação de Auditoria
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexos" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('anexos')?.errors?.required && formulario.get('anexos')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexos.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of anexos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>



    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde...</p>
</ngx-spinner>
