import { Injectable } from '@angular/core';
import { TokenService } from './../token.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private tokenService: TokenService) {
    if(this.tokenService.hasToken()){

    }
  }

  setToken(token:string){
    this.tokenService.setToken(token);
  }


  logout() {
    this.tokenService.removeToken()
  }

  isLoggedIn(){
    return this.tokenService.hasToken()
  }
}
