<div class="container">
  <div class="logo"><img src="../../assets/img/logo_nbs.png" class="logonbs" alt=""></div>
  <h2 class="titulo">Conheça Mais</h2>

  <div class="topicos">
    <h6>O que é o NBS? </h6>
    <p>O NBS é a abreviação de Nutrien Business Services e é a nomeação dada ao centro de
      serviços compartilhados da Nutrien. O NBS é uma estrutura de centralização de serviços,
      que atua de forma semi autônoma e concentra atividades de diferentes setores em apenas
      um lugar. A proposta é que isso gere ganhos de escala, eficiência e governança de
      processos e serviços, antes descentralizados, permitindo que os negócios da Nutrien
      atuem focados em suas áreas de expertise.
      </p>

      <h6>Como surgiu a necessidade de criar o NBS? </h6>
      <p>A Nutrien iniciou uma jornada em Fevereiro/20 através de uma pesquisa que identificou a
        natureza das tarefas administrativas da companhia e, no 1º trimestre de 2022, mapeou em
        detalhe este grupo de atividades. Como resultado deste processo, a recomendação foi a
        centralização de um grupo específico de atividades administrativas e sua migração para o
        NBS.
        </p>

      <h6>Que benefícios o NBS trará para a Nutrien? </h6>
      <p>O NBS, sendo uma nova estrutura de prestação de serviços, será composta por uma
        equipe especializada aliada a componentes tecnológicos avançados, que usará as
        melhores soluções de negócios para atender às necessidades e requisitos para o bom
        desempenho da empresa. O objetivo é termos:
        </p>






        <ul>
          <li>&ordm;	Melhora na experiência do cliente interno, com aumento na qualidade dos serviços. </li>
          <li>&ordm;	Apoiar na tomada de decisões, com controles e relatórios harmonizados, permitindo
            análises aprofundadas de dados e geração de informações.</li>
            <li>&ordm;	Trabalhar de forma mais proativa à medida que alcançamos excelência operacional,
              adquirindo postura de centro de soluções para a Nutrien e seus negócios.</li>
            <li>&ordm;	Impulsionar o sucesso financeiro da Nutrien, por meio de eficiência e ganhos de escala,
              reduzindo os custos da operação.</li>
        </ul>

      <h6>Quais são as áreas em escopo do NBS e quando
        serão implementadas?</h6>
      <p>As áreas que inicialmente farão parte do escopo do NBS são: Pagamentos, Impostos,
        Contabilidade e Suporte a Atividades Administrativas de Lojas / e Centros de Experiência
        (OTD). A primeira fase de operação do NBS acontecerá em Julho de 2022 para as
        entidades Nutrien (antiga Utilfertil), Tec Agro e Agrichem.
        </p>

      <h6>Quais são os serviços que o NBS atenderá? </h6>
      <p>Considerando a primeira fase, em que as áreas que inicialmente farão parte do escopo do
        NBS são: Pagamentos, Contabilidade, Impostos e Lojas / e Centros de Experiência (OTD),
        os serviços atendidos pelo NBS para cada área são:</p>
        <ul class="lista-servicos">
          <li>
            <h6 class="servicos-titulo">Pagamentos</h6>
            <ul class="servicos">

              <li>&ordm;&nbsp;Solicitações de Cartões Corporativos</li>
              <li>&ordm;&nbsp;Solicitação de Lançamento Contábil </li>

            </ul>
          </li>

          <li>
            <h6 class="servicos-titulo">Contabilidade</h6>

            <ul class="servicos">
              <li>&ordm;&nbsp;Auditoria – Documentos Suporte</li>
              <li>&ordm;&nbsp;Baixa Imobilizado</li>
              <li>&ordm;&nbsp;Lançamento Contábil </li>
            </ul>
          </li>

          <li>
            <h6 class="servicos-titulo">Impostos</h6>
            <ul class="servicos">
              <li>&ordm;&nbsp;Aprovação de Entrega de Obrigações Acessórias </li>
              <li>&ordm;&nbsp;Aprovação de Pagamento de Impostos </li>
              <li>&ordm;&nbsp;Fiscalização - Documentos Suporte</li>
              <li>&ordm;&nbsp;Impostos Apurados Sobre Remessas para Exterior </li>
              <li>&ordm;&nbsp;IOF Sobre Contrato de Mútuos – RH </li>
              <li>&ordm;&nbsp;IOF Sobre Contratos de Mútuos Tesouraria </li>
              <li>&ordm;&nbsp;IRRF Sobre Contratos de Mútuos Tesouraria </li>
              <li>&ordm;&nbsp;Retificação de Obrigações Acessórias </li>
              <li>&ordm;&nbsp;Solicitação de Guia Recolhimento ou Pagamentos de Impostos e Taxas</li>
              <li>&ordm;&nbsp;Solicitação de NF, Cancelamento, Devoluções e Carta Correção </li>
              <li>&ordm;&nbsp;Suporte à Loja e Demais Departamentos </li>
              <li>&ordm;&nbsp;Teste e Validação de Processos em Homologação </li>
            </ul>
          </li>

          <li>
            <h6 class="servicos-titulo">Lojas / Centros de Experiência (OTD)</h6>

          </li>
          <li>
            <h6 class="servicos-titulo">Faturamento</h6>
            <ul class="servicos"><li>&ordm;&nbsp;Pedido de Venda </li></ul>
          </li>
        </ul>



      <p>Em breve, também fará parte do escopo os processos de Compras e de Recursos
        Humanos.
        </p>
  </div>


</div>
