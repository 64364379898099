export const empresas = [
    {
      nome:''
    },
    {
      nome:'Agrosema'
    },
    {
      nome:'Marca Agro'
    },
    {
      nome:'Nutrien'
    },
    {
      nome:'TecAgro'
    },
    {
      nome:'Terra Nova'
    }
]