<!-- <div class="container fluid">

    <div class="nbs_box_link_pipe col-md-12 col-sm-8" (click)="clickOnlink()">
        <img [src]="url" [alt]="descricao" class="nbs_box_link_pipe-img">
        <h5 class="nbs_box_link_pipe-title">{{titulo}}</h5>
    </div>
</div> -->

<div class="nbs_box_link_menu" (click)="clickOnlink()">
  <img class="nbs_box_link_menu-image img-responsive" [src]="url" [alt]="descricao" [id]="id_box" />
  <div class="box-title">
      <p class="titulo" routerLink="link">{{ titulo }}</p>
      <p class = "titulo linha-2">{{subtitulo}} </p>
      <p class = "nbs_box_link_menu-title p-2 subtitulo2">{{subtitulo_2}} </p>
  </div>
</div>
