import { RouterModule } from '@angular/router';

import { NbsBannerComponent } from './nbs-banner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations:[NbsBannerComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    NbsBannerComponent
  ]
})
export class NbsBannerModule { }
