<div class="custom-select fakeSelect"
  [ngClass]="{'ng-invalid': !novaListaSelecionado, 'ng-valid': novaListaSelecionado}" (click)="cliqueSelectNovoItem()">
  {{!!novaListaSelecionado ? novaListaSelecionado.value : ""}}
</div>
<div *ngIf="exibirListaNovoItem" class="quadroSelectApi">
  <div class="searchSelectApi"> <i class="fa fa-search" aria-hidden="true"></i> <input type="text" class="inputSearch"
      [(ngModel)]="inputSearch" [placeholder]="placeholder" (keyup)="searchNovoItem()"> </div>
  <div *ngIf="!novaLista?.length"
    class="sem-result-select-api list-group-item list-group-item-action flex-column align-items-start">
    Nenhum resultado encontrado para este filtro </div>
  <div class="list-group selectApi" *ngIf="lista">
    <div *ngFor="let item of novaLista" (click)="selecionaNovoItem(item)"
      class="list-group-item list-group-item-action flex-column align-items-start">
      <p class="titleList">{{item.value}}</p>
    </div>
  </div>