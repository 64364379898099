export const tipo_duvida = [
    {
      nome:''
    },
    {
      nome:'Acesso'
    },
    {
      nome:'Deduções'
    },
    {
      nome:'Pensão'
    },
    {
      nome:'Plano de Saúde'
    },
    {
      nome:'Previdência Privada'
    },
    {
      nome:'Rendimentos'
    },
    {
      nome:'Outros'
    }
]
