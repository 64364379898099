<input type="file" class="form-control form-anexo" [(ngModel)]="textInput" [id]="data?.name"
  (change)="saveAttachment($event.target.files)" multiple="multiple">
<div class="shadow p-3 bg-white rounded form-anexo" *ngIf="!!data?.files?.length" style="width: 350px; margin-top: 1em !important;">
  <div class="card-header">Anexos{{!!data?.label? ' - ' + data.label : ''}}</div>
  <ul class="list-group list-group-flush" *ngFor="let file of data?.files">
    <li class="list-group-item d-flex justify-content-between">
      <span>{{ file?.name }}</span>
      <span>
        <i class='fa fa-trash remove-icon removeFile' (click)="removeAttachment(file)"></i>
      </span>
    </li>
  </ul>
</div>