<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Guias Fiscais - Emissão - Taxas e tributos diversos"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa" (change)="onEmpresaChange($event)">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrichem'">
      <label for="filial_agrichem" class="col-sm-4 col-form-label">*Filial Agrichem </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrichem">
          <option *ngFor="let filial of filiais_agrichem" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_agrichem')?.errors?.required && formulario.get('filial_agrichem')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrosema'">
      <label for="filial_agrosema" class="col-sm-4 col-form-label">*Filial Agrosema </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrosema">
          <option *ngFor="let filial of filiais_agrosema" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_agrosema')?.errors?.required && formulario.get('filial_agrosema')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Nutrien'">
      <label for="filial_nutrien" class="col-sm-4 col-form-label">*Filial Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_nutrien">
          <option *ngFor="let filial of filiais_nutrien" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_nutrien')?.errors?.required && formulario.get('filial_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro'">
      <label for="filial_tec_agro" class="col-sm-4 col-form-label">*Filial Tec Agro </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro">
          <option *ngFor="let filial of filiais_tec_agro" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro')?.errors?.required && formulario.get('filial_tec_agro')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-SGL'">
      <label for="filial_tec_agro_sgl" class="col-sm-4 col-form-label">*Filial Tec Agro-SGL </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_sgl">
          <option *ngFor="let filial of filiais_tec_agro_sgl" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_sgl')?.errors?.required && formulario.get('filial_tec_agro_sgl')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-FRC'">
      <label for="filial_tec_agro_frc" class="col-sm-4 col-form-label">*Filial Tec Agro-FRC </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_frc">
          <option *ngFor="let filial of filiais_tec_agro_frc" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_frc')?.errors?.required && formulario.get('filial_tec_agro_frc')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="impostos_taxas_e_despesas" class="col-sm-4 col-form-label">*Impostos, taxas e despesas </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="impostos_taxas_e_despesas">
          <option *ngFor="let imposto of impostos" [value]="imposto.nome">{{imposto.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('impostos_taxas_e_despesas')?.errors?.required && formulario.get('impostos_taxas_e_despesas')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="numero_do_pedido" class="col-sm-4 col-form-label">Número do pedido</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_do_pedido">
      </div>
    </div>


    <div class="form-group row">
      <label for="descricao_da_solicitacao" class="col-sm-4 col-form-label">*Descrição da solicitação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="descricao_da_solicitacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao_da_solicitacao')?.errors?.required && formulario.get('descricao_da_solicitacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>


    <div class="form-group row">
      <label for="valor_da_guia_ou_nfe" class="col-sm-4 col-form-label">*Valor da guia ou nfe</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_da_guia_ou_nfe" mask="separator.2" thousandSeparator="." [validation]="false">
        <small>Informar apenas números. Ex: 1.111.111</small>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_da_guia_ou_nfe')?.errors?.required && formulario.get('valor_da_guia_ou_nfe')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_de_vencimento" class="col-sm-4 col-form-label">*Data de vencimento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento">
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('data_de_vencimento')?.errors?.required && formulario.get('data_de_vencimento')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>



    <div class="form-group row">
      <label for="email_para_quem_deve_ser_enviado_a_guia" class="col-sm-4 col-form-label">*E-mail para quem deve ser enviada a guia</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_para_quem_deve_ser_enviado_a_guia">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_para_quem_deve_ser_enviado_a_guia')?.errors?.required && formulario.get('email_para_quem_deve_ser_enviado_a_guia')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_para_quem_deve_ser_enviado_a_guia')?.errors?.email && formulario.get('email_para_quem_deve_ser_enviado_a_guia')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="requisitante" class="col-sm-4 col-form-label">Requisitante</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="requisitante">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('requisitante')?.errors?.required && formulario.get('requisitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="email_do_requisitante" class="col-sm-4 col-form-label">*E-mail do requisitante</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_requisitante">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_requisitante')?.errors?.required && formulario.get('email_do_requisitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_requisitante')?.errors?.email && formulario.get('email_do_requisitante')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
      </div>

    </div>





    <div class="form-group row">
      <label for="anexar_documento" class="col-sm-4 col-form-label">*Anexar documento </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexar_documento" id="formFileMultiple" (change)="saveDocumentos($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexar_documento')?.errors?.required && formulario.get('anexar_documento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentos.length!=0">
      <div class="card-header">Anexo - Documento</div>
      <ul class="list-group list-group-flush" *ngFor="let file of documentos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileDocumentos(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="numero_de_documentos" class="col-sm-4 col-form-label">*Número de documentos</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_de_documentos">
        <app-mensagem class="col-form-label mensagem-erro"
      *ngIf="formulario.get('numero_de_documentos')?.errors?.required && formulario.get('numero_de_documentos')?.touched"
      mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
      </div>
    </div>


    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
