<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Lançamento Massivo de Notas"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="email_do_solicitante" class="col-sm-4 col-form-label">*E-mail do solicitante: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_solicitante" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_solicitante')?.errors?.required && formulario.get('email_do_solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_solicitante')?.errors?.email && formulario.get('email_do_solicitante')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="planilha_de_cards" class="col-sm-4 col-form-label">*Anexar Planilha de Pedidos
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="planilha_de_cards" id="formFileMultiple" (change)="saveAnexoCards($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="cards.length!=0">
      <div class="card-header">Anexos - Solicitação de Auditoria</div>
      <ul class="list-group list-group-flush" *ngFor="let file of cards">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileCards(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="anexo_nota" class="col-sm-4 col-form-label">Anexo Nota</label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_nota" id="formFileMultiple" (change)="saveAnexoNota($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="notas.length!=0">
      <div class="card-header">Anexo - Nota ou Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of notas">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNota(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>


    <div class="form-group row">
      <label for="anexo_boleto" class="col-sm-4 col-form-label">Anexo Boleto </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_boleto" id="formFileMultiple" (change)="saveAnexoBoleto($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="boletos.length!=0">
      <div class="card-header">Anexo - Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of boletos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileBoleto(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo XML </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo_xml" id="formFileMultiple" (change)="saveAnexoXML($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="xml.length!=0">
      <div class="card-header">Anexo - Nota ou Boleto</div>
      <ul class="list-group list-group-flush" *ngFor="let file of xml">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileXML(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>




    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde...</p>
</ngx-spinner>
