import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitacao-de-reembolso-de-despesas',
  templateUrl: './solicitacao-de-reembolso-de-despesas.component.html',
  styleUrls: ['./solicitacao-de-reembolso-de-despesas.component.css']
})
export class SolicitacaoDeReembolsoDeDespesasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
