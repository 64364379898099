export const pipe_fields_id = [
  'nome_do_respons_vel_nutrien',
  'e_mail_do_respons_vel_nutrien',
  'empresa',
  'data_de_vencimento_de_imposto',
  'data_in_cio_do_dec_ndio',
  'data_final_do_dec_ndio',
  'apura_o',
  'contrato',
  'observa_o'
]
