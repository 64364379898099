export const navbarData =[
  {
    routerLink: '/recursos-humanos/home/vagas',
    icon:'fa fa-home',
    label: 'Vagas'
  },
  {
    routerLink: '/recursos-humanos/home/calculo-plr',
    icon:'fa fa-home',
    label: 'Cálculo Plr'
  }

]
