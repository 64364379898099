export const planta_de_expedicao = [
  {
    nome: ''
  },
  {
    nome: '7001-Itapetininga Fábrica'
  },
  {
    nome: '7002-Loja Araxá'
  },
  {
    nome: '7003-Loja Cristalina'
  },
  {
    nome: '7004-Loja Morrinhos'
  },
  {
    nome: '7005-Loja Angatuba'
  },
  {
    nome: '7006-Loja Itapeva'
  },
  {
    nome: '7007-Loja Avaré'
  },
  {
    nome: '7008-Loja Limeira'
  },
  {
    nome: '7010-COL Centro de Oper. Logístico'
  },
  {
    nome: '7016-XP Itapetininga'
  },
  {
    nome: '7018-XP Center Patos de Minas'
  },
  {
    nome: '7039-Loja Vargem Grande'
  },
  {
    nome: '7040-Loja Paracatu'
  },
  {
    nome: '7041-Loja Mogi Mirim'
  },
  {
    nome: '7042-XP Center Uberlândia'
  },
  {
    nome: '7043-XP Center Uberaba'
  },
  {
    nome: '7044-CD Uberaba (Varejo)'
  },
  {
    nome: '7045-CD Uberaba (Atacado)'
  },
  {
    nome: '7046-XP Center Coromandel'
  },
  {
    nome: '7047-XP Center Patrocínio'
  },
  {
    nome: '7048-Loja Araçatuba'
  },
  {
    nome: '7049-XP Center Ibia'
  },
  {
    nome: '7050-XP Center Araxá'
  },
  {
    nome: '7079-XP Center São Gotardo'
  },
  {
    nome: '7080-XP Center Alfenas'
  },
  {
    nome: '7081-XP Center Ituituba'
  },
  {
    nome: '7082-Loja Unaí'
  },
  {
    nome: '7083-CD Paulinia Atacado'
  },
  {
    nome: '7084-Loja Cândido Mota'
  },
  {
    nome: '7085-Loja Limeira 2'
  },
  {
    nome: '7086-Loja Itapeva'
  },
  {
    nome: '7087-Loja Itápolis'
  },
  {
    nome: '7088-Loja Ribeirão Preto'
  },
  {
    nome: '7089-CD Paulinia Varejo'
  },
  {
    nome: '7090-Loja Votuporanga'
  },
  {
    nome: '7091-Loja Avare'
  },
  {
    nome: '7092-EC - Campinas'
  },
  {
    nome: '7093-EC - Goiania'
  }
];
