export const operacoes = [
  {
    nome: ''
  },
  {
    nome: '01-Emissão de nota fiscal de mercadoria'
  },
  {
    nome: '02-Emissão de nota fiscal de ativo imobilizado'
  },
  {
    nome: '03-Emissão de nota fiscal complementar'
  },
  {
    nome: '04-Emissão de nota fiscal de remessa'
  },
  {
    nome: '05-Emissão de nota fiscal devoluções de fornecedores'
  },
  {
    nome: '06-Emissão de nota fiscal de serviços-prestados'
  },
  {
    nome: '07-Cancelamento para demais estados (24h)'
  },
  {
    nome: '08-Cancelamento específico para Mato Grosso (3h)'
  },
  {
    nome: '09-Carta de correção'
  },
  {
    nome: '10-Cancelamento com a emissão de nota fiscal de entrada'
  },
  {
    nome: '11-Cancelamento extemporâneo'
  },
  {
    nome: '12-Recusas'
  },
  {
    nome: '13-Suporte ao monitor de nota fiscal (GRC)'
  },
  {
    nome: '14-Emissão de contra nota fiscal de energia elétrica'
  },
  {
    nome: '15-Emissão de contra nota fiscal de produtor rural'
  }
]
