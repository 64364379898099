export const torres =[
  {
    tipo:''
  },
  {
    tipo:'Fiscal'
  },
  {
    tipo:'Contábil'
  },
]
