export const quantidade_de_itens_lancamento = [
  {
    quantidade :''
  },
  {
    quantidade : 'De 1 a 10 Itens'
  },
  {
    quantidade: 'Acima de 10 Itens'
  }
]
