<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Pagamentos">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Lançamento de Notas OCR"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">


    <div class="form-group row">
      <label for="tipo_de_nota" class="col-sm-4 col-form-label">*Tipo de Nota</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tipo_de_nota" (change)="changeTipoDeNota($event)">
          <option *ngFor="let tipo of tipos_de_notas_ipe" [value]="tipo.tipo">{{tipo.tipo}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('tipo_de_nota')?.errors?.required && formulario.get('tipo_de_nota')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>
    <!-- <p> tipo: {{formulario.getRawValue().tipo_de_nota}}</p> -->

    <div *ngIf="formulario.getRawValue().tipo_de_nota !== '' &&
                (formulario.getRawValue().tipo_de_nota == 'Aluguel' ||
                formulario.getRawValue().tipo_de_nota == 'Beneficios' ||
                formulario.getRawValue().tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
                formulario.getRawValue().tipo_de_nota == 'Correio' ||
                formulario.getRawValue().tipo_de_nota == 'Escrituração - Não gera pagamento' ||
                formulario.getRawValue().tipo_de_nota == 'Importação' ||
                formulario.getRawValue().tipo_de_nota == 'Sem valor fiscal' ||
                formulario.getRawValue().tipo_de_nota == 'Serviço')">
      <div class="form-group row">
        <label for="anexo_nota_fiscal_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota Fiscal/Documento</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_nota_fiscal_ou_boleto" id="formFileMultiple" (change)="save2($event)" (change)="saveArquivoNf($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNf.length!=0">
        <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNf">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="clearFieldsOCR()" (click)="removeFileArquivoNf(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>
    
    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Materiais'">
      <div class="form-group row">
        <label for="anexo_nota_fiscal_documento_boleto_se_houver" class="col-sm-4 col-form-label">MATERIAIS *Anexo Nota
          Fiscal/Documento e Boleto se houver</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_nota_fiscal_documento_boleto_se_houver" id="formFileMultiple"
            (change)="save2($event)" (change)="saveArquivoNfBoleto($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNfBoleto.length!=0">
        <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosNfBoleto">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNfBoleto(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group row" *ngIf="arquivosNf.length!=0 || arquivosNfBoleto.length!=0">
      <label for="possui_boleto" class="col-sm-4 col-form-label">Possui boleto?</label>
      <div class="col-sm-8">
        <label class="col-sm-12 col-form-label" for="possui_boleto">
          <input type="checkbox" class="form-check-input"
             value='Possui boleto' (change)="changePossuiBoleto($event.currentTarget.checked)">
          Sim
        </label>
      </div>
    </div>

    <div *ngIf="possuiBoletoChecked">
      <div class="form-group row">
        <label for="anexo_boleto_ipe" class="col-sm-4 col-form-label">*Anexo Boleto IPE</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
            formControlName="anexo_boleto_ipe" id="formFileMultiple" (change)="save3($event)" (change)="saveArquivoBoleto($event)"
            multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosBoleto.length!=0">
        <div class="card-header">Anexo - Arquivo Boleto</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosBoleto">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoBoleto(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>



    <!-- <div class="form-group row">
      <label for="categoria" class="col-sm-4 col-form-label">*Categoria</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="categoria" (change)="changeCategoria($event)">
          <option *ngFor="let categoria of categorias" [value]="categoria.nome">{{categoria.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('categoria')?.errors?.required && formulario.get('categoria')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <!-- <div class="form-group row" *ngIf="formulario.getRawValue().categoria == 'PO'">
      <label for="subcategoria_po" class="col-sm-4 col-form-label">*Subcategoria</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="subcategoria_po" (change)="changeSubcategoria($event)">
          <option *ngFor="let sub of subcategorias" [value]="sub.nome">{{sub.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('subcategoria_po')?.errors?.required && formulario.get('subcategoria_po')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <!-- <div *ngIf="formulario.getRawValue().categoria == 'NPO'">
      <div class="form-group row">
        <label for="tipo_de_lan_amento" class="col-sm-4 col-form-label">*Tipo de Lançamento</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="tipo_de_lan_amento" (change)="changeTipoLancamento($event)">
            <option *ngFor="let tipo of tipo_de_lancamento" [value]="tipo.nome">{{tipo.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_lan_amento')?.errors?.required && formulario.get('tipo_de_lan_amento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>
    </div> -->

    <div *ngIf="formulario.getRawValue().subcategoria_po == 'Materiais'">
      <!-- <div class="form-group row">
        <label for="tipo_de_opera_o" class="col-sm-4 col-form-label">*Tipo de Operação</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="tipo_de_opera_o" (change)="changeTipoOperacao($event)">
            <option *ngFor="let tipo of tipo_de_operacao" [value]="tipo.nome">{{tipo.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('tipo_de_opera_o')?.errors?.required && formulario.get('tipo_de_opera_o')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo (XML)</label>
        <div class="col-sm-8">
          <input type="file" class="form-control form-anexo" aria-label="With textarea" accept="text/xml"
            formControlName="anexo_xml" id="formFileMultiple" (change)="saveArquivoXml($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosXml.length!=0">
        <div class="card-header">Anexo - Arquivo XML</div>
        <ul class="list-group list-group-flush" *ngFor="let file of arquivosXml">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file.name }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoXml(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="marque_todas_as_op_es_que_se_aplicam" class="col-sm-4 col-form-label">Serviço ou Material é um Ativo Fixo?</label>
        <div class="col-sm-8">
          <label class="col-sm-12 col-form-label" for="marque_todas_as_op_es_que_se_aplicam">
            <input type="checkbox" class="form-check-input" formControlName="marque_todas_as_op_es_que_se_aplicam"
              value='Serviço ou Material é um Ativo Fixo' (change)="isAtivoFixo($event)">
            Sim
          </label>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().marque_todas_as_op_es_que_se_aplicam == true">
        <div class="form-group row">
          <label for="n_mero_afe" class="col-sm-4 col-form-label">*Número da AFE</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="n_mero_afe">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('n_mero_afe')?.errors?.required && formulario.get('n_mero_afe')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>

    </div>



    <!-- <div *ngIf="(
                  formulario.getRawValue().subcategoria_po !== '' && 
                  formulario.getRawValue().subcategoria_po !== 'Frete' && 
                  formulario.getRawValue().subcategoria_po !== 'Beneficios' &&
                  formulario.getRawValue().subcategoria_po !== 'Importação' &&
                  formulario.getRawValue().categoria !== ''
                ) || 
                (
                  formulario.getRawValue().categoria == 'NPO' && 
                  formulario.getRawValue().tipo_de_lan_amento !== '' && 
                  formulario.getRawValue().tipo_de_lan_amento !== 'Benefícios'
                )"> -->
          <div *ngIf="formulario.getRawValue().tipo_de_nota !== 'Frete/CTE' && formulario.getRawValue().tipo_de_nota !== ''">

      <div class="form-group row">
        <label for="caracteristicas" class="col-sm-4 col-form-label">Juros e Multas?</label>
        <div class="col-sm-8">
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas" value='Todas as opções'
              (change)="changeCheckTodas($event.currentTarget.checked)" [checked]="princTodasOpcoesChecked">
            Todas as opções
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input #checkUrgente type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="urgenteChecked" value='Urgente' (change)="inserirNoArray($event.target.value)"
              (change)="changeCheckUrgente($event.currentTarget.checked)">
            Urgente
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="antecipacaoChecked" value='Antecipação' (change)="inserirNoArray($event.target.value)"
              (change)="changeCheckAntec($event.currentTarget.checked)">
            Antecipação
          </label> -->
          <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="jurosmultasChecked" value='Juros e Multas' (change)="inserirNoArray($event.target.value)"
              (change)="changeCheckJuros($event.currentTarget.checked)">
            Sim
          </label>
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="custoPlanejamentoChecked" value='Custo Planejado'
              (change)="inserirNoArray($event.target.value)"
              (change)="changecustoPlanejamento($event.currentTarget.checked)">
            Custo Planejado
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="debitoPosteriorChecked" value='Débito Posterior' (change)="inserirNoArray($event.target.value)"
              (change)="changeDebitoPosterior($event.currentTarget.checked)">
            Débito Posterior
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas"
              [checked]="importacaoChecked" value='Importação' (change)="inserirNoArray($event.target.value)"
              (change)="changeImportacao($event.currentTarget.checked)">
            Importação
          </label> -->

          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="bloqueioChecked"
              value='Bloqueio de Pagamento' (change)="inserirNoArray($event.target.value)"
              (change)="changeBloqueio($event.currentTarget.checked)">
            Bloqueio de Pagamento
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="riscoChecked"
              value='Risco Sacado' (change)="inserirNoArray($event.target.value)"
              (change)="changeRisco($event.currentTarget.checked)">
            Risco Sacado
          </label> -->
          <!-- <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input #checkContrato type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="todasOpcoesChecked"
            value = 'Incluir número de Contrato' (change)="changeCheckContrato($event.currentTarget.checked)">
            Incluir número de Contrato
          </label>
          <label class="col-sm-12 col-form-label" for="caracteristicas">
            <input #checkAprovador type="checkbox" class="form-check-input" formControlName="caracteristicas" [checked]="todasOpcoesChecked"
            value = 'Aprovador não é meu lider' (change)="changeCheckAprovador($event.currentTarget.checked)">
            Aprovador não é meu lider
          </label> -->
        </div>
      </div>

      <div class="form-group row" *ngIf="showMotivoUrgencia">
        <label for="motivo_de_urg_ncia" class="col-sm-4 col-form-label">*Motivo de Urgência</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="motivo_de_urg_ncia" (change)="changeMotivoUrgencia($event)">
            <option *ngFor="let item of motivos_de_urgencia" [value]="item.nome">{{item.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('motivo_de_urg_ncia')?.errors?.required && formulario.get('motivo_de_urg_ncia')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- <div class="form-group row" *ngIf="showNumContrato">
        <label for="n_mero_do_contrato" class="col-sm-4 col-form-label">*Número do Contrato</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="n_mero_do_contrato">
            <option *ngFor="let item of listaNumContratos" [value]="item.item">{{item.item}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('n_mero_do_contrato')?.errors?.required && formulario.get('n_mero_do_contrato')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      <div class="form-group row" *ngIf="formulario.getRawValue().motivo_de_urg_ncia == 'Outros'">
        <label for="descricao_outros" class="col-sm-4 col-form-label">*Descrição Outros</label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName="descricao_outros"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descricao_outros')?.errors?.required && formulario.get('descricao_outros')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!-- *ngIf="formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
              && formulario.getRawValue().tipo_de_nota !='Serviço'
              && formulario.getRawValue().tipo_de_nota !='Beneficios'
              && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
              && formulario.getRawValue().tipo_de_nota !='Importação'
              && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'"
              > -->
      <div class="form-group row">

        <label for="fornecedor" class="col-sm-4 col-form-label">*Fornecedor</label>
        <div class="col-sm-8">
          <div class="custom-select fakeSelect"
            [ngClass]="{'ng-invalid': !fornecedorSelecionado, 'ng-valid': fornecedorSelecionado}"
            (click)="cliqueSelectFornecedor()">{{fornecedorSelecionado ? fornecedorSelecionado.nome : ""}}
          </div>
          <div *ngIf="exibirListaFornecedores" class="quadroSelectApi">
            <div class="searchSelectApi">
              <i class="fa fa-search" aria-hidden="true"></i>
              <i class="fa fa-remove" id="iconClearFornecedor" (click)="clearFornecedor()" aria-hidden="true"></i>
              <input type="text" class="inputSearch" formControlName="inputSearchFornecedor"
                placeholder="Ex: 11.111.111-1111/11 ou 11111111111111" (keyup)="searchFornecedor()">
            </div>
            <!-- <div *ngIf="!listaFornecedores.length"
              class="sem-result-select-api list-group-item list-group-item-action flex-column align-items-start">
              Nenhum resultado encontrado para este filtro
            </div> -->
            <div class="list-group selectApi" *ngIf="listaFornecedores.length">
              <div *ngFor="let item of listaFornecedores" (click)="selecionaFornecedor(item)"
                class="list-group-item list-group-item-action flex-column align-items-start">
                <p class="titleList">{{item.nome}}</p>
                <p class="subtitleList" *ngIf="item.cnpj">CNPJ</p>
                <p class="valueList" *ngIf="item.cnpj">{{item.cnpj}}</p>
                <p class="subtitleList" *ngIf="item.codErp">CÓDIGO ERP</p>
                <p class="valueList" *ngIf="item.codErp">{{item.codErp}}</p>
              </div>
            </div>
          </div>
          <app-mensagem class="col-form-label mensagem-erro" *ngIf="exibeErroFornecedor"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="this.fornecedorSelecionado && this.fornecedorSelecionado.nome == 'NÃO CADASTRADO/ENCONTRADO'">
        <label for="cpf_cnpj_fornecedor" class="col-sm-4 col-form-label">*CPF CNPJ Fornecedor</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cpf_cnpj_fornecedor" mask="000.000.000-00||00.000.000/0000-00" [validation]="false">
        </div>
      </div>

      <!-- *ngIf="formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
      && formulario.getRawValue().tipo_de_nota !='Serviço'
      && formulario.getRawValue().tipo_de_nota !='Beneficios'
      && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
      && formulario.getRawValue().tipo_de_nota !='Importação'
      && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'" -->
      <div class="form-group row">
        <label for="tomador" class="col-sm-4 col-form-label">*Empresa Nutrien</label>
        <div class="col-sm-8">
          <div class="custom-select fakeSelect"
            [ngClass]="{'ng-invalid': !tomadorSelecionado, 'ng-valid': tomadorSelecionado}"
            (click)="cliqueSelectTomador()">{{tomadorSelecionado ? tomadorSelecionado.nome : ""}}
          </div>
          <div *ngIf="exibirListaTomadores" class="quadroSelectApi">
            <div class="searchSelectApi">
              <i class="fa fa-search" aria-hidden="true"></i>
              <i class="fa fa-remove" id="iconClearTomador" (click)="clearTomador()" aria-hidden="true"></i>
              <input type="text" class="inputSearch" formControlName="inputSearchTomador"
                placeholder="Ex: 11.111.111-1111/11 ou 11111111111111" (keyup)="searchTomador()">
            </div>
            <!-- <div *ngIf="!listaTomadores.length"
              class="sem-result-select-api list-group-item list-group-item-action flex-column align-items-start">
              Nenhum resultado encontrado para este filtro
            </div> -->
            <div class="list-group selectApi" *ngIf="listaTomadores.length">
              <div *ngFor="let item of listaTomadores" (click)="selecionaTomador(item)"
                class="list-group-item list-group-item-action flex-column align-items-start">
                <p class="titleList">{{item.nome}}</p>
                <p class="subtitleList" *ngIf="item.cnpj">CNPJ</p>
                <p class="valueList" *ngIf="item.cnpj">{{item.cnpj}}</p>
                <p class="subtitleList" *ngIf="item.codErp">CÓDIGO ERP</p>
                <p class="valueList" *ngIf="item.codErp">{{item.codErp}}</p>
              </div>
            </div>
          </div>
          <app-mensagem class="col-form-label mensagem-erro" *ngIf="exibeErroTomador"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="this.tomadorSelecionado && this.tomadorSelecionado.nome == 'NÃO CADASTRADO/ENCONTRADO'">
        <label for="cnpj_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ Empresa Nutrien</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="cnpj_empresa_nutrien" mask="00.000.000/0000-00" [validation]="false">
        </div>
      </div>

      <div class="form-group row">
        <label for="e_mail_do_requisitante" class="col-sm-4 col-form-label">*E-mail do Requisitante</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_do_requisitante"
              (keyup)="validaCampoEmail()">
            <span>@nutrien.com</span>
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_do_requisitante')?.errors?.required && formulario.get('e_mail_do_requisitante')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          <!-- <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_do_requisitante')?.errors?.email && formulario.get('e_mail_do_requisitante')?.touched"
            mensagem="Formato de e-mail inválido"></app-mensagem> -->
        </div>
      </div>


      <!-- <div class="form-group row">
        <label for="e_mail_do_fornecedor" class="col-sm-4 col-form-label">E-mail do Fornecedor</label>
        <div class="col-sm-8 position-relative">
          <div class="dominioEmail">
            <input type="email" class="form-control" formControlName="e_mail_do_fornecedor">
          </div>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_do_fornecedor')?.errors?.email && formulario.get('e_mail_do_fornecedor')?.touched"
            mensagem="Formato de e-mail inválido"></app-mensagem> -->
            <!-- <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('e_mail_do_fornecedor')?.errors?.required && formulario.get('e_mail_do_fornecedor')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem> -->
        <!-- </div>
      </div> -->

      <!-- <div class="form-group row" *ngIf="showAprovador">
        <label for="aprovador" class="col-sm-4 col-form-label">*Aprovador</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="aprovador">
            <option *ngFor="let item of listaAprovadores" [value]="item.item">{{item.item}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('aprovador')?.errors?.required && formulario.get('aprovador')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div> -->

      

    

      <div *ngIf="formulario.getRawValue().tipo_de_opera_o == 'Matéria Prima'">
        <div class="form-group row">
          <label for="migo" class="col-sm-4 col-form-label">*MIGO</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="migo">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('migo')?.errors?.required && formulario.get('migo')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços'
               || formulario.getRawValue().subcategoria_po == 'Beneficios'
               || formulario.getRawValue().subcategoria_po == 'Escrituração - Não gera pagamento'
               || formulario.getRawValue().subcategoria_po == 'Importação'
               || formulario.getRawValue().subcategoria_po == 'Sem valor fiscal'"> <!--|| formulario.getRawValue().subcategoria_po == 'Materiais'-->
        <div class="form-group row">
          <label for="anexo_nota_fiscal_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota Fiscal/Documento e Boleto
            se houver</label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
              formControlName="anexo_nota_fiscal_ou_boleto" id="formFileMultiple" (change)="save2($event)" (change)="saveArquivoNf($event)"
              multiple="multiple">
          </div>
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNf.length!=0">
          <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosNf">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNf(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>

        <div class="form-group row">
          <label for="marque_todas_as_op_es_que_se_aplicam" class="col-sm-4 col-form-label">Serviço ou Material é um Ativo Fixo?</label>
          <div class="col-sm-8">
            <label class="col-sm-12 col-form-label" for="marque_todas_as_op_es_que_se_aplicam">
              <input type="checkbox" class="form-check-input" formControlName="marque_todas_as_op_es_que_se_aplicam"
                value='Serviço ou Material é um Ativo Fixo' (change)="isAtivoFixo($event)" >
              Sim
            </label>
          </div>
        </div>


        <div *ngIf="formulario.getRawValue().marque_todas_as_op_es_que_se_aplicam == true">
          <div class="form-group row">
            <label for="n_mero_afe" class="col-sm-4 col-form-label">*Número da AFE</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="n_mero_afe">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('n_mero_afe')?.errors?.required && formulario.get('n_mero_afe')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="formulario.getRawValue().subcategoria_po == 'Boleto' || 
                  formulario.getRawValue().subcategoria_po == 'Nota de Débito' || 
                  formulario.getRawValue().subcategoria_po == 'Telefonia' || 
                  formulario.getRawValue().subcategoria_po == 'Água' || 
                  formulario.getRawValue().subcategoria_po == 'Energia' || 
                  formulario.getRawValue().subcategoria_po == 'Fatura' || 
                  formulario.getRawValue().subcategoria_po == 'Frete' || 
                  formulario.getRawValue().subcategoria_po == 'Aluguel' || 
                  formulario.getRawValue().subcategoria_po == 'Correios'">
        <div class="form-group row">
          <label for="anexo_nota_fiscal_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota Fiscal/Documento e Boleto
            se houver</label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".pdf"
              formControlName="anexo_nota_fiscal_ou_boleto" id="formFileMultiple" (change)="save2($event)" (change)="saveArquivoNf($event)"
              multiple="multiple">
          </div>
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNf.length!=0">
          <div class="card-header">Anexo - Arquivo Nota Fiscal/Documento e Boleto</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosNf">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNf(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div> -->

      <div *ngIf="formulario.getRawValue().categoria == 'NPO'">

        <!-- <div *ngIf="formulario.getRawValue().tipo_de_lan_amento == 'Depósito Judicial'">
          <div class="form-group row">
            <label for="n_mero_do_processo" class="col-sm-4 col-form-label">*Número do Processo</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="n_mero_do_processo">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('n_mero_do_processo')?.errors?.required && formulario.get('n_mero_do_processo')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
        </div> -->

        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Template Excel</label>
          <div class="col-sm-8">
            <a class="mt-2 d-block"
              href="https://app-storage-service.pipefy.com/v1/signed/uploads/4218e504-eee7-4467-9b85-58e402f1eb04/IPECoreFile-NPOCODINGTEMPLATEv2.xlsx?signature=rPAD3sJwE%2FwRtgkd0e4Gsk1qfVfT%2BGTxOMbx8eETtQc%3D"
              target="_blank" rel="noopener noreferrer">
              Rateio, Centro de Custos e Contas Contábeis
            </a>
          </div>
        </div>

        <div class="form-group row">
          <label for="anexo_npo_modelo_excel" class="col-sm-4 col-form-label">*Anexo (NPO) - Modelo Excel</label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea" accept=".xlsx"
              formControlName="anexo_npo_modelo_excel" id="formFileMultiple" (change)="saveArquivoNpo($event)"
              multiple="multiple">
          </div>
        </div>

        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosNpo.length!=0">
          <div class="card-header">Anexo - Arquivo NPO</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosNpo">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoNpo(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <!--*ngIf="formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
      && formulario.getRawValue().tipo_de_nota !='Serviço'
      && formulario.getRawValue().tipo_de_nota !='Beneficios'
      && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
      && formulario.getRawValue().tipo_de_nota !='Importação'
      && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'"-->
      <div class="form-group row">
        <label for="data_de_emiss_o" class="col-sm-4 col-form-label">*Data de Emissão</label>
        <div class="col-sm-8">
          <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_emiss_o">
          <app-mensagem class="col-form-label mensagem-erro" *ngIf="formulario.getRawValue().data_de_emiss_o > dataAtual"
            mensagem="Não é permitido data futura"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_emiss_o')?.errors?.required && formulario.get('data_de_emiss_o')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!--<*ngIf="formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
      && formulario.getRawValue().tipo_de_nota !='Serviço'
      && formulario.getRawValue().tipo_de_nota !='Beneficios'
      && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
      && formulario.getRawValue().tipo_de_nota !='Importação'
      && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'"-->
      <div class="form-group row">
        <label for="data_de_vencimento" class="col-sm-4 col-form-label">*Data de Vencimento</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" formControlName="data_de_vencimento">
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('data_de_vencimento')?.errors?.required && formulario.get('data_de_vencimento')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div class="form-group row" *ngIf="formulario.getRawValue().tipo_de_nota =='Importação'">
        <label for="moeda" class="col-sm-4 col-form-label">*Moeda</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="moeda">
            <option *ngFor="let moeda of moedas" [value]="moeda.nome">{{moeda.nome}}
            </option>
          </select>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('moeda')?.errors?.required && formulario.get('moeda')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!--*ngIf="formulario.getRawValue().categoria == 'PO'
          && formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
          && formulario.getRawValue().tipo_de_nota !='Serviço'
          && formulario.getRawValue().tipo_de_nota !='Beneficios'
          && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
          && formulario.getRawValue().tipo_de_nota !='Importação'
          && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'"-->
      <div class="form-group row" *ngIf="formulario.getRawValue().categoria == 'PO'">
        <label for="numero_da_nota_fiscal" class="col-sm-4 col-form-label">*Número da Nota Fiscal</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="numero_da_nota_fiscal">
          <small>Informar apenas números e traços/barra. Ex: 1111 ou 11/11-1</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('numero_da_nota_fiscal')?.errors?.required && formulario.get('numero_da_nota_fiscal')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <!--*ngIf="formulario.getRawValue().tipo_de_nota !='Material Compras e aquisição'
      && formulario.getRawValue().tipo_de_nota !='Serviço'
      && formulario.getRawValue().tipo_de_nota !='Beneficios'
      && formulario.getRawValue().tipo_de_nota !='Escrituração - Não gera pagamento'
      && formulario.getRawValue().tipo_de_nota !='Importação'
      && formulario.getRawValue().tipo_de_nota !='Sem valor fiscal'"-->
      <div class="form-group row">
        <label for="valor_da_fatura" class="col-sm-4 col-form-label">*Valor da Fatura</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="valor_da_fatura"
            (keyup)="formataValorDecimal($event.target.value)" (blur)="exibeConfirmaValor()" [value]="valorFaturaAlterado">
          <small>Informar apenas números. Ex: 1111</small>
          <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('valor_da_fatura')?.errors?.required && formulario.get('valor_da_fatura')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços' 
      || formulario.getRawValue().categoria == 'NPO'
      || formulario.getRawValue().subcategoria_po == 'Beneficios'
      || formulario.getRawValue().subcategoria_po == 'Escrituração - Não gera pagamento'
      || formulario.getRawValue().subcategoria_po == 'Importação'
      || formulario.getRawValue().subcategoria_po == 'Sem valor fiscal'
      ">
        <div class="form-group row">
          <label for="boleto_para_pagamento" class="col-sm-4 col-form-label">*Boleto para Pagamento</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='boleto_para_pagamento' value="Sim" class='radio-btn' (change)="onBoletoPagamentoChange($event)">&nbsp;Sim
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='boleto_para_pagamento' value='Não' class='radio-btn' (change)="onBoletoPagamentoChange($event)">&nbsp;Não
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('boleto_para_pagamento')?.errors?.required && formulario.get('boleto_para_pagamento')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
        <div class="form-group row">
          <label for="forma_de_pagamento" class="col-sm-4 col-form-label">Forma de Pagamento</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="forma_de_pagamento">
              <option *ngFor="let forma of formas_pagamento" [value]="forma.nome">{{forma.nome}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().subcategoria_po == 'Serviços'">
        <div class="form-group row">
          <label for="folha_servicos" class="col-sm-4 col-form-label">Folha de Serviços</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="folha_servicos">
            <!-- <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('folha_servicos')?.errors?.required && formulario.get('folha_servicos')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem> -->
          </div>
        </div>
      </div>

      <!-- ***** RETIRADO POR DUPLICIDADE ***** -->
      <!-- <div *ngIf="formulario.getRawValue().categoria == 'NPO'">
        <div class="form-group row">
          <label for="favorecido" class="col-sm-4 col-form-label">*Favorecido</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="favorecido">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('favorecido')?.errors?.required && formulario.get('favorecido')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="valor_do_fi" class="col-sm-4 col-form-label">*Valor do FI</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="valor_do_fi">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('valor_do_fi')?.errors?.required && formulario.get('valor_do_fi')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="form-group row">
          <label for="setor" class="col-sm-4 col-form-label">Setor</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="setor">
              <option *ngFor="let tipo of setores" [value]="tipo.nome">{{tipo.nome}}
              </option>
            </select>
          </div>
        </div>
      </div> -->

      <div class="form-group row">
        <label for="descri_o_breve" class="col-sm-4 col-form-label">Descrição Breve</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="descri_o_breve">
          <!-- <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formulario.get('descri_o_breve')?.errors?.required && formulario.get('descri_o_breve')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem> -->
        </div>
      </div>

      <div *ngIf="formulario.getRawValue().categoria == 'PO'">
        <!--*ngIf="formulario.getRawValue().subcategoria_po == 'Serviços' || formulario.getRawValue().subcategoria_po == 'Materiais'"-->
        <div class="form-group row">
          <label for="qtde_pedidos" class="col-sm-4 col-form-label">*Quantidade de Pedidos</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="qtde_pedidos" (change)="onTipoChange()">
              <option *ngFor="let qtde of quantidade_pedidos" [value]="qtde.nome">{{qtde.nome}}
              </option>
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formulario.get('qtde_pedidos')?.errors?.required && formulario.get('qtde_pedidos')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 1">
          <div class="form-group row">
            <label for="numero_pedido_1" class="col-sm-4 col-form-label">*Número do Pedido 1</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_1">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_1')?.errors?.required && formulario.get('numero_pedido_1')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_1" class="col-sm-4 col-form-label">*Linha do Pedido 1</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_1">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_1')?.errors?.required && formulario.get('numero_linha_pedido_1')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 2">
          <div class="form-group row">
            <label for="numero_pedido_2" class="col-sm-4 col-form-label">*Número do Pedido 2</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_2">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_2')?.errors?.required && formulario.get('numero_pedido_2')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_2" class="col-sm-4 col-form-label">*Linha do Pedido 2</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_2">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_2')?.errors?.required && formulario.get('numero_linha_pedido_2')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 3">
          <div class="form-group row">
            <label for="numero_pedido_3" class="col-sm-4 col-form-label">*Número do Pedido 3</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_3">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_3')?.errors?.required && formulario.get('numero_pedido_3')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_3" class="col-sm-4 col-form-label">*Linha do Pedido 3</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_3">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_3')?.errors?.required && formulario.get('numero_linha_pedido_3')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 4">
          <div class="form-group row">
            <label for="numero_pedido_4" class="col-sm-4 col-form-label">*Número do Pedido 4</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_4">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_4')?.errors?.required && formulario.get('numero_pedido_4')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_4" class="col-sm-4 col-form-label">*Linha do Pedido 4</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_4">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_4')?.errors?.required && formulario.get('numero_linha_pedido_4')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 5">
          <div class="form-group row">
            <label for="numero_pedido_5" class="col-sm-4 col-form-label">*Número do Pedido 5</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_5">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_5')?.errors?.required && formulario.get('numero_pedido_5')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_5" class="col-sm-4 col-form-label">*Linha do Pedido 5</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_5">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_5')?.errors?.required && formulario.get('numero_linha_pedido_5')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 6">
          <div class="form-group row">
            <label for="numero_pedido_6" class="col-sm-4 col-form-label">*Número do Pedido 6</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_6">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_6')?.errors?.required && formulario.get('numero_pedido_6')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_6" class="col-sm-4 col-form-label">*Linha do Pedido 6</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_6">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_6')?.errors?.required && formulario.get('numero_linha_pedido_6')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 7">
          <div class="form-group row">
            <label for="numero_pedido_7" class="col-sm-4 col-form-label">*Número do Pedido 7</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_7">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_7')?.errors?.required && formulario.get('numero_pedido_7')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_7" class="col-sm-4 col-form-label">*Linha do Pedido 7</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_7">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_7')?.errors?.required && formulario.get('numero_linha_pedido_7')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 8">
          <div class="form-group row">
            <label for="numero_pedido_8" class="col-sm-4 col-form-label">*Número do Pedido 8</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_8">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_8')?.errors?.required && formulario.get('numero_pedido_8')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_8" class="col-sm-4 col-form-label">*Linha do Pedido 8</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_8">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_8')?.errors?.required && formulario.get('numero_linha_pedido_8')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 9">
          <div class="form-group row">
            <label for="numero_pedido_9" class="col-sm-4 col-form-label">*Número do Pedido 9</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_9">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_9')?.errors?.required && formulario.get('numero_pedido_9')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_9" class="col-sm-4 col-form-label">*Linha do Pedido 9</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_9">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_9')?.errors?.required && formulario.get('numero_linha_pedido_9')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos >= 10">
          <div class="form-group row">
            <label for="numero_pedido_10" class="col-sm-4 col-form-label">*Número do Pedido 10</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" formControlName="numero_pedido_10">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_pedido_10')?.errors?.required && formulario.get('numero_pedido_10')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <div class="form-group row">
            <label for="numero_linha_pedido_10" class="col-sm-4 col-form-label">*Linha do Pedido 10</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_linha_pedido_10">
              <small>e.g. xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('numero_linha_pedido_10')?.errors?.required && formulario.get('numero_linha_pedido_10')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
          <hr class="line">
        </div>

        <div class="detalhes-pedido" *ngIf="formulario.getRawValue().qtde_pedidos == 'Mais que 10'">
          <div class="form-group row">
            <label for="lista_pedidos" class="col-sm-4 col-form-label">*Lista de Pedidos</label>
            <div class="col-sm-8">
              <textarea class="form-control" aria-label="With textarea" formControlName="lista_pedidos"></textarea>
              <small>e.g. <br />
                xxxx : xxx,xxx-xxx<br />
                xxxx : xxx,xxx-xxx</small>
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formulario.get('lista_pedidos')?.errors?.required && formulario.get('lista_pedidos')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- ********* Lancamento de Notas -->

    <!-- <div *ngIf="formulario.getRawValue().subcategoria_po == 'Frete ' || 
                formulario.getRawValue().subcategoria_po == 'Benefícios' || 
                formulario.getRawValue().subcategoria_po == 'Importação' || 
                formulario.getRawValue().subcategoria_po == 'Nota de Crédito' ||
                formulario.getRawValue().tipo_de_lan_amento == 'Benefícios'"> -->

                <div *ngIf="formulario.getRawValue().tipo_de_nota =='Frete/CTE'">
      <form [formGroup]="formularioLancamentos" (submit)="enviarFormulario()">

        <div class="form-group row">
          <label for="nome" class="col-sm-4 col-form-label">*Nome do Solicitante: </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nome" [attr.disabled]="true">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('nome')?.errors?.required && formularioLancamentos.get('nome')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="email" class="col-sm-4 col-form-label">*E-mail do Solicitante: </label>
          <div class="col-sm-8">
            <input type="email" class="form-control" formControlName="email" [attr.disabled]="true">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('email')?.errors?.required && formularioLancamentos.get('email')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('email')?.errors?.email && formularioLancamentos.get('email')?.touched"
              mensagem="Formato de e-mail inválido"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="cnpj_da_empresa_nutrien" mask="00.000.000/0000-00">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('cnpj_da_empresa_nutrien')?.errors?.required && formularioLancamentos.get('cnpj_da_empresa_nutrien')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="nome_empresa_nutrien" (change)="onEmpresaChange($event)">
              <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('nome_empresa_nutrien')?.errors?.required && formularioLancamentos.get('nome_empresa_nutrien')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <!-- <div class="form-group row">
          <label for="cnpj_da_empresa_nutrien" class="col-sm-4 col-form-label">*CNPJ da empresa Nutrien</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="cnpj_da_empresa_nutrien" (change)="onCnpjEmpresaNutrienChange($event)">
              <option *ngFor="let cnpj of listaEmpresasCnpjs" [value]="cnpj.cnpj">{{cnpj.cnpj}}</option>
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('cnpj_da_empresa_nutrien')?.errors?.required && formularioLancamentos.get('cnpj_da_empresa_nutrien')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="nome_empresa_nutrien" class="col-sm-4 col-form-label">*Nome empresa Nutrien </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nome_empresa_nutrien" [attr.disabled]="true" placeholder="Selecione o CNPJ da empresa acima">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('nome_empresa_nutrien')?.errors?.required && formularioLancamentos.get('nome_empresa_nutrien')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div> -->
    
        <div class="form-group row">
          <label for="risco_sacado" class="col-sm-4 col-form-label">Risco Sacado </label>
          <div class="col-sm-8">
            <input type="checkbox" class="form-check-input" formControlName="risco_sacado" value="Sim"
              (change)='riscoSacado=!riscoSacado' (change)="riscoSacadoValue($event)">
            <small class="form-check-label">Selecionar apenas se NF for relacionada a Risco Sacado. Antecipação de valores a
              fornecedores</small>
          </div>
        </div>
    
        <div class="form-group row">
          <!-- <div class="form-group row"  *ngIf="this.formularioLancamentos.getRawValue().nome_empresa_nutrien!=''"> -->
    
          <label for="tipo_de_nota" class="col-sm-4 col-form-label">*Tipo de Nota </label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="tipo_de_nota" (change)="onTipoDeNotaChange($event)">
              <!-- <option *ngFor="let tipo of tiposDeNotas" [value]="tipo.tipo">{{tipo.tipo}}</option> -->
              <!-- <option value="">Selecione...</option>
              <option value="Aluguel">Aluguel</option>
              <option value="Ativo fixo (AFE)">Ativo fixo (AFE)</option>
              <option value="Benefícios">Benefícios</option>
              <option value="Cartório">Cartório</option>
              <option value="Contas de consumo (Luz, água, telefone, internet, gás)">Contas de consumo (Luz, água, telefone,
                internet, gás)</option>
              <option value="Correio">Correio</option>
              <option value="Devolução de Cliente">Devolução de Cliente</option>
              <option value="Escrituração - Não gera pagamento">Escrituração - Não gera pagamento</option> -->
              <option value="Frete/CTE">Frete/CTE</option>
              <!-- <option value="Guias/Imposto/Taxas">Guias/Imposto/Taxas</option>
              <option value="Importação">Importação</option>
              <option value="Incentivo - Doação">Incentivo - Doação</option>
              <option value="Industrialização">Industrialização</option>
              <option value="Jurídico">Jurídico</option>
              <option value="Material (DANFE)">Material (DANFE)</option>
              <option value="Nota de Crédito">Nota de Crédito</option>
              <option value="Outras NFs sem PO">Outras NFs sem PO</option>
              <option value="Sem valor fiscal">Sem valor fiscal</option>
              <option value="Serviço">Serviço</option> -->
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('tipo_de_nota')?.errors?.required && formularioLancamentos.get('tipo_de_nota')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
    
        <div class="form-group row" *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE'">
          <!-- *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete' || this.formularioLancamentos.getRawValue().tipo_de_nota=='CTE'"> -->
    
          <label for="qual_a_quantidade_de_cte" class="col-sm-4 col-form-label">*Qual a quantidade de CTE </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="qual_a_quantidade_de_cte">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('qual_a_quantidade_de_cte')?.errors?.required && formularioLancamentos.get('qual_a_quantidade_de_cte')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row" *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Ativo fixo (AFE)'">
          <label for="n_mero_afe" class="col-sm-4 col-form-label">*Número AFE </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="n_mero_afe">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('n_mero_afe')?.errors?.required && formularioLancamentos.get('n_mero_afe')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row"
          *ngIf="formularioLancamentos.getRawValue().tipo_de_nota == 'Guias/Imposto/Taxas'
          ||formularioLancamentos.getRawValue().tipo_de_nota == 'Aluguel' 
          || formularioLancamentos.getRawValue().tipo_de_nota == 'Jurídico' 
          || formularioLancamentos.getRawValue().tipo_de_nota == 'Devolução cliente'">
          <!-- *ngIf="formularioLancamentos.getRawValue().tipo_de_nota ==='Reembolso' || formularioLancamentos.getRawValue().tipo_de_nota =='Aluguel' || formularioLancamentos.getRawValue().tipo_de_nota =='Guias'"> -->
          <label for="conta_contabil" class="col-sm-4 col-form-label">*Conta Contábil</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="conta_contabil">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('conta_contabil')?.errors?.required && formularioLancamentos.get('conta_contabil')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="documento_do_fornecedor" class="col-sm-4 col-form-label">*Documento do Fornecedor</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='documento_do_fornecedor' value="CPF" class='radio-btn'
                (change)="onDocumentoChange($event)">&nbsp;CPF
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='documento_do_fornecedor' value='CNPJ' class='radio-btn'
                (change)="onDocumentoChange($event)">&nbsp;CNPJ
            </label>
            <small>Ao selecionar CPF você será redirecionado para outro formulário.</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('documento_do_fornecedor')?.errors?.required && formularioLancamentos.get('documento_do_fornecedor')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
    
        </div>
    
    
    
    
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().documento_do_fornecedor=='CPF'">
          <label for="cpf_do_fornecedor" class="col-sm-4 col-form-label">*CPF Fornecedor</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="cpf_do_fornecedor" mask='000.000.000-99'>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('cpf_do_fornecedor')?.errors?.required && formularioLancamentos.get('cpf_do_fornecedor')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
    
        </div>
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().documento_do_fornecedor=='CNPJ'">
          <label for="cnpj_fornecedor" class="col-sm-4 col-form-label">*CNPJ Fornecedor</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="cnpj_fornecedor" mask="00.000.000/0000-00">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('cnpj_fornecedor')?.errors?.required && formularioLancamentos.get('cnpj_fornecedor')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
    
        </div>
    
        <div class="form-group row">
          <label for="nome_do_fornecedor" class="col-sm-4 col-form-label">*Nome do Fornecedor</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nome_do_fornecedor">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('nome_do_fornecedor')?.errors?.required && formularioLancamentos.get('nome_do_fornecedor')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
    
        <div class="form-group row">
          <label for="nro_nota" class="col-sm-4 col-form-label">*Número da Nota</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="nro_nota">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('nro_nota')?.errors?.required && formularioLancamentos.get('nro_nota')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
    
        </div>
    
        <div class="form-group row">
          <label for="data_de_envio_de_nf" class="col-sm-4 col-form-label">*Data de emissão da NF</label>
          <div class="col-sm-8">
            <input type="date" [max]="dataAtual" class="form-control" formControlName="data_de_envio_de_nf">
            <app-mensagem class="col-form-label mensagem-erro" *ngIf="formularioLancamentos.getRawValue().data_de_envio_de_nf > dataAtual"
              mensagem="Não é permitido data futura"></app-mensagem>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('data_de_envio_de_nf')?.errors?.required && formularioLancamentos.get('data_de_envio_de_nf')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="valor_total_da_nf" class="col-sm-4 col-form-label">*Valor Total da NF</label>
          <div class="col-sm-8">
            <!-- <input type="text" class="form-control" formControlName="valor_total_da_nf" mask="separator.2"
              thousandSeparator="." [validation]="false">
            <small>Informar apenas números. Ex: 1.111.111</small> -->

            <input type="text" class="form-control" formControlName="valor_total_da_nf"
              (keyup)="formataValorTotalNfDecimal($event.target.value)" [value]="valorTotalNfAlterado">
              <small>Informar apenas números. Ex: 1111</small>
    
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('valor_total_da_nf')?.errors?.required && formularioLancamentos.get('valor_total_da_nf')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row"
          *ngIf="(this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE') ||
        (this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Serviço')">
    
          <!-- *ngIf="this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete'"> -->
    
    
          <label for="nf_do_produto_original" class="col-sm-4 col-form-label">NF Do Produto Original </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="nf_do_produto_original" id="formFileMultiple" (change)="saveAnexoNfProdutoOriginal($event)"
              multiple="multiple">
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="nfProdutoOriginal.length!=0">
          <div class="card-header">Anexo - Nf Produto Original</div>
          <ul class="list-group list-group-flush" *ngFor="let file of nfProdutoOriginal">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNfProdutoOriginal(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
        <div class="form-group row"
          *ngIf="this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE'">
          <!-- *ngIf="this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete'"> -->
    
          <label for="n_mero_de_cte" class="col-sm-4 col-form-label">Número de CTE</label>
          <div class="col-sm-8 ">
            <input type="text" class="form-control mb-1" formControlName="n_mero_de_cte">
          </div>
        </div>
    
        <div class="form-group row"
          *ngIf="(this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' || this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Agrichem')
          && this.formularioLancamentos.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas'">
          <!-- *ngIf="this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Guias'"> -->
    
    
          <label for="opex_e_capex" class="col-sm-4 col-form-label">OPEX ou Capex?</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='opex_e_capex' value="Opex" class='radio-btn'>&nbsp;OPEX
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='opex_e_capex' value='Capex' class='radio-btn'>&nbsp;CAPEX
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('opex_e_capex')?.errors?.required && formularioLancamentos.get('opex_e_capex')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().tipo_de_nota=='Importação'">
          <label for="tipo_de_importa_o" class="col-sm-4 col-form-label">Tipo de Importação</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='tipo_de_importa_o' value="Serviço" class='radio-btn'>&nbsp;Serviço
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='tipo_de_importa_o' value='Material' class='radio-btn'>&nbsp;Material
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('tipo_de_importa_o')?.errors?.required && formularioLancamentos.get('tipo_de_importa_o')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="tipo_de_pagamento" class="col-sm-4 col-form-label">Tipo de Pagamento é Boleto? </label>
          <div class="col-sm-8">
            <input type="radio" class="form-check-input" formControlName="tipo_de_pagamento" value="Boleto">
            <small class="form-check-label"> Marcar se tipo de pagamento for boleto</small>
          </div>
        </div>
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE'|| formularioLancamentos.getRawValue().tipo_de_nota=='Serviço'">
          <label for="informacoes_complementares_da_po" class="col-sm-4 col-form-label">Informações Complementares da PO
          </label>
          <div class="col-sm-8">
            <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
                class="form-check-input" formControlName="informacoes_complementares_da_po"
                (change)="custoComplementar=!custoComplementar" (change)="inserirNoArray($event)"
                value='Custo planejado?'>&nbsp;&nbsp;&nbsp;&nbsp; Custo Planejado? </label>
    
            <label class="col-sm-12 col-form-label" for="informacoes_complementares_da_po"><input type="checkbox"
                class="form-check-input" formControlName="informacoes_complementares_da_po"
                (change)="debitoPosterior=!debitoPosterior" (change)="inserirNoArray($event)"
                value='Debito posterior?'>&nbsp;&nbsp;&nbsp;&nbsp; Débito Posterior</label>
          </div>
        </div>
    
        <div class="form-group row"
          *ngIf="(this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE' && custoComplementar)||
        (this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Serviço' && custoComplementar)">
          <label for="tipo_de_custo_planejado" class="col-sm-4 col-form-label">Tipo de Custo Planejado</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='tipo_de_custo_planejado' value="Armazenagem"
                class='radio-btn'>&nbsp;Armazenagem
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='tipo_de_custo_planejado' value='Capatazia'
                class='radio-btn'>&nbsp;Capatazia
            </label>
    
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='tipo_de_custo_planejado' value='Frete' class='radio-btn'>&nbsp;Frete
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('tipo_de_custo_planejado')?.errors?.required && formularioLancamentos.get('tipo_de_custo_planejado')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="nota_com_rasteio_de_valores" class="col-sm-4 col-form-label">Nota com rateio de valores? </label>
          <div class="col-sm-8">
            <input type="checkbox" class="form-check-input" formControlName="nota_com_rasteio_de_valores"
              (change)='notaComRateios=!notaComRateios' (change)="notaComRateiosValue($event)">
          </div>
        </div>
    
        <div class="form-group row" *ngIf="notaComRateios">
          <label for="arquivo_de_rateio" class="col-sm-4 col-form-label">Arquivo de Rateio </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="arquivo_de_rateio" id="formFileMultiple" (change)="saveArquivoRateio($event)"
              multiple="multiple">
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="arquivosDeRateio.length!=0">
          <div class="card-header">Anexo - Arquivo de Rateio</div>
          <ul class="list-group list-group-flush" *ngFor="let file of arquivosDeRateio">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileArquivoRateio(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
        <div class="form-group row">
          <label for="data_de_vencimento_da_nota" class="col-sm-4 col-form-label">*Data de Vencimento da Nota </label>
          <div class="col-sm-8">
            <input type="date" class="form-control" formControlName="data_de_vencimento_da_nota" (change)='onDataDeVencimentoChange($event)'>
    
            <small class="col-sm-12">Os pagamentos devem ser conforme a Política de Pagamentos da Nutrien</small>
            <!-- <small class="col-sm-12 atencao">Atenção<br>
              Aproveitamos para reforçar a política de pagamentos da Nutrien.</small>
            <small class="col-sm-12">Pagamentos são executados apenas nos dias 15 e 25 de cada mês, seguindo as regras
              abaixo:</small>
            <small class="col-sm-12">
              <ul class="col-sm-12">
                <li>• Para pagamentos no dia 15, as notas devem ser postadas no portal NBS até no máximo dia 05. Se postadas
                  depois do dia 05, a nota será paga apenas no dia 25</li>
                <li>• Para pagamentos no dia 25, as notas devem ser postadas no portal NBS até no máximo dia 15. Se postadas
                  depois do dia 15, a nota será paga apenas no dia 15 do mês seguinte.</li>
              </ul>
            </small> -->
    
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('data_de_vencimento_da_nota')?.errors?.required && formularioLancamentos.get('data_de_vencimento_da_nota')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
    
          </div>
        </div>
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().nome_empresa_nutrien =='Agrichem'">
          <label for="departamento" class="col-sm-4 col-form-label">*Departamento </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="departamento">
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('departamento')?.errors?.required && formularioLancamentos.get('departamento')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div
          *ngIf="!(this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Guias/Imposto/Taxas')">
          <!-- *ngIf="!(this.formularioLancamentos.getRawValue().nome_empresa_nutrien=='Nutrien' && this.formularioLancamentos.getRawValue().tipo_de_nota=='Guias')"> -->
    
    
          <div class="form-group row" *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota!='Cartório'">
            <label for="numero_de_pedido" class="col-sm-4 col-form-label">Número de pedido</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="numero_de_pedido">
              <app-mensagem class="col-form-label mensagem-erro"
                *ngIf="formularioLancamentos.get('numero_de_pedido')?.errors?.required && formularioLancamentos.get('numero_de_pedido')?.touched"
                mensagem="Campo Obrigatório"></app-mensagem>
            </div>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="quantidade_de_itens_do_pedido" class="col-sm-4 col-form-label">Quantidade de itens do pedido</label>
          <div class="col-sm-8">
            <select class="custom-select" formControlName="quantidade_de_itens_do_pedido">
              <option *ngFor="let quantidade of quantidadeDeItens" [value]="quantidade.quantidade">{{quantidade.quantidade}}
              </option>
            </select>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('quantidade_de_itens_do_pedido')?.errors?.required && formularioLancamentos.get('quantidade_de_itens_do_pedido')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
    
        <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().quantidade_de_itens_do_pedido =='De 1 a 10 Itens'">
          <label for="numero_do_item_da_po" class="col-sm-4 col-form-label">Número do item da PO </label>
          <div class="col-sm-8">
            <textarea class="form-control" aria-label="With textarea" formControlName="numero_do_item_da_po"></textarea>
          </div>
        </div>
    
        <div *ngIf="formularioLancamentos.getRawValue().quantidade_de_itens_do_pedido =='Acima de 10 Itens'">
          <div class="form-group row">
            <label for="lista_de_itens_da_po" class="col-sm-4 col-form-label">Lista de itens da PO </label>
            <div class="col-sm-8">
              <input type="file" class="form-control form-anexo" aria-label="With textarea"
                formControlName="lista_de_itens_da_po" id="formFileMultiple" (change)="saveListaDeItensDaPO($event)"
                multiple="multiple">
            </div>
          </div>
    
          <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="listaDeItensDaPo.length!=0">
            <div class="card-header">Anexo - Lista de Itens da PO</div>
            <ul class="list-group list-group-flush" *ngFor="let file of listaDeItensDaPo">
              <li class="list-group-item d-flex justify-content-between">
                <span>{{ file.name }}</span>
                <span>
                  <i class='fa fa-trash remove-icon removeFile' (click)="removeListaDeItensDaPO(this.file)"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
    
        <div class="form-group row">
          <label for="centro_de_custo" class="col-sm-4 col-form-label">Centro de custo</label>
          <div class="col-sm-8 ">
            <input type="text" class="form-control mb-1" formControlName="centro_de_custo">
            <small class="text-observacao-centro-de-custo">*Sempre preencher se a nota selecionada não tiver ordem de
              compra/serviço.</small>
          </div>
        </div>
    
    
        <div class="form-group row">
          <label for="anexo_nota_ou_boleto" class="col-sm-4 col-form-label">*Anexo Nota</label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_nota_ou_boleto" id="formFileMultiple" (change)="saveAnexoNota($event)"
              multiple="multiple">
              <small class="text-observacao-centro-de-custo" *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE'">*Obrigatório incluir somente os Dactes (CT-Es) em um único arquivo PDF..</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('anexo_nota_ou_boleto')?.errors?.required && formularioLancamentos.get('anexo_nota_ou_boleto')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="notas.length!=0">
          <div class="card-header">Anexo - Nota ou Boleto</div>
          <ul class="list-group list-group-flush" *ngFor="let file of notas">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileNota(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
        <div class="form-group row" *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Frete/CTE'">
          <label for="certeza_que_incluiu_o_arquivo_conforme_orienta_o" class="col-sm-4 col-form-label">Arquivo Incluído conforme orientação?</label>
          <div class="col-sm-8">
            <input type="checkbox" class="form-check-input" formControlName="certeza_que_incluiu_o_arquivo_conforme_orienta_o" value="Sim"
              (change)='dacteCorreto=!dacteCorreto' (change)="dacteCorretoValue($event)">
          </div>
        </div>
    
    
        <div class="form-group row">
          <label for="anexo_boleto" class="col-sm-4 col-form-label">Anexo Boleto </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_boleto"
              id="formFileMultiple" (change)="saveAnexoBoleto($event)" multiple="multiple">
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="boletos.length!=0">
          <div class="card-header">Anexo - Boleto</div>
          <ul class="list-group list-group-flush" *ngFor="let file of boletos">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileBoleto(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
        <div class="form-group row">
          <label for="anexo_xml" class="col-sm-4 col-form-label">Anexo XML </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="anexo_xml"
              id="formFileMultiple" (change)="saveAnexoXML($event)" multiple="multiple">
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="xml.length!=0">
          <div class="card-header">Anexo - Nota ou Boleto</div>
          <ul class="list-group list-group-flush" *ngFor="let file of xml">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFileXML(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
    
    
    
    
        <div class="form-group row">
          <label for="anexo_documentacao_suporte" class="col-sm-4 col-form-label">Anexo Documentação Suporte </label>
          <div class="col-sm-8">
            <input type="file" class="form-control form-anexo" aria-label="With textarea"
              formControlName="anexo_documentacao_suporte" id="formFileMultiple"
              (change)="saveAnexoDocumentacaoSuporte($event)" multiple="multiple">
            <small *ngIf="this.formularioLancamentos.getRawValue().tipo_de_nota=='Aluguel'">*Para o tipo de nota "ALUGUEL" O
              preenchimento desse campo é obrigatório.</small>
            <app-mensagem class="col-form-label mensagem-erro"
              *ngIf="formularioLancamentos.get('anexo_documentacao_suporte')?.errors?.required && formularioLancamentos.get('anexo_documentacao_suporte')?.touched"
              mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div>
    
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="documentacaoSuporte.length!=0">
          <div class="card-header">Anexo - Documentação Suporte</div>
          <ul class="list-group list-group-flush" *ngFor="let file of documentacaoSuporte">
            <li class="list-group-item d-flex justify-content-between">
              <span>{{ file.name }}</span>
              <span>
                <i class='fa fa-trash remove-icon removeFile' (click)="removeFilDocumentacaoSuporte(this.file)"></i>
              </span>
            </li>
          </ul>
        </div>
    
        <!-- As linhas abaixo foram comentadas de acordo com a demanda 597461824 -->
    
        <!-- <div class="form-group row">
          <label for="nota_para_pagamento_fora_da_politica" class="col-sm-4 col-form-label">*Nota para pagamento fora da
            política</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value="Sim"
                class='radio-btn'>&nbsp;SIM
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='nota_para_pagamento_fora_da_politica' value='Não'
                class='radio-btn'>&nbsp;NÃO
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formularioLancamentos.get('nota_para_pagamento_fora_da_politica')?.errors?.required && formularioLancamentos.get('nota_para_pagamento_fora_da_politica')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div> -->
    
    
    
        <!-- <div class="form-group row">
          <label for="nota_para_pagamento_urgente" class="col-sm-4 col-form-label">*Nota para pagamento urgente</label>
          <div class="col-sm-8">
            <label for="tipo" class=' radio-inline'>
              <input type="radio" formControlName='nota_para_pagamento_urgente' value="Sim" class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;SIM
            </label>
            <label for="tipo" class='radio-inline'>
              <input type="radio" formControlName='nota_para_pagamento_urgente' value='Não' class='radio-btn' (change)="onPagamentoUrgenteChange($event)">&nbsp;NÃO
            </label>
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formularioLancamentos.get('nota_para_pagamento_urgente')?.errors?.required && formularioLancamentos.get('nota_para_pagamento_urgente')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
        </div> -->
    
    
        <!-- <div class="form-group row" *ngIf="formularioLancamentos.getRawValue().nota_para_pagamento_urgente=='Sim'">
          <label for="motivo_da_excecao" class="col-sm-4 col-form-label">*Motivo de Exceção </label>
          <div class="col-sm-8">
            <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_excecao"></textarea>
            <app-mensagem class="col-form-label mensagem-erro"
            *ngIf="formularioLancamentos.get('motivo_da_excecao')?.errors?.required && formularioLancamentos.get('motivo_da_excecao')?.touched"
            mensagem="Campo Obrigatório"></app-mensagem>
          </div>
    
        </div> -->
    
    
    
        <!-- *************** CAMPOS NOVOS *********** -->
    
    
    
    
    
    
    
    
        <div class="form-group row">
          <label for="observacao" class="col-sm-4 col-form-label">Observação sobre o documento </label>
          <div class="col-sm-8">
            <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
          </div>
        </div>
      </form>






    </div>



    <!-- <div class="form-group pt-1 col-lg-3 box-botao" 
      *ngIf="(
        formulario.getRawValue().subcategoria_po !== '' && 
        formulario.getRawValue().subcategoria_po !== 'Frete ' && 
        formulario.getRawValue().subcategoria_po !== 'Benefícios' &&
        formulario.getRawValue().subcategoria_po !== 'Importação' &&
        formulario.getRawValue().subcategoria_po !== 'Nota de Crédito' &&
        formulario.getRawValue().categoria !== ''
      ) || 
      (
        formulario.getRawValue().categoria == 'NPO' && 
        formulario.getRawValue().tipo_de_lan_amento !== '' && 
        formulario.getRawValue().tipo_de_lan_amento !== 'Benefícios'
      )"> -->
      <div class="form-group pt-1 col-lg-3 box-botao" 
      *ngIf="(formulario.getRawValue().tipo_de_nota !== 'Frete/CTE' && formulario.getRawValue().tipo_de_nota !== '') || erroArquivosNfBoleto">
      <button type="submit" class="btn botao"
        [disabled]="formulario.invalid ">Enviar</button>
    </div>

    <!-- <div 
      *ngIf="formulario.getRawValue().subcategoria_po == 'Frete ' || 
             formulario.getRawValue().subcategoria_po == 'Benefícios' || 
             formulario.getRawValue().subcategoria_po == 'Importação' || 
             formulario.getRawValue().subcategoria_po == 'Nota de Crédito' ||
             formulario.getRawValue().tipo_de_lan_amento == 'Benefícios'"> -->
             <div class="form-group pt-1 col-lg-3 box-botao" 
      *ngIf="formulario.getRawValue().tipo_de_nota == 'Frete/CTE'">

        <button type="submit" class="btn botao" [disabled]="formularioLancamentos.invalid || formularioLancamentos.getRawValue().data_de_envio_de_nf > dataAtual">Enviar</button>
    </div>

  </form>
</div>


<div class="footer">
  <app-footer link="payment"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>