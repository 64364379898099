import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxLinkPipeComponent } from './box-link-pipe.component';



@NgModule({
  declarations: [
    BoxLinkPipeComponent
  ],
  imports: [
    CommonModule
  ],

  exports:[
    BoxLinkPipeComponent
  ]
})
export class BoxLinkPipeModule { }
