export const solicitacoes = [
  {
    solicitacao : ''
  },
  {
    solicitacao: 'Alteração de limite'
  },
  {
    solicitacao: 'Alteração e/ou atualização de dados cadastrais'
  },
  {
    solicitacao: 'Cancelamento'
  },
  {
    solicitacao: 'Desbloqueio por regularização'
  },
  {
    solicitacao: 'Liberação gastos em viagens Internacionais'
  },
  {
    solicitacao: 'Novo cartão corporativo / 2ª Via'
  },
  // {
  //   solicitacao: 'Solicitação de Senha do Cartão'
  // },


]
