export const pipe_fields_id_ponto_eletronico =[
  "solicita_o",
  "nome_do_funcion_rio",
  "e_mail",
  "matr_cula",
  "empresa",
  "justificativa_do_ajuste",
  "descri_o",
  "anexos",
]
