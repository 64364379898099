import { AfterViewInit, Component, OnInit, ElementRef, Inject, OnDestroy } from '@angular/core';
import { UserInfosService } from './../../autenticacao/usuario/user-infos.service';
import { DOCUMENT } from '@angular/common';
import { ControleDeVersoesService } from '../../services/controle-de-versoes.service';
import { environment } from 'src/environments/environment';
import { TokenServiceService } from 'src/app/services/token-service.service';

declare var ZenviaChat:any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  environment = environment;

  name: string = '';
  email: string = '';
  urlSalaSuporte: any = "";

  scriptZenvia: any;

  podeAcessarIpe:boolean = false

  constructor(
    private userInfo: UserInfosService,
    @Inject(DOCUMENT) private document:any,
    private elementRef: ElementRef,
    private controledeVersoes: ControleDeVersoesService,
    private tokenS: TokenServiceService
  ) {
    this.tokenS.getSecrets().then
  }
  
  ngAfterViewInit(): void {
    this.afterScriptAdded();
  }

  async afterScriptAdded() {
    return new ZenviaChat( (await this.tokenS.getSecrets()).apikeys.zenviaChat,false).embedded('button')
    .extraData({ nome_usuario: this.userInfo.getName(), email_usuario: this.email, campoX: 'valor de X', outroCampo: { campoInterno: "um valor qualquer" }}).build();
  }

  ngOnInit(): void {
    this.name = this.userInfo.getName();
    this.email = this.userInfo.getUserEmail();
    // console.log('lancamento de notas email', this.email)

    if (this.name && this.email) {
      this.name = this.name.replace(" ","_").replace(",","")
  
      this.urlSalaSuporte = "https://app.pipefy.com/public/form/i09bm1sh?nome_do_solicitante="+this.name+"&e_mail_do_solicitante="+this.email
    } else {
      this.urlSalaSuporte = "https://app.pipefy.com/public/form/i09bm1sh";
    }

    // this.acessarIpe()
  }

  ngOnDestroy(): void {
    const element:any = document.getElementsByClassName("znv-chat");
    if (element.length)
      element[0].remove();
  }

  teste(){
    console.log("entrei")
    window.open('https://selfbooking.lemontech.com.br/nutrien', "_blank");
  }


  acessarIpe(){
    this.podeAcessarIpe = this.controledeVersoes.getAcessaIpe()
  }

}
