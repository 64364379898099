<app-header></app-header>
<div class="nbs_banner">
  <img src="../../assets/img/logo_nbs.png" class="logonbs" alt="">
  <h2 class="nbs_banner-title">FAQ - Perguntas Frequentes</h2>
  <img class="nbs_banner-img" src="../../assets/img/background.png" alt="descricao">
</div>
<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="nbs_box_link_menu col-sm-12 col-md-10 col-lg-10">
        <img class="nbs_box_link_menu-image img-responsive" src="../../assets/img/auditoria.png" alt="descricao" />
        <div class="">
          <p class="nbs_box_link_menu-title p-2">Aqui você encontra as dúvidas mais frequentes que
            recebemos!<br>Separamos por temas para ficar mais fácil e rápido para você.</p>
          <p class="nbs_box_link_menu-title p-2">Caso não encontre a orientação que precisa, utilize o formulário para
            enviar sua dúvida diretamente para gente!<br> Iremos responder o quanto antes.
          </p>

        </div>
      </div>
    </div>

  </div>
  <div class="row duvidas">
    <div class="topicos col-sm-12 col-lg-4">
      <div class="accordion" id="accordionExample">
        <!-- Contabilidade -->
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                Contabilidade
              </button>
            </h5>
          </div>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta11"
                    aria-expanded="true" aria-controls="pergunta11">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta11" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Nem Deus sabe!
                </p>
              </div>
            </div>

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta12"
                    aria-expanded="true" aria-controls="pergunta12">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta12" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Nem Deus sabe!
                </p>
              </div>
            </div>



          </div>
        </div>
        <br>

        <!-- Centro de Experiência -->
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                Centro de Experiência
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta11"
                    aria-expanded="true" aria-controls="pergunta11">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta11" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Nem Deus sabe!
                </p>
              </div>
            </div>

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta12"
                    aria-expanded="true" aria-controls="pergunta12">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta12" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Teste
                </p>
              </div>
            </div>



          </div>
        </div>
        <br>
        <!-- pagamentos -->
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree"
                aria-expanded="true" aria-controls="collapseThree">
                Pagamentos
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta11"
                    aria-expanded="true" aria-controls="pergunta11">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta11" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Nem Deus sabe!
                </p>
              </div>
            </div>

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta12"
                    aria-expanded="true" aria-controls="pergunta12">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta12" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Teste
                </p>
              </div>
            </div>



          </div>
        </div>
        <br>
        <!-- impostos -->
        <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour"
                aria-expanded="true" aria-controls="collapseFour">
                Impostos
              </button>
            </h5>
          </div>
          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta11"
                    aria-expanded="true" aria-controls="pergunta11">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta11" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Nem Deus sabe!
                </p>
              </div>
            </div>

            <div class="card-body box-duvidas">
              <div class="card-header box-duvidas-header" id="pergunta">
                <h5 class="mb-0">
                  <button class="btn btn-link box-duvidas-link" type="button" data-toggle="collapse" data-target="#pergunta12"
                    aria-expanded="true" aria-controls="pergunta12">
                    > O que é link seguro?
                  </button>
                </h5>
              </div>
              <div id="pergunta12" class="collapse box-duvidas-resposta" aria-labelledby="pergunta">
                <p class="card-body">
                  Teste
                </p>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4"></div>
    <div class="col-lg-3 box-contato-wrapper">
        <div class="col-lg-12 box-contato" routerLink="/conheca-mais">
          <h6>Conheça mais sobre o NBS</h6>
      </div>
      <br>
        <div class="col-lg-12 box-contato" (click)="faq()">
          <h6>Não encontrou sua dúvida? Entre em contato!</h6>
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</div>


<div class="footer">

  <app-footer link="menu"></app-footer>
  </div>
