export const pipe_fields_id = [
  'informa_es_da_prioridade',
  'motivo_da_urg_ncia',
  'anexo_de_documento',
  'opera_o',
  'formul_rio_de_solicita_o_de_emiss_o_de_nota_fiscal_est_anexado_e_preenchido',
  'incluir_anexos',
  'formul_rio_de_autoriza_o_da_contabilidade_para_emiss_o_da_nota_fiscal_est_em_anexo_e_assinado_pela_gest_o',
  'pdf_da_nota_fiscal_de_origem_est_em_anexo',
  'os_documentos_atrelados_a_nota_fiscal_conhecimento_de_transporte_cte_foi_cancelado_pela_transportadora',
  'motivo_do_cancelamento_1',
  'autoriza_o_do_fiscal_nutrien',
  'motivo_da_recusa',
  'n_mero_da_nota_fiscal_e_s_rie',
  'n_mero_do_documento_sap',
  'n_mero_da_migo',
  'empresa',
  'filial_tec_agro_frc',
  'filial_nutrien',
  'filial_agrichem',
  'filiais_tec_agro_sgl',
  'requisitante_e_e_mail',
  'e_mail_do_solicitante_principal_nutrien',
  'c_pia_e_mail_da_nutrien_nil_2',
  'c_pia_e_mail_da_nutrien_nil_3',
  'c_pia_e_mail_da_nutrien_nil_4',
  'c_pia_e_mail_da_nutrien_nil_5',
  'n_mero_de_nota_fiscal',
  'finalidade',
  'descri_o_correta',
  'anexar_a_autoriza_o_do_respons_vel_da_unidade_solicitante',
  'peso_correto',
  'transportador',
  'peso',
  'quantidade',
  'volume',
  'tipo_da_carga',
  'anexar_a_autoriza_o_do_respons_vel_do_time_de_cr_dito_e_cobran_a',
  'descri_o_da_solicita_o_e_opera_o',
  'e_mail_nbs',
  'anexar_documento',
  'n_mero_de_documentos',
  'observa_o',
  'data_de_emiss_o_da_nota_fiscal',
  'hor_rio_de_emiss_o_da_nota_fiscal'
];
