import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  faqLink() {
    window.open('https://app.pipefy.com/public/form/RZG0i3pz','_blank')
  }

}
