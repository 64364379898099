import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { Empresas } from './empresas';
import { torres } from './torres';

@Component({
  selector: 'app-documentos-suporte-auditoria',
  templateUrl: './documentos-suporte-auditoria.component.html',
  styleUrls: ['./documentos-suporte-auditoria.component.css']
})
export class DocumentosSuporteAuditoriaComponent implements OnInit {

  formulario!: FormGroup;
  myFiles: any[] = [];
  empresas = Empresas;
  torres = torres

  constructor(private formBuilder: FormBuilder, private trataInput: TrataInputsService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      email:['',[Validators.required, Validators.email]],
      nome_da_empresa:['',[Validators.required]],
      codigo_sap:['',[]],
      torre:['',[]],
      periodo_da_documentacao:['',[Validators.required]],
      documentacao_solicitada:['',[Validators.required]],
      anexo:['',[]],
      observacao:['',[]]
    })

    // console.log(this.myFiles.length)


  }

  resetform(){
      for(let field in this.formulario.controls){
        this.formulario.controls[field].setValue('');
      }
  }



  enviarFormulario(){

    let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
    let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
    let codigo_sap = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_sap)
    let torre = this.trataInput.removeCaracters(this.formulario.getRawValue().torre)
    let periodo_da_documentacao = this.trataInput.removeCaracters(this.formulario.getRawValue().periodo_da_documentacao)
    let documentacao_solicitada = this.trataInput.removeCaracters(this.formulario.getRawValue().documentacao_solicitada)
    let anexo = this.trataInput.removeCaracters(this.formulario.getRawValue().anexo)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = [email, nome_da_empresa, codigo_sap, torre, periodo_da_documentacao, documentacao_solicitada, anexo, observacao]

    let fields = [
      {
        "field_id": "email",
        "field_value" :email
      },
      {
        "field:_id": "nome_da_empresa",
        "field_value": nome_da_empresa
      },
      {
        "field_id":"anexo",
        "field_value": this.myFiles
      }
    ]

    // console.log(fields)
  }


  save(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++){
      // console.log(files[i].name)
      this.myFiles.push(files[i].name)
    }

    // console.log(this.myFiles)
    // console.log(this.myFiles.length)

  }

  removeFile(file: File){
    const index: number = this.myFiles.indexOf(file);
      this.myFiles.splice(index, 1)
      alert("Arquivo Removido")
}

abrirPipePublico() {
  window.open('https://app.pipefy.com/public/form/OUZtXsRa', '_blank')
}

}





