export const cargos = [
  {
    cargo:''
  },
  {
    cargo:'Consultor/Analista'
  },
  {
    cargo:'Coordenador/Supervisor'
  },
  {
    cargo:'Gerente'
  },
  {
    cargo:'Diretor'
  },
]
