export const Empresas = [
  {
    nome:''
  },
  {
    nome:'NUTRIEN SOLUCOES AGRICOLAS LTDA'
  },
  {
    nome:'NUTRIEN PARTICIPACOES LTDA'
  },
  {
    nome:'FRONTEIRA ARMAZÉNS GERAIS LTDA'
  },
  {
    nome:'SEMENTES GOIÁS LTDA'
  },
  {
    nome:'APMS PARTICIPAÇÕES SOCIETÁRIAS LTDA'
  },
  {
    nome:'FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA'
  },
  {
    nome:'AGRICHEM DO BRASIL S.A.'
  }
]
