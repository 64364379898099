export const motivos_de_urgencia = [
  {
    nome:''
  },
  {
    nome: 'Próximo ao vencimento'
  },
  {
    nome: 'Pagamento vencido'
  },
  {
    nome: 'Negociação com o fornecedor'
  },
  {
    nome: 'Outros'
  },
]
