import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { descricao_da_solicitacao } from './descricao_da_solicitacao';
import { empresas } from './empresas';
import { filiais_agrichem } from './filiais_agrichem';
import { filiais_agrosema } from './filiais_agrosema';
import { filiais_nutrien } from './filiais_nutrien';
import { filiais_tec_agro } from './filiais_tec_agro';
import { filiais_tec_agro_frc } from './filiais_tec_agro_frc';
import { filiais_tec_agro_sgl } from './filiais_tec_agro_sgl';
import { pipe_fields_ids } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-emissao-nf-de-importacao',
  templateUrl: './emissao-nf-de-importacao.component.html',
  styleUrls: ['./emissao-nf-de-importacao.component.css']
})
export class EmissaoNfDeImportacaoComponent implements OnInit {

  formulario!: FormGroup;
  empresas = empresas;
  filiais_agrichem = filiais_agrichem;
  //filiais_agrosema = filiais_agrosema;
  filiais_nutrien = filiais_nutrien;
  //filiais_tec_agro = filiais_tec_agro;
  filiais_tec_agro_frc = filiais_tec_agro_frc
  filiais_tec_agro_sgl = filiais_tec_agro_sgl
  descricos_das_solicitacoes = descricao_da_solicitacao;

  declaracaoDeProdutosPerigosos: any[] = []
  declaracaoDeImportacao: any[] = []
  coaInvoices: any[] = []
  exoneracoes: any[] = []
  planilhaDeLotes: any[] = []
  demaisAnexos: any[] = []

  declaracaoDeProdutosPerigososUrl: any[] = []
  declaracaoDeImportacaoUrl: any[] = []
  coaInvoicesUrl: any[] = []
  exoneracoesUrl: any[] = []
  planilhaDeLotesUrl: any[] = []
  demaisAnexosUrl: any[] = []

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''


  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      periodo: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      filial_tecagro: ['', []],
      filial_tec_agro_frc: ['', []],
      filial_tec_agro_sgl: ['', []],
      filial_nutrien: ['', []],
      filial_agrosema: ['', []],
      filial_agrichem: ['', []],
      descricao_da_solicitacao: ['', [Validators.required]],
      numero_da_di: ['', [Validators.required]],
      pedido_de_compra: ['', [Validators.required]],
      prazo_para_pagamento: ['', [Validators.required]],
      quantidade_de_notas_a_serem_emitidas: ['', [Validators.required]],
      produto_perigoso: ['', [Validators.required]],
      declaracao_de_produto_perigoso_e_onu: ['', [Validators.required]],
      declaracao_de_importacao: ['', [Validators.required]],
      coa_invoice: ['', [Validators.required]],
      exoneracoes: ['', [Validators.required]],
      transportadora: ['', [Validators.required]],
      planilha_de_lotes: ['', [Validators.required]],
      nome_do_solicitante: ['', [Validators.required]],
      e_mail: ['', [Validators.required, Validators.email]],
      torre_area_departamento: ['', [Validators.required]],
      observacaoes1: ['', []],
      demais_anexos: ['', []]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('e_mail')?.setValue(this.emailApi)
    // this.formulario.get('nome_do_solicitante')?.setValue(this.userNameApi)


    // if (this.formulario.getRawValue().e_mail != '') {
    //   this.formulario.controls['e_mail'].disable()
    // }
    // if (this.formulario.getRawValue().e_mail == undefined) {
    //   this.formulario.controls['e_mail'].enable()
    // }

    // if (this.formulario.getRawValue().nome_do_solicitante != '') {
    //   this.formulario.controls['nome_do_solicitante'].disable()
    // }
    // if (this.formulario.getRawValue().nome_do_solicitante == undefined) {
    //   this.formulario.controls['nome_do_solicitante'].enable()
    // }
  }

  enviarFormulario() {
    this.spinner.show()
    const criarBody = async () => {

      await this.getPipesUrlDeclaracaoDeProdutosPerigosos(this.declaracaoDeProdutosPerigosos);
      if (this.declaracaoDeProdutosPerigosos.length > 0) {
        while (this.declaracaoDeProdutosPerigosos.length != this.declaracaoDeProdutosPerigososUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.declaracaoDeProdutosPerigosos.length > 0) {
        var declaracaoDeProdutosPerigososUrl2 = ''
        declaracaoDeProdutosPerigososUrl2 = '['
        for (var i = 0; i < this.declaracaoDeProdutosPerigososUrl.length; i++) {
          declaracaoDeProdutosPerigososUrl2 = declaracaoDeProdutosPerigososUrl2 + '"' + this.declaracaoDeProdutosPerigososUrl[i] + '",'
        }
        declaracaoDeProdutosPerigososUrl2 = declaracaoDeProdutosPerigososUrl2.slice(0, -1)
        declaracaoDeProdutosPerigososUrl2 = declaracaoDeProdutosPerigososUrl2 + "]"

      } else {
        declaracaoDeProdutosPerigososUrl2 = ''
      }


      await this.getPipesUrlDeclaracaoDeImportacao(this.declaracaoDeImportacao);
      if (this.declaracaoDeImportacao.length > 0) {
        while (this.declaracaoDeImportacao.length != this.declaracaoDeImportacaoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.declaracaoDeImportacao.length > 0) {
        var declaracaoDeImportacaoUrl2 = ''
        declaracaoDeImportacaoUrl2 = '['
        for (var i = 0; i < this.declaracaoDeImportacaoUrl.length; i++) {
          declaracaoDeImportacaoUrl2 = declaracaoDeImportacaoUrl2 + '"' + this.declaracaoDeImportacaoUrl[i] + '",'
        }
        declaracaoDeImportacaoUrl2 = declaracaoDeImportacaoUrl2.slice(0, -1)
        declaracaoDeImportacaoUrl2 = declaracaoDeImportacaoUrl2 + "]"

      } else {
        declaracaoDeImportacaoUrl2 = ''
      }

      await this.getPipesUrlCoaInvoices(this.coaInvoices);
      if (this.coaInvoices.length > 0) {
        while (this.coaInvoices.length != this.coaInvoicesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.coaInvoices.length > 0) {
        var coaInvoicesUrl2 = ''
        coaInvoicesUrl2 = '['
        for (var i = 0; i < this.coaInvoicesUrl.length; i++) {
          coaInvoicesUrl2 = coaInvoicesUrl2 + '"' + this.coaInvoicesUrl[i] + '",'
        }
        coaInvoicesUrl2 = coaInvoicesUrl2.slice(0, -1)
        coaInvoicesUrl2 = coaInvoicesUrl2 + "]"

      } else {
        coaInvoicesUrl2 = ''
      }

      await this.getPipesUrlExoneracoes(this.exoneracoes);
      if (this.exoneracoes.length > 0) {
        while (this.exoneracoes.length != this.exoneracoesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.exoneracoes.length > 0) {
        var exoneracoesUrl2 = ''
        exoneracoesUrl2 = '['
        for (var i = 0; i < this.exoneracoesUrl.length; i++) {
          exoneracoesUrl2 = exoneracoesUrl2 + '"' + this.exoneracoesUrl[i] + '",'
        }
        exoneracoesUrl2 = exoneracoesUrl2.slice(0, -1)
        exoneracoesUrl2 = exoneracoesUrl2 + "]"

      } else {
        exoneracoesUrl2 = ''
      }

      await this.getPipesUrlPlanilhaDeLotes(this.planilhaDeLotes);
      if (this.planilhaDeLotes.length > 0) {
        while (this.planilhaDeLotes.length != this.planilhaDeLotesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
        }
      }

      if (this.planilhaDeLotes.length > 0) {
        var planilhaDeLotesUrl2 = ''
        planilhaDeLotesUrl2 = '['
        for (var i = 0; i < this.planilhaDeLotesUrl.length; i++) {
          planilhaDeLotesUrl2 = planilhaDeLotesUrl2 + '"' + this.planilhaDeLotesUrl[i] + '",'
        }
        planilhaDeLotesUrl2 = planilhaDeLotesUrl2.slice(0, -1)
        planilhaDeLotesUrl2 = planilhaDeLotesUrl2 + "]"

      } else {
        planilhaDeLotesUrl2 = ''
      }

      await this.getPipesUrlDemaisAnexos(this.demaisAnexos);
      if (this.demaisAnexos.length > 0) {
        while (this.demaisAnexos.length != this.demaisAnexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.demaisAnexos.length > 0) {
        var demaisAnexosUrl2 = ''
        demaisAnexosUrl2 = '['
        for (var i = 0; i < this.demaisAnexosUrl.length; i++) {
          demaisAnexosUrl2 = demaisAnexosUrl2 + '"' + this.demaisAnexosUrl[i] + '",'
        }
        demaisAnexosUrl2 = demaisAnexosUrl2.slice(0, -1)
        demaisAnexosUrl2 = demaisAnexosUrl2 + "]"

      } else {
        demaisAnexosUrl2 = ''
      }

      let periodo = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().periodo))
      let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
      //let filial_tecagro = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tecagro)
      let filiais_tec_agro_sgl = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tec_agro_sgl)
      let filiais_tec_agro_frc = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tec_agro_frc)
      let filial_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_nutrien)
      //let filial_agrosema = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_agrosema)
      let filial_agrichem = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_agrichem)
      let descricao_da_solicitacao = this.formulario.getRawValue().descricao_da_solicitacao
      let numero_da_di = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_da_di)
      let pedido_de_compra = this.trataInput.removeCaracters(this.formulario.getRawValue().pedido_de_compra)
      let prazo_para_pagamento = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().prazo_para_pagamento))
      let quantidade_de_notas_a_serem_emitidas = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_de_notas_a_serem_emitidas)
      let produto_perigoso = this.trataInput.removeCaracters(this.formulario.getRawValue().produto_perigoso)
      let declaracao_de_produto_perigoso_e_onu = this.trataInput.removeCaracters(this.formulario.getRawValue().declaracao_de_produto_perigoso_e_onu)
      let declaracao_de_importacao = this.trataInput.removeCaracters(this.formulario.getRawValue().declaracao_de_importacao)
      let coa_invoice = this.trataInput.removeCaracters(this.formulario.getRawValue().coa_invoice)

      let exoneracoes = this.trataInput.removeCaracters(this.formulario.getRawValue().exoneracoes)
      let transportadora = this.trataInput.removeCaracters(this.formulario.getRawValue().transportadora)
      let planilha_de_lotes = this.trataInput.removeCaracters(this.formulario.getRawValue().planilha_de_lotes)
      let nome_do_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_solicitante)
      let e_mail = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail)
      let torre_area_departamento = this.trataInput.removeCaracters(this.formulario.getRawValue().torre_area_departamento)
      let observacaoes1 = this.trataInput.removeCaracters(this.formulario.getRawValue().observacaoes1)

      let pipe_fields = pipe_fields_ids
      let fields_values = [periodo, empresa, filiais_tec_agro_sgl, filial_nutrien, filiais_tec_agro_frc, filial_agrichem,
        descricao_da_solicitacao, numero_da_di, pedido_de_compra, prazo_para_pagamento, quantidade_de_notas_a_serem_emitidas, produto_perigoso, declaracaoDeProdutosPerigososUrl2,
        declaracaoDeImportacaoUrl2, coaInvoicesUrl2, exoneracoesUrl2, transportadora, planilhaDeLotesUrl2, nome_do_solicitante, e_mail, torre_area_departamento, observacaoes1, demaisAnexosUrl2]

      let pipe_id = '302487515'

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetForm()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            showClass: {
              popup: 'swal2-show'
            },
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.declaracaoDeProdutosPerigososUrl = []
          this.declaracaoDeImportacaoUrl = []
          this.coaInvoicesUrl = []
          this.exoneracoesUrl = []
          this.planilhaDeLotesUrl = []


          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Fiscal', "Fiscal - criação de card -- Emissão de Nota Fiscal de Importação", erro, 'Portal NBS Error', 'diego.alves.d.campos@accenture.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi]

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.declaracaoDeProdutosPerigososUrl = []
          this.declaracaoDeImportacaoUrl = []
          this.coaInvoicesUrl = []
          this.exoneracoesUrl = []
          this.planilhaDeLotesUrl = []
        }
      })
    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  onEmpresaChange(event: any) {
    let empresa = event.target.value

    // console.log("Empresa:", empresa)

    if (empresa === 'Tec Agro-SGL') {

      this.formulario.get('filial_tec_agro_sgl')?.setValidators([Validators.required])
      this.formulario.get('filial_tec_agro_sgl')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators()
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      // this.formulario.get('filial_agrosema')?.clearValidators()
      // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }


    if (empresa === 'Tec Agro-FRC') {

      this.formulario.get('filial_tec_agro_frc')?.setValidators([Validators.required])
      this.formulario.get('filial_tec_agro_frc')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators()
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      // this.formulario.get('filial_agrosema')?.clearValidators()
      // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }

    // if (empresa === 'Tec Agro') {
    //   // console.log("entrei em tec agro")
    //   this.formulario.get('filial_tecagro')?.setValidators([Validators.required])
    //   this.formulario.get('filial_tecagro')?.updateValueAndValidity()

    //   this.formulario.get('filial_nutrien')?.clearValidators()
    //   this.formulario.get('filial_nutrien')?.updateValueAndValidity()

    //   this.formulario.get('filial_agrosema')?.clearValidators()
    //   this.formulario.get('filial_agrosema')?.updateValueAndValidity()

    //   this.formulario.get('filial_agrichem')?.clearValidators()
    //   this.formulario.get('filial_agrichem')?.updateValueAndValidity()

    // }

    if (empresa === 'Nutrien') {
      // console.log("entrei em nutrien")

      this.formulario.get('filial_tecagro')?.clearValidators()
      this.formulario.get('filial_tecagro')?.updateValueAndValidity()

      this.formulario.get('filial_nutrien')?.setValidators([Validators.required])
      this.formulario.get('filial_nutrien')?.updateValueAndValidity()

      this.formulario.get('filial_agrosema')?.clearValidators()
      this.formulario.get('filial_agrosema')?.updateValueAndValidity()

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity()

    }

    // if (empresa === 'Agrosema') {

    //   // console.log("entrei em Agrosema")

    //   this.formulario.get('filial_tecagro')?.clearValidators()
    //   this.formulario.get('filial_tecagro')?.updateValueAndValidity()

    //   this.formulario.get('filial_nutrien')?.clearValidators()
    //   this.formulario.get('filial_nutrien')?.updateValueAndValidity()

    //   this.formulario.get('filial_agrosema')?.setValidators([Validators.required])
    //   this.formulario.get('filial_agrosema')?.updateValueAndValidity()

    //   this.formulario.get('filial_agrichem')?.clearValidators()
    //   this.formulario.get('filial_agrichem')?.updateValueAndValidity()

    // }

    if (empresa === 'Agrichem') {

      // console.log("entrei em Agrichem")


      this.formulario.get('filial_tecagro')?.clearValidators()
      this.formulario.get('filial_tecagro')?.updateValueAndValidity()

      this.formulario.get('filial_nutrien')?.clearValidators()
      this.formulario.get('filial_nutrien')?.updateValueAndValidity()

      this.formulario.get('filial_agrosema')?.clearValidators()
      this.formulario.get('filial_agrosema')?.updateValueAndValidity()

      this.formulario.get('filial_agrichem')?.setValidators([Validators.required])
      this.formulario.get('filial_agrichem')?.updateValueAndValidity()

    }
    // console.log('tecagro', this.formulario.get('filial_tecagro')?.hasError('required'))
    // console.log('filial_nutrien', this.formulario.get('filial_nutrien')?.hasError('required'))
    // console.log('filial_agrosema', this.formulario.get('filial_agrosema')?.hasError('required'))
    // console.log('filial_agrichem', this.formulario.get('filial_agrichem')?.hasError('required'))
    // console.log('filial_terra_nova', this.formulario.get('filial_terra_nova')?.hasError('required'))
    // console.log('filial_bio_rural', this.formulario.get('filial_bio_rural')?.hasError('required'))

  }

  saveDeclaracaoDeProdutosPerigosos(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.declaracaoDeProdutosPerigosos.push(files[i])
    }

    // console.log(this.declaracaoDeProdutosPerigosos)
    // console.log(this.declaracaoDeProdutosPerigosos.length)

  }

  removeFileDeclaracaoDeProdutosPerigosos(file: File) {
    const index: number = this.declaracaoDeProdutosPerigosos.indexOf(file);
    this.declaracaoDeProdutosPerigosos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.declaracaoDeProdutosPerigosos.length == 0) {
      this.formulario.controls['declaracao_de_produto_perigoso_e_onu'].markAsTouched()
      this.formulario.get('declaracao_de_produto_perigoso_e_onu')?.setValue('')
    }
  }

  async getPipesUrlDeclaracaoDeProdutosPerigosos(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.declaracaoDeProdutosPerigososUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.declaracaoDeProdutosPerigososUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveDeclaracaoDeImportacao(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.declaracaoDeImportacao.push(files[i])
    }

    // console.log(this.declaracaoDeImportacao)
    // console.log(this.declaracaoDeImportacao.length)

  }

  removeFileDeclaracaoDeImportacao(file: File) {
    const index: number = this.declaracaoDeImportacao.indexOf(file);
    this.declaracaoDeImportacao.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.declaracaoDeImportacao.length == 0) {
      this.formulario.controls['declaracao_de_importacao'].markAsTouched()
      this.formulario.get('declaracao_de_importacao')?.setValue('')
    }
  }

  async getPipesUrlDeclaracaoDeImportacao(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.declaracaoDeImportacaoUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.declaracaoDeImportacaoUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveCoaInvoices(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.coaInvoices.push(files[i])
    }

    // console.log(this.coaInvoices)
    // console.log(this.coaInvoices.length)

  }

  removeFileCoaInvoices(file: File) {
    const index: number = this.coaInvoices.indexOf(file);
    this.coaInvoices.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.coaInvoices.length == 0) {
      this.formulario.controls['coa_invoice'].markAsTouched()
      this.formulario.get('coa_invoice')?.setValue('')
    }
  }

  async getPipesUrlCoaInvoices(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.coaInvoicesUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.coaInvoicesUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveExoneracoes(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.exoneracoes.push(files[i])
    }

    // console.log(this.exoneracoes)
    // console.log(this.exoneracoes.length)

  }

  removeFileExoneracoes(file: File) {
    const index: number = this.exoneracoes.indexOf(file);
    this.exoneracoes.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.exoneracoes.length == 0) {
      this.formulario.controls['exoneracoes'].markAsTouched()
      this.formulario.get('exoneracoes')?.setValue('')
    }
  }

  async getPipesUrlExoneracoes(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.exoneracoesUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.exoneracoesUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  savePlanilhaDeLotes(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.planilhaDeLotes.push(files[i])
    }

    // console.log(this.planilhaDeLotes)
    // console.log(this.planilhaDeLotes.length)

  }

  removeFilePlanilhaDeLotes(file: File) {
    const index: number = this.planilhaDeLotes.indexOf(file);
    this.planilhaDeLotes.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.planilhaDeLotes.length == 0) {
      this.formulario.controls['planilha_de_lotes'].markAsTouched()
      this.formulario.get('planilha_de_lotes')?.setValue('')
    }
  }

  async getPipesUrlPlanilhaDeLotes(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.planilhaDeLotesUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.planilhaDeLotesUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  async getPipesUrlDemaisAnexos(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.demaisAnexosUrl.push(url)
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/OUZtXsRa" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.demaisAnexosUrl = []
            }
          })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveDemaisAnexos(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.demaisAnexos.push(files[i])
    }

  }

  removeFileDemaisAnexos(file: File) {
    const index: number = this.demaisAnexos.indexOf(file);
    this.demaisAnexos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.demaisAnexos.length == 0) {
      this.formulario.controls['demais_anexos'].markAsTouched()
      this.formulario.get('demais_anexos')?.setValue('')
    }
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/OUZtXsRa', '_blank')
  }

  resetForm() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }

    this.declaracaoDeProdutosPerigosos = []
    this.declaracaoDeImportacao = []
    this.coaInvoices = []
    this.exoneracoes = []
    this.planilhaDeLotes = []
    this.demaisAnexos = []

    this.declaracaoDeProdutosPerigososUrl = []
    this.declaracaoDeImportacaoUrl = []
    this.coaInvoicesUrl = []
    this.exoneracoesUrl = []
    this.planilhaDeLotesUrl = []
    this.demaisAnexosUrl = []

    // this.formulario.get('filial_tecagro')?.clearValidators()
    // this.formulario.get('filial_tecagro')?.updateValueAndValidity();

    this.formulario.get('filial_tec_agro_frc')?.clearValidators()
    this.formulario.get('filial_tec_agro_frc')?.updateValueAndValidity();

    this.formulario.get('filial_tec_agro_sgl')?.clearValidators()
    this.formulario.get('filial_tec_agro_sgl')?.updateValueAndValidity();

    this.formulario.get('filial_nutrien')?.clearValidators()
    this.formulario.get('filial_nutrien')?.updateValueAndValidity();

    // this.formulario.get('filial_agrosema')?.clearValidators()
    // this.formulario.get('filial_agrosema')?.updateValueAndValidity();

    this.formulario.get('filial_agrichem')?.clearValidators()
    this.formulario.get('filial_agrichem')?.updateValueAndValidity();

    this.formulario.markAsUntouched()

  }
}


