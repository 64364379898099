export const tiposNotas = [
  {
      tipo:'',
      label:''
  },
  {
      tipo:"Aluguel",
      label:"Aluguel"
  },
  {
      tipo:"Ativo fixo (AFE)",
      label:"Ativo fixo (AFE)"
  },
  {
      tipo:"Benefícios",
      label:"Benefícios"
  },
  {
      tipo:"Cartório",
      label:"Cartório"
  },
  {
      tipo:"Contas de consumo (Luz, água, telefone, internet, gás)",
      label:"Contas de consumo (Luz, água, telefone, internet, gás)"
  },
  {
    tipo: "Compra e Venda Simultânea (CVS)",
    label: "Compra e Venda Simultânea (CVS)"
  },
  {
      tipo:"Correio",
      label:"Correio"
  },
  {
      tipo:"Devolução de Cliente",
      label:"Devolução de Cliente"
  },
  {
      tipo:"Escrituração - Não gera pagamento",
      label:"Escrituração - Não gera pagamento"
  },
  {
      tipo:"Frete/CTE",
      label:"Frete/CTE"
  },
  {
      tipo:"Guias/Imposto/Taxas",
      label:"Guias/Imposto/Taxas"
  },
  {
      tipo:"Importação",
      label:"Importação"
  },
  {
      tipo:"Incentivo - Doação",
      label:"Incentivo - Doação"
  },
  {
      tipo:"Industrialização",
      label:"Industrialização"
  },
  {
      tipo:"Jurídico",
      label:"Jurídico"
  },
  {
      tipo:"Material (DANFE)",
      label:"Material (DANFE)"
  },
  {
      tipo:"Nota de Crédito",
      label:"Nota de Crédito"
  },
  {
      tipo:"Outras NFs sem PO",
      label:"Outras NFs sem PO"
  },
  {
    tipo:"Seguro",
    label:"Seguro"
  },
  {
      tipo:"Sem valor fiscal",
      label:"Sem valor fiscal"
  },
  {
      tipo:"Serviço",
      label:"Serviço"
  },
  {
      tipo:"Pagamento de Autônomo",
      label:"RPA"
  }
]
