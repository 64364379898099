import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

 isHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  faq(){
    window.open('https://app.pipefy.com/public/form/RZG0i3pz','_blank')
  }

}
