import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignInComponent } from './sign-in.component';


const routes: Routes = [
  {
    path:'login',
    component: SignInComponent,
    children:[{
      path:'',
      component:LoginComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SignInRoutingModule { }
