import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicosFinanceirosRoutingModule } from './servicos-financeiros-routing.module';
import { AccoutingComponent } from './accouting/accouting.component';
import { MenuComponent } from './menu/menu.component';
import { PaymentComponent } from './payment/payment.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { TaxesComponent } from './taxes/taxes.component';
import { HeaderModule } from './../componentes/header/header.module';
import { BoxLinkPipeModule } from '../componentes/box-link-pipe/box-link-pipe.module';
import { NbsBannerComponent } from '../componentes/nbs-banner/nbs-banner.component';
import { NbsBannerModule } from '../componentes/nbs-banner/nbs-banner.module';
import { BoxLinkMenuModule } from '../componentes/box-link-menu/box-link-menu.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { ComprasComponent } from './compras/compras.component';
import { FooterModule } from './../componentes/footer/footer.module';
import { AuditoriaComponent } from './formularios/contabilidade/auditoria/auditoria.component';
import { BaixaImobilizadoComponent } from './formularios/contabilidade/baixa-imobilizado/baixa-imobilizado.component';
import { LancamentoContabilComponent } from './formularios/contabilidade/lancamento-contabil/lancamento-contabil.component';
import { FormulariosTituloModule } from '../componentes/formularios-titulo/formularios-titulo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MensagemModule } from '../componentes/mensagem/mensagem.module';
import { PedidoDeVendaEFaturamentoComponent } from './formularios/lojas/pedido-de-venda-e-faturamento/pedido-de-venda-e-faturamento.component';
import { ServicosParaCartaoDeCreditoCorporativoComponent } from './formularios/pagamentos/servicos-para-cartao-de-credito-corporativo/servicos-para-cartao-de-credito-corporativo.component';
import { SolicitacaoDeLancamentosDeNotasComponent } from './formularios/pagamentos/solicitacao-de-lancamentos-de-notas/solicitacao-de-lancamentos-de-notas.component';
import { IpeComponent } from './formularios/pagamentos/ipe/ipe.component';
import { SolicitacaoDeReembolsoDeDespesasComponent } from './formularios/pagamentos/solicitacao-de-reembolso-de-despesas/solicitacao-de-reembolso-de-despesas.component';
import { NgxMaskModule } from 'ngx-mask';
import { DocumentosSuporteFiscalizacaoComponent } from './formularios/fiscal/documentos-suporte-fiscalizacao/documentos-suporte-fiscalizacao.component';
import { DocumentosSuporteAuditoriaComponent } from './formularios/fiscal/documentos-suporte-auditoria/documentos-suporte-auditoria.component';
import { RetificacaoObrigacoesComponent } from './formularios/fiscal/retificacao-obrigacoes/retificacao-obrigacoes.component';
import { RemessasExteriorComponent } from './formularios/fiscal/remessas-exterior/remessas-exterior.component';
import { PagamentoDeTaxasComponent } from './formularios/fiscal/pagamento-de-taxas/pagamento-de-taxas.component';
import { CancelamentoNfComponent } from './formularios/fiscal/cancelamento-nf/cancelamento-nf.component';
import { SuporteFiscalComponent } from './formularios/fiscal/suporte-fiscal/suporte-fiscal.component';
import { SuporteEmTestesComponent } from './formularios/fiscal/suporte-em-testes/suporte-em-testes.component';
import { PagamentoIrrfTesourariaComponent } from './formularios/fiscal/pagamento-irrf-tesouraria/pagamento-irrf-tesouraria.component';
import { PagamentoIofTesourariaComponent } from './formularios/fiscal/pagamento-iof-tesouraria/pagamento-iof-tesouraria.component';
import { PagamentoIofRhComponent } from './formularios/fiscal/pagamento-iof-rh/pagamento-iof-rh.component';
import { CancelamentoSolicitacaoModule } from '../componentes/cancelamento-solicitacao/cancelamento-solicitacao.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AjudaComponent } from './formularios/geral/faq/ajuda.component';
import { CancelamentoDeSolicitacaoComponent } from './formularios/geral/cancelamento-de-solicitacao/cancelamento-de-solicitacao.component';
import { EmissaoNfDeImportacaoComponent } from './formularios/fiscal/emissao-nf-de-importacao/emissao-nf-de-importacao.component';
import { RequisicaoDeComprasComponent } from './formularios/compras/requisicao-de-compras/requisicao-de-compras.component';
import { SuporteParaComprasComponent } from './formularios/compras/suporte-para-compras/suporte-para-compras.component';
import { ConsultaDeChamadosComponent } from './consulta-de-chamados/consulta-de-chamados.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LancamentosDeNotasMassivaComponent } from './formularios/pagamentos/lancamentos-de-notas-massiva/lancamentos-de-notas-massiva.component';
import { AlteracaoPedidosComponent } from './formularios/lojas/alteracao-pedidos/alteracao-pedidos.component';
import { BaixaDeTitulosComponent } from './formularios/lojas/baixa-de-titulos/baixa-de-titulos.component';
import { FaturamentoComponent } from './formularios/lojas/faturamento/faturamento.component';
import { ReembolsoBeneficiosComponent } from './formularios/pagamentos/reembolso-beneficios/reembolso-beneficios.component';
import { FornecedoresComponent } from './formularios/fornecedores/fornecedores.component';
import { PdfpopupModule } from '../componentes/pdfpopup/pdfpopup.module';
import { IpeComponent_2 } from './formularios/pagamentos/ipe-2/ipe.component';
import { IpeLancamentosComponent} from './formularios/pagamentos/ipe-lancamentos/ipe-lancamentos.component';
import { IpeLancamentosOcrComponent } from './formularios/pagamentos/ipe-lancamentos-ocr/ipe-lancamentos-ocr.component';
import { IpeLancamentosOcrRefatComponent} from './formularios/pagamentos/ipe-lancamentos-ocr-refat/ipe-lancamentos-ocr-refat.component';
import { IpeLancamentosOcrRefatNewComponent} from './formularios/pagamentos/ipe-lancamentos-ocr-refat-new/ipe-lancamentos-ocr-refat-new.component';
import { FormMatModule } from '../componentes/formMat/form.module';
import { CadastroRenovacaoClientesAgrometrikaComponent } from './formularios/lojas/cadastro-renovacao-clientes-agrometrika/cadastro-renovacao-clientes-agrometrika.component';
import { SolicitacaoDeLancamentosDeNotasOcrComponent } from './formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr/solicitacao-de-lancamentos-de-notas-ocr.component';
import { CriacaoContaContabilComponent } from './formularios/contabilidade/criacao-conta-contabil/criacao-conta-contabil.component';
import { SolicitacaoDeLancamentosDeNotasComponentOcrNew } from './formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr-new/solicitacao-de-lancamentos-de-notas-ocr-new.component';
import { SolicitacaoDeLancamentosDeNotasComponentOcrNewTeste } from './formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr-new-teste/solicitacao-de-lancamentos-de-notas-ocr-new-teste.component';

@NgModule({
  declarations: [
    AccoutingComponent,
    MenuComponent,
    PaymentComponent,
    CustomerServiceComponent,
    TaxesComponent,
    CadastroComponent,
    ComprasComponent,
    AuditoriaComponent,
    BaixaImobilizadoComponent,
    LancamentoContabilComponent,
    PedidoDeVendaEFaturamentoComponent,
    ServicosParaCartaoDeCreditoCorporativoComponent,
    SolicitacaoDeLancamentosDeNotasComponent,
    IpeComponent,
    SolicitacaoDeReembolsoDeDespesasComponent,
    DocumentosSuporteFiscalizacaoComponent,
    DocumentosSuporteAuditoriaComponent,
    RetificacaoObrigacoesComponent,
    RemessasExteriorComponent,
    PagamentoDeTaxasComponent,
    CancelamentoNfComponent,
    SuporteFiscalComponent,
    SuporteEmTestesComponent,
    PagamentoIrrfTesourariaComponent,
    PagamentoIofTesourariaComponent,
    PagamentoIofRhComponent,
    AjudaComponent,
    CancelamentoDeSolicitacaoComponent,
    EmissaoNfDeImportacaoComponent,
    RequisicaoDeComprasComponent,
    SuporteParaComprasComponent,
    ConsultaDeChamadosComponent,
    LancamentosDeNotasMassivaComponent,
    AlteracaoPedidosComponent,
    BaixaDeTitulosComponent,
    FaturamentoComponent,
    ReembolsoBeneficiosComponent,
    FornecedoresComponent,
    IpeComponent_2,
    IpeLancamentosComponent,
    IpeLancamentosOcrComponent,
    IpeLancamentosOcrRefatComponent,
    IpeLancamentosOcrRefatNewComponent,
    CadastroRenovacaoClientesAgrometrikaComponent,
    SolicitacaoDeLancamentosDeNotasOcrComponent,
    CriacaoContaContabilComponent,
    SolicitacaoDeLancamentosDeNotasComponentOcrNew,
    SolicitacaoDeLancamentosDeNotasComponentOcrNewTeste
  ],
  imports: [
BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  ServicosFinanceirosRoutingModule,
  HeaderModule,
  BoxLinkPipeModule,
  NbsBannerModule,
  BoxLinkMenuModule,
  FooterModule,
  FormulariosTituloModule,
  MensagemModule,
  CancelamentoSolicitacaoModule,
  NgxSpinnerModule,
  NgxPaginationModule,
  PdfpopupModule,
  FormMatModule,
  NgxMaskModule.forChild(),
  ]
})
export class ServicosFinanceirosModule { }
