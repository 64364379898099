import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-fornecedores',
  templateUrl: './fornecedores.component.html',
  styleUrls: ['./fornecedores.component.css']
})
export class FornecedoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
