export const TipoDePedido = [
  {
    tipo:''
  },
  {
    tipo:'Venda Normal'
  },
  {
    tipo:'Venda Futura'
  },
  {
    tipo:'Venda Conta e Ordem'
  },
  {
    tipo:'Bonificação'
  }
]
