export const filiais_tec_agro_sgl = [
  {
    nome: ''
  },
  {nome: 'Filial_0302_03.482.332/0002-29_GO'},
  {nome: 'Filial_0303_03.482.332/0003-00_GO'},
  {nome: 'Filial_0304_03.482.332/0004-90_TO'},
  {nome: 'Filial_0305_03.482.332/0005-71_GO'},
  {nome: 'Filial_0306_03.482.332/0006-52_MG'},
  {nome: 'Filial_0307_03.482.332/0007-33_MS'},
  {nome: 'Filial_0308_03.482.332/0008-14_PR'},
  {nome: 'Matriz_0301_03.482.332/0001-48_GO'},
  {nome: 'Tec Agro-SGL'}
]

