export const filiais_tec_agro_frc = [
  {
    nome: ''
  },
  {nome: 'Filial_02_36.854.495/0002-44_GO'},
  {nome: 'Filial_04_36.854.495/0003-25_GO'},
  {nome: 'Filial_05_36.854.495/0004-06_GO'},
  {nome: 'Filial_06_36.854.495/0005-97_GO'},
  {nome: 'Filial_08_36.854.495/0006-78_GO'},
  {nome: 'Filial_07_36.854.495/0007-59_GO'},
  {nome: 'Filial_09_36.854.495/0008-30_GO'},
  {nome: 'Filial_13_36.854.495/0009-10_SP'},
  {nome: 'Filial_14_36.854.495/0010-54_MG'},
  {nome: 'Filial_18_36.854.495/0011-35_GO'},
  {nome: 'Filial_17_36.854.495/0012-16_GO'},
  {nome: 'Filial_16_36.854.495/0013-05_TO'},
  {nome: 'Filial_15_36.854.495/0014-88_TO'},
  {nome: 'Filial_19_36.854.495/0015-69_GO'},
  {nome: 'Filial_20_36.854.495/0016-40_GO'},
  {nome: 'Filial_22_36.854.495/0017-20_GO'},
  {nome: 'Filial_23_36.854.495/0018-01_TO'},
  {nome: 'Filial_24_36.854.495/0019-92_TO'},
  {nome: 'Filial_25_36.854.495/0020-26_TO'},
  {nome: 'Filial_26_36.854.495/0021-07_GO'},
  {nome: 'Filial_33_36.854.495/0022-98_GO'},
  {nome: 'Filial_27_36.854.495/0023-79_GO'},
  {nome: 'Filial_28_36.854.495/0024-50_GO'},
  {nome: 'Filial_32_36.854.495/0025-30_GO'},
  {nome: 'Filial_29_36.854.495/0026-11_GO'},
  {nome: 'Filial_41_36.854.495/0027-00_GO'},
  {nome: 'Filial_40_36.854.495/0028-83_GO'},
  {nome: 'Filial_37_36.854.495/0029-64_GO'},
  {nome: 'Filial_35_36.854.495/0030-06_GO'},
  {nome: 'Filial_38_36.854.495/0032-60_TO'},
  {nome: 'Filial_39_36.854.495/0033-40_TO'},
  {nome: 'Filial_34_36.854.495/0034-21_GO'},
  {nome: 'Filial_42_36.854.495/0035-02_GO'},
  {nome: 'Matriz_01_36.854.495/0001-63_GO'},
  {nome: 'Tec Agro-FRC'}
]

