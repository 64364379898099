export const campanha = [
  {
    campanha: ''
  },
  {
    campanha: 'Safra 2022/2023'
  },
  {
    campanha: 'Safra 2023/2024'
  },
  {
    campanha: 'Safrinha 2022'
  },
  {
    campanha: 'Safrinha 2023'
  }
]
