export const setores = [
  {
    nome:''
  },
  {
    nome: 'RH'
  },
  {
    nome: 'Marketing'
  },
  {
    nome: 'Juridico'
  }
]
