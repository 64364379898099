export const pipe_fields_id = [
  'nome_do_respons_vel_nutrien',
  'e_mail_respons_vel_nutrien',
  'empresa',
  'data_de_vencimento_de_imposto',
  'data_para_pagamento_da_invoice',
  'taxa_de_c_mbio',
  'anexar_invoice',
  'anexar_informa_es_de_contrato_ou_gross_up',
  'observa_o'
]
