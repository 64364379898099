export const tipo_de_lancamento = [
  {
    nome:''
  },
  {
    nome: 'Depósito Judicial'
  },
  {
    nome: 'Devolução de Impostos Retidos Indevidamente'
  },
  {
    nome: 'Doações'
  },
  {
    nome: 'Fatura de Cartão de Crédito Corporativo'
  },
  {
    nome: 'Indenização a Clientes e Representantes'
  },
  {
    nome: 'Multas de Trânsito'
  },
  {
    nome: 'Multas Rescisórias e Reclamatórias Trabalhistas'
  },
  {
    nome: 'Reembolso à Funcionário'
  },
  {
    nome: 'Reembolso de Despesas à Terceiros'
  },
  {
    nome: 'Reembolsos de Encargos Financeiros'
  },
  {
    nome: 'Taxas'
  },
  {
    nome: 'Cartório'
  },
  {
    nome: 'Devolução a clientes'
  },
  {
    nome: 'Taxas, Guias e Imposto'
  }, 
  {
    nome: 'Jurídico'
  },
  {
    nome: 'Retorno'
  },
  {
    nome: 'Remessa'
  },
  {
    nome: 'Nota de Crédito'
  },
  {
    nome: 'Outras NFs sem PO'
  },
  {
    nome: 'Notas de Débito'
  }
  
]
