import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {ReactiveFormsModule,FormsModule } from '@angular/forms';
import { SignInComponent } from './sign-in.component';
import { SignInRoutingModule } from './sign-in.routing.module';




@NgModule({
  declarations: [
    LoginComponent,
    SignInComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SignInRoutingModule
  ],
  exports:[
    SignInComponent,
    LoginComponent
  ]
})
export class SignInModule { }
