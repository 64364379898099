import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';


import { FormField } from 'src/app/componentes/formMat/form.component';


@Component({
  selector: 'app-cadastro-renovacao-clientes-agrometrika',
  templateUrl: './cadastro-renovacao-clientes-agrometrika.component.html',
  styleUrls: ['./cadastro-renovacao-clientes-agrometrika.component.css']
})
export class CadastroRenovacaoClientesAgrometrikaComponent implements OnInit {
  fields: FormField[] = [];
  constructor() { }

  ngOnInit(): void {
    this.fields = [
      {
        label: 'Empresa',
        validators: Validators.required,
        name: 'empresa',

        select: [
          {
            type: 'option',

            name: 'Agrichem',

            value: 'Agrichem',
          },

          {
            type: 'option',

            name: 'Agrosema',

            value: 'Agrosema',
          },

          {
            type: 'option',

            name: 'Nutrien',

            value: 'Nutrien',
          },

          {
            type: 'option',

            name: 'Tec Agro',

            value: 'TecAgro',
          },
        ],
        type: 'radioVertical',
      },
      {
        label: 'Tipo de Solicitação',
        validators: Validators.required,
        name: 'tipo_de_solicita_o',

        select: [
          {
            type: 'option',

            name: 'Cadastrar novo cliente',

            value: 'Cadastrar novo cliente',
          },

          {
            type: 'option',

            name: 'Renovação de Crédito',

            value: 'Renovação de Crédito',
          },
        ],
        type: 'radioVertical',
      },

      {
        label: 'Tipo de Cliente',
        validators: Validators.required,
        name: 'tipo_de_cliente',
        select: [
          {
            type: 'option',

            name: 'Pessoa Física',

            value: 'Pessoa Física',
            fields: [
              {
                label: 'CPF',
                validators: Validators.required,
                name: 'cpf',
                type: 'cpf',
              },
            ]
          },

          {
            type: 'option',
            name: 'Pessoa Jurídica',

            value: 'Pessoa Jurídica',
            fields: [
              {
                label: 'CNPJ',
                validators: Validators.required,
                name: 'cnpj',
                type: 'cnpj',
              },
            ]

          },
        ],
        type: 'radioVertical',
      },
      {
        label:'Ficha Cadastral',
        validators:Validators.required,
        name:'ficha_cadastral',
        type:'attachment',
      },
      {
        label:'Documentos obrigatórios cliente',
        validators:Validators.required,
        name:'documentos_obrigat_rios_cliente',
        type:'attachment',
      },
      {
        label:'Limite de crédito Agrichem',
        name:'limite_agrichem',
        validators: Validators.required,
        type:'currency',
        placeholder:'R$',
      },
      {
        label:'Limite de crédito Fronteira',
        name:'limite_fronteira',
        validators: Validators.required,
        type:'currency',
        placeholder:'R$',
      },
      {
        label:'Limite de crédito Sementes Goiás',
        name:'limite_sementes_goi_s',
        validators: Validators.required,
        type:'currency',
        placeholder:'R$',
      },
      {
        label:'Limite de crédito Nutrien',
        name:'limite_nutrien',
        validators: Validators.required,
        type:'currency',
        placeholder:'R$',
      },
      {
        label:'Limite de crédito Agrosema',
        name:'limite_agrosema',
        validators: Validators.required,
        type:'currency',
        placeholder:'R$',
      },
      {
        label: 'Nome Solicitante',
        validators: Validators.required,
        name: 'nome_solicitante',
        type: 'text',
      },
      {
        label: 'E-mail solicitante',
        validators: Validators.required,
        name: 'e_mail_solicitante',
        type: 'email',
      },
      {
        label: 'E-mail secundário',
        name: 'e_mail_secund_rio',
        type: 'email',
      },
      {
        label: 'Município loja ou Xp Nutrien que o cliente é atendido',
        validators: Validators.required,
        name: 'munic_pio_loja_ou_xp_nutrien_que_o_cliente_atendido',
        type: 'text',
      },
      {
        label: 'Estado loja ou Xp Nutrien',
        validators: Validators.required,
        name: 'estado_loja_ou_xp_nutrien',
        type: 'select',
        select: [
          {
            type: 'option',
            name: 'Acre',
            value:'Acre'
          },

          {
            type: 'option',
            name: 'Alagoas',
            value:'Alagoas'
          },
          {
            type: 'option',
            name: 'Amapá',
            value:'Amapá'
          },
          {
            type: 'option',
            name: 'Amazonas',
            value:'Amazonas'
          },
          {
            type: 'option',
            name: 'Bahia',
            value:'Bahia'
          },
          {
            type: 'option',
            name: 'Ceará',
            value:'Ceará'
          },
          {
            type: 'option',
            name: 'Distrito Federal',
            value:'Distrito Federal'
          },
          {
            type: 'option',
            name: 'Espírito Santo',
            value:'Espírito Santo'
          },
          {
            type: 'option',
            name: 'Goiás',
            value:'Goiás'
          },
          {
            type: 'option',
            name: 'Maranhão',
            value:'Maranhão'
          },
          {
            type: 'option',
            name: 'Mato Grosso',
            value:'Mato Grosso'
          },
          {
            type: 'option',
            name: 'Mato Grosso do Sul',
            value:'Mato Grosso do Sul'
          },
          {
            type: 'option',
            name: 'Minas Gerais',
            value:'Minas Gerais'
          },
          {
            type: 'option',
            name: 'Pará',
            value:'Pará'
          },
          {
            type: 'option',
            name: 'Paraíba',
            value:'Paraíba'
          },
          {
            type: 'option',
            name: 'Paraná',
            value:'Paraná'
          },
          {
            type: 'option',
            name: 'Pernambuco',
            value:'Pernambuco'
          },
          {
            type: 'option',
            name: 'Piauí',
            value:'Piauí'
          },
          {
            type: 'option',
            name: 'Rio de Janeiro',
            value:'Rio de Janeiro'
          },
          {
            type: 'option',
            name: 'Rio Grande do Norte',
            value:'Rio Grande do Norte'
          },
          {
            type: 'option',
            name: 'Rio Grande do Sul',
            value:'Rio Grande do Sul'
          },
          {
            type: 'option',
            name: 'Rondônia',
            value:'Rondônia'
          },
          {
            type: 'option',
            name: 'Roraima',
            value:'Roraima'
          },
          {
            type: 'option',
            name: 'Santa Catarina',
            value:'Santa Catarina'
          },
          {
            type: 'option',
            name: 'São Paulo',
            value:'São Paulo'
          },
          {
            type: 'option',
            name: 'Sergipe',
            value:'Sergipe'
          },
          {
            type: 'option',
            name: 'Tocantins',
            value:'Tocantins'
          },
        ]
      },

      {
        label: 'Observação',
        name: 'observa_o',

        type: 'textArea',
      },
      {
        label: 'Estado loja ou Xp Nutrien',
        validators: Validators.required,
        name: 'estado_loja_ou_xp_nutrien',
        type: 'select',
        select: [
          {
            type: 'option',
            name: 'Acre',
            value:'Acre'
          },

          {
            type: 'option',
            name: 'Alagoas',
            value:'Alagoas'
          },
          {
            type: 'option',
            name: 'Amapá',
            value:'Amapá'
          },
          {
            type: 'option',
            name: 'Amazonas',
            value:'Amazonas'
          },
          {
            type: 'option',
            name: 'Bahia',
            value:'Bahia'
          },
          {
            type: 'option',
            name: 'Ceará',
            value:'Ceará'
          },
          {
            type: 'option',
            name: 'Distrito Federal',
            value:'Distrito Federal'
          },
          {
            type: 'option',
            name: 'Espírito Santo',
            value:'Espírito Santo'
          },
          {
            type: 'option',
            name: 'Goiás',
            value:'Goiás'
          },
          {
            type: 'option',
            name: 'Maranhão',
            value:'Maranhão'
          },
          {
            type: 'option',
            name: 'Mato Grosso',
            value:'Mato Grosso'
          },
          {
            type: 'option',
            name: 'Mato Grosso do Sul',
            value:'Mato Grosso do Sul'
          },
          {
            type: 'option',
            name: 'Minas Gerais',
            value:'Minas Gerais'
          },
          {
            type: 'option',
            name: 'Pará',
            value:'Pará'
          },
          {
            type: 'option',
            name: 'Paraíba',
            value:'Paraíba'
          },
          {
            type: 'option',
            name: 'Paraná',
            value:'Paraná'
          },
          {
            type: 'option',
            name: 'Pernambuco',
            value:'Pernambuco'
          },
          {
            type: 'option',
            name: 'Piauí',
            value:'Piauí'
          },
          {
            type: 'option',
            name: 'Rio de Janeiro',
            value:'Rio de Janeiro'
          },
          {
            type: 'option',
            name: 'Rio Grande do Norte',
            value:'Rio Grande do Norte'
          },
          {
            type: 'option',
            name: 'Rio Grande do Sul',
            value:'Rio Grande do Sul'
          },
          {
            type: 'option',
            name: 'Rondônia',
            value:'Rondônia'
          },
          {
            type: 'option',
            name: 'Roraima',
            value:'Roraima'
          },
          {
            type: 'option',
            name: 'Santa Catarina',
            value:'Santa Catarina'
          },
          {
            type: 'option',
            name: 'São Paulo',
            value:'São Paulo'
          },
          {
            type: 'option',
            name: 'Sergipe',
            value:'Sergipe'
          },
          {
            type: 'option',
            name: 'Tocantins',
            value:'Tocantins'
          },
        ]
      },

      {
        label: 'Demanda Urgente?',
        validators: Validators.required,
        name: 'demanda_urgente',
        select: [
          {
            type: 'option',

            name: 'Sim',

            value: 'Sim',
            fields: [
              {
                label: 'Descreva a urgência',
                validators: Validators.required,
                name: 'descreva_a_urg_ncia',
                type: 'textArea',
              },
            ]
          },

          {
            type: 'option',

            name: 'Não',

            value: 'Não',
          },
        ],
        type: 'radioVertical',
      },


      {
        type: 'buttonSubmit',
        name: 'Enviar',
      },
    ];
  }
  enviarFormulario(event: any) {
    console.log(event)
  }

  mascaraReais(valor: number): string {
    const formatador = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatador.format(valor);
  }
}


