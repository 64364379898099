export const quantidadeDeNotas = [
  {
    quantidade:'1'
  },
  {
    quantidade:'2'
  },
  {
    quantidade:'3'
  },
  {
    quantidade:'4'
  },
  {
    quantidade:'5'
  },
  {
    quantidade:'6'
  },
  {
    quantidade:'7'
  },
  {
    quantidade:'8'
  },
  {
    quantidade:'9'
  },
  {
    quantidade:'10'
  }
]
