export const empresas = [
  {
    nome:""
  },
  {
    nome:"Agrichem"
  },
  {
    nome:"Agrosema"
  },
  {
    nome:"BioRural"
  },
  {
    nome:"TechAgro"
  },
  {
    nome:"Terra Nova"
  },
  {
    nome:"Utilfertil"
  }
]
