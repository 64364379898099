<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Emissão de Nota Fiscal de Importação"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="periodo" class="col-sm-4 col-form-label">*Período</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="periodo">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('periodo')?.errors?.required && formulario.get('periodo')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa" (change)="onEmpresaChange($event)">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro'">
      <label for="filial_tecagro" class="col-sm-4 col-form-label">*Filiais - Tec Agro </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tecagro">
          <option *ngFor="let filial of filiais_tec_agro" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('filial_tecagro')?.errors?.required && formulario.get('filial_tecagro')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-SGL'">
      <label for="filial_tec_agro_sgl" class="col-sm-4 col-form-label">*Filial Tec Agro-SGL </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_sgl">
          <option *ngFor="let filial of filiais_tec_agro_sgl" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_sgl')?.errors?.required && formulario.get('filial_tec_agro_sgl')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Tec Agro-FRC'">
      <label for="filial_tec_agro_frc" class="col-sm-4 col-form-label">*Filial Tec Agro-FRC </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_tec_agro_frc">
          <option *ngFor="let filial of filiais_tec_agro_frc" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('filial_tec_agro_frc')?.errors?.required && formulario.get('filial_tec_agro_frc')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>


    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Nutrien'">
      <label for="filial_nutrien" class="col-sm-4 col-form-label">*Filiais - Nutrien </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_nutrien">
          <option *ngFor="let filial of filiais_nutrien" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('filial_nutrien')?.errors?.required && formulario.get('filial_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrosema'">
      <label for="filial_agrosema" class="col-sm-4 col-form-label">*Filiais - Agrosema </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrosema">
          <option *ngFor="let filial of filiais_agrosema" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('filial_agrosema')?.errors?.required && formulario.get('filial_agrosema')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="this.formulario.getRawValue().empresa =='Agrichem'">
      <label for="filial_agrichem" class="col-sm-4 col-form-label">*Filiais - Agrichem </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="filial_agrichem">
          <option *ngFor="let filial of filiais_agrichem" [value]="filial.nome">{{filial.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('filial_agrichem')?.errors?.required && formulario.get('filial_agrichem')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="descricao_da_solicitacao" class="col-sm-4 col-form-label">*Descrição da Solicitação </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="descricao_da_solicitacao">
          <option *ngFor="let descricao of descricos_das_solicitacoes" [value]="descricao.nome">{{descricao.nome}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('descricao_da_solicitacao')?.errors?.required && formulario.get('descricao_da_solicitacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_da_di" class="col-sm-4 col-form-label">*Número da Di</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_da_di">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_da_di')?.errors?.required && formulario.get('numero_da_di')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="pedido_de_compra" class="col-sm-4 col-form-label">*Pedido de Compra</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="pedido_de_compra">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('pedido_de_compra')?.errors?.required && formulario.get('pedido_de_compra')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="prazo_para_pagamento" class="col-sm-4 col-form-label">*Prazo para pagamento</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="prazo_para_pagamento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('prazo_para_pagamento')?.errors?.required && formulario.get('prazo_para_pagamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="quantidade_de_notas_a_serem_emitidas" class="col-sm-4 col-form-label">*Quantidade de Notas a serem
        emitidas</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="quantidade_de_notas_a_serem_emitidas">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('quantidade_de_notas_a_serem_emitidas')?.errors?.required && formulario.get('quantidade_de_notas_a_serem_emitidas')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="produto_perigoso" class="col-sm-4 col-form-label">*Produto Perigoso</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="produto_perigoso">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('produto_perigoso')?.errors?.required && formulario.get('produto_perigoso')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="declaracao_de_produto_perigoso_e_onu" class="col-sm-4 col-form-label">*Declaração de Produtos
        Perigosos e ONU </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="declaracao_de_produto_perigoso_e_onu" id="formFileMultiple"
          (change)="saveDeclaracaoDeProdutosPerigosos($event)" multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('declaracao_de_produto_perigoso_e_onu')?.errors?.required && formulario.get('declaracao_de_produto_perigoso_e_onu')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="declaracaoDeProdutosPerigosos.length!=0">
      <div class="card-header">Anexos - Declaração de Produtos Perigosos</div>
      <ul class="list-group list-group-flush" *ngFor="let file of declaracaoDeProdutosPerigosos">
        <li class="list-group-item d-flex justify-content-between">
          <span class="fileName">{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile'
              (click)="removeFileDeclaracaoDeProdutosPerigosos(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="declaracao_de_importacao" class="col-sm-4 col-form-label">*Declaração de Importação </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="declaracao_de_importacao" id="formFileMultiple" (change)="saveDeclaracaoDeImportacao($event)"
          multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('declaracao_de_importacao')?.errors?.required && formulario.get('declaracao_de_importacao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="declaracaoDeImportacao.length!=0">
      <div class="card-header">Anexos - Declaração de Importação</div>
      <ul class="list-group list-group-flush" *ngFor="let file of declaracaoDeImportacao">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileDeclaracaoDeImportacao(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="coa_invoice" class="col-sm-4 col-form-label">*COA/Invoice </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="coa_invoice"
          id="formFileMultiple" (change)="saveCoaInvoices($event)" multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('coa_invoice')?.errors?.required && formulario.get('coa_invoice')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="coaInvoices.length!=0">
      <div class="card-header">Anexos - COA / Invoice</div>
      <ul class="list-group list-group-flush" *ngFor="let file of coaInvoices">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileCoaInvoices(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="exoneracoes" class="col-sm-4 col-form-label">*Exonerações, Isenções, guia ICMS, Taxas e Tela Débito
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea" formControlName="exoneracoes"
          id="formFileMultiple" (change)="saveExoneracoes($event)" multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('exoneracoes')?.errors?.required && formulario.get('exoneracoes')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="exoneracoes.length!=0">
      <div class="card-header">Anexo - Exonerações</div>
      <ul class="list-group list-group-flush" *ngFor="let file of exoneracoes">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileExoneracoes(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="transportadora" class="col-sm-4 col-form-label">*Transportadora</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="transportadora">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('transportadora')?.errors?.required && formulario.get('transportadora')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="planilha_de_lotes" class="col-sm-4 col-form-label">*Planilha de Lotes </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="planilha_de_lotes" id="formFileMultiple" (change)="savePlanilhaDeLotes($event)"
          multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('planilha_de_lotes')?.errors?.required && formulario.get('planilha_de_lotes')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="planilhaDeLotes.length!=0">
      <div class="card-header">Anexo - Planilha de Lotes</div>
      <ul class="list-group list-group-flush" *ngFor="let file of planilhaDeLotes">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFilePlanilhaDeLotes(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="nome_do_solicitante" class="col-sm-4 col-form-label">*Nome do solicitante</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_solicitante">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_solicitante')?.errors?.required && formulario.get('nome_do_solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail" class="col-sm-4 col-form-label">*E-mail do solicitante</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="e_mail">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.required && formulario.get('e_mail')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail')?.errors?.email && formulario.get('e_mail')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="torre_area_departamento" class="col-sm-4 col-form-label">*Torre/ Área/ Departamento</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="torre_area_departamento">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('torre_area_departamento')?.errors?.required && formulario.get('torre_area_departamento')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="observacaoes1" class="col-sm-4 col-form-label">Observações </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacaoes1"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observacaoes1')?.errors?.required && formulario.get('observacaoes1')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>
    <div class="descricao-observacoes">
      <p class="text-center">Detalhamento da operação</p>
    </div>

    <div class="form-group row mt-3">
      <label for="demais_anexos" class="col-sm-4 col-form-label">Demais anexos </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="demais_anexos" id="formFileMultiple" (change)="saveDemaisAnexos($event)" multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="demaisAnexos.length!=0">
      <div class="card-header">Anexo - Demais Anexos</div>
      <ul class="list-group list-group-flush" *ngFor="let file of demaisAnexos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileDemaisAnexos(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>

  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->

</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
