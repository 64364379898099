<header class="header_nbs">
    <img class="header_nbs-logo" (click)="homePage()" src="../../../assets/img/logo.png" alt="">
    <h1 class="header_nbs-title" *ngIf="!!environment.production">Portal NBS</h1>
    <h1 class="header_nbs-title" *ngIf="!!environment.homolog">Portal NBS Homologação</h1>
    <h1 class="header_nbs-title" *ngIf="!!environment.dev">Portal NBS Dev.</h1>
    <h1 class="header_nbs-title" *ngIf="!!environment.localhost">Portal NBS Local.</h1>

    <div class="header_nbs-user-infos" >
        <div >
            <span class ="userName" *ngIf="userName!==undefined" style="color:white;">Olá, {{userName}}</span>
        </div>
        <i class="fa fa-sign-out logout-btn" (click)="logout()" alt="sair"><span class="logout-text">&nbsp; Sair</span></i>
    </div>
</header>
<p id="versaoApp" *ngIf="!!environment.production">Versão: {{environment.versao}}</p>
<p id="versaoApp" *ngIf="!!environment.dev">Versão DEV: {{environment.versao}}</p>
<p id="versaoApp" *ngIf="!!environment.homolog">Versão HML: {{environment.versao}}</p>
<p id="versaoApp" *ngIf="!!environment.localhost">Versão LOCAL: {{environment.versao}}</p>

