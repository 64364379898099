export const contabilidade = [
  {
    nome:''
  },
  {
    nome:'Auditoria- Documentos Suporte'
  },
  {
    nome:'Baixa Imobilizado'
  },
  {
    nome:'Lançamento Contábil'
  }
]
