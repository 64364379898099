import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte-em-testes',
  templateUrl: './suporte-em-testes.component.html',
  styleUrls: ['./suporte-em-testes.component.css']
})
export class SuporteEmTestesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
