<app-header></app-header>

<div class="nbs_banner">
 <img class="img-responsive img-logo" src="../../../assets/img/logo_favicon.png" alt="">
  <div class="box-title title-nutrien">
      <h2 class="nbs_banner-title">Nutrien</h2>
      <h2 class="nbs_banner-subtitle">Business Services</h2>
  </div>

  <img class="nbs_banner-img" src="../../../assets/img/fa-main-menu/banner.png" alt="descricao">
</div>

<div class="nbs_menu container">
  <div id="nbs-menu-content">
    <div id="nbs_nav-quick-access" class="mr-5">
      <h5 id="nbs_title-quick-access" class="p-2 m-0">Acesso rápido de finanças</h5>
      <nav id="nbs_links-quick-access" class="nav flex-column">
        <ul>
          <li>
            <a class="nav-link" routerLink="/payment/forms/solicitacao-de-lancamento-de-notas"> > Solicitar Lançamento/Pagamentos Gerais</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/customer-service/forms/venda-e-faturamento-pedido"> > Emitir Pedido de Venda e Fatura</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/taxes/forms/cancelamento-recusa-emissao-manual-notas-fiscais"> > Nota Fiscal: Cancelar, Recusar e Emitir Manual</a>
          </li>
          <li>
            <a class="nav-link" href="https://selfbooking.lemontech.com.br/nutrien" target="_blank"> > Reembolso Viagem / Despesa</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/payment/forms/servicos-para-cartao-de-credito-corporativo"> > Cartão Corporativo</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/accouting/forms/auditoria"> > Suporte Auditoria</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/forms/consulta-de-chamado"> > Consultar Solicitações</a>
          </li>
          <li>
            <a class="nav-link" href="https://app.pipefy.com/public/form/8CMl776i" target="_blank"> > Solicitar Requisição de Compras</a>
          </li>
          <li>
            <a class="nav-link" routerLink="/forms/cancelamento-de-solicitacao"> > Cancelar Solicitação</a>
          </li>
        </ul>
      </nav>
    </div>

    <div id="nbs-content-page">
      <div class="row">
        <div class="h5 nbs_menu-title col-lg-12">
          <!-- <p class="title"> Para qual área deseja abrir uma solicitação?</p> -->
        </div>
      </div>

      <!-- <div class="row">
        <ng-container *ngIf="verificaPerfil('082ebe29-12af-4082-8845-7d829de7c985')">
          <div class="nbs_box_link_menu">
            <img class="nbs_box_link_menu-image img-responsive" src="../../../assets/img/contabilidade.png"
              alt="Contabilidade" />
            <div class="">
              <p class="nbs_box_link_menu-title p-2 box-titulo" routerLink="link">Contabilidade1</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificaPerfil('082ebe29-12af-4082-8845-7d829de7c985')">
          <div class="nbs_box_link_menu">
            <img class="nbs_box_link_menu-image img-responsive" src="../../../assets/img/contabilidade.png"
              alt="Contabilidade" />
            <div class="">
              <p class="nbs_box_link_menu-title p-2 box-titulo" routerLink="link">Contabilidade2</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificaPerfil('teste')">
          <div class="nbs_box_link_menu">
            <img class="nbs_box_link_menu-image img-responsive" src="../../../assets/img/contabilidade.png"
              alt="Contabilidade" />
            <div class="">
              <p class="nbs_box_link_menu-title p-2 box-titulo" routerLink="link">Contabilidade3</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificaPerfil('082ebe29-12af-4082-8845-7d829de7c985')">
          <div class="nbs_box_link_menu">
            <img class="nbs_box_link_menu-image img-responsive" src="../../../assets/img/contabilidade.png"
              alt="Contabilidade" />
            <div class="">
              <p class="nbs_box_link_menu-title p-2 box-titulo" routerLink="link">Contabilidade4</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificaPerfil('082ebe29-12af-4082-8845-7d829de7c985')">
          <div class="nbs_box_link_menu">
            <img class="nbs_box_link_menu-image img-responsive" src="../../../assets/img/contabilidade.png"
              alt="Contabilidade" />
            <div class="">
              <p class="nbs_box_link_menu-title p-2 box-titulo" routerLink="link">Contabilidade5</p>
            </div>
          </div>
        </ng-container>
      </div> -->

      <div class=" d-flex column justify-content-between menu-items">
        <app-box-link-menu descricao="Contabilidade" url="../../../assets/img/fa-main-menu/contabilidade.png" titulo="Contabilidade"
          routerLink="/accouting"></app-box-link-menu>

        <div class="repair-position1">
          <app-box-link-menu descricao="Pagamentos" url="../../../assets/img/fa-main-menu/financas.png" titulo="Finanças"
            routerLink="/payment"></app-box-link-menu>
        </div>
      </div>

      <div class="d-flex column justify-content-between menu-items">

        <app-box-link-menu descricao="" url="../../../assets/img/fa-main-menu/lojas.png" titulo="Lojas"
          subtitulo2="Centros de Experiência" routerLink="/customer-service"></app-box-link-menu>

        <div class="repair-position">
          <app-box-link-menu descricao="Taxas" url="../../../assets/img/fa-main-menu/fiscal2.png" titulo="Fiscal" routerLink="/taxes">
          </app-box-link-menu>
        </div>

      </div>

      <div class=" d-flex column justify-content-between menu-items">
        <app-box-link-menu descricao="Compras" url="../../../assets/img/fa-main-menu/requisicaoDeCompras.png"
          titulo="Compras" subtitulo="Serviços e Materiais Indiretos"
          routerLink="/compras"></app-box-link-menu>

        <div class="repair-position-cadastro">
          <app-box-link-menu descricao="cadastro" url="../../../assets/img/fa-main-menu/cadastro2.png" titulo="Cadastro"
            subtitulo="Clientes, Fornecedores, Materiais" routerLink="/cadastro"></app-box-link-menu>
        </div>

      </div>
      <div class=" d-flex column justify-content-between menu-items">
        <!-- <app-box-link-menu descricao="suporte para compras" url="../../../assets/img/fa-main-menu/suporte.png"
          titulo="Suporte para Compras" subtitulo="Serviços e Materiais Indiretos"
          (click)="goToUrl('https://app.pipefy.com/public/form/6wliaNG2')"></app-box-link-menu> -->


          <app-box-link-menu descricao="suporte para compras"
            url="../../../assets/img/fa-main-menu/consulta.png" titulo="Consulta de Chamados"
            routerLink='/forms/consulta-de-chamado'></app-box-link-menu>
            <div class="repair-position-rh">
              <app-box-link-menu descricao="Recursos Humanos" url="../../../assets/img/fa-main-menu/recursos-humanos.png"
          titulo="Recursos Humanos" subtitulo="Clique aqui para acessar o myHR"
          (click)="goToUrl('https://hcm17.sapsf.com/login?&company=nutrien')"></app-box-link-menu>
            </div>

      </div>

      <div class=" d-flex column justify-content-between menu-items">


        <app-box-link-menu descricao="suporte para compras" url="../../../assets/img/fa-main-menu/consulta.png" titulo="Testes Api"
          routerLink='/teste-backend' *ngIf="email=='diego.campos@nutrien.com' || email=='silvio.angelo@nutrien.com' " ></app-box-link-menu>
      </div>
    </div>
  </div>
</div>




<app-cancelamento-solicitacao></app-cancelamento-solicitacao>
<app-footer [exibeVoltar]="false"></app-footer>
<!-- <footer>
  <div class="wrapper">
    <div class="row linha2">-->
      <!--<-- <div class="col-lg-3 box-voltar" (click)="navegar()">
        <i class="fa fa-chevron-circle-left icon-voltar"></i>
        <span class="text-voltar">&nbsp;Voltar</span>
      </div> -->
      <!--<div class="col-lg-3"></div>
      <div class="col-lg-6">
        <div class="logo" (click)="navegar()"><img class="logo_nbs" src="../../../assets/img/logo_nbs.png"
            (click)="navegar()"></div>
      </div>
      <div class="col-lg-3"></div>
      !-- <div class="col-lg-3 box-duvidas" (click)="faq()">
        <i class="fa fa-question-circle icon-duvidas"></i>
        <span class="text-duvidas">Ficou com alguma duvida? <br>É só mandar por aqui que a gente te ajuda!</span>
      </div> --
    </div>
  </div>
</footer> -->
