<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Autoatendimento">
</app-nbs-banner>


<div class="container">


    <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Benefício Movimentação"
        titulo="Solicitação de Benefícios"></app-formularios-titulo>

    <div class="mensagem-topo">
        <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
        <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

    <app-form-mat [fields]="fields" urlPipefy="https://app.pipefy.com/public/form/TmOnbl5o" (changeForm)="enviarFormulario($event)" pipeId="303016980"></app-form-mat>


<div class="footer">
    <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>