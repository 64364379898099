export const areas = [
  {
    nome:''
  },
  {
    nome:'Contabilidade'
  },
  {
    nome:'Fiscal'
  },
  {
    nome:'Lojas Centros de Experiência'
  },
  {
    nome:'Pagamentos'
  },
  {
    nome:'Compras de Serviços e Materiais Indiretos'
  },
  {
    nome:'Outros'
  }
]
