export const lista_cnpjs_empresa_nutrien = [
    { nome: "", cnpj: ""},
    { nome: "Agrosema", cnpj: "00.567.813/0001-68"},
    { nome: "Agrosema", cnpj: "00.567.813/0008-34"},
    { nome: "Agrosema", cnpj: "00.567.813/0011-30"},
    { nome: "TecAgro (FCR)", cnpj: "02.745.323/0001-30"},
    { nome: "TecAgro (SGL)", cnpj: "03.482.332/0001-48"},
    { nome: "TecAgro (SGL)", cnpj: "03.482.332/0003-00"},
    { nome: "TecAgro (SGL)", cnpj: "03.482.332/0004-90"},
    { nome: "Agrichem", cnpj: "03.860.998/0001-92"},
    { nome: "Agrichem", cnpj: "03.860.998/0004-35"},
    { nome: "Agrosema", cnpj: "04.399.024/0001-16"},
    { nome: "Agrosema", cnpj: "04.399.024/0009-73"},
    { nome: "Agrosema", cnpj: "04.399.024/0011-98"},
    { nome: "Agrosema", cnpj: "04.399.024/0015-11"},
    { nome: "Agrosema", cnpj: "04.399.024/0017-83"},
    { nome: "Agrosema", cnpj: "04.399.024/0018-64"},
    { nome: "Agrosema", cnpj: "04.399.024/0022-40"},
    { nome: "Agrosema", cnpj: "04.399.024/0023-21"},
    { nome: "Agrosema", cnpj: "04.399.024/0027-55"},
    { nome: "Agrosema", cnpj: "04.399.024/0028-36"},
    { nome: "Agrosema", cnpj: "04.399.024/0029-17"},
    { nome: "Agrosema", cnpj: "04.399.024/0032-12"},
    { nome: "TecAgro (FCR)", cnpj: "11.957.512/0001-67"},
    { nome: "Nutrien", cnpj: "15.224.597/0001-25"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0001-63"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0003-25"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0006-78"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0007-59"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0009-10"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0011-35"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0012-16"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0013-05"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0017-20"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0018-01"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0019-92"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0020-26"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0021-07"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0025-30"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0028-83"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0029-64"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0030-06"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0031-89"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0033-40"},
    { nome: "TecAgro (FCR)", cnpj: "36.854.495/0034-21"},
    { nome: "TecAgro (FCR)", cnpj: "38.854.395/0017-20"},
    { nome: "Nutrien", cnpj: "88.305.859/0004-00"},
    { nome: "Nutrien", cnpj: "88.305.859/0016-36"},
    { nome: "Nutrien", cnpj: "88.305.859/0017-17"},
    { nome: "Nutrien", cnpj: "88.305.859/0018-06"},
    { nome: "Nutrien", cnpj: "88.305.859/0022-84"},
    { nome: "Nutrien", cnpj: "88.305.859/0025-27"},
    { nome: "Nutrien", cnpj: "88.305.859/0026-08"},
    { nome: "Nutrien", cnpj: "88.305.859/0028-70"},
    { nome: "Nutrien", cnpj: "88.305.859/0031-75"},
    { nome: "Nutrien", cnpj: "88.305.859/0032-56"},
    { nome: "Nutrien", cnpj: "88.305.859/0033-37"},
    { nome: "Nutrien", cnpj: "88.305.859/0034-18"},
    { nome: "Nutrien", cnpj: "88.305.859/0035-07"},
    { nome: "Nutrien", cnpj: "88.305.859/0036-80"},
    { nome: "Nutrien", cnpj: "88.305.859/0037-60"},
    { nome: "Nutrien", cnpj: "88.305.859/0040-66"},
    { nome: "Nutrien", cnpj: "88.305.859/0041-47"},
    { nome: "Nutrien", cnpj: "88.305.859/0042-28"},
    { nome: "Nutrien", cnpj: "88.305.859/0044-90"},
    { nome: "Nutrien", cnpj: "88.305.859/0045-70"},
    { nome: "Nutrien", cnpj: "88.305.859/0046-51"},
    { nome: "Nutrien", cnpj: "88.305.859/0047-32"},
    { nome: "Nutrien", cnpj: "88.305.859/0048-13"},
    { nome: "Nutrien", cnpj: "88.305.859/0050-38"},
    { nome: "Nutrien", cnpj: "88.305.859/0051-19"},
    { nome: "Nutrien", cnpj: "88.305.859/0052-08"},
    { nome: "Nutrien", cnpj: "88.305.859/0053-80"},
    { nome: "Nutrien", cnpj: "88.305.859/0054-61"},
    { nome: "Nutrien", cnpj: "88.305.859/0057-04"},
    { nome: "Nutrien", cnpj: "88.305.859/0058-95"},
    { nome: "Nutrien", cnpj: "88.305.859/0059-76"},
    { nome: "Nutrien", cnpj: "88.305.859/0060-00"},
    { nome: "Nutrien", cnpj: "88.305.859/0061-90"}
]