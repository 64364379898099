import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComprasRoutingModule } from './compras-routing.module';
import { MenuComprasComponent } from './menu-compras/menu-compras.component';
import { HeaderModule } from './../componentes/header/header.module';
import { NbsBannerModule } from './../componentes/nbs-banner/nbs-banner.module';
import { BoxLinkMenuModule } from '../componentes/box-link-menu/box-link-menu.module';
import { BoxLinkPipeModule } from '../componentes/box-link-pipe/box-link-pipe.module';
import { FooterModule } from '../componentes/footer/footer.module';




@NgModule({
  declarations: [
    MenuComprasComponent
  ],
  imports: [
  CommonModule,
    ComprasRoutingModule,
    HeaderModule,
    NbsBannerModule,
    BoxLinkPipeModule,
    BoxLinkMenuModule,
    FooterModule
  ]
})
export class ComprasModule { }
