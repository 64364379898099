import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../autenticacao/auth-guard/auth-guard.service';
import { AccoutingComponent } from './accouting/accouting.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { MenuComponent } from './menu/menu.component';
import { PaymentComponent } from './payment/payment.component';
import { TaxesComponent } from './taxes/taxes.component';

const routes: Routes = [
  {
    path: 'accouting',
    component: AccoutingComponent,
    // canActivate:[AuthGuardService]
  },
  {
    path:'customer-service',
    component: CustomerServiceComponent
  },
  {
    path:'payment',
    component: PaymentComponent
  },
  {
    path:'taxes',
    component: TaxesComponent
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicosFinanceirosRoutingModule { }
