import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { pipe_fields_ids_delegar_gestao } from './pipe_fields_delegar_gestao';
import { FormField } from 'src/app/componentes/formMat/form.interface';

@Component({
  selector: 'app-delegar-gestao',
  templateUrl: './delegar-gestao.component.html',
  styleUrls: ['./delegar-gestao.component.css']
})
export class DelegarGestaoComponent implements OnInit {

  fields: FormField[] = [];

  constructor() {

  }

  ngOnInit(): void {

    this.fields = [
      {
        "type": "text",
        "name": "nome_do_solicitante",
        "label": "Nome do solicitante"
      },
      {
        "type": "email",
        "name": "e_mail_do_solicitante",
        "label": "E-mail do solicitante"
      },
      {
        "type": "cpf",
        "name": "cpf",
        "label": "CPF",
        "validators": Validators.required
      },
      {
        "type": "select",
        "name": "empresa",
        "label": "Empresa",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "AGRICHEM DO BRASIL SA",
            "value": "AGRICHEM DO BRASIL SA"
          },
          // {
          //   "type": "option",
          //   "name": "AGROSEMA COMERCIAL AGRICOLA LTDA",
          //   "value": "AGROSEMA COMERCIAL AGRICOLA LTDA"
          // },
          {
            "type": "option",
            "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
            "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
          },
          {
            "type": "option",
            "name": "CONFIANÇA AGRÍCOLA LTDA",
            "value": "CONFIANÇA AGRÍCOLA LTDA"
          },
          {
            "type": "option",
            "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
            "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
          },
          // {
          //   "type": "option",
          //   "name": "MARCA AGRO MERCANTIL LTDA",
          //   "value": "MARCA AGRO MERCANTIL LTDA",
          // },
          {
            "type": "option",
            "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
            "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
          },
          // {
          //    "type": "option",
          //    "name": "MARCA AGRO MERCANTIL LTDA",
          //    "value": "MARCA AGRO MERCANTIL LTDA",
          // },
          {
            "type": "option",
            "name": "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
            "value": "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA"
          },
          {
            "type": "option",
            "name": "SEMENTES GOIÁS LTDA",
            "value": "SEMENTES GOIÁS LTDA"
          },
          {
            "type": "option",
            "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
            "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
          }
        ]
      },
      {
        "type": "select",
        "name": "processo",
        "label": "Processo",
        "value": "Delegar gestão",
        "_disabled": true,
        "select": [
          {
            "type": "option",
            "name": "Delegar gestão",
            "value": "Delegar gestão"
          }
        ]
      },
      {
        "type": "textArea",
        "name": "descri_o",
        "label": "Descrição",
        "validators": Validators.required
      },
      {
        "type": "attachment",
        "name": "documenta_o_e_ou_anexo",
        "label": "Documentação e/ou Anexo"
      },
      {
        "label": "Nome do Colaborador que Receberá a Delegação",
        "name": "nome_do_colaborador_que_receber_a_delega_o",
        "type": "text",
        "validators": Validators.required
      },
      {
        "label": "E-mail do Colaborador que Receberá a Delegação",
        "name": "e_mail_do_colaborador_que_receber_a_delega_o",
        "type": "email",
        "validators": Validators.required
      },
      {
        "label": "Período da Delegação - Início",
        "name": "per_odo_da_delega_o_in_cio",
        "type": "date",
        "validators": Validators.required
      },
      {
        "label": "Período da Delegação - Fim",
        "name": "per_odo_da_delega_o_fim",
        "type": "date",
        "validators": Validators.required
      },
      {
        "type": "checklistVertical",
        "label": "Serviços a Delegar",
        "name": "servi_os_a_delegar",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Auto Atendimento LG",
            "value": "Auto Atendimento LG"
          },
          {
            "type": "option",
            "name": "Auto Atendimento Links",
            "value": "Auto Atendimento Links"
          },
          {
            "type": "option",
            "name": "Frequência (Aprovação do ponto eletrônico)",
            "value": "Frequência (Aprovação do ponto eletrônico)"
          },
          {
            "type": "option",
            "name": "Gen.te Flui Workflow",
            "value": "Gen.te Flui Workflow"
          },
          {
            "type": "option",
            "name": "Processos Workflow",
            "value": "Processos Workflow"
          },
          {
            "type": "option",
            "name": "Serviços Mobile",
            "value": "Serviços Mobile"
          },
        ]
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }
}
