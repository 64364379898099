export const motivos = [
  {
    nome:''
  },
  {
    nome:'Divergência NF x PO'
  },
  {
    nome:'Dúvida sobre criação de PR/PO'
  },
  {
    nome:'Dúvida sobre cadastro (fornecedor/item)'
  },
  {
    nome:'Dúvida sobre contrato de fornecimento'
  },
  {
    nome:'Problema com requisição'
  }
]
