<footer>
  <div class="wrapper">
  <!-- <div class="row">
    <div class="col-lg-12 box-duvidas" (click)="faq()" >
      <i class="fa fa-question-circle"></i>
      <span>Dúvidas</span>
    </div>
  </div> -->
  <div class="row linha2">
    <div  class="col-lg-3 box-voltar" >
      <i  *ngIf="exibeVoltar" class="fa fa-chevron-circle-left icon-voltar" (click)="navegar()"></i>
      <span *ngIf="exibeVoltar" class="text-voltar" (click)="navegar()">&nbsp;Voltar</span>
    </div>
    <div class="col-lg-6">
      <div class="logo  box-logo"  (click)="goHome()"><img class="logo_nbs  box-logo" src="../../../assets/img/logo_nbs.png"  (click)="goHome()"></div>
    </div>
    <div class="col-lg-3"></div>
    <!-- <div class="col-lg-3 box-duvidas"  >
      <i class="fa fa-question-circle icon-duvidas" (click)="faq()"></i>
      <span class="text-duvidas" (click)="faq()">Ficou com alguma duvida? <br>É só mandar por aqui que a gente te ajuda!</span>
    </div> -->
    <!-- <div class="col-lg-9 box-texto">
      <p>
        Escolha a opção desejada<br>
        para ter acesso ao formulário de solicitação.
      </p>
    </div> -->
  </div>
 
</div>
<div class="row">
  <p class="text-duvidas" (click)="termo()">Termo de Uso</p>
</div>
</footer>
