export const empresasBaixa =[
  {
    nome:""
  },
  {
    nome:"Nutrien Utilfértil"
  },
  {
    nome:'Nutrien Paricipações'
  },
  {
    nome:'Agrichem'
  },
  {
    nome:'APMS'
  },
  {
    nome:'FAG'
  },
  {
    nome:'FCR'
  },
  {
    nome:'SGL'
  }

]
