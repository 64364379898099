<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">
  <div class="logo-container">
    <button class="logo" (click)="toogleCollapse()"><i [class]= icon ></i></button>

    <div class="logo-text" *ngIf="collapsed">Portal RH</div>

    <button class="btn-close" *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fa fa-times close-icon"></i>

    </button>
  </div>
  <ul class="sidenav-nav">
    <li class="sidenav-nav-item" *ngFor="let data of navData">
      <a href="" class="sidenav-nav-link" [routerLink]="[data.routerLink]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <i class="sidenav-link-icon"[class] = "data.icon">
        <span class="sidenav-link-text" *ngIf="collapsed">{{data.label}}</span>
      </i></a>

    </li>
  </ul>
</div>

