<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Finanças">
</app-nbs-banner>
<div class="container">
  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>



  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Serviços para Cartão de Crédito Corporativo"
          url="../../../assets/img/fa-menus/financas/cartao_de_credito_corporativo.png"
          titulo="Cartão de Crédito Corporativo" subtitulo="Serviços"
          link="/payment/forms/servicos-para-cartao-de-credito-corporativo"></app-box-link-pipe>
      </div>
      <div class="col-lg-6"> <!--*ngIf="!podeAcessarIpe"-->
        <app-box-link-pipe descricao="Lançamento/Pagamento de Notas/Impostos e outras cobrança"
          url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="Lançamento/Pagamento"
          subtitulo="Notas/Impostos e outras cobranças"
          link="/payment/forms/solicitacao-de-lancamento-de-notas"></app-box-link-pipe>
      </div>
    </div>
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Solicitação de Reembolso de Despesas"
          url="../../../assets/img/fa-menus/financas/solicitacao_de_reembolso.png" titulo="Solicitação de reembolso"
          subtitulo="Despesas / Viagens" link="https://selfbooking.lemontech.com.br/nutrien"
          tipo_de_link="externo"></app-box-link-pipe>
      </div>

      <div class="col-lg-6">
        <app-box-link-pipe descricao="Service Desk Payments" url="../../../assets/img/background-sala-suporte.png"
          titulo="Service Desk Payments" subtitulo="" [link]="urlSalaSuporte" tipo_de_link="externo"
          id_box="helpDesk"></app-box-link-pipe>
      </div>
    </div>

<ng-container *ngIf="!!environment.dev || environment.localhost">
  <!-- <div class="row menu-itens">
    <div class="col-lg-6">
      <app-box-link-pipe descricao="Lançamento de Notas"
        url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png"  titulo="Lançamento de Notas" subtitulo=""
        link="/payment/forms/lancamento-de-notas"></app-box-link-pipe>
    </div>

  </div> -->
  <!-- <div class="row menu-itens" > -->
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Lançamento de Notas OCR"
          url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="Lançamento de Notas IPE - Homologação"
          subtitulo="" link="/teste"></app-box-link-pipe>
      </div>

      <div class="col-lg-6">
        <app-box-link-pipe descricao="Lançamento de Notas OCR - Versão 27/03"
          url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="Lançamento de Notas OCR - Versão 27/03"
          subtitulo="" link="/lancamentos-ocr"></app-box-link-pipe>
      </div>

    </div>
    <!-- <div class="col-lg-6">
      <app-box-link-pipe descricao="Lançamento de Notas OCR"
        url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="Lançamento de Notas OCR"
        subtitulo="" link="/payment/forms/lancamento-de-notas-ocr"></app-box-link-pipe>
    </div> -->
    <!-- <div class="col-lg-6">
      <app-box-link-pipe descricao="OCR Refatorado"
        url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="OCR Refatorado" subtitulo=""
        link="/payment/forms/lancamento-de-notas-ocr-refat-new"></app-box-link-pipe>
    </div> -->
  <!-- </div> -->
  <div class="row menu-itens">
    <!-- <div class="col-lg-6">
      <app-box-link-pipe descricao="OCR Refatorado OLD"
        url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="OCR Refatorado OLD" subtitulo=""
        link="/payment/forms/lancamento-de-notas-ocr-refat"></app-box-link-pipe>
    </div> -->
  </div>
  <!-- <div class="row menu-itens">

      <div class="col-lg-6" *ngIf=" 
                                  email=='thiago.uehara@nutrien.com'
                                  ||email=='gabriel.schimidt@nutrien.com'">
        <app-box-link-pipe descricao="Lançamento de notas massivas" url="../../../assets/img/fa-menus/financas/solicitacao_de_reembolso.png" titulo="Lançamento de notas" subtitulo="Massiva" link="/payment/forms/lancamento-de-notas-massiva"></app-box-link-pipe>
      </div>
  </div> -->
</ng-container>



  </div>


</div>
<div class="footer">

  <app-footer link="menu"></app-footer>
</div>
