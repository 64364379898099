import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-compras',
  templateUrl: './menu-compras.component.html',
  styleUrls: ['./menu-compras.component.css']
})
export class MenuComprasComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }


  faqLink() {
    window.open('https://app.pipefy.com/public/form/RZG0i3pz','_blank')
  }

  goToUrl(url:string){
    window.open(url,'_blank')
  }

  navegar(){
    this.router.navigate([''])
  }

  faq(){
    window.open('https://app.pipefy.com/public/form/RZG0i3pz','_blank')
  }

  goHome(){
    this.router.navigate([''])
  }

}
