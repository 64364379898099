import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { solicitacoes } from './solicitacoes';
import { empresas } from './empresas';
import { cargos } from './cargos';
import { pipe_fields_id } from './pipe_fields_ids';
import Swal from 'sweetalert2';
import { PipefyService } from 'src/app/services/pipefy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-servicos-para-cartao-de-credito-corporativo',
  templateUrl: './servicos-para-cartao-de-credito-corporativo.component.html',
  styleUrls: ['./servicos-para-cartao-de-credito-corporativo.component.css']
})
export class ServicosParaCartaoDeCreditoCorporativoComponent implements OnInit {

  formulario!: FormGroup;
  solicitacoes = solicitacoes
  empresas = empresas
  cargos = cargos

  quais_dados_deseja_atualizar: string = ''


  centroDeCusto: boolean = false;
  nome: boolean = false;
  telefone: boolean = false;
  endereco: boolean = false;

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  novoValorDoLimite: any;

  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      solicitacao: [, [Validators.required]],
      voce_esta_solicitando: ['', []],
      motivo_da_solicitacao: ['', []],
      empresa: ['', []],
      cpf: ['', []],
      rg: ['', []],
      matricula: ['', []],
      nome_completo: ['', [Validators.required]],
      e_mail: ['', []],
      e_mail_gestor:['',[]],
      quatro_primeiros_numeros_do_cartao: ['', []],
      quatro_ultimos_numeros_do_cartao: ['', []],
      quais_dados_deseja_atualizar: ['', []],
      centro_de_custo: ['', []],
      nome_a_ser_alterado: ['', []],
      data_de_nascimento: ['', []],
      nome_completo_da_mae: ['', []],
      nome_completo_do_pai: ['', []],
      sexo: ['', []],
      //ddi_do_celular: ['', []],
      ddd: ['', []],
      numero_do_celular: ['', []],
      //primeiro_nome_do_portador: ['', []],
      //ultimo_nome_do_portador: ['', []],
      cargo_2: ['', []],
      endereco_completo: ['', []],
      numero: ['', []],
      complemento: ['', []],
      bairro: ['', []],
      cidade: ['', []],
      uf: ['', []],
      cep: ['', []],
      pais: ['', []],
      limite_do_cartao: ['', []],
      novo_valor_do_limite: ['', []],
      pais_de_destino: ['', []],
      data_de_inicio_da_viagem: ['', []],
      data_de_retorno_da_viagem: ['', []],
      primeiros_4_digitos_do_cartao: ['', []],
      ultimos_4_digitos_do_cartao: ['', []],
      data_de_desligamento_do_funcionario: ['', []],
      observacao: ['', []],
      aceite_do_termo: ['', []],
      qual_o_cancelamento_ser_necess_rio:['',[]],
      gestor_demandante: ['', []]
    })


    this.emailApi = this.userInfo.getUserEmail()

    // this.formulario.get('email')?.setValue(this.emailApi)


    // if (this.formulario.getRawValue().email != '') {
    //   this.formulario.controls['email'].disable()
    // }
    // if (this.formulario.getRawValue().email == undefined) {
    //   this.formulario.controls['email'].enable()
    // }
  }

  prepEnviarFormulario() {
    this.formulario.get('aceite_do_termo')?.setValue('');
    if (this.formulario.getRawValue().solicitacao=='Novo cartão corporativo / 2ª Via' && this.formulario.getRawValue().aceite_do_termo == '') {
      Swal.fire({
        title: 'Aceite do Termo',
        html: 
        '<div>' +
          '<p>DECLARO ter solicitado a minha empregadora, o CARTÃO DE CRÉDITO EMPRESARIAL BANCO ITAÚ.</p>' +
          '<p>Assumo integral responsabilidade civil e criminal pela utilização do referido CARTÃO, especialmente na hipótese de sua utilização para fins diversos e/ou particulares.</p>' +
          '<p>Declaro que fui cientificado:</p>' +
          '<p>1) Do dever de guarda e conservação do CARTÃO recebido nesta data;</p>' +
          '<p>2) Da vedação de sua utilização para fins particulares, sendo que nesta hipótese assumirei a responsabilidade pelo ressarcimento da importância, mediante desconto em folha, a qual desde já autorizo;</p>' +
          '<p>3) De que é expressamente proibida sua transferência, cessão ou empréstimo a terceiros;</p>' +
          '<p>4) Do dever de comunicação imediata e direta com o BANCO ITAÚ em caso de roubo, furto, extravio, perda, destruição, danificação, a fim de que seja providenciado seu bloqueio, o telefone consta no verso do cartão.</p>' +
          '<p>5) De que é de minha responsabilidade o controle das transações realizadas, bem como de solicitar a cada transação o competente comprovante compreendendo nota fiscal de venda ao consumidor ou cupom fiscal do estabelecimento emitidos a pessoa física  para prestação de contas mensal.</p>' +
          '<p>6) Havendo divergência entre o valor informado e os comprovantes de pagamento entregues, me declaro ciente de que devo restituir o valor a empresa.</p>' +
          '<p>7) De que é expressamente proibido o uso do cartão nas funções de saque ou débito;</p>' +
          '<p>8) Estar ciente de que na hipótese de rescisão do contrato de trabalho não poderei mais usar o cartão e o mesmo será cancelado.</p>' +
          '<p>9) Declaro ter lido a Política de Cartão de Crédito Corporativo e estar ciente do conteúdo.</p>' +
          '<p>Link política: <a href="https://conectanutrien.com.br/gallery/16/sub_gallery/28" target="_blank" rel="noopener noreferrer">Clique aqui ou acesse a página do Conecta > Biblioteca > Políticas</a> para ler o documento a política na íntegra</p>' +
          '<p>Por fim, declaro estar ciente de meus direitos, obrigações e responsabilidades quanto à correta utilização do CARTÃO DE CRÉDITO EMPRESARIAL.</p>' +
          '<p class="text-center">Aceita o Termo?</p>' +
        '</div>',
        confirmButtonText: 'Sim, eu concordo',
        confirmButtonColor: '#88D600',
        showDenyButton: true,
        denyButtonText: 'Não, eu li o termo mas não concordo',
        denyButtonColor: '#ff6961',
        allowEscapeKey: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        customClass: 'modalAceiteTermo'
      })
      .then((result) => {
        result.isConfirmed ? this.formulario.get('aceite_do_termo')?.setValue('Sim') : this.formulario.get('aceite_do_termo')?.setValue('Não');
        this.enviarFormulario();
      })
    } else {
      // Aceite do termo não é exibido, portanto segue sem o modal
      this.enviarFormulario();
    }
  }
  pasteValorDecimal(event: any) {
    event.preventDefault();
    let clipboardData = event.clipboardData;
    this.novoValorDoLimite = clipboardData.getData('text').replace(/[^0-9.,]/g, '').replace(/,/g, '.');;
    this.novoValorDoLimite = (Number.isInteger(parseFloat(this.novoValorDoLimite)))? parseFloat(this.novoValorDoLimite) + ".00" : this.novoValorDoLimite;
    this.formataValorDecimal(this.novoValorDoLimite)
  }

  focusValorDecimal(event: any) {
    event.target.select()
  }

  formataValorDecimal( input: any ) {
    if( input == '0,0' || input == '0.0' ) {
      input = "";
    } else if ( input == "0," || input == "0.") {
      input = "";
    }
    input = input.replace(/[^0-9]/g, ''); // remove all the characters except the numeric values
    if( input.replace( /[^0-9]/g, '' ).length == 1 ) input = "0.0" + input;
    else if( input.replace( /[^0-9]/g, '' ).length == 2 ) input = "0." + input;
    else input = input.substring( 0, input.length - 2 ) + '.' + input.substring( input.length - 2, input.length );
    input = new Number( input ); // set the precision
    input = input.toFixed( 2 );    // only works with the "."
    input = input.replace( /\./g, ',' ); // change the splitter to ","
    let x = input.split( ',' ); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while( rgx.test( valor ) ) {
      valor = valor.replace( rgx, '$1' + '.' + '$2' );
    }
    valor = valor == "NaN"? "" : valor;
    this.novoValorDoLimite = valor + decimal;
    this.formulario.get('novo_valor_do_limite')?.setValue(this.novoValorDoLimite);
  }

  enviarFormulario() {

    this.spinner.show()

    let solicitacao = this.trataInput.removeCaracters(this.formulario.getRawValue().solicitacao)
    let voce_esta_solicitando = this.trataInput.removeCaracters(this.formulario.getRawValue().voce_esta_solicitando)
    let motivo_da_solicitacao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_solicitacao)
    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    let cpf = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf)
    let rg = this.trataInput.removeCaracters(this.formulario.getRawValue().rg)
    let matricula = this.trataInput.removeCaracters(this.formulario.getRawValue().matricula)
    let nome_completo = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_completo)
    let e_mail = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail) + '@nutrien.com';
    let e_mail_gestor = this.formulario.getRawValue().e_mail_gestor !==""?this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_gestor) + '@nutrien.com' :'';
    let quatro_primeiros_numeros_do_cartao = this.trataInput.removeCaracters(this.formulario.getRawValue().quatro_primeiros_numeros_do_cartao)
    let quatro_ultimos_numeros_do_cartao = this.trataInput.removeCaracters(this.formulario.getRawValue().quatro_ultimos_numeros_do_cartao)
    let quais_dados_deseja_atualizar = this.trataInput.removeCaracters(this.formulario.getRawValue().quais_dados_deseja_atualizar)
    let centro_de_custo = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_custo)
    let nome_a_ser_alterado = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_a_ser_alterado)
    let data_de_nascimento = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_nascimento)
    let nome_completo_da_mae = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_completo_da_mae)
    let nome_completo_do_pai = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_completo_do_pai)
    let sexo = this.trataInput.removeCaracters(this.formulario.getRawValue().sexo)
    //let ddi_do_celular = this.trataInput.removeCaracters(this.formulario.getRawValue().ddi_do_celular)
    // let ddd = this.trataInput.removeCaracters(this.formulario.getRawValue().ddd)
    let numero_do_celular = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_celular)
    //let primeiro_nome_do_portador = this.trataInput.removeCaracters(this.formulario.getRawValue().primeiro_nome_do_portador)
    //let ultimo_nome_do_portador = this.trataInput.removeCaracters(this.formulario.getRawValue().ultimo_nome_do_portador)
    let cargo_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().cargo_2)
    let endereco_completo = this.trataInput.removeCaracters(this.formulario.getRawValue().endereco_completo)
    let numero = this.trataInput.removeCaracters(this.formulario.getRawValue().numero)
    let complemento = this.trataInput.removeCaracters(this.formulario.getRawValue().complemento)
    let bairro = this.trataInput.removeCaracters(this.formulario.getRawValue().bairro)
    let cidade = this.trataInput.removeCaracters(this.formulario.getRawValue().cidade)
    let uf = this.trataInput.removeCaracters(this.formulario.getRawValue().uf)
    let cep = this.trataInput.removeCaracters(this.formulario.getRawValue().cep)
    let pais = this.trataInput.removeCaracters(this.formulario.getRawValue().pais)
    let limite_do_cartao = this.trataInput.removeCaracters(this.formulario.getRawValue().limite_do_cartao)
    let novo_valor_do_limite = this.formulario.get('novo_valor_do_limite')?.value;
    let pais_de_destino = this.trataInput.removeCaracters(this.formulario.getRawValue().pais_de_destino)
    let data_de_inicio_da_viagem = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_inicio_da_viagem))
    let data_de_retorno_da_viagem = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_retorno_da_viagem))
    let primeiros_4_digitos_do_cartao = this.trataInput.removeCaracters(this.formulario.getRawValue().primeiros_4_digitos_do_cartao)
    let ultimos_4_digitos_do_cartao = this.trataInput.removeCaracters(this.formulario.getRawValue().ultimos_4_digitos_do_cartao)
    let data_de_desligamento_do_funcionario = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_desligamento_do_funcionario)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
    let aceiteTermo = this.trataInput.removeCaracters(this.formulario.getRawValue().aceite_do_termo)
    let qual_o_cancelamento_ser_necess_rio = this.formulario.getRawValue().qual_o_cancelamento_ser_necess_rio
    let gestor_demandante = this.trataInput.removeCaracters(this.formulario.getRawValue().gestor_demandante);




    let pipe_fields = pipe_fields_id
    let fields_values = [solicitacao,voce_esta_solicitando,motivo_da_solicitacao,empresa,cpf,
      rg,matricula,nome_completo,e_mail,e_mail_gestor,quatro_primeiros_numeros_do_cartao,quatro_ultimos_numeros_do_cartao,this.quais_dados_deseja_atualizar,centro_de_custo,nome_a_ser_alterado,
      data_de_nascimento,nome_completo_da_mae,nome_completo_do_pai,sexo,numero_do_celular,cargo_2,
      endereco_completo,numero,complemento,bairro,cidade,uf,cep,pais,limite_do_cartao,novo_valor_do_limite,pais_de_destino,data_de_inicio_da_viagem,
      data_de_retorno_da_viagem,primeiros_4_digitos_do_cartao,ultimos_4_digitos_do_cartao,data_de_desligamento_do_funcionario,observacao,aceiteTermo,qual_o_cancelamento_ser_necess_rio, gestor_demandante]
    let pipe_id = "302443169"

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        if (aceiteTermo == 'Sim' || this.formulario.getRawValue().solicitacao !='Novo cartão corporativo / 2ª Via') {
          Swal.fire({
            title: 'Chamado aberto!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
        } else {
          Swal.fire({
            title: 'Chamado aberto!',
            text: 'O chamado ' + card_id + ' não será atendido devido ao não aceite do termo do cartão.',
            icon: 'info',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
        }
        this.resetform()
        this.formulario.get('solicitacao')?.setValue('');
        this.formulario.get('solicitacao')?.clearValidators()
        this.formulario.get('solicitacao')?.updateValueAndValidity();
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/UmC6I9yE" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>'+ erro,
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Cartão de Crédito Corporativo", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/UmC6I9yE" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
      }
    })
  }

  preencheEmailLogado() {
    if (this.emailApi && this.emailApi.indexOf('@nutrien.com') !== -1) {
      let name = this.emailApi.substring(0, this.emailApi.lastIndexOf("@"));
      this.formulario.controls['e_mail']?.setValue(name);
    }
  }

  focusEmail(event: any) {
    event.target.select();
  }

  pasteEmail(value: any, from: any) {
    value.preventDefault();
    this.validateEmail(value.clipboardData.getData('text'), from);
  }

  validateEmail(value: any, from: any) {
    value = value.replace(/([^a-zA-Z0-9-Z\.\_\-\b]+)/g, "");
    from == 'solicitante' ? this.formulario.controls['e_mail']?.setValue(value) : this.formulario.controls['e_mail_gestor']?.setValue(value);
  }

  //os campos abaixo atualizam os campos obrigatórios da opção 'Quais dados deseja atualizar'
  // na Opção ALTERAÇÃO E/OU ATUALIZAÇÃO DE DADOS CADASTRAIS
  atualizaCentroDeCustoValue(event: any) {
    this.quais_dados_deseja_atualizar = "Centro de custo"
    let valor = event.target
    if (valor.checked) {
      this.centroDeCusto = true
      this.formulario.get('centro_de_custo')?.setValidators([Validators.required])
      this.formulario.get('centro_de_custo')?.updateValueAndValidity();
    } else {
      this.centroDeCusto = false
      this.formulario.get('centro_de_custo')?.clearValidators()
      this.formulario.get('centro_de_custo')?.updateValueAndValidity();
    }
  }

  atualizaNomeValue(event: any) {
    this.quais_dados_deseja_atualizar = "Nome"
    let valor = event.target
    if (valor.checked) {
      this.nome = true
      this.formulario.get('nome_a_ser_alterado')?.setValidators([Validators.required])
      this.formulario.get('nome_a_ser_alterado')?.updateValueAndValidity();
    } else {
      this.nome = false
      this.formulario.get('nome_a_ser_alterado')?.clearValidators()
      this.formulario.get('nome_a_ser_alterado')?.updateValueAndValidity();
    }
  }

  atualizaTelefoneValue(event: any) {
    this.quais_dados_deseja_atualizar = "Telefone"
    let valor = event.target
    if (valor.checked) {
      this.telefone = true
      this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
      this.formulario.get('numero_do_celular')?.updateValueAndValidity();
    } else {
      this.telefone = false
      this.formulario.get('numero_do_celular')?.clearValidators()
      this.formulario.get('numero_do_celular')?.updateValueAndValidity();
    }
  }

  atualizaEnderecoValue(event: any) {
    this.quais_dados_deseja_atualizar = "Endereço"
    let valor = event.target
    if (valor.checked) {
      this.endereco = true
      this.formulario.get('endereco_completo')?.setValidators([Validators.required])
      this.formulario.get('endereco_completo')?.updateValueAndValidity();

      this.formulario.get('numero')?.setValidators([Validators.required])
      this.formulario.get('numero')?.updateValueAndValidity();

      this.formulario.get('bairro')?.setValidators([Validators.required])
      this.formulario.get('bairro')?.updateValueAndValidity();

      this.formulario.get('cidade')?.setValidators([Validators.required])
      this.formulario.get('cidade')?.updateValueAndValidity();

      this.formulario.get('uf')?.setValidators([Validators.required])
      this.formulario.get('uf')?.updateValueAndValidity();

      this.formulario.get('cep')?.setValidators([Validators.required])
      this.formulario.get('cep')?.updateValueAndValidity();

      this.formulario.get('pais')?.setValidators([Validators.required])
      this.formulario.get('pais')?.updateValueAndValidity();
    } else {
      this.endereco = false
      this.formulario.get('endereco_completo')?.clearValidators()
      this.formulario.get('endereco_completo')?.updateValueAndValidity();

      this.formulario.get('numero')?.clearValidators()
      this.formulario.get('numero')?.updateValueAndValidity();

      this.formulario.get('bairro')?.clearValidators()
      this.formulario.get('bairro')?.updateValueAndValidity();

      this.formulario.get('cidade')?.clearValidators()
      this.formulario.get('cidade')?.updateValueAndValidity();

      this.formulario.get('uf')?.clearValidators()
      this.formulario.get('uf')?.updateValueAndValidity();

      this.formulario.get('cep')?.clearValidators()
      this.formulario.get('cep')?.updateValueAndValidity();

      this.formulario.get('pais')?.clearValidators()
      this.formulario.get('pais')?.updateValueAndValidity();
    }
  }

  onVoceEstaSolicitandoChange(event: any) {
    let valor = event.target.value
    // console.log('valor',valor)


    if (valor == '1ª via de cartão') {
      this.formulario.get('motivo_da_solicitacao')?.clearValidators()
      this.formulario.get('motivo_da_solicitacao')?.updateValueAndValidity();
      this.formulario.get('numero_de_protocolo_de_cancelamento')?.clearValidators()
      this.formulario.get('numero_de_protocolo_de_cancelamento')?.updateValueAndValidity();
    }
    if (valor == '2ª ou mais vias de cartão') {
      this.formulario.get('motivo_da_solicitacao')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_solicitacao')?.updateValueAndValidity();
    }

    if(valor  == ''){
      this.formulario.get('motivo_da_solicitacao')?.clearValidators()
      this.formulario.get('motivo_da_solicitacao')?.updateValueAndValidity();
      this.formulario.get('numero_de_protocolo_de_cancelamento')?.setValidators([])
      this.formulario.get('numero_de_protocolo_de_cancelamento')?.updateValueAndValidity();
    }

  }

  //FIM DA ATUALIZAÇÃO DE CAMPOS EM DADOS CADASTRAIS

  // A FUNÇÃO ABAIXO ATUALIZA OS CAMPOS OBRIGATÓRIOS DE FORMA GERAL

  onSolicitacaoChange(event: any) {
    this.resetValidators()
    this.resetform()
    let solicitacao = event.target.value
    // console.log(solicitacao)
    let voce_esta_solicitando = this.formulario.get('voce_esta_solicitando')?.value
    let dados_que_deseja_atualizar = this.formulario.get('quais_dados_deseja_atualizar')?.value

    this.preencheEmailLogado();

    this.formulario.get('gestor_demandante')?.clearValidators;
    this.formulario.get('gestor_demandante')?.setValue('');
    this.formulario.get('gestor_demandante')?.updateValueAndValidity();

    if (solicitacao === 'Novo cartão corporativo / 2ª Via') {
      this.formulario.get('novo_valor_do_limite')?.setValue('');
      this.formulario.get('novo_valor_do_limite')?.clearValidators();
      this.formulario.get('novo_valor_do_limite')?.updateValueAndValidity();     
      
      this.formulario.get('empresa')?.setValidators([Validators.required])
      this.formulario.get('empresa')?.updateValueAndValidity();

      this.formulario.get('cpf')?.setValidators([Validators.required])
      this.formulario.get('cpf')?.updateValueAndValidity();

      this.formulario.get('rg')?.setValidators([Validators.required])
      this.formulario.get('rg')?.updateValueAndValidity();

      this.formulario.get('matricula')?.setValidators([Validators.required])
      this.formulario.get('matricula')?.updateValueAndValidity();

      this.formulario.get('nome_completo')?.setValidators([Validators.required])
      this.formulario.get('nome_completo')?.updateValueAndValidity();

      this.formulario.get('e_mail')?.setValidators([Validators.required])
      this.formulario.get('e_mail')?.updateValueAndValidity();

      this.formulario.get('e_mail_gestor')?.setValidators([Validators.required])
      this.formulario.get('e_mail_gestor')?.updateValueAndValidity();

      this.formulario.get('centro_de_custo')?.setValidators([Validators.required])
      this.formulario.get('centro_de_custo')?.updateValueAndValidity();

      this.formulario.get('data_de_nascimento')?.setValidators([Validators.required])
      this.formulario.get('data_de_nascimento')?.updateValueAndValidity();

      this.formulario.get('nome_completo_da_mae')?.setValidators([Validators.required])
      this.formulario.get('nome_completo_da_mae')?.updateValueAndValidity();

      this.formulario.get('sexo')?.setValidators([Validators.required])
      this.formulario.get('sexo')?.updateValueAndValidity();

      // this.formulario.get('ddi_do_celular')?.setValidators([Validators.required])
      // this.formulario.get('ddi_do_celular')?.updateValueAndValidity();

      // this.formulario.get('ddd')?.setValidators([Validators.required])
      // this.formulario.get('ddd')?.updateValueAndValidity();

      this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
      this.formulario.get('numero_do_celular')?.updateValueAndValidity();

      // this.formulario.get('primeiro_nome_do_portador')?.setValidators([Validators.required])
      // this.formulario.get('primeiro_nome_do_portador')?.updateValueAndValidity();

      // this.formulario.get('ultimo_nome_do_portador')?.setValidators([Validators.required])
      // this.formulario.get('ultimo_nome_do_portador')?.updateValueAndValidity();

      this.formulario.get('cargo_2')?.setValidators([Validators.required])
      this.formulario.get('cargo_2')?.updateValueAndValidity();

      this.formulario.get('endereco_completo')?.setValidators([Validators.required])
      this.formulario.get('endereco_completo')?.updateValueAndValidity();

      this.formulario.get('numero')?.setValidators([Validators.required])
      this.formulario.get('numero')?.updateValueAndValidity();

      this.formulario.get('bairro')?.setValidators([Validators.required])
      this.formulario.get('bairro')?.updateValueAndValidity();

      this.formulario.get('cidade')?.setValidators([Validators.required])
      this.formulario.get('cidade')?.updateValueAndValidity();

      this.formulario.get('uf')?.setValidators([Validators.required])
      this.formulario.get('uf')?.updateValueAndValidity();

      this.formulario.get('cep')?.setValidators([Validators.required])
      this.formulario.get('cep')?.updateValueAndValidity();

      this.formulario.get('pais')?.setValidators([Validators.required])
      this.formulario.get('pais')?.updateValueAndValidity();
    }

    if (solicitacao === 'Alteração e/ou atualização de dados cadastrais') {
      // console.log("entrei aqui")

      this.formulario.get('quais_dados_deseja_atualizar')?.setValidators([Validators.required])
      this.formulario.get('quais_dados_deseja_atualizar')?.updateValueAndValidity();
    }

    if (solicitacao === 'Alteração de limite') {

      // this.formulario.get('ddi_do_celular')?.setValidators([Validators.required])
      // this.formulario.get('ddi_do_celular')?.updateValueAndValidity();
      
      // this.formulario.get('ddd')?.setValidators([Validators.required])
      // this.formulario.get('ddd')?.updateValueAndValidity();
      
      this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
      this.formulario.get('numero_do_celular')?.updateValueAndValidity();
    }

    if (solicitacao === 'Alteração de limite' || solicitacao === 'Alteração e/ou atualização de dados cadastrais' || solicitacao === 'Liberação para gastos em viagens Internacionais') {
      // console.log("entrei aqui 2")

      this.formulario.get('empresa')?.setValidators([Validators.required])
      this.formulario.get('empresa')?.updateValueAndValidity();

      this.formulario.get('cpf')?.setValidators([Validators.required])
      this.formulario.get('cpf')?.updateValueAndValidity();

      this.formulario.get('matricula')?.setValidators([Validators.required])
      this.formulario.get('matricula')?.updateValueAndValidity();

      this.formulario.get('nome_completo')?.setValidators([Validators.required])
      this.formulario.get('nome_completo')?.updateValueAndValidity();

      this.formulario.get('e_mail')?.setValidators([Validators.required])
      this.formulario.get('e_mail')?.updateValueAndValidity();

      // this.formulario.get('e_mail_gestor')?.setValidators([Validators.required])
      // this.formulario.get('e_mail_gestor')?.updateValueAndValidity();

      this.formulario.get('quatro_primeiros_numeros_do_cartao')?.setValidators([Validators.required])
      this.formulario.get('quatro_primeiros_numeros_do_cartao')?.updateValueAndValidity();

      this.formulario.get('quatro_ultimos_numeros_do_cartao')?.setValidators([Validators.required])
      this.formulario.get('quatro_ultimos_numeros_do_cartao')?.updateValueAndValidity();

    }

    if (solicitacao === 'Liberação para gastos em viagens Internacionais') {
      this.formulario.get('e_mail')?.setValidators([Validators.required])
      this.formulario.get('e_mail')?.updateValueAndValidity();

      this.formulario.get('e_mail_gestor')?.setValidators([Validators.required])
      this.formulario.get('e_mail_gestor')?.updateValueAndValidity();

      // this.formulario.get('ddi_do_celular')?.setValidators([Validators.required])
      // this.formulario.get('ddi_do_celular')?.updateValueAndValidity();
      
      // this.formulario.get('ddd')?.setValidators([Validators.required])
      // this.formulario.get('ddd')?.updateValueAndValidity();
      
      this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
      this.formulario.get('numero_do_celular')?.updateValueAndValidity();

      this.formulario.get('pais_de_destino')?.setValidators([Validators.required])
      this.formulario.get('pais_de_destino')?.updateValueAndValidity();

      this.formulario.get('data_de_inicio_da_viagem')?.setValidators([Validators.required])
      this.formulario.get('data_de_inicio_da_viagem')?.updateValueAndValidity();

      this.formulario.get('data_de_retorno_da_viagem')?.setValidators([Validators.required])
      this.formulario.get('data_de_retorno_da_viagem')?.updateValueAndValidity();
    }

   if (solicitacao === 'Solicitação de Senha do Cartão') {
    this.formulario.get('empresa')?.setValidators([Validators.required])
    this.formulario.get('empresa')?.updateValueAndValidity();

    this.formulario.get('cpf')?.setValidators([Validators.required])
    this.formulario.get('cpf')?.updateValueAndValidity();

    this.formulario.get('rg')?.setValidators([Validators.required])
    this.formulario.get('rg')?.updateValueAndValidity();

    this.formulario.get('matricula')?.setValidators([Validators.required])
    this.formulario.get('matricula')?.updateValueAndValidity();

    this.formulario.get('nome_completo')?.setValidators([Validators.required])
    this.formulario.get('nome_completo')?.updateValueAndValidity();

    this.formulario.get('e_mail')?.setValidators([Validators.required])
    this.formulario.get('e_mail')?.updateValueAndValidity();

    this.formulario.get('e_mail_gestor')?.setValidators([Validators.required])
    this.formulario.get('e_mail_gestor')?.updateValueAndValidity();

    this.formulario.get('centro_de_custo')?.setValidators([Validators.required])
    this.formulario.get('centro_de_custo')?.updateValueAndValidity();

    this.formulario.get('data_de_nascimento')?.setValidators([Validators.required])
    this.formulario.get('data_de_nascimento')?.updateValueAndValidity();

    this.formulario.get('nome_completo_da_mae')?.setValidators([Validators.required])
    this.formulario.get('nome_completo_da_mae')?.updateValueAndValidity();

    this.formulario.get('sexo')?.setValidators([Validators.required])
    this.formulario.get('sexo')?.updateValueAndValidity();

    // this.formulario.get('ddi_do_celular')?.setValidators([Validators.required])
    // this.formulario.get('ddi_do_celular')?.updateValueAndValidity();

    // this.formulario.get('ddd')?.setValidators([Validators.required])
    // this.formulario.get('ddd')?.updateValueAndValidity();

    this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
    this.formulario.get('numero_do_celular')?.updateValueAndValidity();

    // this.formulario.get('primeiro_nome_do_portador')?.setValidators([Validators.required])
    // this.formulario.get('primeiro_nome_do_portador')?.updateValueAndValidity();

    // this.formulario.get('ultimo_nome_do_portador')?.setValidators([Validators.required])
    // this.formulario.get('ultimo_nome_do_portador')?.updateValueAndValidity();

    this.formulario.get('cargo_2')?.setValidators([Validators.required])
    this.formulario.get('cargo_2')?.updateValueAndValidity();

    this.formulario.get('endereco_completo')?.setValidators([Validators.required])
    this.formulario.get('endereco_completo')?.updateValueAndValidity();

    this.formulario.get('numero')?.setValidators([Validators.required])
    this.formulario.get('numero')?.updateValueAndValidity();

    this.formulario.get('bairro')?.setValidators([Validators.required])
    this.formulario.get('bairro')?.updateValueAndValidity();

    this.formulario.get('cidade')?.setValidators([Validators.required])
    this.formulario.get('cidade')?.updateValueAndValidity();

    this.formulario.get('uf')?.setValidators([Validators.required])
    this.formulario.get('uf')?.updateValueAndValidity();

    this.formulario.get('cep')?.setValidators([Validators.required])
    this.formulario.get('cep')?.updateValueAndValidity();

    this.formulario.get('pais')?.setValidators([Validators.required])
    this.formulario.get('pais')?.updateValueAndValidity();

   }

   if (solicitacao === 'Desbloqueio por regularização') {

this.formulario.get('empresa')?.setValidators([Validators.required])
  this.formulario.get('empresa')?.updateValueAndValidity();

  this.formulario.get('cpf')?.setValidators([Validators.required])
  this.formulario.get('cpf')?.updateValueAndValidity();

  this.formulario.get('matricula')?.setValidators([Validators.required])
  this.formulario.get('matricula')?.updateValueAndValidity();

  this.formulario.get('nome_completo')?.setValidators([Validators.required])
  this.formulario.get('nome_completo')?.updateValueAndValidity();

  this.formulario.get('e_mail')?.setValidators([Validators.required])
  this.formulario.get('e_mail')?.updateValueAndValidity();

  this.formulario.get('quatro_primeiros_numeros_do_cartao')?.setValidators([Validators.required])
  this.formulario.get('quatro_primeiros_numeros_do_cartao')?.updateValueAndValidity();

  this.formulario.get('quatro_ultimos_numeros_do_cartao')?.setValidators([Validators.required])
  this.formulario.get('quatro_ultimos_numeros_do_cartao')?.updateValueAndValidity();


   }

   if (solicitacao === 'Cancelamento') {
    this.formulario.get('empresa')?.setValidators([Validators.required])
    this.formulario.get('empresa')?.updateValueAndValidity();

    this.formulario.get('matricula')?.setValidators([Validators.required])
    this.formulario.get('matricula')?.updateValueAndValidity();

    this.formulario.get('nome_completo')?.setValidators([Validators.required])
    this.formulario.get('nome_completo')?.updateValueAndValidity();

    this.formulario.get('e_mail')?.setValidators([Validators.required])
    this.formulario.get('e_mail')?.updateValueAndValidity();

    this.formulario.get('e_mail_gestor')?.setValidators([Validators.required])
    this.formulario.get('e_mail_gestor')?.updateValueAndValidity();

    // this.formulario.get('ddi_do_celular')?.setValidators([Validators.required])
    // this.formulario.get('ddi_do_celular')?.updateValueAndValidity();
    
    // this.formulario.get('ddd')?.setValidators([Validators.required])
    // this.formulario.get('ddd')?.updateValueAndValidity();
    
    this.formulario.get('numero_do_celular')?.setValidators([Validators.required])
    this.formulario.get('numero_do_celular')?.updateValueAndValidity();

    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.setValidators([Validators.required])
    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.updateValueAndValidity();

    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.setValidators([Validators.required])
    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.updateValueAndValidity();

    this.formulario.get('centro_de_custo')?.setValidators([Validators.required])
    this.formulario.get('centro_de_custo')?.updateValueAndValidity();

    this.formulario.get('gestor_demandante')?.setValidators(Validators.required);
    this.formulario.get('gestor_demandante')?.updateValueAndValidity();
   }
  }

  onMotivoDoCancelamentoChange(event: any){
    const valor = event.target.value
    if(valor == 'Desligamento'){
    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.clearValidators()
    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.updateValueAndValidity();

    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.clearValidators()
    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.updateValueAndValidity();

    }else{
    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.setValidators([Validators.required])
    this.formulario.get('quatro_primeiros_numeros_do_cartao')?.updateValueAndValidity();

    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.setValidators([Validators.required])
    this.formulario.get('quatro_ultimos_numeros_do_cartao')?.updateValueAndValidity();
    }
  }

  resetValidators(){
    Object.keys(this.formulario.controls).forEach((key) => {
      const control = this.formulario.controls[key];
      control.setValidators([])
      control.updateValueAndValidity()
      control.markAsPristine();
      control.markAsUntouched();
      if(key=='solicitacao'){
        control.setValidators([Validators.required])
        control.updateValueAndValidity()
      }
  });
  }

  resetform() {
    for (let field in this.formulario.controls) {
      if(field !='solicitacao'){
        this.formulario.controls[field].setValue('');
      }
    }
    this.formulario.markAsUntouched()
  }

  mostraValor(event: any) {
    const valor = event?.target.value
    console.log(valor)
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/UmC6I9yE', '_blank')
  }
}
