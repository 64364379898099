export const filiais_agrichem = [
  {
    nome: ''
  },
  {
    nome:'AGRICHEM DO BRASIL S.A'
  },
  {
    nome:'Filial_0004_03.860.998/0004-35_SP'
  },
  {
    nome:'Matriz_0001_03.860.998/0001-92_SP'
  }
]
