export const subcategorias = [
  {
    nome:''
  },
  {
    nome: 'Materiais'
  },
  {
    nome: 'Serviços'
  },
  {
    nome: 'Boleto'
  },
  {
    nome: 'Nota de Débito'
  },
  {
    nome: 'Telefonia'
  },
  {
    nome: 'Água'
  },
  {
    nome: 'Energia'
  },
  {
    nome: 'Fatura'
  },
  {
    nome: 'Frete'
  },
  {
    nome: 'Aluguel'
  },
  {
    nome: 'Correios'
  },
  {
    nome: 'Outros '
  },
  {
    nome: 'Ativo Fixo (AFE)'
  },
  {
    nome: 'Beneficios'
  },
  {
    nome: 'Escrituração - Não gera pagamento'
  },
  {
    nome: 'Importação'
  },
  {
    nome: 'Reembolso'
  },
  {
    nome: 'Sem valor fiscal'
  }
]
