export const tipo_de_operacao = [
  {
    nome:''
  },
  {
    nome: 'Compras/Aquisição'
  },
  {
    nome: 'Transferência'
  },
  {
    nome: 'Remessa'
  },
  {
    nome: 'Retorno'
  },
  {
    nome: 'Devolução - Cliente'
  },
  {
    nome: 'Faccionistas (Industrialização)'
  },
  {
    nome: 'Frete/CTE Atrelado às demais NF de Mateirias'
  },
]
