import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.interface';


@Component({
  selector: 'app-suporte-fiscal',
  templateUrl: './suporte-fiscal.component.html',
  styleUrls: ['./suporte-fiscal.component.css']
})
export class SuporteFiscalComponent implements OnInit {

  fields: FormField[] = [];


  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "label": "Nome do solicitante",
        "name": "requisitante",
        "type": "nome-solicitante",
        "validators": Validators.required
      },
      {
        "label": "E-mail do solicitante",
        "name": "e_mail",
        "type": "email-solicitante",
        "validators": Validators.required
      },
      {
        "label": "E-mail Nutrien - 2",
        "name": "e_mail_nutrien_2",
        "type": "email"
      },
      {
        "label": "E-mail Nutrien - 3",
        "name": "e_mail_nutrien_3",
        "type": "email"
      },
      {
        "label": "Empresa",
        "name": "empresa",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Agrichem",
            "value": "Agrichem",
            "fields": [
              {
                "label": "Filiais - Agrichem",
                "name": "filial_agrichem",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL S.A",
                    "value": "AGRICHEM DO BRASIL S.A"
                  },
                  {
                    "type": "option",
                    "name": "Filial_0004_03.860.998/0004-35_SP",
                    "value": "Filial_0004_03.860.998/0004-35_SP"
                  },
                  {
                    "type": "option",
                    "name": "Matriz_0001_03.860.998/0001-92_SP",
                    "value": "Matriz_0001_03.860.998/0001-92_SP"
                  }
                ],
                "type": "select"
              }
            ]
          },
          {
            "type": "option",
            "name": "Nutrien",
            "value": "Nutrien",
            "fields": [
              {
                "label": "Filiais - Nutrien",
                "name": "filial_nutrien",
                "select": [
                  {
                    "type": "option",
                    "name": "FILIAL_7001_88.305.859/0004-00_SP",
                    "value": "FILIAL_7001_88.305.859/0004-00_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7002_88.305.859/0015-55_MG",
                    "value": "FILIAL_7002_88.305.859/0015-55_MG"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7003_88.305.859/0020-12_GO",
                    "value": "FILIAL_7003_88.305.859/0020-12_GO"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7004_88.305.859/0021-01_GO",
                    "value": "FILIAL_7004_88.305.859/0021-01_GO"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7005_88.305.859/0014-74_SP",
                    "value": "FILIAL_7005_88.305.859/0014-74_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7006_88.305.859/0005-83_SP",
                    "value": "FILIAL_7006_88.305.859/0005-83_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7007_88.305.859/0018-06_SP",
                    "value": "FILIAL_7007_88.305.859/0018-06_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7008_88.305.859/0019-89_SP",
                    "value": "FILIAL_7008_88.305.859/0019-89_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7009_88.305.859/0026-08_SP",
                    "value": "FILIAL_7009_88.305.859/0026-08_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7010_88.305.859/0022-84_SP",
                    "value": "FILIAL_7010_88.305.859/0022-84_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7011_88.305.859/0056-23_SP",
                    "value": "FILIAL_7011_88.305.859/0056-23_SP"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Coromandel-0033-37-Mg",
                    "value": "Filial-Nutrien-Coromandel-0033-37-Mg"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7012_88.305.859/0041-47_SP",
                    "value": "FILIAL_7012_88.305.859/0041-47_SP"
                  },
                  {
                    "type": "option",
                    "name": "FILIAL_7013_88.305.859/0039-22_SP",
                    "value": "FILIAL_7013_88.305.859/0039-22_SP"
                  },
                  {"type": "option","name": "FILIAL_7014_88.305.859/0042-28_SP","value": "FILIAL_7014_88.305.859/0042-28_SP"},
                  {"type": "option","name": "FILIAL_7015_88.305.859/0025-27_MG","value": "FILIAL_7015_88.305.859/0025-27_MG"},
                  {"type": "option","name": "FILIAL_7016_88.305.859/0040-66_SP","value": "FILIAL_7016_88.305.859/0040-66_SP"},
                  {"type": "option","name": "FILIAL_7017_88.305.859/0051-19_SP","value": "FILIAL_7017_88.305.859/0051-19_SP"},
                  {"type": "option","name": "FILIAL_7018_88.305.859/0036-80_MG","value": "FILIAL_7018_88.305.859/0036-80_MG"},
                  {"type": "option","name": "FILIAL_7019_88.305.859/0052-08_SP","value": "FILIAL_7019_88.305.859/0052-08_SP"},
                  {"type": "option","name": "FILIAL_7020_88.305.859/0050-38_SP","value": "FILIAL_7020_88.305.859/0050-38_SP"},
                  {"type": "option","name": "FILIAL_7021_88.305.859/0062-71_SP","value": "FILIAL_7021_88.305.859/0062-71_SP"},
                  {"type": "option","name": "FILIAL_7022_88.305.859/0073-24_MS","value": "FILIAL_7022_88.305.859/0073-24_MS"},
                  {"type": "option","name": "FILIAL_7023_88.305.859/0074-05_MS","value": "FILIAL_7023_88.305.859/0074-05_MS"},
                  {"type": "option","name": "FILIAL_7024_88.305.859/0080-53_MS","value": "FILIAL_7024_88.305.859/0080-53_MS"},
                  {"type": "option","name": "FILIAL_7025_88.305.859/0072-43_MS","value": "FILIAL_7025_88.305.859/0072-43_MS"},
                  {"type": "option","name": "FILIAL_7026_88.305.859/0075-96_MS","value": "FILIAL_7026_88.305.859/0075-96_MS"},
                  {"type": "option","name": "FILIAL_7027_88.305.859/0079-10_MS","value": "FILIAL_7027_88.305.859/0079-10_MS"},
                  {"type": "option","name": "FILIAL_7028_88.305.859/0077-58_MS","value": "FILIAL_7028_88.305.859/0077-58_MS"},
                  {"type": "option","name": "FILIAL_7029_88.305.859/0081-34_MS","value": "FILIAL_7029_88.305.859/0081-34_MS"},
                  {"type": "option","name": "FILIAL_7030_88.305.859/0078-39_MS","value": "FILIAL_7030_88.305.859/0078-39_MS"},
                  {"type": "option","name": "FILIAL_7031_88.305.859/0076-77_MS","value": "FILIAL_7031_88.305.859/0076-77_MS"},
                  {"type": "option","name": "FILIAL_7032_88.305.859/0069-48_MG","value": "FILIAL_7032_88.305.859/0069-48_MG"},
                  {"type": "option","name": "FILIAL_7033_88.305.859/0070-81_MG","value": "FILIAL_7033_88.305.859/0070-81_MG"},
                  {"type": "option","name": "FILIAL_7034_88.305.859/0071-62_MG","value": "FILIAL_7034_88.305.859/0071-62_MG"},
                  {"type": "option","name": "FILIAL_7035_88.305.859/0067-86_MG","value": "FILIAL_7035_88.305.859/0067-86_MG"},
                  {"type": "option","name": "FILIAL_7036_88.305.859/0066-03_MG","value": "FILIAL_7036_88.305.859/0066-03_MG"},
                  {"type": "option","name": "FILIAL_7037_88.305.859/0065-14_MG","value": "FILIAL_7037_88.305.859/0065-14_MG"},
                  {"type": "option","name": "FILIAL_7039_88.305.859/0017-17_SP","value": "FILIAL_7039_88.305.859/0017-17_SP"},
                  {"type": "option","name": "FILIAL_7040_88.305.859/0027-99_MG","value": "FILIAL_7040_88.305.859/0027-99_MG"},
                  {"type": "option","name": "FILIAL_7041_88.305.859/0028-70_SP","value": "FILIAL_7041_88.305.859/0028-70_SP"},
                  {"type": "option","name": "FILIAL_7042_88.305.859/0037-60_MG","value": "FILIAL_7042_88.305.859/0037-60_MG"},
                  {"type": "option","name": "FILIAL_7043_88.305.859/0032-56_MG","value": "FILIAL_7043_88.305.859/0032-56_MG"},
                  {"type": "option","name": "FILIAL_7044_88.305.859/0054-61_MG","value": "FILIAL_7044_88.305.859/0054-61_MG"},
                  {"type": "option","name": "FILIAL_7045_88.305.859/0053-80_MG","value": "FILIAL_7045_88.305.859/0053-80_MG"},
                  {"type": "option","name": "FILIAL_7046_88.305.859/0033-37_MG","value": "FILIAL_7046_88.305.859/0033-37_MG"},
                  {"type": "option","name": "FILIAL_7047_88.305.859/0044-90_MG","value": "FILIAL_7047_88.305.859/0044-90_MG"},
                  {"type": "option","name": "FILIAL_7048_88.305.859/0029-50_SP","value": "FILIAL_7048_88.305.859/0029-50_SP"},
                  {"type": "option","name": "FILIAL_7049_88.305.859/0034-18_MG","value": "FILIAL_7049_88.305.859/0034-18_MG"},
                  {"type": "option","name": "FILIAL_7050_88.305.859/0045-70_MG","value": "FILIAL_7050_88.305.859/0045-70_MG"},
                  {"type": "option","name": "FILIAL_7051_88.305.859/0073-24_MS","value": "FILIAL_7051_88.305.859/0073-24_MS"},
                  {"type": "option","name": "FILIAL_7052_88.305.859/0082-15_MS","value": "FILIAL_7052_88.305.859/0082-15_MS"},
                  {"type": "option","name": "FILIAL_7078_88.305.859/0063-52_SP","value": "FILIAL_7078_88.305.859/0063-52_SP"},
                  {"type": "option","name": "FILIAL_7079_88.305.859/0031-75_MG","value": "FILIAL_7079_88.305.859/0031-75_MG"},
                  {"type": "option","name": "FILIAL_7080_88.305.859/0043-09_MG","value": "FILIAL_7080_88.305.859/0043-09_MG"},
                  {"type": "option","name": "FILIAL_7081_88.305.859/0035-07_MG","value": "FILIAL_7081_88.305.859/0035-07_MG"},
                  {"type": "option","name": "FILIAL_7082_88.305.859/0030-94_MG","value": "FILIAL_7082_88.305.859/0030-94_MG"},
                  {"type": "option","name": "FILIAL_7083_88.305.859/0038-41_SP","value": "FILIAL_7083_88.305.859/0038-41_SP"},
                  {"type": "option","name": "FILIAL_7084_88.305.859/0046-51_SP","value": "FILIAL_7084_88.305.859/0046-51_SP"},
                  {"type": "option","name": "FILIAL_7085_88.305.859/0047-32_SP","value": "FILIAL_7085_88.305.859/0047-32_SP"},
                  {"type": "option","name": "FILIAL_7086_88.305.859/0048-13_SP","value": "FILIAL_7086_88.305.859/0048-13_SP"},
                  {"type": "option","name": "FILIAL_7087_88.305.859/0049-02_SP","value": "FILIAL_7087_88.305.859/0049-02_SP"},
                  {"type": "option","name": "FILIAL_7088_88.305.859/0055-42_SP","value": "FILIAL_7088_88.305.859/0055-42_SP"},
                  {"type": "option","name": "FILIAL_7089_88.305.859/0024-46_SP","value": "FILIAL_7089_88.305.859/0024-46_SP"},
                  {"type": "option","name": "FILIAL_7090_88.305.859/0059-76_SP","value": "FILIAL_7090_88.305.859/0059-76_SP"},
                  {"type": "option","name": "FILIAL_7091_88.305.859/0057-04_SP","value": "FILIAL_7091_88.305.859/0057-04_SP"},
                  {"type": "option","name": "FILIAL_7092_88.305.859/0060-00_SP","value": "FILIAL_7092_88.305.859/0060-00_SP"},
                  {"type": "option","name": "FILIAL_7093_88.305.859/0061-90_GO","value": "FILIAL_7093_88.305.859/0061-90_GO"},
                  {"type": "option","name": "FILIAL_7094_88.305.859/0058-95_GO","value": "FILIAL_7094_88.305.859/0058-95_GO"},
                  {"type": "option","name": "FILIAL_7095_88.305.859/0064-33_SP","value": "FILIAL_7095_88.305.859/0064-33_SP"},
                  {"type": "option","name": "MATRIZ_7000_88.305.859/0001-50_SP","value": "MATRIZ_7000_88.305.859/0001-50_SP"},
                  {"type": "option","name": "NUTRIEN SOLUÇÕES AGRÍCOLAS","value": "NUTRIEN SOLUÇÕES AGRÍCOLAS"}
                ],
                "type": "select",
                "validators": Validators.required
              },
            ]
          },
          // {
          //   "type": "option",
          //   "name": "Tec Agro",
          //   "value": "Tec Agro",
          //   "fields": [
          //     {
          //       "label": "Filialis - Tec Agro",
          //       "name": "filial_tec_agro",
          //       "select": [
          //         {
          //           "type": "option",
          //           "name": "Apms Participações Societárias Ltda",
          //           "value": "Apms Participações Societárias Ltda"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Sementes Goiás - Tocantins-0004-90-To",
          //           "value": "Filial-Sementes Goiás - Tocantins-0004-90-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Sementes Goiás - Unidade 02-0003-00-Go",
          //           "value": "Filial-Sementes Goiás - Unidade 02-0003-00-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Sementes Goiás -0002-29-Go",
          //           "value": "Filial-Sementes Goiás -0002-29-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Sementes Goiás-0005-71-Go",
          //           "value": "Filial-Sementes Goiás-0005-71-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição) -0022-98-Go",
          //           "value": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição) -0022-98-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Araxá-0010-54-Mg",
          //           "value": "Filial-Tec agro - Araxá-0010-54-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Catalão-0006-78-Go",
          //           "value": "Filial-Tec agro - Catalão-0006-78-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Catalão-0034-21-Go",
          //           "value": "Filial-Tec agro - Catalão-0034-21-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go",
          //           "value": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Cristalina-0030-06-Go",
          //           "value": "Filial-Tec agro - Cristalina-0030-06-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Formosa-0012-16-Go",
          //           "value": "Filial-Tec agro - Formosa-0012-16-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Goiatuba-0029-64-Go",
          //           "value": "Filial-Tec agro - Goiatuba-0029-64-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Goiânia-0008-30-Go",
          //           "value": "Filial-Tec agro - Goiânia-0008-30-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Goiânia-0031-89-Go",
          //           "value": "Filial-Tec agro - Goiânia-0031-89-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Goiatuba-0007-59-Go",
          //           "value": "Filial-Tec agro - Goiânia-0031-89-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Guaraí-0020-26-To",
          //           "value": "Filial-Tec agro - Guaraí-0020-26-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Gurupí-0014-88-To",
          //           "value": "Filial-Tec agro - Gurupí-0014-88-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Itapetininga-0009-10-Sp",
          //           "value": "Filial-Tec agro - Itapetininga-0009-10-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Jataí-0005-97-Go",
          //           "value": "Filial-Tec agro - Jataí-0005-97-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Jataí-0024-50-Go",
          //           "value": "Filial-Tec agro - Jataí-0024-50-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Jussara-0021-07-Go",
          //           "value": "Filial-Tec agro - Jussara-0021-07-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To",
          //           "value": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Mineiros-0015-69-Go",
          //           "value": "Filial-Tec agro - Mineiros-0015-69-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Montividiu-0002-44-Go",
          //           "value": "Filial-Tec agro - Montividiu-0002-44-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Morrinhos-0026-11-Go",
          //           "value": "Filial-Tec agro - Morrinhos-0026-11-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Palmas (Centro De Distribuição) -0019-92-To",
          //           "value": "Filial-Tec agro - Palmas (Centro De Distribuição) -0019-92-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Palmas (Operador Logístico Verde log) -0018-01-To",
          //           "value": "Filial-Tec agro - Palmas (Operador Logístico Verde log) -0018-01-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To",
          //           "value": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Paraúna-0003-25-Go",
          //           "value": "Filial-Tec agro - Paraúna-0003-25-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Porto Nacional-0013-05-To",
          //           "value": "Filial-Tec agro - Porto Nacional-0013-05-To"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Rio Verde-0025-30-Go",
          //           "value": "Filial-Tec agro - Rio Verde-0025-30-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Rio Verde-0027-00-Go",
          //           "value": "Filial-Tec agro - Rio Verde-0027-00-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Rio Verde-0028-83-Go",
          //           "value": "Filial-Tec agro - Rio Verde-0028-83-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Shego-0004-06-Go",
          //           "value": "Filial-Tec agro - Shego-0004-06-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Silviania-0017-20-Go",
          //           "value": "Filial-Tec agro - Silviania-0017-20-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Uruaçú-0011-35-Go",
          //           "value": "Filial-Tec agro - Uruaçú-0011-35-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro - Vianópolis-0016-40-Go",
          //           "value": "Filial-Tec agro - Vianópolis-0016-40-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go",
          //           "value": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go",
          //           "value": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go",
          //           "value": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Fronteira Armazéns Gerais Ltda",
          //           "value": "Fronteira Armazéns Gerais Ltda"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Fronteira Comércio Ltda",
          //           "value": "Fronteira Comércio Ltda"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Matriz -Fronteira Armazéns Gerais-0001-30-Go",
          //           "value": "Matriz -Fronteira Armazéns Gerais-0001-30-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Matriz -Holding Apms-0001-27-Go",
          //           "value": "Matriz -Holding Apms-0001-27-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Matriz -Sementes Goiás-0001-48-Go",
          //           "value": "Matriz -Sementes Goiás-0001-48-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Matriz -Tec agro - Rio Verde-0001-63-Go",
          //           "value": "Matriz -Tec agro - Rio Verde-0001-63-Go"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Sementes Goiás Ltda",
          //           "value": "Sementes Goiás Ltda"
          //         }
          //       ],
          //       "type": "select",
          //       "validators": Validators.required
          //     }
          //   ]
          // },
          {
            "type": "option",
            "name": "Tec Agro-FRC",
            "value": "Tec Agro-FRC",
            "fields": [
              {
                "label": "Filialis - Tec Agro FRC",
                "name": "filiais_tec_agro_frc",
                "select": [
                  {"type": "option","name": "Filial_02_36.854.495/0002-44_GO","value": "Filial_02_36.854.495/0002-44_GO"},
                  {"type": "option","name": "Filial_04_36.854.495/0003-25_GO","value": "Filial_04_36.854.495/0003-25_GO"},
                  {"type": "option","name": "Filial_05_36.854.495/0004-06_GO","value": "Filial_05_36.854.495/0004-06_GO"},
                  {"type": "option","name": "Filial_06_36.854.495/0005-97_GO","value": "Filial_06_36.854.495/0005-97_GO"},
                  {"type": "option","name": "Filial_08_36.854.495/0006-78_GO","value": "Filial_08_36.854.495/0006-78_GO"},
                  {"type": "option","name": "Filial_07_36.854.495/0007-59_GO","value": "Filial_07_36.854.495/0007-59_GO"},
                  {"type": "option","name": "Filial_09_36.854.495/0008-30_GO","value": "Filial_09_36.854.495/0008-30_GO"},
                  {"type": "option","name": "Filial_13_36.854.495/0009-10_SP","value": "Filial_13_36.854.495/0009-10_SP"},
                  {"type": "option","name": "Filial_14_36.854.495/0010-54_MG","value": "Filial_14_36.854.495/0010-54_MG"},
                  {"type": "option","name": "Filial_18_36.854.495/0011-35_GO","value": "Filial_18_36.854.495/0011-35_GO"},
                  {"type": "option","name": "Filial_17_36.854.495/0012-16_GO","value": "Filial_17_36.854.495/0012-16_GO"},
                  {"type": "option","name": "Filial_16_36.854.495/0013-05_TO","value": "Filial_16_36.854.495/0013-05_TO"},
                  {"type": "option","name": "Filial_15_36.854.495/0014-88_TO","value": "Filial_15_36.854.495/0014-88_TO"},
                  {"type": "option","name": "Filial_19_36.854.495/0015-69_GO","value": "Filial_19_36.854.495/0015-69_GO"},
                  {"type": "option","name": "Filial_20_36.854.495/0016-40_GO","value": "Filial_20_36.854.495/0016-40_GO"},
                  {"type": "option","name": "Filial_22_36.854.495/0017-20_GO","value": "Filial_22_36.854.495/0017-20_GO"},
                  {"type": "option","name": "Filial_23_36.854.495/0018-01_TO","value": "Filial_23_36.854.495/0018-01_TO"},
                  {"type": "option","name": "Filial_24_36.854.495/0019-92_TO","value": "Filial_24_36.854.495/0019-92_TO"},
                  {"type": "option","name": "Filial_25_36.854.495/0020-26_TO","value": "Filial_25_36.854.495/0020-26_TO"},
                  {"type": "option","name": "Filial_26_36.854.495/0021-07_GO","value": "Filial_26_36.854.495/0021-07_GO"},
                  {"type": "option","name": "Filial_33_36.854.495/0022-98_GO","value": "Filial_33_36.854.495/0022-98_GO"},
                  {"type": "option","name": "Filial_27_36.854.495/0023-79_GO","value": "Filial_27_36.854.495/0023-79_GO"},
                  {"type": "option","name": "Filial_28_36.854.495/0024-50_GO","value": "Filial_28_36.854.495/0024-50_GO"},
                  {"type": "option","name": "Filial_32_36.854.495/0025-30_GO","value": "Filial_32_36.854.495/0025-30_GO"},
                  {"type": "option","name": "Filial_29_36.854.495/0026-11_GO","value": "Filial_29_36.854.495/0026-11_GO"},
                  {"type": "option","name": "Filial_41_36.854.495/0027-00_GO","value": "Filial_41_36.854.495/0027-00_GO"},
                  {"type": "option","name": "Filial_40_36.854.495/0028-83_GO","value": "Filial_40_36.854.495/0028-83_GO"},
                  {"type": "option","name": "Filial_37_36.854.495/0029-64_GO","value": "Filial_37_36.854.495/0029-64_GO"},
                  {"type": "option","name": "Filial_35_36.854.495/0030-06_GO","value": "Filial_35_36.854.495/0030-06_GO"},
                  {"type": "option","name": "Filial_38_36.854.495/0032-60_TO","value": "Filial_38_36.854.495/0032-60_TO"},
                  {"type": "option","name": "Filial_39_36.854.495/0033-40_TO","value": "Filial_39_36.854.495/0033-40_TO"},
                  {"type": "option","name": "Filial_34_36.854.495/0034-21_GO","value": "Filial_34_36.854.495/0034-21_GO"},
                  {"type": "option","name": "Filial_42_36.854.495/0035-02_GO","value": "Filial_42_36.854.495/0035-02_GO"},
                  {"type": "option","name": "Matriz_01_36.854.495/0001-63_GO","value": "Matriz_01_36.854.495/0001-63_GO"},
                  {"type": "option","name": "Tec Agro-FRC","value": "Tec Agro-FRC"}

                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Tec Agro-SGL",
            "value": "Tec Agro-SGL",
            "fields": [
              {
                "label": "Filialis - Tec Agro SGL",
                "name": "filiais_tec_agro_sgl",
                "select": [
                    {"type": "option","name": "Filial_0302_03.482.332/0002-29_GO","value": "Filial_0302_03.482.332/0002-29_GO"},
                    {"type": "option","name": "Filial_0303_03.482.332/0003-00_GO","value": "Filial_0303_03.482.332/0003-00_GO"},
                    {"type": "option","name": "Filial_0304_03.482.332/0004-90_TO","value": "Filial_0304_03.482.332/0004-90_TO"},
                    {"type": "option","name": "Filial_0305_03.482.332/0005-71_GO","value": "Filial_0305_03.482.332/0005-71_GO"},
                    {"type": "option","name": "Filial_0306_03.482.332/0006-52_MG","value": "Filial_0306_03.482.332/0006-52_MG"},
                    {"type": "option","name": "Filial_0307_03.482.332/0007-33_MS","value": "Filial_0307_03.482.332/0007-33_MS"},
                    {"type": "option","name": "Filial_0308_03.482.332/0008-14_PR","value": "Filial_0308_03.482.332/0008-14_PR"},
                    {"type": "option","name": "Matriz_0301_03.482.332/0001-48_GO","value": "Matriz_0301_03.482.332/0001-48_GO"},
                    {"type": "option","name": "Tec Agro-SGL","value": "Tec Agro-SGL"}
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ],
        "type": "select",

      },
      {
        "label": "Opções",
        "name": "op_es",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Ajuste GRC - CTE Agrichem (Exclusivo fiscal)",
            "value": "Ajuste GRC - CTE Agrichem (Exclusivo fiscal)"
          },
          {
            "type": "option",
            "name": "CFOP",
            "value": "CFOP"
          },
          {
            "type": "option",
            "name": "Desbloqueio de período",
            "value": "Desbloqueio de período"
          },
          {
            "type": "option",
            "name": "Dúvida de abertura de chamado no portal NBS fiscal",
            "value": "Dúvida de abertura de chamado no portal NBS fiscal"
          },
          {
            "type": "option",
            "name": "Dúvida sobre o que solicitar na emissão de carta de correção",
            "value": "Dúvida sobre o que solicitar na emissão de carta de correção"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre as retenções",
            "value": "Dúvidas sobre as retenções"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre entrada de nota fiscal",
            "value": "Dúvidas sobre entrada de nota fiscal"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre tipo de operações para emissão de notas fiscais de saída",
            "value": "Dúvidas sobre tipo de operações para emissão de notas fiscais de saída"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre tipo de operação (Específico para Agrosema campo TO)",
            "value": "Dúvidas sobre tipo de operação (Específico para Agrosema campo TO)"
          },
          {
            "type": "option",
            "name": "Estorno de lançamento de nota fiscal (Entrada e Saida)",
            "value": "Estorno de lançamento de nota fiscal (Entrada e Saida)"
          },
          {
            "type": "option",
            "name": "Orientação relativa a emissão de notas fiscais de operações especificas Exemplos (Sucata, venda de ativo imobilizado, nota fiscal de remessa)",
            "value": "Orientação relativa a emissão de notas fiscais de operações especificas Exemplos (Sucata, venda de ativo imobilizado, nota fiscal de remessa)"
          },
          {
            "type": "option",
            "name": "Sequencial de nota fiscal",
            "value": "Sequencial de nota fiscal"
          },
          {
            "type": "option",
            "name": "Solicitação de relatórios fiscais exclusivo contabilidade NBS",
            "value": "Solicitação de relatórios fiscais exclusivo contabilidade NBS"
          },
          {
            "type": "option",
            "name": "Suporte para lançamentos de nota fiscais de entrada",
            "value": "Suporte para lançamentos de nota fiscais de entrada"
          }
        ],
        "type": "select"
      },
      {
        "label": "Descrição da Solicitação",
        "name": "descri_o_da_solicita_o_1",
        "type": "textArea",
        "validators": Validators.required
      },
      {
        "type": "attachment",
        "label": "Documentos Suporte Anexo",
        "name": "documentos_suporte",
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }
}
