import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxLinkMenuComponent } from './box-link-menu.component';



@NgModule({
  declarations: [BoxLinkMenuComponent],
  imports: [
    CommonModule
  ],
  exports:[
    BoxLinkMenuComponent
  ]
})
export class BoxLinkMenuModule { }
