import { promocao } from './promocao';
import { empresas_beneficios } from './empresas';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from '../../../autenticacao/usuario/user-infos.service';
import { PipefyService } from '../../../services/pipefy.service';
import { TrataInputsService } from '../../../services/trata-inputs.service';
import { pipe_fields_incidentes } from '../../../servicos-financeiros/formularios/pipe_fields_incidentes';
import { pipe_fields_beneficios_ids } from './pipe_fields_ids'
import Swal from 'sweetalert2';
import { FormField } from 'src/app/componentes/formMat/form.component';


@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css']
})
export class BeneficiosComponent implements OnInit {
  fields: FormField[] = [];
  constructor() { }
  ngOnInit(): void {
    this.fields = [
      {
        "label": "Tipo de benefício?",
        name: "tipo_de_benef_cio",
        "type": "radioHorizontal",
        validators: Validators.required,
        select: [
          {
            type: "option",
            name: "VT",
            value: "VT",
            fields: [
              {
                "label": "Opções de Vale Transporte:",
                name: "op_es_de_vale_transporte",
                "type": "radioHorizontal",
                validators: Validators.required,
                select: [
                  {
                    type: "option",
                    name: "Inclusão",
                    value: "Inclusão",
                    fields: [
                      {
                        "label": "Formulário de inclusão de benefício",
                        name: "formul_rio_de_altera_o_de_benef_cio",
                        "type": "attachment",
                        validators: Validators.required,
                        descricao2: [
                          {
                            text: "Clique aqui, ",
                            url: "https://conectanutrien.com.br/gallery/37/sub_gallery/167/sub_gallery/248"
                          },
                          {
                            text: "baixe o Formulário de Inclusão/Alteração, preencha as informações e faça o upload do arquivo."
                          }
                        ]
                      },
                    ]
                  },
                  {
                    type: "option",
                    name: "Alteração",
                    value: "Alteração",
                    fields: [
                      {
                        "label": "Formulário de alteração de benefício",
                        name: "formul_rio_de_altera_o_de_benef_cio",
                        "type": "attachment",
                        validators: Validators.required,
                        descricao2: [
                          {
                            text: "Clique aqui, ",
                            url: "https://conectanutrien.com.br/gallery/37/sub_gallery/167/sub_gallery/248"
                          },
                          {
                            text: "baixe o Formulário de Inclusão/Alteração, preencha as informações e faça o upload do arquivo."
                          }
                        ]
                      },
                    ]
                  },
                  {
                    type: "option",
                    name: "Cancelamento",
                    value: "Cancelamento"
                  }
                ]
              }
            ]
          },
          // {
          //   type: "option",
          //   name: "Seguro de vida",
          //   value: "Seguro de vida",
          //   fields: [
          //     {
          //       "label": "Opções de Seguro de Vida",
          //       name: "op_es_de_seguro_de_vida",
          //       "type": "radioVertical",
          //       validators: Validators.required,
          //       select: [
          //         {
          //           type: "option",
          //           name: "Atualização de Beneficiário",
          //           value: "Atualização de Beneficiário",
          //           fields: [
          //             {
          //               "label": "Formulário de alteração de benefício",
          //               name: "formul_rio_de_altera_o_de_benef_cio",
          //               "type": "attachment",
          //               validators: Validators.required,
          //               descricao: [
          //                 {
          //                   type: "text",
          //                   value: "Para consultar modelos de formulários acesse o link."
          //                 },
          //                 {
          //                   type: "url",
          //                   value: "https://conectanutrien.com.br/benefits/7"
          //                 }
          //               ]
          //             },
          //           ]
          //         }
          //       ]
          //     }
          //   ]
          // },
          {
            type: "option",
            name: "Previdência Privada",
            value: "Previdência Privada"
          }
        ]
      },
      {
        "label": "Nome do Colaborador",
        name: "nome_do_candidato",
        "type": "text",
        validators: Validators.required
      },
      {
        "label": "Email do Colaborador",
        name: "email_do_candidato",
        "type": "email",
        validators: Validators.required
      },
      {
        "label": "Nome do Solicitante",
        name: "nome_do_solicitante",
        "type": "text",
        validators: Validators.required
      },
      {
        "label": "E-mail do solicitante",
        name: "e_mail_do_solicitante",
        "type": "email",
        validators: Validators.required
      },
      // {
      //   "label": "Fora do prazo de solicitação",
      //   name: "etiquetas",
      //   "type": "label_select"
      // },
      {
        "label": "Observações",
        name: "observa_es",
        "type": "textArea"
      },
      {
        type: "buttonSubmit",
        name: "Enviar"
      }
    ]

  }

  enviarFormulario(event: any): any {
    //
  }
}
