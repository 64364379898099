import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField, FormOptions, ListPipefyConnector } from '../form.component';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectSearchComponent),
        multi: true
    }]
})

export class SelectSearchComponent implements ControlValueAccessor {

    @Input('placeholder') placeholder: string = "";
    @Input() ordenar: "asc" | "dsc" | undefined = undefined;
    lista: ListPipefyConnector[] = [];
    newList: ListPipefyConnector[] = [];
    disabled: boolean = false;
    fnRegisterOnChange!: (value: any) => void;
    fnRegisterOnTouched!: () => void;
    value: string | undefined;
    del: boolean = false;

    inputSearch: string = "";
    exibirListaNovoItem: boolean = false;
    novaLista: any[] = [];
    novaListaSelecionado: ListPipefyConnector = {} as any;


    cliqueSelectNovoItem() {
        this.inputSearch = "";
        this.novaLista = this.lista;
        this.exibirListaNovoItem = !this.exibirListaNovoItem;
        this.fnRegisterOnTouched();
    }

    selecionaNovoItem(item: any) {
        this.novaListaSelecionado = item;
        this.fnRegisterOnChange(this.novaListaSelecionado.name);
        this.value = this.novaListaSelecionado.value;
        this.novaLista = this.lista;
        this.exibirListaNovoItem = false;
        this.fnRegisterOnTouched();
    }

    searchNovoItem() {
        this.novaLista = this.lista;
        this.novaLista = this.novaLista.filter(item => item.value.toLowerCase().includes(this.inputSearch.toLowerCase()));
        this.fnRegisterOnTouched();
    }

    writeValue(data: FormField): void {
        if (!data && !this.del) {
            this.del = true;
            return;
        }
        this.novaListaSelecionado.value = !!data?.value ? data.value : "";
        this.lista = !!data?.select ? this.ordenarArray(data?.select) : this.lista;
        setTimeout(() => {
            this.fnRegisterOnChange(undefined);
        }, 500);
    }
    registerOnChange(fn: any): void {
        this.fnRegisterOnChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.fnRegisterOnTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ordenarArray(array: FormOptions[]): FormOptions[] {
        switch (this.ordenar) {
            case "asc":
                return array.sort((a, b) => {
                    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                });
                break;
            case "dsc":
                return array.sort((a, b) => {
                    return a.value > b.value ? -1 : a.value < b.value ? 1 : 0;
                });
            default:
                return array;
                break;
        }

    }
}
