<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_lojas.png" descricao="teste" titulo="Lojas"
  subtitulo="Centros de Experiência"></app-nbs-banner>


<div class="container">
  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>



  <div class="itens">
    <!-- <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Pedido de Venda e Faturamento"
          url="../../../assets/img/fa-menus/lojas/venda_e_faturamento.png" titulo="Venda e Faturamento"
          subtitulo="Pedido" link="/customer-service/forms/venda-e-faturamento-pedido">
        </app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Alteração/Exclusão de Pedidos"
          url="../../../assets/img/fa-menus/financas/solicitacao_de_reembolso.png"
          titulo="Alteração/Exclusão de Pedidos" subtitulo="Pedidos de venda"
          link="/customer-service/forms/alteracao-pedidos">
        </app-box-link-pipe>
      </div>
    </div>
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Baixa de Títulos"
          url="../../../assets/img/novas/servicos-financeiros/menus/lojas/baixa_de_titulos.png"
          titulo="Baixa de Títulos" link="/customer-service/forms/baixa-de-titulos">
        </app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Faturamento" subtitulo="Exceto Fertilizantes"
          url="../../../assets/img/novas/servicos-financeiros/menus/lojas/faturamento-exceto-fertilizantes.png"
          titulo="Faturamento" subtitulo="Exceto Fertilizantes " link="/customer-service/forms/faturamento">
        </app-box-link-pipe>
      </div>
    </div> -->
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="cadastro e renovacao de clientes-agrometrika"
          url="../../../assets/img/novas/servicos-financeiros/menus/lojas/cadastro.png"
          titulo="Cadastro e Renovação de Clientes - Agrometrika"
          link="customer-service/forms/cadastro-renovacao-clientes-agrometrika">
        </app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Baixa de Títulos"
          url="../../../assets/img/novas/servicos-financeiros/menus/lojas/baixa_de_titulos.png"
          titulo="Baixa de Títulos" link="/customer-service/forms/baixa-de-titulos">
        </app-box-link-pipe>
      </div>
    </div>

  </div>


</div>
<div class="footer">

  <app-footer link="menu"></app-footer>
</div>