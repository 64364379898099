<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Solicitação de Pagamento IRRF - Contrato de Mútuos Tesouraria"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome_responsavel_nutrien" class="col-sm-4 col-form-label">*Nome Responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_responsavel_nutrien">
        <app-mensagem class="mensagem-erro"
        *ngIf="formulario.get('nome_responsavel_nutrien')?.errors?.required && formulario.get('nome_responsavel_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="email_do_responsavel_nutrien" class="col-sm-4 col-form-label">*E-mail do responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_responsavel_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_responsavel_nutrien')?.errors?.required && formulario.get('email_do_responsavel_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_responsavel_nutrien')?.errors?.email && formulario.get('email_do_responsavel_nutrien')?.touched"
        mensagem="Formato de E-mail Inválido"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_de_vencimento_de_imposto" class="col-sm-4 col-form-label">*Data de Vencimento de Imposto</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento_de_imposto">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_vencimento_de_imposto')?.errors?.required && formulario.get('data_de_vencimento_de_imposto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_do_inicio_do_decendio" class="col-sm-4 col-form-label">*Data do início do decêndio</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_do_inicio_do_decendio">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_do_inicio_do_decendio')?.errors?.required && formulario.get('data_do_inicio_do_decendio')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_final_do_decendio" class="col-sm-4 col-form-label">*Data final do decêndio</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_final_do_decendio">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_final_do_decendio')?.errors?.required && formulario.get('data_final_do_decendio')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">*Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('observacao')?.errors?.required && formulario.get('observacao')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="apuracao" class="col-sm-4 col-form-label">*Apuração </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="apuracao" id="formFileMultiple" (change)="saveApuracoes($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('apuracao')?.errors?.required && formulario.get('apuracao')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="apuracoes.length!=0">
      <div class="card-header">Anexo Apuração</div>
      <ul class="list-group list-group-flush" *ngFor="let file of apuracoes">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="contrato" class="col-sm-4 col-form-label">*Contrato </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="contrato" id="formFileMultiple" (change)="saveContratos($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('contrato')?.errors?.required && formulario.get('contrato')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="contratos.length!=0">
      <div class="card-header">Anexo - Contrato</div>
      <ul class="list-group list-group-flush" *ngFor="let file of contratos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileContratos(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
