export const pagamento =[
  {
    nome:''
  },
  {
    nome:'Solicitação de Novo Cartão'
  },
  {
    nome:'Solicitação de Lançamento de Notas'
  },
  {
    nome:'Solicitação de Reembolso de Despesas'
  }
]
