<div class="row box">
    <div class="col-lg-3 box-item">
      <h4>Gestão de Vagas</h4>
    </div>

    <div class="col-lg-3 box-item">
      <h4>Gestão de Treinamentos</h4>
    </div>

    <div class="col-lg-3 box-item">
      <h4>Gestão de Eventos</h4>
    </div>
</div>
