import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { Empresas } from './empresas';

@Component({
  selector: 'app-documentos-suporte-fiscalizacao',
  templateUrl: './documentos-suporte-fiscalizacao.component.html',
  styleUrls: ['./documentos-suporte-fiscalizacao.component.css']
})
export class DocumentosSuporteFiscalizacaoComponent implements OnInit {

  formulario!: FormGroup;
  myFiles: any[] = [];
  empresas = Empresas



  constructor(private formBuilder: FormBuilder, private trataInput: TrataInputsService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      nome_da_empresa:['',[Validators.required]],
      requisitante:['',[Validators.required]],
      torre_area_departamento_loja:['',[Validators.required]],
      prazo_para_entrega:['',[Validators.required]],
      data_de_vencimento:['',[Validators.required]],
      email:['',[Validators.required, Validators.email]],
      documento_suporte:['',[]],
      observacao:['',[]]
    })

    // console.log(this.myFiles.length)


  }

  resetform(){
      for(let field in this.formulario.controls){
        this.formulario.controls[field].setValue('');
      }
  }



  enviarFormulario(){

    let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
    let requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().requisitante)
    let torre_area_departamento_loja = this.trataInput.removeCaracters(this.formulario.getRawValue().torre_area_departamento_loja)
    let prazo_para_entrega = this.trataInput.removeCaracters(this.formulario.getRawValue().prazo_para_entrega)
    let data_de_vencimento = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento)
    let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)

    let documento_suporte = this.trataInput.removeCaracters(this.formulario.getRawValue().documento_suporte)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = [nome_da_empresa, requisitante, torre_area_departamento_loja, prazo_para_entrega,email, data_de_vencimento, observacao]
    // console.log(pipe_fields)


    let fields = [
      {
        "field_id": "email",
        "field_value" :email
      },
      {
        "field:_id": "nome_da_empresa",
        "field_value": nome_da_empresa
      },
      {
        "field_id":"anexo",
        "field_value": this.myFiles
      }
    ]

    // console.log(fields)
  }


  save(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++){
      // console.log(files[i].name)
      this.myFiles.push(files[i].name)
    }

    // console.log(this.myFiles)
    // console.log(this.myFiles.length)

  }

  removeFile(file: File){
    const index: number = this.myFiles.indexOf(file);
      this.myFiles.splice(index, 1)
      alert("Arquivo Removido")
}

abrirPipePublico() {
  window.open('https://app.pipefy.com/public/form/OUZtXsRa', '_blank')
}

}





