import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-url-invalida',
  templateUrl: './url-invalida.component.html',
  styleUrls: ['./url-invalida.component.css']
})
export class UrlInvalidaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
