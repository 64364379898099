export const pipe_fields_ids = [
  'tipo_de_nota',
  'caracteristicas',
  'motivo_de_urg_ncia',
  'descri_o_outros',
  'fornecedor',
  'cpf_cnpj_fornecedor',
  'tomador',
  'cnpj_empresa_nutrien',
  'e_mail_do_requisitante',
  // 'e_mail_do_fornecedor',
  'categoria',
  'subcategoria_po',
  'tipo_de_opera_o',
  'migo',
  'tipo_de_lan_amento',
  // 'n_mero_do_processo',
  // 'anexo_nota_fiscal_ou_boleto', //anexo
  // 'anexo_xml', //anexo
  // 'anexo_nota_fiscal_documento_e_boleto_se_houver', //anexo
  // 'anexo_npo_modelo_excel', //anexo
  'marque_todas_as_op_es_que_se_aplicam',
  'data_de_emiss_o',
  'data_de_vencimento',
  'moeda',
  'numero_da_nota_fiscal',
  'valor_da_fatura',
  'boleto_para_pagamento',
  'forma_de_pagamento',
  'folha_de_servi_os',
  // 'favorecido',
  // 'valor_do_fi',
  // 'setor',
  'descri_o_breve',
  'quantidade_de_pedidos',
  'n_mero_do_pedido_1',
  'n_mero_da_linha_do_pedido_1',
  'n_mero_do_pedido_2',
  'n_mero_da_linha_do_pedido_2',
  'n_mero_do_pedido_3',
  'n_mero_da_linha_do_pedido_3',
  'n_mero_do_pedido_4',
  'n_mero_da_linha_do_pedido_4',
  'n_mero_do_pedido_5',
  'n_mero_da_linha_do_pedido_5',
  'n_mero_do_pedido_6',
  'n_mero_da_linha_do_pedido_6',
  'n_mero_do_pedido_7',
  'n_mero_da_linha_do_pedido_7',
  'n_mero_do_pedido_8',
  'n_mero_da_linha_do_pedido_8',
  'n_mero_do_pedido_9',
  'n_mero_da_linha_do_pedido_9',
  'n_mero_do_pedido_10',
  'n_mero_da_linha_do_pedido_10',
  'lista_de_pedidos',
  'anexo_nota_fiscal_documento_e_boleto_se_houver_long_text_servi_o', //campo texto longo para anexo
  'anexo_xml_long_text', //campo texto longo para anexo
  'anexo_nota_fiscal_documento_e_boleto_se_houver_long_text_material', //campo texto longo para anexo
  'anexo_npo_modelo_excel_long_text', //campo texto longo para anexo
  'anexo_boleto_long_text', //campo texto longo para anexo
  'n_mero_afe',
  'chave_de_acesso',
  'email_logado_na_cria_o_do_card'
]
