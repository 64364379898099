import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';




@Component({
  selector: 'app-nbs-banner',
  templateUrl: './nbs-banner.component.html',
  styleUrls: ['./nbs-banner.component.css']
})
export class NbsBannerComponent implements OnInit {

  @Input() url: string = ''
  @Input() descricao: string = ''
  @Input() titulo: string = ''
  @Input() width: string = ''
  @Input() height: string = ''
  @Input() subtitulo: string = ''


  constructor() { }

  ngOnInit(): void {
  }


}
