export const pipe_fields_faturamento = [
  "sistema_do_pedido",
  "nome_do_cliente",
  "inscri_o_estatudual_ou_nome_propriedade",
  "ordem_de_venda",
  "centro",
  "cidade_estado_pedido_origem",
  "cidade_estado_faturamento",
  "cultura",
  "solicita_o_retorno_webagil_realizada",
  "n_mero_da_solicita_o",
  "tipo_de_faturamento",
  "quantidade_de_produtos_a_serem_faturados_no_pedido",
  "c_digo_produto_1",
  "descri_o_produto_1",
  "quantidade_1",
  "lote_1",
  "c_digo_produto_2",
  "descri_o_produto_2",
  "quantidade_2",
  "lote_2",
  "c_digo_produto_3",
  "descri_o_produto_3",
  "quantidade_3",
  "lote_3",
  "c_digo_produto_4",
  "descri_o_produto_4",
  "quantidade_4",
  "lote_4",
  "c_digo_produto_5",
  "descri_o_produto_5",
  "quantidade_5",
  "lote_5",
  "c_digo_produto_6",
  "descri_o_produto_6",
  "quantidade_6",
  "lote_6",
  "c_digo_produto_7",
  "descri_o_produto_7",
  "quantidade_7",
  "lote_7",
  "c_digo_produto_8",
  "descri_o_produto_8",
  "quantidade_8",
  "lote_8",
  "c_digo_produto_9",
  "descri_o_produto_9",
  "quantidade_9",
  "lote_9",
  "c_digo_produto_10",
  "descri_o_produto_10",
  "quantidade_10",
  "lote_10",
  "c_digo_produto_11",
  "descri_o_produto_11",
  "quantidade_11",
  "lote_11",
  "c_digo_produto_12",
  "descri_o_produto_12",
  "quantidade_12",
  "lote_12",
  "c_digo_produto_13",
  "descri_o_produto_13",
  "quantidade_13",
  "lote_13",
  "c_digo_produto_14",
  "descri_o_produto_14",
  "quantidade_14",
  "lote_14",
  "c_digo_produto_15",
  "descri_o_produto_15",
  "quantidade_15",
  "lote_15",
  "c_digo_produto_16",
  "descri_o_produto_16",
  "quantidade_16",
  "lote_16",
  "c_digo_produto_17",
  "descri_o_produto_17",
  "quantidade_17",
  "lote_17",
  "c_digo_produto_18",
  "descri_o_produto_18",
  "quantidade_18",
  "lote_18",
  "c_digo_produto_19",
  "descri_o_produto_19",
  "quantidade_19",
  "lote_19",
  "c_digo_produto_20",
  "descri_o_produto_20",
  "quantidade_20",
  "lote_20",
  "lat_long_entrada",
  "data_solicitada_para_faturamento",
  "faturamento_cont_m_defensivo_qu_mico",
  "nome_e_e_mail_respons_vel_t_cnico",
  "emitir_boleto_ao_cliente_no_momento_do_faturamento",
  "data_de_vencimento",
  "incluir_documentos_pasta_de_roteiriza_o",
  "e_mail_para_envio_de_documenta_o",
  "estado_loja_xp",
  "cidade_loja_xp",
  "solicitante",
  "e_mail_solicitante",
  "e_mail_secund_rio",
  "observa_es",
  "demanda_urgente",
  "descreva_a_urg_ncia",
]
