export const pipe_fields_id =[
  'nome_respons_vel_nutrien',
  'e_mail_do_respons_vel_nutrien',
  'empresa',
  'data_do_in_cio_do_dec_nio',
  'data_final_do_dec_ndio',
  'observa_o',
  'apura_o',
  'contrato',
]
