import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

  @Input() collapsed = false;
  @Input() screenWidth = 0;






  constructor(private route: Router) { }

  ngOnInit(): void {
    this.goToVagas();
    // console.log(this.collapsed)
  }

  getBodyClass():string{
    let styleClass = '';

    if(this.collapsed && this.screenWidth > 768) {
      styleClass = 'body-trimmed';
    } else if(this.collapsed && this.screenWidth <= 768 && this.screenWidth >0){
      styleClass = 'body-md-screen'
    }

    return styleClass;

  }

  goToVagas(){
    this.route.navigate(['/recursos-humanos/home/vagas'])
  }

}
