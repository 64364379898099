export const fluxos =[
  {
    area:'',
    nome:''
  },
  {
    area:'Contabilidade',
    nome:'Auditoria Documentos Suporte'
  },
  {
    area:'Contabilidade',
    nome:'Baixa Imobilizado'
  },
  {
    area:'Contabilidade',
    nome:'Lançamento Contábil'
  },
  {
    area:'Lojas',
    nome:'Venda e Faturamento Pedido'
  },
  {
    area:'Pagamento',
    nome:'Cartão de Crédito Corporativo Serviços'
  },
  {
    area:'Pagamento',
    nome:'Lançamento/Pagamento Notas/Impostos e outras cobranças'
  },
  {
    area:'Fiscal',
    nome:'Solicitação de pagamento impostos apurados remessas para exterior'
  },
  {
    area:'Fiscal',
    nome:'Guias fiscais Emissão - taxas e tributos diversos'
  },
  {
    area:'Fiscal',
    nome:'Solicitação de Pagamento IRRF ou IOF - Contrato de Mútuos '
  },
  {
    area:'Fiscal',
    nome:'Cancelamento, Recusa,Emissão Manual, Notas Fiscais'
  },
  {
    area:'Fiscal',
    nome:'Suporte Fiscal'
  },
  {
    area:'Fiscal',
    nome:'Notas Fiscais de Importação - Emissão'
  },
  {
    area:'Outros',
    nome:'Tire suas dúvidas'
  },
  {
    area:'Outros',
    nome:'Cancelamento de Solicitação'
  }
]
