export const pipe_fields_id = [
  'nome',
  'tel',
  'email',
  'melhor_forma_de_contato',
  'rea',
  'contabilidade',
  'fiscal',
  'lojas_centros_de_experi_ncia',
  'pagamentos',
  'compras_de_servi_os_e_materiais_indiretos',
  'd_vida_info'
]
