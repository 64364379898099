export const fiscal = [
  {
    nome:''
  },
  {
    nome:'Revisão e aprovação de obrigações acessórias'
  },
  {
    nome:'Revisão e aprovação de apuração e pagamento fiscal'
  },
  {
    nome:'Solicitação de documentos suporte fiscalização'
  },
  {
    nome:'Solicitação de documentos suporte auditoria'
  },
  {
    nome:'Retificação obrigações acessórias e apurações'
  },
  {
    nome:'Solicitação de Pagamento IOF sobre Contratos de Mútuos Tesouraria'
  },
  {
    nome:'Solicitação de Pagamento IOF sobre Contrato de Mútuos - RH'
  },
  {
    nome:'Solicitação de pagamento de taxas/tributos diversos'
  },
  {
    nome:'Solicitação cancelamento NFs, Recusa NFs, Emissão de NFs manuais'
  },
  {
    nome:'Suporte fiscal para lojas e demais departamentos'
  },
  {
    nome:'Suporte em testes/homologações de ajustes em tabelas fiscais e obrigações acessórias'
  },
  {
    nome:'Solicitação de Pagamento IRRF sobre Contrato de Mútuos Tesouraria'
  },
  {
    nome:'Solicitação de Pagamento Impostos Apurados sobre Remessas para Exterior'
  }
]
