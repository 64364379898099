export const torres = [
  {
    tipo:''
  },
  {
    tipo:'Contábil'
  },
  {
    tipo:'Fiscal'
  }
]
