export const descricao_da_solicitacao = [
  {
    nome:''
  },
  {
    nome:'1 - Nota Fiscal de Importação'
  },
  {
    nome:'2 – Nota Fiscal Filha '
  },
  {
    nome:'3 – Nota Fiscal Complementar'
  },
  {
    nome:'4 - Cancelamento de Nota Fiscal de Importação'
  },
  {
    nome:'5 - Carta de Correção'
  }
]
