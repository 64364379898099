export const areasResponsavelLancamento = [
    "Contabilidade Nutrien",
    "Custo Nutrien",
    "Imobilizado Nutrien",
    "Tax (Diretos)",
    "Tax (Indiretos)",
    "Tesouraria (Empréstimos)",
    "Tesouraria (Caixa e aplicações)",
    "Tesouraria (FIDC/Cessões)",
    "Tesouraria (PCLD)",
    "Tesouraria (Derivativos)",
    "Tesouraria (Barter)",
    "Tesouraria - MarcaAgro"
]