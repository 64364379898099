import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { quantidadeDeProdutos } from './quantidade-de-produtos';
import { pipe_fields_faturamento } from './pipe_fields'

@Component({
  selector: 'app-faturamento',
  templateUrl: './faturamento.component.html',
  styleUrls: ['./faturamento.component.css']
})
export class FaturamentoComponent implements OnInit {


  formulario!: FormGroup;
  quantidadeDeProdutos = quantidadeDeProdutos
  anexos: any[] = [];
  email: string = ''
  anexosUrl: any[] = []
  faltamCamposObrigatorios: boolean = true
  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      sistema_do_pedido: ['', [Validators.required]],
      nome_do_cliente: ['', [Validators.required]],
      inscri_o_estatudual_ou_nome_propriedade: ['', []],
      ordem_de_venda: ['', []],
      centro: ['', []],
      cidade_estado_pedido_origem: ['', []],
      cidade_estado_faturamento: ['', []],
      cultura: ['', []],
      solicita_o_retorno_webagil_realizada: ['', []],
      n_mero_da_solicita_o: ['', []],
      tipo_de_faturamento: ['', [Validators.required]],
      quantidade_de_produtos_a_serem_faturados_no_pedido: ['', []],
      c_digo_produto_1: ['', []],
      descri_o_produto_1: ['', []],
      quantidade_1: ['', []],
      lote_1: ['', []],
      c_digo_produto_2: ['', []],
      descri_o_produto_2: ['', []],
      quantidade_2: ['', []],
      lote_2: ['', []],
      c_digo_produto_3: ['', []],
      descri_o_produto_3: ['', []],
      quantidade_3: ['', []],
      lote_3: ['', []],
      c_digo_produto_4: ['', []],
      descri_o_produto_4: ['', []],
      quantidade_4: ['', []],
      lote_4: ['', []],
      c_digo_produto_5: ['', []],
      descri_o_produto_5: ['', []],
      quantidade_5: ['', []],
      lote_5: ['', []],
      c_digo_produto_6: ['', []],
      descri_o_produto_6: ['', []],
      quantidade_6: ['', []],
      lote_6: ['', []],
      c_digo_produto_7: ['', []],
      descri_o_produto_7: ['', []],
      quantidade_7: ['', []],
      lote_7: ['', []],
      c_digo_produto_8: ['', []],
      descri_o_produto_8: ['', []],
      quantidade_8: ['', []],
      lote_8: ['', []],
      c_digo_produto_9: ['', []],
      descri_o_produto_9: ['', []],
      quantidade_9: ['', []],
      lote_9: ['', []],
      c_digo_produto_10: ['', []],
      descri_o_produto_10: ['', []],
      quantidade_10: ['', []],
      lote_10: ['', []],
      c_digo_produto_11: ['', []],
      descri_o_produto_11: ['', []],
      quantidade_11: ['', []],
      lote_11: ['', []],
      c_digo_produto_12: ['', []],
      descri_o_produto_12: ['', []],
      quantidade_12: ['', []],
      lote_12: ['', []],
      c_digo_produto_13: ['', []],
      descri_o_produto_13: ['', []],
      quantidade_13: ['', []],
      lote_13: ['', []],
      c_digo_produto_14: ['', []],
      descri_o_produto_14: ['', []],
      quantidade_14: ['', []],
      lote_14: ['', []],
      c_digo_produto_15: ['', []],
      descri_o_produto_15: ['', []],
      quantidade_15: ['', []],
      lote_15: ['', []],
      c_digo_produto_16: ['', []],
      descri_o_produto_16: ['', []],
      quantidade_16: ['', []],
      lote_16: ['', []],
      c_digo_produto_17: ['', []],
      descri_o_produto_17: ['', []],
      quantidade_17: ['', []],
      lote_17: ['', []],
      c_digo_produto_18: ['', []],
      descri_o_produto_18: ['', []],
      quantidade_18: ['', []],
      lote_18: ['', []],
      c_digo_produto_19: ['', []],
      descri_o_produto_19: ['', []],
      quantidade_19: ['', []],
      lote_19: ['', []],
      c_digo_produto_20: ['', []],
      descri_o_produto_20: ['', []],
      quantidade_20: ['', []],
      lote_20: ['', []],
      lat_long_entrada: ['', []],
      data_solicitada_para_faturamento: ['', [Validators.required]],
      faturamento_cont_m_defensivo_qu_mico: ['', [Validators.required]],
      nome_e_e_mail_respons_vel_t_cnico: ['', []],
      emitir_boleto_ao_cliente_no_momento_do_faturamento: ['', [Validators.required]],
      data_de_vencimento: ['', []],
      incluir_documentos_pasta_de_roteiriza_o: ['', [Validators.required]],
      e_mail_para_envio_de_documenta_o: ['', []],
      // ctv: ['', [Validators.required]],
      estado_loja_xp: ['', [Validators.required]],
      cidade_loja_xp: ['', [Validators.required]],
      solicitante: ['', [Validators.required]],
      e_mail_solicitante: ['', [Validators.required]],
      e_mail_secund_rio: ['', []],
      observa_es: ['', []],
      demanda_urgente: ['', [Validators.required]],
      descreva_a_urg_ncia: ['', []]
    })
  }


  enviarFormulario() {
    this.spinner.show()
    let sistema_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().sistema_do_pedido)
    let nome_do_cliente = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_cliente)
    let inscri_o_estatudual_ou_nome_propriedade = this.trataInput.removeCaracters(this.formulario.getRawValue().inscri_o_estatudual_ou_nome_propriedade)
    let ordem_de_venda = this.trataInput.removeCaracters(this.formulario.getRawValue().ordem_de_venda)
    let centro = this.trataInput.removeCaracters(this.formulario.getRawValue().centro)
    let cidade_estado_pedido_origem = this.trataInput.removeCaracters(this.formulario.getRawValue().cidade_estado_pedido_origem)
    let cidade_estado_faturamento = this.trataInput.removeCaracters(this.formulario.getRawValue().cidade_estado_faturamento)
    let cultura = this.trataInput.removeCaracters(this.formulario.getRawValue().cultura)
    let solicita_o_retorno_webagil_realizada = this.trataInput.removeCaracters(this.formulario.getRawValue().solicita_o_retorno_webagil_realizada)
    let n_mero_da_solicita_o = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_solicita_o)
    let tipo_de_faturamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_faturamento)
    let quantidade_de_produtos_a_serem_faturados_no_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_de_produtos_a_serem_faturados_no_pedido)
    let c_digo_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_1)
    let descri_o_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_1)
    let quantidade_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_1)
    let lote_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_1)
    let c_digo_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_2)
    let descri_o_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_2)
    let quantidade_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_2)
    let lote_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_2)
    let c_digo_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_3)
    let descri_o_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_3)
    let quantidade_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_3)
    let lote_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_3)
    let c_digo_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_4)
    let descri_o_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_4)
    let quantidade_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_4)
    let lote_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_4)
    let c_digo_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_5)
    let descri_o_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_5)
    let quantidade_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_5)
    let lote_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_5)
    let c_digo_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_6)
    let descri_o_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_6)
    let quantidade_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_6)
    let lote_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_6)
    let c_digo_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_7)
    let descri_o_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_7)
    let quantidade_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_7)
    let lote_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_7)
    let c_digo_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_8)
    let descri_o_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_8)
    let quantidade_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_8)
    let lote_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_8)
    let c_digo_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_9)
    let descri_o_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_9)
    let quantidade_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_9)
    let lote_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_9)
    let c_digo_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_10)
    let descri_o_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_10)
    let quantidade_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_10)
    let lote_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_10)
    let c_digo_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_11)
    let descri_o_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_11)
    let quantidade_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_11)
    let lote_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_11)
    let c_digo_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_12)
    let descri_o_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_12)
    let quantidade_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_12)
    let lote_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_12)
    let c_digo_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_13)
    let descri_o_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_13)
    let quantidade_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_13)
    let lote_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_13)
    let c_digo_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_14)
    let descri_o_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_14)
    let quantidade_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_14)
    let lote_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_14)
    let c_digo_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_15)
    let descri_o_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_15)
    let quantidade_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_15)
    let lote_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_15)
    let c_digo_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_16)
    let descri_o_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_16)
    let quantidade_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_16)
    let lote_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_16)
    let c_digo_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_17)
    let descri_o_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_17)
    let quantidade_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_17)
    let lote_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_17)
    let c_digo_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_18)
    let descri_o_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_18)
    let quantidade_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_18)
    let lote_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_18)
    let c_digo_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_19)
    let descri_o_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_19)
    let quantidade_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_19)
    let lote_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_19)
    let c_digo_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_produto_20)
    let descri_o_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_produto_20)
    let quantidade_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_20)
    let lote_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().lote_20)
    let lat_long_entrada = this.trataInput.removeCaracters(this.formulario.getRawValue().lat_long_entrada)
    let data_solicitada_para_faturamento = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_solicitada_para_faturamento))
    let faturamento_cont_m_defensivo_qu_mico = this.trataInput.removeCaracters(this.formulario.getRawValue().faturamento_cont_m_defensivo_qu_mico)
    let nome_e_e_mail_respons_vel_t_cnico = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_e_e_mail_respons_vel_t_cnico)
    let emitir_boleto_ao_cliente_no_momento_do_faturamento = this.trataInput.removeCaracters(this.formulario.getRawValue().emitir_boleto_ao_cliente_no_momento_do_faturamento)
    let data_de_vencimento = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento))

    let incluir_documentos_pasta_de_roteiriza_o = this.trataInput.removeCaracters(this.formulario.getRawValue().incluir_documentos_pasta_de_roteiriza_o)
    let e_mail_para_envio_de_documenta_o = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_para_envio_de_documenta_o)
    // let ctv = this.trataInput.removeCaracters(this.formulario.getRawValue().ctv)
    let estado_loja_xp = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_loja_xp)
    let cidade_loja_xp = this.trataInput.removeCaracters(this.formulario.getRawValue().cidade_loja_xp)
    let solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().solicitante)
    let e_mail_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_solicitante)
    let e_mail_secund_rio = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_secund_rio)
    let observa_es = this.trataInput.removeCaracters(this.formulario.getRawValue().observa_es)
    let demanda_urgente = this.trataInput.removeCaracters(this.formulario.getRawValue().demanda_urgente)
    let descreva_a_urg_ncia = this.trataInput.removeCaracters(this.formulario.getRawValue().descreva_a_urg_ncia)




    let pipe_fields = pipe_fields_faturamento
    let fields_values = [sistema_do_pedido,
      nome_do_cliente,
      inscri_o_estatudual_ou_nome_propriedade,
      ordem_de_venda,
      centro,
      cidade_estado_pedido_origem,
      cidade_estado_faturamento,
      cultura,
      solicita_o_retorno_webagil_realizada,
      n_mero_da_solicita_o,
      tipo_de_faturamento,
      quantidade_de_produtos_a_serem_faturados_no_pedido,
      c_digo_produto_1,
      descri_o_produto_1,
      quantidade_1,
      lote_1,
      c_digo_produto_2,
      descri_o_produto_2,
      quantidade_2,
      lote_2,
      c_digo_produto_3,
      descri_o_produto_3,
      quantidade_3,
      lote_3,
      c_digo_produto_4,
      descri_o_produto_4,
      quantidade_4,
      lote_4,
      c_digo_produto_5,
      descri_o_produto_5,
      quantidade_5,
      lote_5,
      c_digo_produto_6,
      descri_o_produto_6,
      quantidade_6,
      lote_6,
      c_digo_produto_7,
      descri_o_produto_7,
      quantidade_7,
      lote_7,
      c_digo_produto_8,
      descri_o_produto_8,
      quantidade_8,
      lote_8,
      c_digo_produto_9,
      descri_o_produto_9,
      quantidade_9,
      lote_9,
      c_digo_produto_10,
      descri_o_produto_10,
      quantidade_10,
      lote_10,
      c_digo_produto_11,
      descri_o_produto_11,
      quantidade_11,
      lote_11,
      c_digo_produto_12,
      descri_o_produto_12,
      quantidade_12,
      lote_12,
      c_digo_produto_13,
      descri_o_produto_13,
      quantidade_13,
      lote_13,
      c_digo_produto_14,
      descri_o_produto_14,
      quantidade_14,
      lote_14,
      c_digo_produto_15,
      descri_o_produto_15,
      quantidade_15,
      lote_15,
      c_digo_produto_16,
      descri_o_produto_16,
      quantidade_16,
      lote_16,
      c_digo_produto_17,
      descri_o_produto_17,
      quantidade_17,
      lote_17,
      c_digo_produto_18,
      descri_o_produto_18,
      quantidade_18,
      lote_18,
      c_digo_produto_19,
      descri_o_produto_19,
      quantidade_19,
      lote_19,
      c_digo_produto_20,
      descri_o_produto_20,
      quantidade_20,
      lote_20,
      lat_long_entrada,
      data_solicitada_para_faturamento,
      faturamento_cont_m_defensivo_qu_mico,
      nome_e_e_mail_respons_vel_t_cnico,
      emitir_boleto_ao_cliente_no_momento_do_faturamento,
      data_de_vencimento,
      incluir_documentos_pasta_de_roteiriza_o,
      e_mail_para_envio_de_documenta_o,
      estado_loja_xp,
      cidade_loja_xp,
      solicitante,
      e_mail_solicitante,
      e_mail_secund_rio,
      observa_es,
      demanda_urgente,
      descreva_a_urg_ncia
    ]
    let pipe_id = '302756320'

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/BSp1DAsp" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Tech', "Lojas - Faturamento", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    }, (error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/BSp1DAsp" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
      }
    })
  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/BSp1DAsp', '_blank')
  }

  onSistemaDoPedidoChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Siagri' || valor == 'Protheus / TOTVs Agrosema') {
      console.log("entrei aqui 1")
      this.formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.setValidators([Validators.required])
      this.formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.updateValueAndValidity()

      this.formulario.get('cidade_estado_pedido_origem')?.setValidators([Validators.required])
      this.formulario.get('cidade_estado_pedido_origem')?.updateValueAndValidity()

      this.formulario.get('cidade_estado_faturamento')?.setValidators([Validators.required])
      this.formulario.get('cidade_estado_faturamento')?.updateValueAndValidity()

      this.formulario.get('cultura')?.setValidators([Validators.required])
      this.formulario.get('cultura')?.updateValueAndValidity()

      this.formulario.get('solicita_o_retorno_webagil_realizada')?.setValidators([Validators.required])
      this.formulario.get('solicita_o_retorno_webagil_realizada')?.updateValueAndValidity()

      this.formulario.get('ordem_de_venda')?.clearValidators()
      this.formulario.get('ordem_de_venda')?.updateValueAndValidity()
    }

    if (valor == 'SAP') {
      console.log("entrei aqui 2")

      this.formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.clearValidators()
      this.formulario.get('inscri_o_estatudual_ou_nome_propriedade')?.updateValueAndValidity()

      this.formulario.get('cidade_estado_pedido_origem')?.clearValidators()
      this.formulario.get('cidade_estado_pedido_origem')?.updateValueAndValidity()

      this.formulario.get('cidade_estado_faturamento')?.clearValidators()
      this.formulario.get('cidade_estado_faturamento')?.updateValueAndValidity()

      this.formulario.get('cultura')?.clearValidators()
      this.formulario.get('cultura')?.updateValueAndValidity()

      this.formulario.get('solicita_o_retorno_webagil_realizada')?.clearValidators()
      this.formulario.get('solicita_o_retorno_webagil_realizada')?.updateValueAndValidity()

      this.formulario.get('ordem_de_venda')?.setValidators([Validators.required])
      this.formulario.get('ordem_de_venda')?.updateValueAndValidity()

      this.formulario.get('centro')?.setValidators([Validators.required])
      this.formulario.get('centro')?.updateValueAndValidity()
    }

    if (valor != 'SAP') {
      this.formulario.get('centro')?.clearValidators()
      this.formulario.get('centro')?.updateValueAndValidity()
    }
  }

  onSolicitacaoWebAgilRealizadaChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Sim') {
      this.formulario.get('n_mero_da_solicita_o')?.setValidators([Validators.required])
      this.formulario.get('n_mero_da_solicita_o')?.updateValueAndValidity()

    }
    else {
      this.formulario.get('n_mero_da_solicita_o')?.clearValidators()
      this.formulario.get('n_mero_da_solicita_o')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_solicita_o')?.setValue('')
    }
  }

  onTipodeFaturamentoChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Parcial') {
      this.formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.setValidators([Validators.required])
      this.formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.updateValueAndValidity()
    }

    else {
      this.formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.clearValidators()
      this.formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.updateValueAndValidity()
      this.formulario.get('quantidade_de_produtos_a_serem_faturados_no_pedido')?.setValue('')
    }
  }

  onFaturamentoNecessitaDeEmissaoChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Sim') {
      // this.formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.setValidators([Validators.required])
      // this.formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.updateValueAndValidity()
    }
    else {
      this.formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.clearValidators()
      this.formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.updateValueAndValidity()
      this.formulario.get('nome_e_e_mail_respons_vel_t_cnico')?.setValue('')
      this.formulario.controls['nome_e_e_mail_respons_vel_t_cnico'].markAsUntouched()
    }
  }

  onEmitirBoletoChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Sim') {
      this.formulario.get('data_de_vencimento')?.setValidators([Validators.required])
      this.formulario.get('data_de_vencimento')?.updateValueAndValidity()
    } else {
      this.formulario.get('data_de_vencimento')?.clearValidators()
      this.formulario.get('data_de_vencimento')?.updateValueAndValidity()
      this.formulario.get('data_de_vencimento')?.setValue('')
      this.formulario.controls['data_de_vencimento'].markAsUntouched()
    }
  }

  onIncluirDocumentosChange(event: any) {
    let valor = event.target.value
    console.log(valor)

    if (valor == 'Não') {
      this.formulario.get('e_mail_para_envio_de_documenta_o')?.setValidators([Validators.required])
      this.formulario.get('e_mail_para_envio_de_documenta_o')?.updateValueAndValidity()
    } else {
      this.formulario.get('e_mail_para_envio_de_documenta_o')?.clearValidators()
      this.formulario.get('e_mail_para_envio_de_documenta_o')?.updateValueAndValidity()
      this.formulario.get('e_mail_para_envio_de_documenta_o')?.setValue('')
      this.formulario.controls['e_mail_para_envio_de_documenta_o'].markAsUntouched()
    }
  }

  onQuantidadeDeProdutosChange(event: any) {

    let valor = event.target.value

    if(valor == 1){
      console.log("entrei no 1")
      this.ajustaValidators1()
      this.limpaCampos1()
    }

    if(valor == 2){
      console.log("entrei no 2")
      this.ajustaValidators2()
      this.limpaCampos2()

    }

    if(valor == 3){
      console.log("entrei no 3")
      this.ajustaValidators3()
      this.limpaCampos3()

    }

    if(valor == 4){
      console.log("entrei no 4")
      this.ajustaValidators4()
      this.limpaCampos4()

    }

    if(valor == 5){
      console.log("entrei no 5")
      this.ajustaValidators5()
      this.limpaCampos5()

    }

    if(valor == 6){
      console.log("entrei no 6")
      this.ajustaValidators6()
      this.limpaCampos6()

    }

    if(valor == 7){
      console.log("entrei no 7")
      this.ajustaValidators7()
      this.limpaCampos7()

    }

    if(valor == 8){
      console.log("entrei no 8")
      this.ajustaValidators8()
      this.limpaCampos8()

    }

    if(valor == 9){
      console.log("entrei no 9")
      this.ajustaValidators9()
      this.limpaCampos9()

    }

    if(valor == 10){
      console.log("entrei no 10")
      this.ajustaValidators10()
      this.limpaCampos10()

    }

    if(valor == 11){
      console.log("entrei no 11")
      this.ajustaValidators11()
      this.limpaCampos11()

    }

    if(valor == 12){
      console.log("entrei no 12")
      this.ajustaValidators12()
      this.limpaCampos12()

    }

    if(valor == 13){
      console.log("entrei no 13")
      this.ajustaValidators13()
      this.limpaCampos13()

    }

    if(valor == 14){
      console.log("entrei no 14")
      this.ajustaValidators14()
      this.limpaCampos14()

    }

    if(valor == 15){
      console.log("entrei no 15")
      this.ajustaValidators15()
      this.limpaCampos15()

    }

    if(valor == 16){
      console.log("entrei no 16")
      this.ajustaValidators16()
      this.limpaCampos16()

    }

    if(valor == 17){
      console.log("entrei no 17")
      this.ajustaValidators17()
      this.limpaCampos17()

    }

    if(valor == 18){
      console.log("entrei no 18")
      this.ajustaValidators18()
      this.limpaCampos18()

    }

    if(valor == 19){
      console.log("entrei no 19")
      this.ajustaValidators19()
      this.limpaCampos19()

    }

    if(valor == 20){
      console.log("entrei no 20")
      this.ajustaValidators20()
    }
  }

  ajustaValidators1() {

    console.log("um")
    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.clearValidators()
    this.formulario.get('descri_o_produto_2')?.clearValidators()
    this.formulario.get('quantidade_2')?.clearValidators()

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.clearValidators()
    this.formulario.get('descri_o_produto_3')?.clearValidators()
    this.formulario.get('quantidade_3')?.clearValidators()

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.clearValidators()
    this.formulario.get('descri_o_produto_4')?.clearValidators()
    this.formulario.get('quantidade_4')?.clearValidators()

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.clearValidators()
    this.formulario.get('descri_o_produto_5')?.clearValidators()
    this.formulario.get('quantidade_5')?.clearValidators()

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.clearValidators()
    this.formulario.get('descri_o_produto_6')?.clearValidators()
    this.formulario.get('quantidade_6')?.clearValidators()

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators2() {

    console.log("dois")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.clearValidators()
    this.formulario.get('descri_o_produto_3')?.clearValidators()
    this.formulario.get('quantidade_3')?.clearValidators()

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.clearValidators()
    this.formulario.get('descri_o_produto_4')?.clearValidators()
    this.formulario.get('quantidade_4')?.clearValidators()

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.clearValidators()
    this.formulario.get('descri_o_produto_5')?.clearValidators()
    this.formulario.get('quantidade_5')?.clearValidators()

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.clearValidators()
    this.formulario.get('descri_o_produto_6')?.clearValidators()
    this.formulario.get('quantidade_6')?.clearValidators()

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators3() {

    console.log("tres")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.clearValidators()
    this.formulario.get('descri_o_produto_4')?.clearValidators()
    this.formulario.get('quantidade_4')?.clearValidators()

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.clearValidators()
    this.formulario.get('descri_o_produto_5')?.clearValidators()
    this.formulario.get('quantidade_5')?.clearValidators()

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.clearValidators()
    this.formulario.get('descri_o_produto_6')?.clearValidators()
    this.formulario.get('quantidade_6')?.clearValidators()

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators4() {

    console.log("quatro")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.clearValidators()
    this.formulario.get('descri_o_produto_5')?.clearValidators()
    this.formulario.get('quantidade_5')?.clearValidators()

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.clearValidators()
    this.formulario.get('descri_o_produto_6')?.clearValidators()
    this.formulario.get('quantidade_6')?.clearValidators()

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators5() {

    console.log("cinco")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.clearValidators()
    this.formulario.get('descri_o_produto_6')?.clearValidators()
    this.formulario.get('quantidade_6')?.clearValidators()

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators6() {

    console.log("seis")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.clearValidators()
    this.formulario.get('descri_o_produto_7')?.clearValidators()
    this.formulario.get('quantidade_7')?.clearValidators()

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators7() {

    console.log("sete")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.clearValidators()
    this.formulario.get('descri_o_produto_8')?.clearValidators()
    this.formulario.get('quantidade_8')?.clearValidators()

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators8() {

    console.log("oito")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.clearValidators()
    this.formulario.get('descri_o_produto_9')?.clearValidators()
    this.formulario.get('quantidade_9')?.clearValidators()

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators9() {

    console.log("nove")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.clearValidators()
    this.formulario.get('descri_o_produto_10')?.clearValidators()
    this.formulario.get('quantidade_10')?.clearValidators()

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators10() {

    console.log("dez")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.clearValidators()
    this.formulario.get('descri_o_produto_11')?.clearValidators()
    this.formulario.get('quantidade_11')?.clearValidators()

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators11() {

    console.log("onze")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.clearValidators()
    this.formulario.get('descri_o_produto_12')?.clearValidators()
    this.formulario.get('quantidade_12')?.clearValidators()

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators12() {

    console.log("doze")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.clearValidators()
    this.formulario.get('descri_o_produto_13')?.clearValidators()
    this.formulario.get('quantidade_13')?.clearValidators()

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators13() {

    console.log("treze")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.clearValidators()
    this.formulario.get('descri_o_produto_14')?.clearValidators()
    this.formulario.get('quantidade_14')?.clearValidators()

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators14() {

    console.log("quatorze")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.clearValidators()
    this.formulario.get('descri_o_produto_15')?.clearValidators()
    this.formulario.get('quantidade_15')?.clearValidators()

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators15() {

    console.log("quinze")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.clearValidators()
    this.formulario.get('descri_o_produto_16')?.clearValidators()
    this.formulario.get('quantidade_16')?.clearValidators()

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators16() {

    console.log("dezesseis")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_16')?.setValidators([Validators.required])
    this.formulario.get('quantidade_16')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.clearValidators()
    this.formulario.get('descri_o_produto_17')?.clearValidators()
    this.formulario.get('quantidade_17')?.clearValidators()

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators17() {

    console.log("dezessete")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_16')?.setValidators([Validators.required])
    this.formulario.get('quantidade_16')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_17')?.setValidators([Validators.required])
    this.formulario.get('quantidade_17')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.clearValidators()
    this.formulario.get('descri_o_produto_18')?.clearValidators()
    this.formulario.get('quantidade_18')?.clearValidators()

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators18() {

    console.log("dezoito")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_16')?.setValidators([Validators.required])
    this.formulario.get('quantidade_16')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_17')?.setValidators([Validators.required])
    this.formulario.get('quantidade_17')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_18')?.setValidators([Validators.required])
    this.formulario.get('quantidade_18')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.clearValidators()
    this.formulario.get('descri_o_produto_19')?.clearValidators()
    this.formulario.get('quantidade_19')?.clearValidators()

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators19() {

    console.log("dezenove")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_16')?.setValidators([Validators.required])
    this.formulario.get('quantidade_16')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_17')?.setValidators([Validators.required])
    this.formulario.get('quantidade_17')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_18')?.setValidators([Validators.required])
    this.formulario.get('quantidade_18')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_19')?.setValidators([Validators.required])
    this.formulario.get('quantidade_19')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.clearValidators()
    this.formulario.get('descri_o_produto_20')?.clearValidators()
    this.formulario.get('quantidade_20')?.clearValidators()

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }

  ajustaValidators20() {

    console.log("vinte")

    this.formulario.get('c_digo_produto_1')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_1')?.setValidators([Validators.required])
    this.formulario.get('quantidade_1')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_1')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_1')?.updateValueAndValidity()
    this.formulario.get('quantidade_1')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_2')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_2')?.setValidators([Validators.required])
    this.formulario.get('quantidade_2')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_2')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_2')?.updateValueAndValidity()
    this.formulario.get('quantidade_2')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_3')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_3')?.setValidators([Validators.required])
    this.formulario.get('quantidade_3')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_3')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_3')?.updateValueAndValidity()
    this.formulario.get('quantidade_3')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_4')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_4')?.setValidators([Validators.required])
    this.formulario.get('quantidade_4')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_4')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_4')?.updateValueAndValidity()
    this.formulario.get('quantidade_4')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_5')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_5')?.setValidators([Validators.required])
    this.formulario.get('quantidade_5')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_5')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_5')?.updateValueAndValidity()
    this.formulario.get('quantidade_5')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_6')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_6')?.setValidators([Validators.required])
    this.formulario.get('quantidade_6')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_6')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_6')?.updateValueAndValidity()
    this.formulario.get('quantidade_6')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_7')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_7')?.setValidators([Validators.required])
    this.formulario.get('quantidade_7')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_7')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_7')?.updateValueAndValidity()
    this.formulario.get('quantidade_7')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_8')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_8')?.setValidators([Validators.required])
    this.formulario.get('quantidade_8')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_8')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_8')?.updateValueAndValidity()
    this.formulario.get('quantidade_8')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_9')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_9')?.setValidators([Validators.required])
    this.formulario.get('quantidade_9')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_9')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_9')?.updateValueAndValidity()
    this.formulario.get('quantidade_9')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_10')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_10')?.setValidators([Validators.required])
    this.formulario.get('quantidade_10')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_10')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_10')?.updateValueAndValidity()
    this.formulario.get('quantidade_10')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_11')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_11')?.setValidators([Validators.required])
    this.formulario.get('quantidade_11')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_11')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_11')?.updateValueAndValidity()
    this.formulario.get('quantidade_11')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_12')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_12')?.setValidators([Validators.required])
    this.formulario.get('quantidade_12')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_12')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_12')?.updateValueAndValidity()
    this.formulario.get('quantidade_12')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_13')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_13')?.setValidators([Validators.required])
    this.formulario.get('quantidade_13')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_13')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_13')?.updateValueAndValidity()
    this.formulario.get('quantidade_13')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_14')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_14')?.setValidators([Validators.required])
    this.formulario.get('quantidade_14')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_14')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_14')?.updateValueAndValidity()
    this.formulario.get('quantidade_14')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_15')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_15')?.setValidators([Validators.required])
    this.formulario.get('quantidade_15')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_15')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_15')?.updateValueAndValidity()
    this.formulario.get('quantidade_15')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_16')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_16')?.setValidators([Validators.required])
    this.formulario.get('quantidade_16')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_16')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_16')?.updateValueAndValidity()
    this.formulario.get('quantidade_16')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_17')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_17')?.setValidators([Validators.required])
    this.formulario.get('quantidade_17')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_17')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_17')?.updateValueAndValidity()
    this.formulario.get('quantidade_17')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_18')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_18')?.setValidators([Validators.required])
    this.formulario.get('quantidade_18')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_18')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_18')?.updateValueAndValidity()
    this.formulario.get('quantidade_18')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_19')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_19')?.setValidators([Validators.required])
    this.formulario.get('quantidade_19')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_19')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_19')?.updateValueAndValidity()
    this.formulario.get('quantidade_19')?.updateValueAndValidity()

    this.formulario.get('c_digo_produto_20')?.setValidators([Validators.required])
    this.formulario.get('descri_o_produto_20')?.setValidators([Validators.required])
    this.formulario.get('quantidade_20')?.setValidators([Validators.required])

    this.formulario.get('c_digo_produto_20')?.updateValueAndValidity()
    this.formulario.get('descri_o_produto_20')?.updateValueAndValidity()
    this.formulario.get('quantidade_20')?.updateValueAndValidity()

  }


  limpaCampos1(){
    this.formulario.get('c_digo_produto_2')?.setValue('')
    this.formulario.get('descri_o_produto_2')?.setValue('')
    this.formulario.get('quantidade_2')?.setValue('')
    this.formulario.get('lote_2')?.setValue('')

    this.formulario.get('c_digo_produto_2')?.markAsUntouched()
    this.formulario.get('descri_o_produto_2')?.markAsUntouched()
    this.formulario.get('quantidade_2')?.markAsUntouched()
    this.formulario.get('lote_2')?.markAsUntouched()

    this.formulario.get('c_digo_produto_3')?.setValue('')
    this.formulario.get('descri_o_produto_3')?.setValue('')
    this.formulario.get('quantidade_3')?.setValue('')
    this.formulario.get('lote_3')?.setValue('')

    this.formulario.get('c_digo_produto_3')?.markAsUntouched()
    this.formulario.get('descri_o_produto_3')?.markAsUntouched()
    this.formulario.get('quantidade_3')?.markAsUntouched()
    this.formulario.get('lote_3')?.markAsUntouched()

    this.formulario.get('c_digo_produto_4')?.setValue('')
    this.formulario.get('descri_o_produto_4')?.setValue('')
    this.formulario.get('quantidade_4')?.setValue('')
    this.formulario.get('lote_4')?.setValue('')

    this.formulario.get('c_digo_produto_4')?.markAsUntouched()
    this.formulario.get('descri_o_produto_4')?.markAsUntouched()
    this.formulario.get('quantidade_4')?.markAsUntouched()
    this.formulario.get('lote_4')?.markAsUntouched()

    this.formulario.get('c_digo_produto_5')?.setValue('')
    this.formulario.get('descri_o_produto_5')?.setValue('')
    this.formulario.get('quantidade_5')?.setValue('')
    this.formulario.get('lote_5')?.setValue('')

    this.formulario.get('c_digo_produto_5')?.markAsUntouched()
    this.formulario.get('descri_o_produto_5')?.markAsUntouched()
    this.formulario.get('quantidade_5')?.markAsUntouched()
    this.formulario.get('lote_5')?.markAsUntouched()

    this.formulario.get('c_digo_produto_6')?.setValue('')
    this.formulario.get('descri_o_produto_6')?.setValue('')
    this.formulario.get('quantidade_6')?.setValue('')
    this.formulario.get('lote_6')?.setValue('')

    this.formulario.get('c_digo_produto_6')?.markAsUntouched()
    this.formulario.get('descri_o_produto_6')?.markAsUntouched()
    this.formulario.get('quantidade_6')?.markAsUntouched()
    this.formulario.get('lote_6')?.markAsUntouched()

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }
  
  limpaCampos2(){

    this.formulario.get('c_digo_produto_3')?.setValue('')
    this.formulario.get('descri_o_produto_3')?.setValue('')
    this.formulario.get('quantidade_3')?.setValue('')
    this.formulario.get('lote_3')?.setValue('')

    this.formulario.get('c_digo_produto_3')?.markAsUntouched()
    this.formulario.get('descri_o_produto_3')?.markAsUntouched()
    this.formulario.get('quantidade_3')?.markAsUntouched()
    this.formulario.get('lote_3')?.markAsUntouched()

    this.formulario.get('c_digo_produto_4')?.setValue('')
    this.formulario.get('descri_o_produto_4')?.setValue('')
    this.formulario.get('quantidade_4')?.setValue('')
    this.formulario.get('lote_4')?.setValue('')

    this.formulario.get('c_digo_produto_4')?.markAsUntouched()
    this.formulario.get('descri_o_produto_4')?.markAsUntouched()
    this.formulario.get('quantidade_4')?.markAsUntouched()
    this.formulario.get('lote_4')?.markAsUntouched()

    this.formulario.get('c_digo_produto_5')?.setValue('')
    this.formulario.get('descri_o_produto_5')?.setValue('')
    this.formulario.get('quantidade_5')?.setValue('')
    this.formulario.get('lote_5')?.setValue('')

    this.formulario.get('c_digo_produto_5')?.markAsUntouched()
    this.formulario.get('descri_o_produto_5')?.markAsUntouched()
    this.formulario.get('quantidade_5')?.markAsUntouched()
    this.formulario.get('lote_5')?.markAsUntouched()

    this.formulario.get('c_digo_produto_6')?.setValue('')
    this.formulario.get('descri_o_produto_6')?.setValue('')
    this.formulario.get('quantidade_6')?.setValue('')
    this.formulario.get('lote_6')?.setValue('')

    this.formulario.get('c_digo_produto_6')?.markAsUntouched()
    this.formulario.get('descri_o_produto_6')?.markAsUntouched()
    this.formulario.get('quantidade_6')?.markAsUntouched()
    this.formulario.get('lote_6')?.markAsUntouched()

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos3(){

    this.formulario.get('c_digo_produto_4')?.setValue('')
    this.formulario.get('descri_o_produto_4')?.setValue('')
    this.formulario.get('quantidade_4')?.setValue('')
    this.formulario.get('lote_4')?.setValue('')

    this.formulario.get('c_digo_produto_4')?.markAsUntouched()
    this.formulario.get('descri_o_produto_4')?.markAsUntouched()
    this.formulario.get('quantidade_4')?.markAsUntouched()
    this.formulario.get('lote_4')?.markAsUntouched()

    this.formulario.get('c_digo_produto_5')?.setValue('')
    this.formulario.get('descri_o_produto_5')?.setValue('')
    this.formulario.get('quantidade_5')?.setValue('')
    this.formulario.get('lote_5')?.setValue('')

    this.formulario.get('c_digo_produto_5')?.markAsUntouched()
    this.formulario.get('descri_o_produto_5')?.markAsUntouched()
    this.formulario.get('quantidade_5')?.markAsUntouched()
    this.formulario.get('lote_5')?.markAsUntouched()

    this.formulario.get('c_digo_produto_6')?.setValue('')
    this.formulario.get('descri_o_produto_6')?.setValue('')
    this.formulario.get('quantidade_6')?.setValue('')
    this.formulario.get('lote_6')?.setValue('')

    this.formulario.get('c_digo_produto_6')?.markAsUntouched()
    this.formulario.get('descri_o_produto_6')?.markAsUntouched()
    this.formulario.get('quantidade_6')?.markAsUntouched()
    this.formulario.get('lote_6')?.markAsUntouched()

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos4(){

    this.formulario.get('c_digo_produto_5')?.setValue('')
    this.formulario.get('descri_o_produto_5')?.setValue('')
    this.formulario.get('quantidade_5')?.setValue('')
    this.formulario.get('lote_5')?.setValue('')

    this.formulario.get('c_digo_produto_5')?.markAsUntouched()
    this.formulario.get('descri_o_produto_5')?.markAsUntouched()
    this.formulario.get('quantidade_5')?.markAsUntouched()
    this.formulario.get('lote_5')?.markAsUntouched()

    this.formulario.get('c_digo_produto_6')?.setValue('')
    this.formulario.get('descri_o_produto_6')?.setValue('')
    this.formulario.get('quantidade_6')?.setValue('')
    this.formulario.get('lote_6')?.setValue('')

    this.formulario.get('c_digo_produto_6')?.markAsUntouched()
    this.formulario.get('descri_o_produto_6')?.markAsUntouched()
    this.formulario.get('quantidade_6')?.markAsUntouched()
    this.formulario.get('lote_6')?.markAsUntouched()

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos5(){

    this.formulario.get('c_digo_produto_6')?.setValue('')
    this.formulario.get('descri_o_produto_6')?.setValue('')
    this.formulario.get('quantidade_6')?.setValue('')
    this.formulario.get('lote_6')?.setValue('')

    this.formulario.get('c_digo_produto_6')?.markAsUntouched()
    this.formulario.get('descri_o_produto_6')?.markAsUntouched()
    this.formulario.get('quantidade_6')?.markAsUntouched()
    this.formulario.get('lote_6')?.markAsUntouched()

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos6(){

    this.formulario.get('c_digo_produto_7')?.setValue('')
    this.formulario.get('descri_o_produto_7')?.setValue('')
    this.formulario.get('quantidade_7')?.setValue('')
    this.formulario.get('lote_7')?.setValue('')
    
    this.formulario.get('c_digo_produto_7')?.markAsUntouched()
    this.formulario.get('descri_o_produto_7')?.markAsUntouched()
    this.formulario.get('quantidade_7')?.markAsUntouched()
    this.formulario.get('lote_7')?.markAsUntouched()

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos7(){

    this.formulario.get('c_digo_produto_8')?.setValue('')
    this.formulario.get('descri_o_produto_8')?.setValue('')
    this.formulario.get('quantidade_8')?.setValue('')
    this.formulario.get('lote_8')?.setValue('')

    this.formulario.get('c_digo_produto_8')?.markAsUntouched()
    this.formulario.get('descri_o_produto_8')?.markAsUntouched()
    this.formulario.get('quantidade_8')?.markAsUntouched()
    this.formulario.get('lote_8')?.markAsUntouched()

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos8(){

    this.formulario.get('c_digo_produto_9')?.setValue('')
    this.formulario.get('descri_o_produto_9')?.setValue('')
    this.formulario.get('quantidade_9')?.setValue('')
    this.formulario.get('lote_9')?.setValue('')

    this.formulario.get('c_digo_produto_9')?.markAsUntouched()
    this.formulario.get('descri_o_produto_9')?.markAsUntouched()
    this.formulario.get('quantidade_9')?.markAsUntouched()
    this.formulario.get('lote_9')?.markAsUntouched()

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos9(){

    this.formulario.get('c_digo_produto_10')?.setValue('')
    this.formulario.get('descri_o_produto_10')?.setValue('')
    this.formulario.get('quantidade_10')?.setValue('')
    this.formulario.get('lote_10')?.setValue('')

    this.formulario.get('c_digo_produto_10')?.markAsUntouched()
    this.formulario.get('descri_o_produto_10')?.markAsUntouched()
    this.formulario.get('quantidade_10')?.markAsUntouched()
    this.formulario.get('lote_10')?.markAsUntouched()

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos10(){

    this.formulario.get('c_digo_produto_11')?.setValue('')
    this.formulario.get('descri_o_produto_11')?.setValue('')
    this.formulario.get('quantidade_11')?.setValue('')
    this.formulario.get('lote_11')?.setValue('')

    this.formulario.get('c_digo_produto_11')?.markAsUntouched()
    this.formulario.get('descri_o_produto_11')?.markAsUntouched()
    this.formulario.get('quantidade_11')?.markAsUntouched()
    this.formulario.get('lote_11')?.markAsUntouched()

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos11(){

    this.formulario.get('c_digo_produto_12')?.setValue('')
    this.formulario.get('descri_o_produto_12')?.setValue('')
    this.formulario.get('quantidade_12')?.setValue('')
    this.formulario.get('lote_12')?.setValue('')

    this.formulario.get('c_digo_produto_12')?.markAsUntouched()
    this.formulario.get('descri_o_produto_12')?.markAsUntouched()
    this.formulario.get('quantidade_12')?.markAsUntouched()
    this.formulario.get('lote_12')?.markAsUntouched()

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos12(){

    this.formulario.get('c_digo_produto_13')?.setValue('')
    this.formulario.get('descri_o_produto_13')?.setValue('')
    this.formulario.get('quantidade_13')?.setValue('')
    this.formulario.get('lote_13')?.setValue('')

    this.formulario.get('c_digo_produto_13')?.markAsUntouched()
    this.formulario.get('descri_o_produto_13')?.markAsUntouched()
    this.formulario.get('quantidade_13')?.markAsUntouched()
    this.formulario.get('lote_13')?.markAsUntouched()

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos13(){

    this.formulario.get('c_digo_produto_14')?.setValue('')
    this.formulario.get('descri_o_produto_14')?.setValue('')
    this.formulario.get('quantidade_14')?.setValue('')
    this.formulario.get('lote_14')?.setValue('')

    this.formulario.get('c_digo_produto_14')?.markAsUntouched()
    this.formulario.get('descri_o_produto_14')?.markAsUntouched()
    this.formulario.get('quantidade_14')?.markAsUntouched()
    this.formulario.get('lote_14')?.markAsUntouched()

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos14(){

    this.formulario.get('c_digo_produto_15')?.setValue('')
    this.formulario.get('descri_o_produto_15')?.setValue('')
    this.formulario.get('quantidade_15')?.setValue('')
    this.formulario.get('lote_15')?.setValue('')

    this.formulario.get('c_digo_produto_15')?.markAsUntouched()
    this.formulario.get('descri_o_produto_15')?.markAsUntouched()
    this.formulario.get('quantidade_15')?.markAsUntouched()
    this.formulario.get('lote_15')?.markAsUntouched()

    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos15(){
    
    this.formulario.get('c_digo_produto_16')?.setValue('')
    this.formulario.get('descri_o_produto_16')?.setValue('')
    this.formulario.get('quantidade_16')?.setValue('')
    this.formulario.get('lote_16')?.setValue('')

    this.formulario.get('c_digo_produto_16')?.markAsUntouched()
    this.formulario.get('descri_o_produto_16')?.markAsUntouched()
    this.formulario.get('quantidade_16')?.markAsUntouched()
    this.formulario.get('lote_16')?.markAsUntouched()

    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos16(){
    
    this.formulario.get('c_digo_produto_17')?.setValue('')
    this.formulario.get('descri_o_produto_17')?.setValue('')
    this.formulario.get('quantidade_17')?.setValue('')
    this.formulario.get('lote_17')?.setValue('')

    this.formulario.get('c_digo_produto_17')?.markAsUntouched()
    this.formulario.get('descri_o_produto_17')?.markAsUntouched()
    this.formulario.get('quantidade_17')?.markAsUntouched()
    this.formulario.get('lote_17')?.markAsUntouched()

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos17(){

    this.formulario.get('c_digo_produto_18')?.setValue('')
    this.formulario.get('descri_o_produto_18')?.setValue('')
    this.formulario.get('quantidade_18')?.setValue('')
    this.formulario.get('lote_18')?.setValue('')

    this.formulario.get('c_digo_produto_18')?.markAsUntouched()
    this.formulario.get('descri_o_produto_18')?.markAsUntouched()
    this.formulario.get('quantidade_18')?.markAsUntouched()
    this.formulario.get('lote_18')?.markAsUntouched()

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos18(){

    this.formulario.get('c_digo_produto_19')?.setValue('')
    this.formulario.get('descri_o_produto_19')?.setValue('')
    this.formulario.get('quantidade_19')?.setValue('')
    this.formulario.get('lote_19')?.setValue('')

    this.formulario.get('c_digo_produto_19')?.markAsUntouched()
    this.formulario.get('descri_o_produto_19')?.markAsUntouched()
    this.formulario.get('quantidade_19')?.markAsUntouched()
    this.formulario.get('lote_19')?.markAsUntouched()

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  limpaCampos19(){

    this.formulario.get('c_digo_produto_20')?.setValue('')
    this.formulario.get('descri_o_produto_20')?.setValue('')
    this.formulario.get('quantidade_20')?.setValue('')
    this.formulario.get('lote_20')?.setValue('')

    this.formulario.get('c_digo_produto_20')?.markAsUntouched()
    this.formulario.get('descri_o_produto_20')?.markAsUntouched()
    this.formulario.get('quantidade_20')?.markAsUntouched()
    this.formulario.get('lote_20')?.markAsUntouched()

  }

  
  






}
