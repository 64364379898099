import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-pdfpopup',
  templateUrl: './pdfpopup.component.html',
  styleUrls: ['./pdfpopup.component.css']
})
export class PdfpopupComponent implements OnInit {


  @Input() url:string = ''
  @Input() descricao:string = ''
  @Input() titulo: string = ''
  @Input() link: string = ''
  @Input() complemento: string =''
  @Input() subtitulo: string =''
  @Input() subtitulo_2: string =''
  @Input() tipo_de_link:string = ''
  @Input() id_box:string = ''

  constructor() { }

  ngOnInit(): void {
  }



  //método que irá exibir o popup contendo o Pdf

  async showPdfPopup(pdfFilename: string) {
    const pdfUrl = `../../../assets/${pdfFilename}`;

    const { value } = await Swal.fire({
      html: `
        <embed src="${pdfUrl}" width="100%" height="600px" />
      `,
      width: '80%',
      heightAuto: true,
      showConfirmButton: true,
      confirmButtonText: 'Fechar',
      confirmButtonColor: 'green'
    });
  }


}
