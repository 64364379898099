import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField } from '../form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFileComponent),
    multi: true
  }]
})

export class SelectFileComponent implements ControlValueAccessor {
  @Input('placeholder') placeholder: string = "";
  data: FormField = { name: "", type: "attachment", files: [], label: "" };
  textInput: string = "";
  disabled: boolean = false;
  del: boolean = false;
  fnRegisterOnChange!: (value: any) => void;
  fnRegisterOnTouched!: () => void;

  saveAttachment(values: any): void {
    this.data.files = !!this.data?.files?.length ? this.data.files : [];
    for (var i = 0; i < values.length; i++) {
      this.data.files.push(values[i]);
    }
    this.fnRegisterOnTouched();
    this.fnRegisterOnChange(this.data?.files);
  }

  removeAttachment(file: File) {
    if (!this.data.files?.length) {
      return;
    }
    const index: number = this.data.files.indexOf(file);
    this.data.files.splice(index, 1);
    Swal.fire({
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500,
    });
    this.fnRegisterOnTouched();
    this.fnRegisterOnChange(this.data?.files);
  }



  writeValue(data: any): void {
    // if((!data  && !this.del) || typeof data == 'string') {
    //   this.del = true;
    //     return;
    // }
    if (!!data?.name) {
      this.data = data;
      this.data = {
        ...data, files: []
      }
      setTimeout(() => {
        this.fnRegisterOnChange([]);
      }, 500);

    } else if (typeof data == "object") {
      this.data.files = data
    }
    if (data == null) {
      this.textInput = "";
    }
  }
  registerOnChange(fn: any): void {
    this.fnRegisterOnChange = fn;

  }
  registerOnTouched(fn: any): void {
    this.fnRegisterOnTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
