import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecursosHumanosRoutingModule } from './recursos-humanos-routing.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderModule } from '../componentes/header/header.module';
import { FooterModule } from '../componentes/footer/footer.module';
import { VagasComponent } from './gestao-de-vagas/vagas/vagas.component';
import { BodyComponent } from './body/body.component';
import { HomeRhComponent } from './home-rh/home-rh.component';
import { CalculoPlrComponent } from './calculo-plr/calculo-plr/calculo-plr.component';
import { NbsBannerModule } from '../componentes/nbs-banner/nbs-banner.module';
import { DuvidasSobrePagamentoComponent } from './formularios/duvidas-sobre-pagamento/duvidas-sobre-pagamento.component';
import { DuvidasSobrePontoComponent } from './formularios/duvidas-sobre-ponto/duvidas-sobre-ponto.component';
import { DuvidasSobreInformeDeRendimentosComponent } from './formularios/duvidas-sobre-informe-de-rendimentos/duvidas-sobre-informe-de-rendimentos.component';
import { DuvidasSobreBeneficiosComponent } from './formularios/duvidas-sobre-beneficios/duvidas-sobre-beneficios.component';
import { MedicinaESegurancaComponent } from './formularios/medicina-e-seguranca/medicina-e-seguranca.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule } from 'ngx-mask';
import { FormulariosTituloModule } from '../componentes/formularios-titulo/formularios-titulo.module';
import { MensagemModule } from '../componentes/mensagem/mensagem.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlteracaoDadosBancariosComponent } from './formularios/alteracao-dados-bancarios/alteracao-dados-bancarios.component';
import { PontoEletronicoComponent } from './formularios/ponto-eletronico/ponto-eletronico.component';
import { CancelamentoDeDesligamentoComponent } from './formularios/cancelamento-de-desligamento/cancelamento-de-desligamento.component';
import { DelegarGestaoComponent } from './formularios/delegar-gestao/delegar-gestao.component';
import { AdmissaoComponent } from './formularios/admissao/admissao.component';
import { BeneficiosComponent } from './formularios/beneficios/beneficios.component';
import { RescisaoComponent } from './formularios/rescisao/rescisao.component';
import { FormMatModule } from '../componentes/formMat/form.module';
import { MovimentacaoComponent } from './formularios/movimentacao/movimentacao.component';
import { AtendimentoDeDuvidasComponent } from './formularios/atendimento-de-duvidas/atendimento-de-duvidas.component';
import { MovimentacaoUoPosicaoCargo } from './movimentacao-uo-posicao-cargo/movimentacao-uo-posicao-cargo.component';
import { AtendimentoImpostoDeRendaComponent } from './formularios/atendimento-imposto-de-renda/atendimento-imposto-de-renda.component';
import { FeriasComponent } from './formularios/ferias/ferias.component';


@NgModule({
  declarations: [
    SidenavComponent,
    VagasComponent,
    BodyComponent,
    HomeRhComponent,
    CalculoPlrComponent,
    DuvidasSobrePagamentoComponent,
    DuvidasSobrePontoComponent,
    DuvidasSobreInformeDeRendimentosComponent,
    DuvidasSobreBeneficiosComponent,
    MedicinaESegurancaComponent,
    AlteracaoDadosBancariosComponent,
    PontoEletronicoComponent,
    CancelamentoDeDesligamentoComponent,
    DelegarGestaoComponent,
    AdmissaoComponent,
    BeneficiosComponent,
    RescisaoComponent,
    DelegarGestaoComponent,
    MovimentacaoComponent,
    MovimentacaoUoPosicaoCargo,
    AtendimentoDeDuvidasComponent,
    AtendimentoImpostoDeRendaComponent,
    FeriasComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RecursosHumanosRoutingModule,
    HeaderModule,
    FooterModule,
    NbsBannerModule,
    FormulariosTituloModule,
    MensagemModule,
    NgxSpinnerModule,
    FormMatModule,
    NgxPaginationModule,
    NgxMaskModule.forChild(),

  ]
})
export class RecursosHumanosModule { }
