import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from '../servicos-financeiros/menu/menu.component';
import { AuthGuardService } from './../autenticacao/auth-guard/auth-guard.service';


const routes: Routes = [{
  path:'menu',
  component: MenuComponent
}, {
  path:'home',
  component: HomeComponent,
  canActivate: [AuthGuardService]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class HomeRoutingModule { }
