export const finalidade = [
  {
    nome:''
  },
  {
    nome:'Descrição do Produto',


  },
  {
    nome:'Lote',


  },
  {
    nome:'Peso para transporte',
  },
  {
    nome:'Dados do transporte',
  },
  {
    nome: 'Vencimento',
  }
]
