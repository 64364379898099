export const codigoSap =[
  {
    nome:''
  },
  {
    nome:'7806 - Utilfertil'
  },
  {
    nome:'7805 - Agrium'
  },
  {
    nome:'7813 - Agrichem'
  },
  {
    nome:'7820 - APMS'
  },
  {
    nome:'7823 - FAG'
  },
  {
    nome:'7822 - FCR'
  },
  {
    nome:'7821 - Sementes'
  }

]
