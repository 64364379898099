export const formasDePagamento = [
  {
    forma: ''
  },
  {
    forma: 'À vista'
  },
  {
    forma: 'À prazo'
  },
  {
    forma: 'Parcelado'
  }
]
