export const status = [
  {

  },
  {
    nome: 'Aguardando Atendimento'
  },
  {
    nome: 'Em Andamento'
  },
  {
    nome:'Pendência com Solicitante'
  },
  {
    nome:'Concluído'
  },
  {
    nome:'Cancelado'
  }
]
