import { Component, OnInit } from '@angular/core';
import { AzureService } from './../../autenticacao/azure.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from './../../autenticacao/usuario/user-infos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenServiceService } from 'src/app/services/token-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { Groups } from './../../autenticacao/usuario/groups';
import Swal from 'sweetalert2';
import { ControleDeVersoesService } from '../../services/controle-de-versoes.service';
import { environment } from 'src/environments/environment';

// const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
// type ProfileType = {
//   givenName?: string,
//   surname?: string,
//   userPrincipalName?: string,
//   id?: string
// };

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  email : string = ''

  constructor(private router:Router, 
              private msalService: MsalService, 
              private userInfo: UserInfosService, 
              private spinner: NgxSpinnerService, 
              private route: ActivatedRoute,) { }

  ngOnInit(){ }

}
