import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-admissao',
  templateUrl: './admissao.component.html',
  styleUrls: ['./admissao.component.css']
})
export class AdmissaoComponent implements OnInit {


  fields: FormField[] = [];

  constructor() {}

  ngOnInit(): void {
    this.fields = [
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        "validators": Validators.required,
      },
      {
        "label": "E-mail do solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        "validators": [Validators.required, Validators.email],
      },
      {
        "label": "Nome do Candidato",
        "name": "nome_do_candidato",
        "type": "text",
        "validators": Validators.required,
      },
      {
        "label": "E-mail do Candidato",
        "name": "email_do_candidato",
        "type": "email",
        "validators": [Validators.required, Validators.email],
      },
      {
        "label": "Contato",
        "name": "n_mero_de_telefone",
        "type": "tel"
      },
      {
        type: "select",
        name: "empresa",
        label: "Empresa",
        validators: Validators.required,
        descricao: [
          {
            type: "text",
          value: "As empresas acima tem os seguintes nomes nessa lista:",

          },
          {
            type: "text",
          value: "TecAgro = Fronteira Comércio;"

          },
          {
            type: "text",
          value: "Nutrien HQ e UtilFertil = Nutrien Soluções Agricolas."
          }
        ],
        select: [
          {
            type: "option",
            name: "AGRICHEM DO BRASIL SA",
            value: "AGRICHEM DO BRASIL SA",
          },
          {
            type: "option",
            name: "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
            value: "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
          },
          {
            type: "option",
            name: "CONFIANÇA AGRÍCOLA LTDA",
            value: "CONFIANÇA AGRÍCOLA LTDA",
          },
          {
            type: "option",
            name: "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
            value: "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
          },
          // {
          //   type: "option",
          //   name: "MARCA AGRO MERCANTIL LTDA",
          //   value: "MARCA AGRO MERCANTIL LTDA",
          // },
          {
            type: "option",
            name: "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
            value: "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
          },
          {
            type: "option",
            name: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
            value: "SAFRARRICA COMERCIO E REPRESENTACOES DE PRODUTOS AGRICOLAS LTDA",
          },
          {
            type: "option",
            name: "SEMENTES GOIÁS LTDA",
            value: "SEMENTES GOIÁS LTDA",
          },
          {
            type: "option",
            name: "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
            value: "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
          },
        ]
      },
      {
        "label": "Cargo",
        "name": "cargo",
        "type": "connector",
        "connector": "CBO",
        selectOrdenar: "asc",
        validators: Validators.required,
      },
      {
        "label": "Carta oferta",
        "name": "carta_oferta",
        "type": "attachment",
        "validators": Validators.required,
      },
      {
        "type": "radioHorizontal",
        "label": "Data de Admissão encontrada? ",
        "name": "data_de_admiss_o_encontrada",
        validators: Validators.required,
        "value": "Sim",
        select: [
          {
            type: "option",
            name: "Sim",
            value: "Sim",
            fields: [

              {
                "label": "Data de Admissão",
                "name": "data_de_admiss_o",
                "type": "connector",
                connector: "Data Admissão",
                validators: Validators.required,
              },
            ]
          },
          {
            type: "option",
            name: "Não",
            value: "Não",
            fields: [
              {
                "label": "Nova data de admissão",
                "name": "nova_data_de_admiss_o",
                "type": "date",
                validators: Validators.required,
              },
              {
                name: "anexo_de_aprova_o_de_nova_data",
                label: "Anexo de aprovação de nova data",
                type: "attachment",
                validators: Validators.required
              }
            ]
          }
        ]
      },
      {
        "label": "Gestor Direto",
        "name": "gestor_demandante_1",
        "type": "text",
        "validators": Validators.required,
      },
      {
        "label": "E-mail do Gestor Direto",
        "name": "e_mail_do_gestor_direto",
        "type": "email",
        "validators": Validators.required,
      },
      {
        "type": "text",
        "name": "cargo_do_gestor_direto",
        "label": "Cargo do Gestor Direto",
        "validators": Validators.required
      },
      {
        "label": "Link da Posição da Gupy",
        "name": "link_da_posi_o_da_gupy",
        "type": "text",
        "validators": Validators.required,
      },
      {
        label: "Observação",
        name: "observa_o",
        type: "textArea"
      },
      {
        type: "buttonSubmit",
        name: "Enviar"
      }
    ];
  }

  enviarFormulario(event: any) {
    console.log(event);
  }
}
