export const filiais_nutrien = [
  {
    nome: ''
  },
  {nome: 'FILIAL_7001_88.305.859/0004-00_SP'},
  {nome: 'FILIAL_7002_88.305.859/0015-55_MG'},
  {nome: 'FILIAL_7003_88.305.859/0020-12_GO'},
  {nome: 'FILIAL_7004_88.305.859/0021-01_GO'},
  {nome: 'FILIAL_7005_88.305.859/0014-74_SP'},
  {nome: 'FILIAL_7006_88.305.859/0005-83_SP'},
  {nome: 'FILIAL_7007_88.305.859/0018-06_SP'},
  {nome: 'FILIAL_7008_88.305.859/0019-89_SP'},
  {nome: 'FILIAL_7009_88.305.859/0026-08_SP'},
  {nome: 'FILIAL_7010_88.305.859/0022-84_SP'},
  {nome: 'FILIAL_7011_88.305.859/0056-23_SP'},
  {nome: 'FILIAL_7012_88.305.859/0041-47_SP'},
  {nome: 'FILIAL_7013_88.305.859/0039-22_SP'},
  {nome: 'FILIAL_7014_88.305.859/0042-28_SP'},
  {nome: 'FILIAL_7015_88.305.859/0025-27_MG'},
  {nome: 'FILIAL_7016_88.305.859/0040-66_SP'},
  {nome: 'FILIAL_7017_88.305.859/0051-19_SP'},
  {nome: 'FILIAL_7018_88.305.859/0036-80_MG'},
  {nome: 'FILIAL_7019_88.305.859/0052-08_SP'},
  {nome: 'FILIAL_7020_88.305.859/0050-38_SP'},
  {nome: 'FILIAL_7021_88.305.859/0062-71_SP'},
  {nome: 'FILIAL_7022_88.305.859/0073-24_MS'},
  {nome: 'FILIAL_7023_88.305.859/0074-05_MS'},
  {nome: 'FILIAL_7024_88.305.859/0080-53_MS'},
  {nome: 'FILIAL_7025_88.305.859/0072-43_MS'},
  {nome: 'FILIAL_7026_88.305.859/0075-96_MS'},
  {nome: 'FILIAL_7027_88.305.859/0079-10_MS'},
  {nome: 'FILIAL_7028_88.305.859/0077-58_MS'},
  {nome: 'FILIAL_7029_88.305.859/0081-34_MS'},
  {nome: 'FILIAL_7030_88.305.859/0078-39_MS'},
  {nome: 'FILIAL_7031_88.305.859/0076-77_MS'},
  {nome: 'FILIAL_7032_88.305.859/0069-48_MG'},
  {nome: 'FILIAL_7033_88.305.859/0070-81_MG'},
  {nome: 'FILIAL_7034_88.305.859/0071-62_MG'},
  {nome: 'FILIAL_7035_88.305.859/0067-86_MG'},
  {nome: 'FILIAL_7036_88.305.859/0066-03_MG'},
  {nome: 'FILIAL_7037_88.305.859/0065-14_MG'},
  {nome: 'FILIAL_7039_88.305.859/0017-17_SP'},
  {nome: 'FILIAL_7040_88.305.859/0027-99_MG'},
  {nome: 'FILIAL_7041_88.305.859/0028-70_SP'},
  {nome: 'FILIAL_7042_88.305.859/0037-60_MG'},
  {nome: 'FILIAL_7043_88.305.859/0032-56_MG'},
  {nome: 'FILIAL_7044_88.305.859/0054-61_MG'},
  {nome: 'FILIAL_7045_88.305.859/0053-80_MG'},
  {nome: 'FILIAL_7046_88.305.859/0033-37_MG'},
  {nome: 'FILIAL_7047_88.305.859/0044-90_MG'},
  {nome: 'FILIAL_7048_88.305.859/0029-50_SP'},
  {nome: 'FILIAL_7049_88.305.859/0034-18_MG'},
  {nome: 'FILIAL_7050_88.305.859/0045-70_MG'},
  {nome: 'FILIAL_7051_88.305.859/0073-24_MS'},
  {nome: 'FILIAL_7052_88.305.859/0082-15_MS'},
  {nome: 'FILIAL_7078_88.305.859/0063-52_SP'},
  {nome: 'FILIAL_7079_88.305.859/0031-75_MG'},
  {nome: 'FILIAL_7080_88.305.859/0043-09_MG'},
  {nome: 'FILIAL_7081_88.305.859/0035-07_MG'},
  {nome: 'FILIAL_7082_88.305.859/0030-94_MG'},
  {nome: 'FILIAL_7083_88.305.859/0038-41_SP'},
  {nome: 'FILIAL_7084_88.305.859/0046-51_SP'},
  {nome: 'FILIAL_7085_88.305.859/0047-32_SP'},
  {nome: 'FILIAL_7086_88.305.859/0048-13_SP'},
  {nome: 'FILIAL_7087_88.305.859/0049-02_SP'},
  {nome: 'FILIAL_7088_88.305.859/0055-42_SP'},
  {nome: 'FILIAL_7089_88.305.859/0024-46_SP'},
  {nome: 'FILIAL_7090_88.305.859/0059-76_SP'},
  {nome: 'FILIAL_7091_88.305.859/0057-04_SP'},
  {nome: 'FILIAL_7092_88.305.859/0060-00_SP'},
  {nome: 'FILIAL_7093_88.305.859/0061-90_GO'},
  {nome: 'FILIAL_7094_88.305.859/0058-95_GO'},
  {nome: 'FILIAL_7095_88.305.859/0064-33_SP'},
  {nome: 'MATRIZ_7000_88.305.859/0001-50_SP'},
  {nome: 'NUTRIEN SOLUÇÕES AGRÍCOLAS'}
]
