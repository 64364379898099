<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>

<div class="container">
  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>

  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Solicitação de Pagamento Impostos Apurados sobre Remessas para Exterior"
          url="../../../assets/img/fa-menus/fiscal/impostos_apurados.png"
          titulo="Solicitação de Pagamento Impostos Apurados" subtitulo="Remessas para Exterior" link="/taxes/forms/solicitação-de-pagamentos-impostos-apurados-sobre-remessas-para-exterior">
        </app-box-link-pipe>
      </div>

      <div class="col-lg-6 ">
        <app-box-link-pipe descricao="Emissão de guias fiscais (taxas e tributos diversos)"
          url="../../../assets/img/fa-menus/fiscal/guias_fiscais.png"
          titulo="Guias fiscais" subtitulo="Emissão - taxas e tributos diversos" link="/taxes/forms/guias-fiscais-emissao-taxas-e-tributos-diversos">
        </app-box-link-pipe>
      </div>
    </div>

    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Solicitação de Pagamento IRRF sobre Contrato de Mútuos Tesouraria"
          url="../../../assets/img/fa-menus/fiscal/irrf_tesouraria.png"
          titulo="Solicitação de Pagamento IRRF ou IOF" subtitulo="Contrato de Mútuos" link="/taxes/forms/solicitacao-de-pagamento-irrf-contratos-de-mutuos-tesouraria">
        </app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Solicitação cancelamento NFs, Recusa NFs, Emissão de NFs manuais"
          url="../../../assets/img/fa-menus/fiscal/cancelamento.png"
          titulo="Cancelamento, Recusa, Emissão Manual" subtitulo="Notas Fiscais" link="/taxes/forms/cancelamento-recusa-emissao-manual-notas-fiscais">
        </app-box-link-pipe>
      </div>
    </div>

    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Suporte fiscal para lojas e demais departamentos"
          url="../../../assets/img/fa-menus/fiscal/suporte_fiscal.png"
          titulo="Suporte fiscal" link="/taxes/forms/suporte-fiscal">
        </app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Emissão de Nota Fiscal de Importação"
          url="../../../assets/img/fa-menus/fiscal/emissao_de_nf_de_importacao.png"
          titulo="Notas Fiscais de Importação" subtitulo="Emissão" link="/taxes/forms/emissao-de-nota-fiscal-de-importacao">
        </app-box-link-pipe>
      </div>
    </div>

    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Retificação de obrigações acessórias e apurações (Uso exclusivo do fiscal)"
          url="../../../assets/img/fa-menus/financas/cartao_de_credito_corporativo.png"
          titulo="Retificação de obrigações acessórias e apurações " subtitulo="(Uso exclusivo do fiscal)" link="taxes/forms/retificacao-obrigacoes-acessorias-e-apuracoes">
        </app-box-link-pipe>
      </div>
    </div>
  </div>




  <!-- <div class="itens">
  <div class="row menu-itens">
  <div class="col-lg-6">
    <app-box-link-pipe descricao="Solicitação de Pagamento Impostos Apurados sobre Remessas para Exterior"
      url="../../../assets/img/fa-menus/fiscal/impostos_apurados.png"
      titulo="Solicitação de Pagamento Impostos Apurados" subtitulo="Remessas para Exterior" link="https://app.pipefy.com/public/form/3Sd_ATrN" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>

  <div class="col-lg-6 ">
    <app-box-link-pipe descricao="Emissão de guias fiscais (taxas e tributos diversos)"
      url="../../../assets/img/fa-menus/fiscal/guias_fiscais.png"
      titulo="Guias fiscais" subtitulo="Emissão - taxas e tributos diversos" link="https://app.pipefy.com/public/form/jjF2PhTT" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>
</div>

<div class="row menu-itens">

  <div class="col-lg-6">
    <app-box-link-pipe descricao="Solicitação de Pagamento IRRF sobre Contrato de Mútuos Tesouraria"
      url="../../../assets/img/fa-menus/fiscal/irrf_tesouraria.png"
      titulo="Solicitação de Pagamento IRRF ou IOF" subtitulo="Contrato de Mútuos" link="https://app.pipefy.com/public/form/6B6NuBUW" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>

  <div class="col-lg-6">
    <app-box-link-pipe descricao="Solicitação cancelamento NFs, Recusa NFs, Emissão de NFs manuais"
      url="../../../assets/img/fa-menus/fiscal/cancelamento.png"
      titulo="Cancelamento, Recusa, Emissão Manual" subtitulo="Notas Fiscais" link="https://app.pipefy.com/public/form/u6uwJWwb" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <app-box-link-pipe descricao="Suporte fiscal para lojas e demais departamentos"
      url="../../../assets/img/fa-menus/fiscal/suporte_fiscal.png"
      titulo="Suporte fiscal" link="https://app.pipefy.com/public/form/UDx2tg_1" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>

  <div class="col-lg-6">
    <app-box-link-pipe descricao="Emissão de Nota Fiscal de Importação"
      url="../../../assets/img/fa-menus/fiscal/emissao_de_nf_de_importacao.png"
      titulo="Notas Fiscais de Importação" subtitulo="Emissão" link="https://app.pipefy.com/public/form/OUZtXsRa" tipo_de_link='externo'>
    </app-box-link-pipe>
  </div>
</div>

  </div> -->



</div>
<div class="footer">

  <app-footer link="menu"></app-footer>
</div>


