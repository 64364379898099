import { AlteracaoPedidosComponent } from './servicos-financeiros/formularios/lojas/alteracao-pedidos/alteracao-pedidos.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
 import { MsalGuard2 } from './autenticacao/local-guard/local-guard.guard';
import { AccoutingComponent } from './servicos-financeiros/accouting/accouting.component';
import { CustomerServiceComponent } from './servicos-financeiros/customer-service/customer-service.component';
import { PaymentComponent } from './servicos-financeiros/payment/payment.component';
import { TaxesComponent } from './servicos-financeiros/taxes/taxes.component';
import { MenuComponent } from './servicos-financeiros/menu/menu.component';
import { CadastroComponent } from './servicos-financeiros/cadastro/cadastro.component';
import { MenuComprasComponent } from './compras/menu-compras/menu-compras.component';
import { UrlInvalidaComponent } from './url-invalida/url-invalida.component';
import { FaqComponent } from './faq/faq.component';
import { ConhecaMaisComponent } from './conheca-mais/conheca-mais.component';
import { HomeRhComponent } from './recursos-humanos/home-rh/home-rh.component';
import { AuditoriaComponent } from './servicos-financeiros/formularios/contabilidade/auditoria/auditoria.component';
import { BaixaImobilizadoComponent } from './servicos-financeiros/formularios/contabilidade/baixa-imobilizado/baixa-imobilizado.component';
import { LancamentoContabilComponent } from './servicos-financeiros/formularios/contabilidade/lancamento-contabil/lancamento-contabil.component';
import { PedidoDeVendaEFaturamentoComponent } from './servicos-financeiros/formularios/lojas/pedido-de-venda-e-faturamento/pedido-de-venda-e-faturamento.component';
import { ServicosParaCartaoDeCreditoCorporativoComponent } from './servicos-financeiros/formularios/pagamentos/servicos-para-cartao-de-credito-corporativo/servicos-para-cartao-de-credito-corporativo.component';
import { SolicitacaoDeLancamentosDeNotasComponent } from './servicos-financeiros/formularios/pagamentos/solicitacao-de-lancamentos-de-notas/solicitacao-de-lancamentos-de-notas.component';
import { IpeComponent } from './servicos-financeiros/formularios/pagamentos/ipe/ipe.component';
import { DocumentosSuporteAuditoriaComponent } from './servicos-financeiros/formularios/fiscal/documentos-suporte-auditoria/documentos-suporte-auditoria.component';
import { DocumentosSuporteFiscalizacaoComponent } from './servicos-financeiros/formularios/fiscal/documentos-suporte-fiscalizacao/documentos-suporte-fiscalizacao.component';
import { RetificacaoObrigacoesComponent } from './servicos-financeiros/formularios/fiscal/retificacao-obrigacoes/retificacao-obrigacoes.component';
import { PagamentoIofTesourariaComponent } from './servicos-financeiros/formularios/fiscal/pagamento-iof-tesouraria/pagamento-iof-tesouraria.component';
import { PagamentoIofRhComponent } from './servicos-financeiros/formularios/fiscal/pagamento-iof-rh/pagamento-iof-rh.component';
import { RemessasExteriorComponent } from './servicos-financeiros/formularios/fiscal/remessas-exterior/remessas-exterior.component';
import { SuporteFiscalComponent } from './servicos-financeiros/formularios/fiscal/suporte-fiscal/suporte-fiscal.component';
import { PagamentoDeTaxasComponent } from './servicos-financeiros/formularios/fiscal/pagamento-de-taxas/pagamento-de-taxas.component';
import { CancelamentoNfComponent } from './servicos-financeiros/formularios/fiscal/cancelamento-nf/cancelamento-nf.component';
import { PagamentoIrrfTesourariaComponent } from './servicos-financeiros/formularios/fiscal/pagamento-irrf-tesouraria/pagamento-irrf-tesouraria.component';
import { TesteBackendComponent } from './teste-backend/teste-backend.component';
import { AjudaComponent } from './servicos-financeiros/formularios/geral/faq/ajuda.component';
import { CancelamentoDeSolicitacaoComponent } from './servicos-financeiros/formularios/geral/cancelamento-de-solicitacao/cancelamento-de-solicitacao.component';
import { EmissaoNfDeImportacaoComponent } from './servicos-financeiros/formularios/fiscal/emissao-nf-de-importacao/emissao-nf-de-importacao.component';
import { RequisicaoDeComprasComponent } from './servicos-financeiros/formularios/compras/requisicao-de-compras/requisicao-de-compras.component';
import { SuporteParaComprasComponent } from './servicos-financeiros/formularios/compras/suporte-para-compras/suporte-para-compras.component';
import { ConsultaDeChamadosComponent } from './servicos-financeiros/consulta-de-chamados/consulta-de-chamados.component';
import { DuvidasSobrePagamentoComponent } from './recursos-humanos/formularios/duvidas-sobre-pagamento/duvidas-sobre-pagamento.component';
import { GroupsResolver} from './servicos-financeiros/menu/groups.resolver'
import { DuvidasSobrePontoComponent } from './recursos-humanos/formularios/duvidas-sobre-ponto/duvidas-sobre-ponto.component';
import { DuvidasSobreInformeDeRendimentosComponent } from './recursos-humanos/formularios/duvidas-sobre-informe-de-rendimentos/duvidas-sobre-informe-de-rendimentos.component';
import { DuvidasSobreBeneficiosComponent } from './recursos-humanos/formularios/duvidas-sobre-beneficios/duvidas-sobre-beneficios.component';
import { MedicinaESegurancaComponent } from './recursos-humanos/formularios/medicina-e-seguranca/medicina-e-seguranca.component';
import { AlteracaoDadosBancariosComponent } from './recursos-humanos/formularios/alteracao-dados-bancarios/alteracao-dados-bancarios.component';
import { PontoEletronicoComponent } from './recursos-humanos/formularios/ponto-eletronico/ponto-eletronico.component';
import { CancelamentoDeDesligamentoComponent } from './recursos-humanos/formularios/cancelamento-de-desligamento/cancelamento-de-desligamento.component';
import { DelegarGestaoComponent } from './recursos-humanos/formularios/delegar-gestao/delegar-gestao.component';
import { LancamentosDeNotasMassivaComponent } from './servicos-financeiros/formularios/pagamentos/lancamentos-de-notas-massiva/lancamentos-de-notas-massiva.component';
import { BaixaDeTitulosComponent } from './servicos-financeiros/formularios/lojas/baixa-de-titulos/baixa-de-titulos.component';
import { FaturamentoComponent } from './servicos-financeiros/formularios/lojas/faturamento/faturamento.component';
import { ReembolsoBeneficiosComponent } from './servicos-financeiros/formularios/pagamentos/reembolso-beneficios/reembolso-beneficios.component';
import { AdmissaoComponent } from './recursos-humanos/formularios/admissao/admissao.component';
import { BeneficiosComponent } from './recursos-humanos/formularios/beneficios/beneficios.component';
import { FornecedoresComponent } from './servicos-financeiros/formularios/fornecedores/fornecedores.component';
import { RescisaoComponent } from './recursos-humanos/formularios/rescisao/rescisao.component';
import { IpeLancamentosComponent } from './servicos-financeiros/formularios/pagamentos/ipe-lancamentos/ipe-lancamentos.component';
import { IpeLancamentosOcrComponent } from './servicos-financeiros/formularios/pagamentos/ipe-lancamentos-ocr/ipe-lancamentos-ocr.component';
import { IpeLancamentosOcrRefatComponent } from './servicos-financeiros/formularios/pagamentos/ipe-lancamentos-ocr-refat/ipe-lancamentos-ocr-refat.component';
import { IpeLancamentosOcrRefatNewComponent } from './servicos-financeiros/formularios/pagamentos/ipe-lancamentos-ocr-refat-new/ipe-lancamentos-ocr-refat-new.component';
import { MovimentacaoComponent } from './recursos-humanos/formularios/movimentacao/movimentacao.component';
import { AtendimentoDeDuvidasComponent } from './recursos-humanos/formularios/atendimento-de-duvidas/atendimento-de-duvidas.component';
import { MovimentacaoUoPosicaoCargo } from './recursos-humanos/movimentacao-uo-posicao-cargo/movimentacao-uo-posicao-cargo.component';
import { AtendimentoImpostoDeRendaComponent } from './recursos-humanos/formularios/atendimento-imposto-de-renda/atendimento-imposto-de-renda.component';
import { FeriasComponent } from './recursos-humanos/formularios/ferias/ferias.component';
import { CadastroRenovacaoClientesAgrometrikaComponent } from './servicos-financeiros/formularios/lojas/cadastro-renovacao-clientes-agrometrika/cadastro-renovacao-clientes-agrometrika.component';
import { SolicitacaoDeLancamentosDeNotasOcrComponent } from './servicos-financeiros/formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr/solicitacao-de-lancamentos-de-notas-ocr.component';
import { AdsPerfil } from './componentes/formMat/form.enum';
import { MsalGuard } from './autenticacao/msal-guard/msal.guard';
import { AccentureComponent } from './accenture/accenture.component';
import { CriacaoContaContabilComponent } from './servicos-financeiros/formularios/contabilidade/criacao-conta-contabil/criacao-conta-contabil.component';
import { SolicitacaoDeLancamentosDeNotasComponentOcrNew } from './servicos-financeiros/formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr-new/solicitacao-de-lancamentos-de-notas-ocr-new.component';
import { SolicitacaoDeLancamentosDeNotasComponentOcrNewTeste } from './servicos-financeiros/formularios/pagamentos/solicitacao-de-lancamentos-de-notas-ocr-new-teste/solicitacao-de-lancamentos-de-notas-ocr-new-teste.component';



const routes: Routes = [
  {
    path: 'accenture',
    component: AccentureComponent
  },
  {
    path: '',
    component: MenuComponent,
    canActivate: [MsalGuard2],
    resolve:{
      groups: GroupsResolver
    }
  },
  // {
  //   path:'',
  //   component:MenuComponent,
  //   canActivate: [MsalGuard2],
  //   resolve:{
  //     groups: GroupsResolver
  //   }
  // },
  // {
  //   path:'homeProd',
  //   component:HomeComponent,
  //   canActivate:[MsalGuard2],
  // },
  {
    path: 'auth', component: MsalRedirectComponent
  },
  {
    path: 'accouting',
    component: AccoutingComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'customer-service',
    component: CustomerServiceComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes',
    component: TaxesComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'menu',
    component: MenuComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'compras',
    component: MenuComprasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'cadastro',
    component: CadastroComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'fornecedores',
    component: FornecedoresComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'conheca-mais',
    component: ConhecaMaisComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'accouting/forms/auditoria',
    component: AuditoriaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'accouting/forms/baixa-imobilizado',
    component: BaixaImobilizadoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'accouting/forms/lancamento-contabil',
    component: LancamentoContabilComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'accouting/forms/criacao-conta-contabil',
    component: CriacaoContaContabilComponent,
    canActivate: [MsalGuard2]
  },
  /// {
  ///   path: 'customer-service/forms/venda-e-faturamento-pedido',
  ///   component: PedidoDeVendaEFaturamentoComponent,
  ///   canActivate: [MsalGuard2]
  /// },
  /// {
  ///   path: 'customer-service/forms/alteracao-pedidos',
  ///   component: AlteracaoPedidosComponent,
  ///   canActivate: [MsalGuard2]
  /// },
  {
    path:'customer-service/forms/baixa-de-titulos',
    component: BaixaDeTitulosComponent,
    canActivate: [MsalGuard2]
  },
  /// {
  ///   path:'customer-service/forms/faturamento',
  ///   component: FaturamentoComponent,
  ///   canActivate: [MsalGuard2]
  /// },
  {
    path: 'payment/forms/servicos-para-cartao-de-credito-corporativo',
    component: ServicosParaCartaoDeCreditoCorporativoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'payment/forms/solicitacao-de-lancamento-de-notas',
    component: SolicitacaoDeLancamentosDeNotasComponentOcrNew,
    canActivate: [MsalGuard2]
  },
  {
    path:'lancamentos-ocr',
    component:SolicitacaoDeLancamentosDeNotasComponentOcrNew,
    canActivate: [MsalGuard2]
  },
  {
    path: 'teste',
    component: SolicitacaoDeLancamentosDeNotasOcrComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'testeIoga',
    component: SolicitacaoDeLancamentosDeNotasComponentOcrNewTeste,
    canActivate: [MsalGuard2]
  },
  {
    path: 'payment/forms/ipe',
    component: IpeComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'payment/forms/lancamento-de-notas-massiva',
    component: LancamentosDeNotasMassivaComponent,
    canActivate: [MsalGuard2]
  },

  {
    path:'payment/forms/reembolso-beneficios',
    component: ReembolsoBeneficiosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitacao-de-documentos-suporte-fiscalizacao',
    component: DocumentosSuporteFiscalizacaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitacao-de-documentos-suporte-auditoria',
    component: DocumentosSuporteAuditoriaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/retificacao-obrigacoes-acessorias-e-apuracoes',
    component: RetificacaoObrigacoesComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitação-de-pagamento-iof-sobre-contratos-de-mutuos-tesouraria',
    component: PagamentoIofTesourariaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitação-de-pagamento-iof-sobre-contratos-de-mutuos-rh',
    component: PagamentoIofRhComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitação-de-pagamentos-impostos-apurados-sobre-remessas-para-exterior',
    component: RemessasExteriorComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/suporte-fiscal',
    component: SuporteFiscalComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/solicitacao-de-pagamento-irrf-contratos-de-mutuos-tesouraria',
    component: PagamentoIrrfTesourariaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/guias-fiscais-emissao-taxas-e-tributos-diversos',
    component: PagamentoDeTaxasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/cancelamento-recusa-emissao-manual-notas-fiscais',
    component: CancelamentoNfComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'taxes/forms/emissao-de-nota-fiscal-de-importacao',
    component: EmissaoNfDeImportacaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'compras/forms/requisicao-de-compras',
    component: RequisicaoDeComprasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'compras/forms/suporte-para-compras',
    component: SuporteParaComprasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'forms/ajuda',
    component: AjudaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'forms/cancelamento-de-solicitacao',
    component: CancelamentoDeSolicitacaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'forms/consulta-de-chamado',
    component: ConsultaDeChamadosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'teste-backend',
    component: TesteBackendComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'recursos-humanos/home',
    component: HomeRhComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/duvidas-sobre-pagamentos',
    component: DuvidasSobrePagamentoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/duvidas-sobre-o-ponto',
    component: DuvidasSobrePontoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/duvidas-sobre-informe-de-rendimentos',
    component: DuvidasSobreInformeDeRendimentosComponent,
    canActivate: [MsalGuard2]
  },

  {
    path:'rh/forms/duvidas-sobre-beneficios',
    component: DuvidasSobreBeneficiosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/medicina-e-seguranca-do-trabalho',
    component: MedicinaESegurancaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/alteracao-dados-bancarios',
    component: AlteracaoDadosBancariosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/ponto-eletronico',
    component: PontoEletronicoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/cancelamento-de-desligamento',
    component: CancelamentoDeDesligamentoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/delegar-gestao',
    component: DelegarGestaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/admissao',
    component: AdmissaoComponent,
    canActivate: [MsalGuard2],
    // resolve: {
    //   GroupsResolver
    // }
    // data: {
    //   ads: [AdsPerfil.APPDEL_NBS_HROps]
    // }
  },
  {
    path:'rh/forms/rescisao',
    component: RescisaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'rh/forms/beneficios',
    component: BeneficiosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'rh/forms/movimentacao',
    component: MovimentacaoComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'rh/forms/atendimento-de-duvidas',
    component: AtendimentoDeDuvidasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'rh/forms/atendimento-imposto-de-renda',
    component: AtendimentoImpostoDeRendaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'rh/forms/movimentacao-uo-posicao-cargo',
    component: MovimentacaoUoPosicaoCargo,
    canActivate: [MsalGuard2]
  },
  {
    path: 'rh/forms/ferias',
    component: FeriasComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'payment/forms/lancamento-de-notas',
    component: IpeLancamentosComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'payment/forms/lancamento-de-notas-ocr',
    component: IpeLancamentosOcrComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'payment/forms/lancamento-de-notas-ocr-refat',
    component: IpeLancamentosOcrRefatComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'payment/forms/lancamento-de-notas-ocr-refat-new',
    component: IpeLancamentosOcrRefatNewComponent,
    canActivate: [MsalGuard2]
  },
  {
    path:'customer-service/forms/cadastro-renovacao-clientes-agrometrika',
    component: CadastroRenovacaoClientesAgrometrikaComponent,
    canActivate: [MsalGuard2]
  },
  {
    path: 'denied',
    component: UrlInvalidaComponent
  },
  {
    path: '**', pathMatch: 'full', redirectTo: ''
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
{
      initialNavigation: 'enabled',
      useHash: true
    })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
