import { AbstractControl } from '@angular/forms';

export class Validadores {

    static cpf(control: AbstractControl): { [key: string]: any } | null {
        if (!!!control.value ) {
            return null;
        }

        let value = control.value;
        value = value.replace('.', '').replace('.', '').replace('-', '');

        if (value.length < 11 && value.length > 0) {
            return {cpf: true};
        }
        if(value.length == 11) {
            return verificaCPF(value)? null : {cpf : true}
        }

        return null;
    }

    static cnpj(control: AbstractControl): { [key: string]: any } | null{
        if (!!!control.value ) {
            return null;
        }
        if (control.value.length < 14 && control.value.length > 0) {
            return {cnpj: true};
        }
        if(control.value.length >= 14) {
            return verificaCNPJ(control.value)? null : {cnpj: true}
        }

        return null;
    }
    
}
function verificaCPF(cpf: string){
    cpf = cpf.replace(/[^\d]+/g,''); // Remove caracteres não numéricos
  
    if(cpf.length !== 11) return false; // Verifica se a string tem 11 caracteres
  
    // Verifica se todos os dígitos são iguais (CPF inválido)
    for(let i = 0; i < 10; i++) {
      if(cpf.charAt(i) !== cpf.charAt(i+1)) {
        break;
      }
      if(i === 9) {
        return false;
      }
    }
  
    let soma = 0;
    let resto;
  
    // Verifica o primeiro dígito verificador
    for(let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.charAt(i-1)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if(resto === 10 || resto === 11) resto = 0;
  
    if(resto !== parseInt(cpf.charAt(9))) return false;
  
    soma = 0;
  
    // Verifica o segundo dígito verificador
    for(let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.charAt(i-1)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if(resto === 10 || resto === 11) resto = 0;
  
    if(resto !== parseInt(cpf.charAt(10))) return false;
  
    return true;
  }
  
function verificaCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length != 14)
        return false;

    var tamanhoTotal = cnpj.length - 2
    var cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
    var digitosVerificadores = cnpj.substring(tamanhoTotal);
    var soma = 0;
    var pos = tamanhoTotal - 7;
    for (let i = tamanhoTotal; i >= 1; i--) {
        soma += cnpjSemDigitos.charAt(tamanhoTotal - i) as any * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitosVerificadores.charAt(0) as any)
        return false;

    tamanhoTotal = tamanhoTotal + 1;
    cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
    soma = 0;
    pos = tamanhoTotal - 7;
    for (let i = tamanhoTotal; i >= 1; i--) {
        soma += cnpjSemDigitos.charAt(tamanhoTotal - i) as any * pos--;
        if (pos < 2)
            pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitosVerificadores.charAt(1) as any)
        return false;

    return true;

}