import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';
import { AdsPerfil } from 'src/app/componentes/formMat/form.enum';

@Component({
  selector: 'app-movimentacao',
  templateUrl: './movimentacao.component.html',
  styleUrls: ['./movimentacao.component.css']
})
export class MovimentacaoComponent implements OnInit {
  fields: FormField[] = [];

  constructor() { }

  ngOnInit(): void {
    this.fields = [
      {
        "type": "select",
        "name": "tipo_de_movimenta_o",
        "label": "Tipo de movimentação:",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Alteração de Centro de Custo do Colaborador",
            "value": "Alteração de Centro de Custo do Colaborador",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_colaborador",
                "label": "Nome do colaborador",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_colaborador",
                "label": "E-mail do colaborador",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "nome_da_empresa_atual",
                "label": "Nome da Empresa atual",
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ]
              },
              {
                "type": "connector",
                "connector": "Centro de custo",
                "name": "centro_de_custo_futuro",
                "label": "Centro de custo futuro",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "categoria_do_centro_de_custo_1",
                "label": "Categoria do Centro de Custo:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "COGs",
                    "value": "COGs"
                  },
                  {
                    "type": "option",
                    "name": "SG&A",
                    "value": "SG&A"
                  },
                  {
                    "type": "option",
                    "name": "CAPEX",
                    "value": "CAPEX"
                  }
                ]
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Alteração de Nomenclatura/Gestor Superior da U.O.",
            "value": "Alteração de Nomenclatura/Gestor Superior da U.O.",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Unidade organizacional",
                "name": "unidade_organizacional_atual",
                "label": "Unidade Organizacional atual",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "gestor_da_u_o_atual",
                "label": "Gestor da U.O. atual",
                "validators": Validators.required
              },
              {
                "type": "checklistVertical",
                "name": "tipo_de_altera_o",
                "label": "Tipo de alteração",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Nomenclatura",
                    "value": "Nomenclatura",
                    "fields": [
                      {
                        "type": "text",
                        "name": "nomenclatura_u_o_futura",
                        "label": "Nomenclatura U.O. Futura",
                        "validators": Validators.required
                      }
                    ]
                  },
                  {
                    "type": "option",
                    "name": "Gestor",
                    "value": "Gestor",
                    "fields": [
                      {
                        "type": "text",
                        "name": "gestor_u_o_futuro",
                        "label": "Gestor U.O. Futura",
                        "validators": Validators.required
                      },
                    ]
                  }
                ]
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Aumento salarial com escalonamento",
            "value": "Aumento salarial com escalonamento",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_colaborador",
                "label": "Nome do colaborador",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_colaborador",
                "label": "E-mail do colaborador",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "nome_da_empresa_atual",
                "label": "Nome da Empresa atual",
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ]
              },
              {
                "type": "currency",
                "name": "sal_rio_futuro",
                "label": "Salário futuro",
                "validators": Validators.required
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Inclusão de Centro de Custo",
            "value": "Inclusão de Centro de Custo",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "empresa_que_recebe_o_centro_de_custo",
                "label": "Empresa que recebe o Centro de Custo",
                "validators": Validators.required
              },
              {
                "type": "number",
                "name": "novo_centro_de_custo",
                "label": "Novo Centro de Custo",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "categoria_do_centro_de_custo_1",
                "label": "Categoria do Centro de Custo:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "COGs",
                    "value": "COGs"
                  },
                  {
                    "type": "option",
                    "name": "SG&A",
                    "value": "SG&A"
                  },
                  {
                    "type": "option",
                    "name": "CAPEX",
                    "value": "CAPEX"
                  }
                ]
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Promoção com escalonamento",
            "value": "Promoção com escalonamento",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_colaborador",
                "label": "Nome do colaborador",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_colaborador",
                "label": "E-mail do colaborador",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "nome_da_empresa_atual",
                "label": "Nome da Empresa atual",
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ],
              },
              {
                "type": "select",
                "name": "copy_of_nome_da_posi_o_atual",
                "label": "Nível Hierárquico futuro",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Presidente",
                    "value": "Presidente"
                  },
                  {
                    "type": "option",
                    "name": "VP",
                    "value": "VP"
                  },
                  {
                    "type": "option",
                    "name": "Diretor",
                    "value": "Diretor"
                  },
                  {
                    "type": "option",
                    "name": "Gerente Sr",
                    "value": "Gerente Sr"
                  },
                  {
                    "type": "option",
                    "name": "Gerente",
                    "value": "Gerente"
                  },
                  {
                    "type": "option",
                    "name": "Especialista",
                    "value": "Especialista"
                  },
                  {
                    "type": "option",
                    "name": "Coordenador",
                    "value": "Coordenador"
                  },
                  {
                    "type": "option",
                    "name": "Consultor",
                    "value": "Consultor"
                  },
                  {
                    "type": "option",
                    "name": "Supervisor",
                    "value": "Supervisor"
                  },
                  {
                    "type": "option",
                    "name": "Tecnico",
                    "value": "Tecnico"
                  },
                  {
                    "type": "option",
                    "name": "Senior",
                    "value": "Senior"
                  },
                  {
                    "type": "option",
                    "name": "Pleno",
                    "value": "Pleno"
                  },
                  {
                    "type": "option",
                    "name": "Junior",
                    "value": "Junior"
                  },
                  {
                    "type": "option",
                    "name": "AGD",
                    "value": "AGD"
                  },
                  {
                    "type": "option",
                    "name": "Assistente",
                    "value": "Assistente"
                  },
                  {
                    "type": "option",
                    "name": "Auxiliar",
                    "value": "Auxiliar"
                  },
                  {
                    "type": "option",
                    "name": "Lider",
                    "value": "Lider"
                  },
                  {
                    "type": "option",
                    "name": "Encarregado",
                    "value": "Encarregado"
                  },
                  {
                    "type": "option",
                    "name": "Promotor",
                    "value": "Promotor"
                  },
                  {
                    "type": "option",
                    "name": "Trainee",
                    "value": "Trainee"
                  },
                  {
                    "type": "option",
                    "name": "Estagiario",
                    "value": "Estagiario"
                  },
                  {
                    "type": "option",
                    "name": "Aprendiz",
                    "value": "Aprendiz"
                  },
                  {
                    "type": "option",
                    "name": "Operacional",
                    "value": "Operacional"
                  }
                ]
              },
              {
                "type": "connector",
                "connector": "CBO",
                "name": "cargo_futuro",
                "label": "Cargo futuro"
              },
              {
                "type": "currency",
                "name": "sal_rio_futuro",
                "label": "Salário futuro",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "novo_n_mero_de_posi_o",
                "label": "Novo Número de Posição",
                "validators": Validators.required
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "radioHorizontal",
                "name": "copy_of_n_vel_da_u_o",
                "label": "Necessário avaliar ASO para a nova função (SHE)?",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "SIM",
                    "value": "SIM"
                  },
                  {
                    "type": "option",
                    "name": "NÃO",
                    "value": "NÃO"
                  }
                ]
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Transferência de U.O. do colaborador",
            "value": "Transferência de U.O. do colaborador",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_colaborador",
                "label": "Nome do colaborador",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_colaborador",
                "label": "E-mail do colaborador",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Unidade organizacional",
                "name": "unidade_organizacional_atual",
                "label": "Unidade Organizacional atual",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Unidade organizacional",
                "name": "unidade_organizacional_futura",
                "label": "Unidade Organizacional futura",
                "validators": Validators.required
              },
              {
                "type": "attachment",
                "name": "anexo",
                "label": "Anexo"
              },
              {
                "type": "textArea",
                "name": "observa_o",
                "label": "Observação:"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          },
          {
            "type": "option",
            "name": "Gestor intercompany",
            "value": "Gestor intercompany",
            "fields": [
              {
                "type": "select",
                "name": "data_da_movimenta_o_1",
                "label": "Data da movimentação:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "mai/23",
                    "value": "mai/23"
                  },
                  {
                    "type": "option",
                    "name": "jun/23",
                    "value": "jun/23"
                  },
                  {
                    "type": "option",
                    "name": "jul/23",
                    "value": "jul/23"
                  },
                  {
                    "type": "option",
                    "name": "ago/23",
                    "value": "ago/23"
                  },
                  {
                    "type": "option",
                    "name": "set/23",
                    "value": "set/23"
                  },
                  {
                    "type": "option",
                    "name": "out/23",
                    "value": "out/23"
                  },
                  {
                    "type": "option",
                    "name": "nov/23",
                    "value": "nov/23"
                  },
                  {
                    "type": "option",
                    "name": "dez/23",
                    "value": "dez/23"
                  },
                  {
                    "type": "option",
                    "name": "jan/24",
                    "value": "jan/24"
                  },
                  {
                    "type": "option",
                    "name": "fev/24",
                    "value": "fev/24"
                  },
                  {
                    "type": "option",
                    "name": "mar/24",
                    "value": "mar/24"
                  },
                  {
                    "type": "option",
                    "name": "abr/24",
                    "value": "abr/24"
                  },
                  {
                    "type": "option",
                    "name": "mai/24",
                    "value": "mai/24"
                  },
                  {
                    "type": "option",
                    "name": "jun/24",
                    "value": "jun/24"
                  },
                  {
                    "type": "option",
                    "name": "jul/24",
                    "value": "jul/24"
                  },
                  {
                    "type": "option",
                    "name": "ago/24",
                    "value": "ago/24"
                  },
                  {
                    "type": "option",
                    "name": "set/24",
                    "value": "set/24"
                  },
                  {
                    "type": "option",
                    "name": "out/24",
                    "value": "out/24"
                  },
                  {
                    "type": "option",
                    "name": "nov/24",
                    "value": "nov/24"
                  },
                  {
                    "type": "option",
                    "name": "dez/24",
                    "value": "dez/24"
                  },
                  {
                    "type": "option",
                    "name": "jan/25",
                    "value": "jan/25"
                  },
                  {
                    "type": "option",
                    "name": "fev/25",
                    "value": "fev/25"
                  },
                  {
                    "type": "option",
                    "name": "mar/25",
                    "value": "mar/25"
                  },
                  {
                    "type": "option",
                    "name": "abr/25",
                    "value": "abr/25"
                  },
                  {
                    "type": "option",
                    "name": "mai/25",
                    "value": "mai/25"
                  },
                  {
                    "type": "option",
                    "name": "jun/25",
                    "value": "jun/25"
                  },
                  {
                    "type": "option",
                    "name": "jul/25",
                    "value": "jul/25"
                  },
                  {
                    "type": "option",
                    "name": "ago/25",
                    "value": "ago/25"
                  },
                  {
                    "type": "option",
                    "name": "set/25",
                    "value": "set/25"
                  },
                  {
                    "type": "option",
                    "name": "out/25",
                    "value": "out/25"
                  },
                  {
                    "type": "option",
                    "name": "nov/25",
                    "value": "nov/25"
                  },
                  {
                    "type": "option",
                    "name": "dez/25",
                    "value": "dez/25"
                  }
                ]
              },
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "date",
                "name": "data_da_solicita_o",
                "label": "Data da solicitação:",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_gestor_intercompany",
                "label": "Nome do Gestor Intercompany:",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "email_do_gestor_intercompany",
                "label": "Email do Gestor Intercompany:",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "copy_of_empresa_que_ser_gestor",
                "label": "Empresa atual do Gestor:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "CASA DO ADUBO",
                    "value": "CASA DO ADUBO"
                  },
                  {
                    "type": "option",
                    "name": "MARCA AGRO",
                    "value": "MARCA AGRO"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN ARGENTINA",
                    "value": "NUTRIEN ARGENTINA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN CHILE",
                    "value": "NUTRIEN CHILE"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN URUGUAI",
                    "value": "NUTRIEN URUGUAI"
                  },
                  {
                    "type": "option",
                    "name": "SAFRA RICA",
                    "value": "SAFRA RICA"
                  }
                ]

              },
              {
                "type": "select",
                "name": "copy_of_empresa",
                "label": "País de origem:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Afeganistão",
                    "value": "Afeganistão"
                  },
                  {
                    "type": "option",
                    "name": "África do Sul",
                    "value": "África do Sul"
                  },
                  {
                    "type": "option",
                    "name": "Albânia",
                    "value": "Albânia"
                  },
                  {
                    "type": "option",
                    "name": "Alemanha",
                    "value": "Alemanha"
                  },
                  {
                    "type": "option",
                    "name": "Andorra",
                    "value": "Andorra"
                  },
                  {
                    "type": "option",
                    "name": "Angola",
                    "value": "Angola"
                  },
                  {
                    "type": "option",
                    "name": "Antígua e Barbuda",
                    "value": "Antígua e Barbuda"
                  },
                  {
                    "type": "option",
                    "name": "Arábia Saudita",
                    "value": "Arábia Saudita"
                  },
                  {
                    "type": "option",
                    "name": "Argélia",
                    "value": "Argélia"
                  },
                  {
                    "type": "option",
                    "name": "Argentina",
                    "value": "Argentina"
                  },
                  {
                    "type": "option",
                    "name": "Armênia",
                    "value": "Armênia"
                  },
                  {
                    "type": "option",
                    "name": "Austrália",
                    "value": "Austrália"
                  },
                  {
                    "type": "option",
                    "name": "Áustria",
                    "value": "Áustria"
                  },
                  {
                    "type": "option",
                    "name": "Azerbaijão",
                    "value": "Azerbaijão"
                  },
                  {
                    "type": "option",
                    "name": "Bahamas",
                    "value": "Bahamas"
                  },
                  {
                    "type": "option",
                    "name": "Bangladesh",
                    "value": "Bangladesh"
                  },
                  {
                    "type": "option",
                    "name": "Barbados",
                    "value": "Barbados"
                  },
                  {
                    "type": "option",
                    "name": "Barein",
                    "value": "Barein"
                  },
                  {
                    "type": "option",
                    "name": "Belarus",
                    "value": "Belarus"
                  },
                  {
                    "type": "option",
                    "name": "Bélgica",
                    "value": "Bélgica"
                  },
                  {
                    "type": "option",
                    "name": "Belize",
                    "value": "Belize"
                  },
                  {
                    "type": "option",
                    "name": "Benin",
                    "value": "Benin"
                  },
                  {
                    "type": "option",
                    "name": "Bolívia",
                    "value": "Bolívia"
                  },
                  {
                    "type": "option",
                    "name": "Bósnia-Herzegóvina",
                    "value": "Bósnia-Herzegóvina"
                  },
                  {
                    "type": "option",
                    "name": "Botsuana",
                    "value": "Botsuana"
                  },
                  {
                    "type": "option",
                    "name": "Brasil",
                    "value": "Brasil"
                  },
                  {
                    "type": "option",
                    "name": "Brunei",
                    "value": "Brunei"
                  },
                  {
                    "type": "option",
                    "name": "Bulgária",
                    "value": "Bulgária"
                  },
                  {
                    "type": "option",
                    "name": "Burkina Fasso",
                    "value": "Burkina Fasso"
                  },
                  {
                    "type": "option",
                    "name": "Burundi",
                    "value": "Burundi"
                  },
                  {
                    "type": "option",
                    "name": "Butão",
                    "value": "Butão"
                  },
                  {
                    "type": "option",
                    "name": "Cabo Verde",
                    "value": "Cabo Verde"
                  },
                  {
                    "type": "option",
                    "name": "Camarões",
                    "value": "Camarões"
                  },
                  {
                    "type": "option",
                    "name": "Camboja",
                    "value": "Camboja"
                  },
                  {
                    "type": "option",
                    "name": "Canadá",
                    "value": "Canadá"
                  },
                  {
                    "type": "option",
                    "name": "Catar",
                    "value": "Catar"
                  },
                  {
                    "type": "option",
                    "name": "Cazaquistão*",
                    "value": "Cazaquistão*"
                  },
                  {
                    "type": "option",
                    "name": "Chade",
                    "value": "Chade"
                  },
                  {
                    "type": "option",
                    "name": "Chile",
                    "value": "Chile"
                  },
                  {
                    "type": "option",
                    "name": "China",
                    "value": "China"
                  },
                  {
                    "type": "option",
                    "name": "Chipre",
                    "value": "Chipre"
                  },
                  {
                    "type": "option",
                    "name": "Cingapura",
                    "value": "Cingapura"
                  },
                  {
                    "type": "option",
                    "name": "Colômbia",
                    "value": "Colômbia"
                  },
                  {
                    "type": "option",
                    "name": "Comores",
                    "value": "Comores"
                  },
                  {
                    "type": "option",
                    "name": "Congo",
                    "value": "Congo"
                  },
                  {
                    "type": "option",
                    "name": "Coreia do Norte",
                    "value": "Coreia do Norte"
                  },
                  {
                    "type": "option",
                    "name": "Coreia do Sul",
                    "value": "Coreia do Sul"
                  },
                  {
                    "type": "option",
                    "name": "Costa do Marfim",
                    "value": "Costa do Marfim"
                  },
                  {
                    "type": "option",
                    "name": "Costa Rica",
                    "value": "Costa Rica"
                  },
                  {
                    "type": "option",
                    "name": "Croácia",
                    "value": "Croácia"
                  },
                  {
                    "type": "option",
                    "name": "Cuba",
                    "value": "Cuba"
                  },
                  {
                    "type": "option",
                    "name": "Dinamarca",
                    "value": "Dinamarca"
                  },
                  {
                    "type": "option",
                    "name": "Djibuti",
                    "value": "Djibuti"
                  },
                  {
                    "type": "option",
                    "name": "Dominica",
                    "value": "Dominica"
                  },
                  {
                    "type": "option",
                    "name": "Egito*",
                    "value": "Egito*"
                  },
                  {
                    "type": "option",
                    "name": "El Salvador",
                    "value": "El Salvador"
                  },
                  {
                    "type": "option",
                    "name": "Emirados Árabes Unidos",
                    "value": "Emirados Árabes Unidos"
                  },
                  {
                    "type": "option",
                    "name": "Equador",
                    "value": "Equador"
                  },
                  {
                    "type": "option",
                    "name": "Eritreia",
                    "value": "Eritreia"
                  },
                  {
                    "type": "option",
                    "name": "Eslováquia",
                    "value": "Eslováquia"
                  },
                  {
                    "type": "option",
                    "name": "Eslovênia",
                    "value": "Eslovênia"
                  },
                  {
                    "type": "option",
                    "name": "Espanha",
                    "value": "Espanha"
                  },
                  {
                    "type": "option",
                    "name": "Estados Unidos",
                    "value": "Estados Unidos"
                  },
                  {
                    "type": "option",
                    "name": "Estônia",
                    "value": "Estônia"
                  },
                  {
                    "type": "option",
                    "name": "Etiópia",
                    "value": "Etiópia"
                  },
                  {
                    "type": "option",
                    "name": "Fiji",
                    "value": "Fiji"
                  },
                  {
                    "type": "option",
                    "name": "Filipinas",
                    "value": "Filipinas"
                  },
                  {
                    "type": "option",
                    "name": "Finlândia",
                    "value": "Finlândia"
                  },
                  {
                    "type": "option",
                    "name": "França",
                    "value": "França"
                  },
                  {
                    "type": "option",
                    "name": "Gabão",
                    "value": "Gabão"
                  },
                  {
                    "type": "option",
                    "name": "Gâmbia",
                    "value": "Gâmbia"
                  },
                  {
                    "type": "option",
                    "name": "Gana",
                    "value": "Gana"
                  },
                  {
                    "type": "option",
                    "name": "Geórgia",
                    "value": "Geórgia"
                  },
                  {
                    "type": "option",
                    "name": "Granada",
                    "value": "Granada"
                  },
                  {
                    "type": "option",
                    "name": "Grécia",
                    "value": "Grécia"
                  },
                  {
                    "type": "option",
                    "name": "Guatemala",
                    "value": "Guatemala"
                  },
                  {
                    "type": "option",
                    "name": "Guiana",
                    "value": "Guiana"
                  },
                  {
                    "type": "option",
                    "name": "Guiné",
                    "value": "Guiné"
                  },
                  {
                    "type": "option",
                    "name": "Guiné-Bissau",
                    "value": "Guiné-Bissau"
                  },
                  {
                    "type": "option",
                    "name": "Guiné-Equatorial",
                    "value": "Guiné-Equatorial"
                  },
                  {
                    "type": "option",
                    "name": "Haiti",
                    "value": "Haiti"
                  },
                  {
                    "type": "option",
                    "name": "Honduras",
                    "value": "Honduras"
                  },
                  {
                    "type": "option",
                    "name": "Hungria",
                    "value": "Hungria"
                  },
                  {
                    "type": "option",
                    "name": "Iêmen",
                    "value": "Iêmen"
                  },
                  {
                    "type": "option",
                    "name": "Ilhas Marshall",
                    "value": "Ilhas Marshall"
                  },
                  {
                    "type": "option",
                    "name": "Ilhas Salomão",
                    "value": "Ilhas Salomão"
                  },
                  {
                    "type": "option",
                    "name": "Índia",
                    "value": "Índia"
                  },
                  {
                    "type": "option",
                    "name": "Indonésia",
                    "value": "Indonésia"
                  },
                  {
                    "type": "option",
                    "name": "Irã",
                    "value": "Irã"
                  },
                  {
                    "type": "option",
                    "name": "Iraque",
                    "value": "Iraque"
                  },
                  {
                    "type": "option",
                    "name": "Irlanda",
                    "value": "Irlanda"
                  },
                  {
                    "type": "option",
                    "name": "Islândia",
                    "value": "Islândia"
                  },
                  {
                    "type": "option",
                    "name": "Israel",
                    "value": "Israel"
                  },
                  {
                    "type": "option",
                    "name": "Itália",
                    "value": "Itália"
                  },
                  {
                    "type": "option",
                    "name": "Jamaica",
                    "value": "Jamaica"
                  },
                  {
                    "type": "option",
                    "name": "Japão",
                    "value": "Japão"
                  },
                  {
                    "type": "option",
                    "name": "Jordânia",
                    "value": "Jordânia"
                  },
                  {
                    "type": "option",
                    "name": "Kiribati",
                    "value": "Kiribati"
                  },
                  {
                    "type": "option",
                    "name": "Kuwait",
                    "value": "Kuwait"
                  },
                  {
                    "type": "option",
                    "name": "Laos",
                    "value": "Laos"
                  },
                  {
                    "type": "option",
                    "name": "Lesoto",
                    "value": "Lesoto"
                  },
                  {
                    "type": "option",
                    "name": "Letônia",
                    "value": "Letônia"
                  },
                  {
                    "type": "option",
                    "name": "Líbano",
                    "value": "Líbano"
                  },
                  {
                    "type": "option",
                    "name": "Libéria",
                    "value": "Libéria"
                  },
                  {
                    "type": "option",
                    "name": "Líbia",
                    "value": "Líbia"
                  },
                  {
                    "type": "option",
                    "name": "Liechtenstein",
                    "value": "Liechtenstein"
                  },
                  {
                    "type": "option",
                    "name": "Lituânia",
                    "value": "Lituânia"
                  },
                  {
                    "type": "option",
                    "name": "Luxemburgo",
                    "value": "Luxemburgo"
                  },
                  {
                    "type": "option",
                    "name": "Macedônia",
                    "value": "Macedônia"
                  },
                  {
                    "type": "option",
                    "name": "Madagáscar",
                    "value": "Madagáscar"
                  },
                  {
                    "type": "option",
                    "name": "Malásia",
                    "value": "Malásia"
                  },
                  {
                    "type": "option",
                    "name": "Malauí",
                    "value": "Malauí"
                  },
                  {
                    "type": "option",
                    "name": "Maldivas",
                    "value": "Maldivas"
                  },
                  {
                    "type": "option",
                    "name": "Mali",
                    "value": "Mali"
                  },
                  {
                    "type": "option",
                    "name": "Malta",
                    "value": "Malta"
                  },
                  {
                    "type": "option",
                    "name": "Marrocos",
                    "value": "Marrocos"
                  },
                  {
                    "type": "option",
                    "name": "Maurício",
                    "value": "Maurício"
                  },
                  {
                    "type": "option",
                    "name": "Mauritânia",
                    "value": "Mauritânia"
                  },
                  {
                    "type": "option",
                    "name": "México",
                    "value": "México"
                  },
                  {
                    "type": "option",
                    "name": "Micronésia",
                    "value": "Micronésia"
                  },
                  {
                    "type": "option",
                    "name": "Moçambique",
                    "value": "Moçambique"
                  },
                  {
                    "type": "option",
                    "name": "Moldávia",
                    "value": "Moldávia"
                  },
                  {
                    "type": "option",
                    "name": "Mônaco",
                    "value": "Mônaco"
                  },
                  {
                    "type": "option",
                    "name": "Mongólia",
                    "value": "Mongólia"
                  },
                  {
                    "type": "option",
                    "name": "Montenegro",
                    "value": "Montenegro"
                  },
                  {
                    "type": "option",
                    "name": "Myanmar",
                    "value": "Myanmar"
                  },
                  {
                    "type": "option",
                    "name": "Namíbia",
                    "value": "Namíbia"
                  },
                  {
                    "type": "option",
                    "name": "Nauru",
                    "value": "Nauru"
                  },
                  {
                    "type": "option",
                    "name": "Nepal",
                    "value": "Nepal"
                  },
                  {
                    "type": "option",
                    "name": "Nicarágua",
                    "value": "Nicarágua"
                  },
                  {
                    "type": "option",
                    "name": "Níger",
                    "value": "Níger"
                  },
                  {
                    "type": "option",
                    "name": "Nigéria",
                    "value": "Nigéria"
                  },
                  {
                    "type": "option",
                    "name": "Noruega",
                    "value": "Noruega"
                  },
                  {
                    "type": "option",
                    "name": "Nova Zelândia",
                    "value": "Nova Zelândia"
                  },
                  {
                    "type": "option",
                    "name": "Omã",
                    "value": "Omã"
                  },
                  {
                    "type": "option",
                    "name": "Países Baixos",
                    "value": "Países Baixos"
                  },
                  {
                    "type": "option",
                    "name": "Palau",
                    "value": "Palau"
                  },
                  {
                    "type": "option",
                    "name": "Panamá",
                    "value": "Panamá"
                  },
                  {
                    "type": "option",
                    "name": "Papua Nova Guiné",
                    "value": "Papua Nova Guiné"
                  },
                  {
                    "type": "option",
                    "name": "Paquistão",
                    "value": "Paquistão"
                  },
                  {
                    "type": "option",
                    "name": "Paraguai",
                    "value": "Paraguai"
                  },
                  {
                    "type": "option",
                    "name": "Peru",
                    "value": "Peru"
                  },
                  {
                    "type": "option",
                    "name": "Polônia",
                    "value": "Polônia"
                  },
                  {
                    "type": "option",
                    "name": "Portugal",
                    "value": "Portugal"
                  },
                  {
                    "type": "option",
                    "name": "Quênia",
                    "value": "Quênia"
                  },
                  {
                    "type": "option",
                    "name": "Quirguistão",
                    "value": "Quirguistão"
                  },
                  {
                    "type": "option",
                    "name": "Quirguistão",
                    "value": "Quirguistão"
                  },
                  {
                    "type": "option",
                    "name": "Reino Unido**",
                    "value": "Reino Unido**"
                  },
                  {
                    "type": "option",
                    "name": "República Centro-Africana",
                    "value": "República Centro-Africana"
                  },
                  {
                    "type": "option",
                    "name": "República Democrática do Congo",
                    "value": "República Democrática do Congo"
                  },
                  {
                    "type": "option",
                    "name": "República Dominicana",
                    "value": "República Dominicana"
                  },
                  {
                    "type": "option",
                    "name": "República Tcheca",
                    "value": "República Tcheca"
                  },
                  {
                    "type": "option",
                    "name": "Romênia",
                    "value": "Romênia"
                  },
                  {
                    "type": "option",
                    "name": "Ruanda",
                    "value": "Ruanda"
                  },
                  {
                    "type": "option",
                    "name": "Rússia*",
                    "value": "Rússia*"
                  },
                  {
                    "type": "option",
                    "name": "Samoa",
                    "value": "Samoa"
                  },
                  {
                    "type": "option",
                    "name": "San Marino",
                    "value": "San Marino"
                  },
                  {
                    "type": "option",
                    "name": "Santa Lúcia",
                    "value": "Santa Lúcia"
                  },
                  {
                    "type": "option",
                    "name": "São Cristóvão e Névis",
                    "value": "São Cristóvão e Névis"
                  },
                  {
                    "type": "option",
                    "name": "São Tomé e Príncipe",
                    "value": "São Tomé e Príncipe"
                  },
                  {
                    "type": "option",
                    "name": "São Vicente e Granadinas",
                    "value": "São Vicente e Granadinas"
                  },
                  {
                    "type": "option",
                    "name": "Seicheles",
                    "value": "Seicheles"
                  },
                  {
                    "type": "option",
                    "name": "Senegal",
                    "value": "Senegal"
                  },
                  {
                    "type": "option",
                    "name": "Serra Leoa",
                    "value": "Serra Leoa"
                  },
                  {
                    "type": "option",
                    "name": "Sérvia",
                    "value": "Sérvia"
                  },
                  {
                    "type": "option",
                    "name": "Síria",
                    "value": "Síria"
                  },
                  {
                    "type": "option",
                    "name": "Somália",
                    "value": "Somália"
                  },
                  {
                    "type": "option",
                    "name": "Sri Lanka",
                    "value": "Sri Lanka"
                  },
                  {
                    "type": "option",
                    "name": "Suazilândia",
                    "value": "Suazilândia"
                  },
                  {
                    "type": "option",
                    "name": "Sudão",
                    "value": "Sudão"
                  },
                  {
                    "type": "option",
                    "name": "Sudão do Sul",
                    "value": "Sudão do Sul"
                  },
                  {
                    "type": "option",
                    "name": "Suécia",
                    "value": "Suécia"
                  },
                  {
                    "type": "option",
                    "name": "Suíça",
                    "value": "Suíça"
                  },
                  {
                    "type": "option",
                    "name": "Suriname",
                    "value": "Suriname"
                  },
                  {
                    "type": "option",
                    "name": "Tadjiquistão",
                    "value": "Tadjiquistão"
                  },
                  {
                    "type": "option",
                    "name": "Tailândia",
                    "value": "Tailândia"
                  },
                  {
                    "type": "option",
                    "name": "Tanzânia",
                    "value": "Tanzânia"
                  },
                  {
                    "type": "option",
                    "name": "Timor-Leste",
                    "value": "Timor-Leste"
                  },
                  {
                    "type": "option",
                    "name": "Togo",
                    "value": "Togo"
                  },
                  {
                    "type": "option",
                    "name": "Tonga",
                    "value": "Tonga"
                  },
                  {
                    "type": "option",
                    "name": "Trinidad e Tobago",
                    "value": "Trinidad e Tobago"
                  },
                  {
                    "type": "option",
                    "name": "Tunísia",
                    "value": "Tunísia"
                  },
                  {
                    "type": "option",
                    "name": "Turcomenistão",
                    "value": "Turcomenistão"
                  },
                  {
                    "type": "option",
                    "name": "Turquia",
                    "value": "Turquia"
                  },
                  {
                    "type": "option",
                    "name": "Tuvalu",
                    "value": "Tuvalu"
                  },
                  {
                    "type": "option",
                    "name": "Ucrânia",
                    "value": "Ucrânia"
                  },
                  {
                    "type": "option",
                    "name": "Uganda",
                    "value": "Uganda"
                  },
                  {
                    "type": "option",
                    "name": "Uruguai",
                    "value": "Uruguai"
                  },
                  {
                    "type": "option",
                    "name": "Uzbequistão",
                    "value": "Uzbequistão"
                  },
                  {
                    "type": "option",
                    "name": "Vanuatu",
                    "value": "Vanuatu"
                  },
                  {
                    "type": "option",
                    "name": "Venezuela",
                    "value": "Venezuela"
                  },
                  {
                    "type": "option",
                    "name": "Vietnã",
                    "value": "Vietnã"
                  },
                  {
                    "type": "option",
                    "name": "Zâmbia",
                    "value": "Zâmbia"
                  },
                  {
                    "type": "option",
                    "name": "Zimbábue",
                    "value": "Zimbábue"
                  },
                ]
              },
              {
                "type": "radioHorizontal",
                "name": "brasileiro",
                "label": "É brasileiro?",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Sim",
                    "value": "Sim",
                    "fields": [
                      {
                        "type": "cpf",
                        "name": "cpf",
                        "label": "CPF",
                        "validators": Validators.required
                      }
                    ]
                  },
                  {
                    "type": "option",
                    "name": "Não",
                    "value": "Não"
                  },
                ]
              },
              {
                "type": "date",
                "name": "data_de_nascimento",
                "label": "Data de Nascimento",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "copy_of_empresa_1",
                "label": "Empresa que será gestor:",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ]
              },
              {
                "type": "connector",
                "connector": "Unidade organizacional",
                "name": "unidade_organizacional_1",
                "label": "Unidade Organizacional:",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "l_der_do_gestor_intercompany",
                "label": "Líder do Gestor Intercompany:",
                "validators": Validators.required
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }

            ]
          },
          {
            "type": "option",
            "name": "Transferência de Empresa",
            "value": "Transferência de Empresa",
            "ad": [AdsPerfil.APPDEL_NBS_HROps],
            "fields": [
              {
                "type": "text",
                "name": "nome_do_solicitante",
                "label": "Nome do solicitante",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_solicitante",
                "label": "E-mail do solicitante",
                "validators": Validators.required
              },
              {
                "type": "text",
                "name": "nome_do_colaborador",
                "label": "Nome do colaborador",
                "validators": Validators.required
              },
              {
                "type": "email",
                "name": "e_mail_do_colaborador",
                "label": "E-mail do colaborador",
                "validators": Validators.required
              },
              {
                "label": "Nome da Empresa Atual",
                "name": "nome_de_empresa_atual",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CASA DO ADUBO S.A",
                    "value": "CASA DO ADUBO S.A"
                  },
                  {
                    "type": "option",
                    "name": "CASAL COMERCIO E SERVIÇOS LTDA",
                    "value": "CASAL COMERCIO E SERVIÇOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "MARCA AGRO MERCANTIL LTDA",
                    "value": "MARCA AGRO MERCANTIL LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN PARTICIPAÇÕES LTDA",
                    "value": "NUTRIEN PARTICIPAÇÕES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "REPRESENTAÇÕES DE PRODUTOS AGRÍCOLAS LTDA",
                    "value": "REPRESENTAÇÕES DE PRODUTOS AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SAFRA RICA COMÉRCIO",
                    "value": "SAFRA RICA COMÉRCIO"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ],
                "type": "select"
              },
              {
                "label": "Nome da Empresa Futura",
                "name": "nome_da_empresa_futura_1",
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CASA DO ADUBO S.A",
                    "value": "CASA DO ADUBO S.A"
                  },
                  {
                    "type": "option",
                    "name": "CASAL COMERCIO E SERVIÇOS LTDA",
                    "value": "CASAL COMERCIO E SERVIÇOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "MARCA AGRO MERCANTIL LTDA",
                    "value": "MARCA AGRO MERCANTIL LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN PARTICIPAÇÕES LTDA",
                    "value": "NUTRIEN PARTICIPAÇÕES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "REPRESENTAÇÕES DE PRODUTOS AGRÍCOLAS LTDA",
                    "value": "REPRESENTAÇÕES DE PRODUTOS AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SAFRA RICA COMÉRCIO",
                    "value": "SAFRA RICA COMÉRCIO"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Estabelecimento",
                "label": "Estabelecimento futuro",
                "name": "estabelecimento_futuro",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Unidade organizacional",
                "label": "Unidade Organizacional futura",
                "name": "unidade_organizacional_futura",
                "validators": Validators.required
              },
              {
                "type": "connector",
                "connector": "Centro de custo",
                "label": "Centro de custo futuro",
                "name": "centro_de_custo_futuro",
                "validators": Validators.required
              },
              { "type": "connector",
                "connector": "CBO",
                "label": "Cargo futuro",
                "name": "cargo_futuro",
                "validators": Validators.required
              },
              {
                "type": "currency",
                "label": "Salário futuro",
                "name": "sal_rio_futuro"
              },
              {
                "label": "Código da Posição futura",
                "name": "nome_da_posi_o_futura",
                "type": "number"
              },
              {
                "label": "Data da Transferência",
                "name": "data_da_transfer_ncia_1",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "01/jan/23",
                    "value": "01/jan/23"
                  },
                  {
                    "type": "option",
                    "name": "01/fev/23",
                    "value": "01/fev/23"
                  },
                  {
                    "type": "option",
                    "name": "01/mar/23",
                    "value": "01/mar/23"
                  },
                  {
                    "type": "option",
                    "name": "01/abr/23",
                    "value": "01/abr/23"
                  },
                  {
                    "type": "option",
                    "name": "01/mai/23",
                    "value": "01/abr/23"
                  },
                  {
                    "type": "option",
                    "name": "01/jun/23",
                    "value": "01/jun/23"
                  },
                  {
                    "type": "option",
                    "name": "01/jul/23",
                    "value": "01/jul/23"
                  },
                  {
                    "type": "option",
                    "name": "01/ago/23",
                    "value": "01/ago/23"
                  },
                  {
                    "type": "option",
                    "name": "01/set/23",
                    "value": "01/set/23"
                  },
                  {
                    "type": "option",
                    "name": "01/out/23",
                    "value": "01/out/23"
                  },
                  {
                    "type": "option",
                    "name": "01/nov/23",
                    "value": "01/nov/23"
                  },
                  {
                    "type": "option",
                    "name": "01/dez/23",
                    "value": "01/dez/23"
                  },
                  {
                    "type": "option",
                    "name": "01/jan/24",
                    "value": "01/jan/24"
                  },
                  {
                    "type": "option",
                    "name": "01/fev/24",
                    "value": "01/fev/24"
                  },
                  {
                    "type": "option",
                    "name": "01/mar/24",
                    "value": "01/mar/24"
                  },
                  {
                    "type": "option",
                    "name": "01/abr/24",
                    "value": "01/abr/24"
                  },
                  {
                    "type": "option",
                    "name": "01/mai/24",
                    "value": "01/mai/24"
                  },
                  {
                    "type": "option",
                    "name": "01/jun/24",
                    "value": "01/jun/24"
                  },
                  {
                    "type": "option",
                    "name": "01/jul/24",
                    "value": "01/jul/24"
                  },
                  {
                    "type": "option",
                    "name": "01/ago/24",
                    "value": "01/ago/24"
                  },
                  {
                    "type": "option",
                    "name": "01/set/24",
                    "value": "01/set/24"
                  },
                  {
                    "type": "option",
                    "name": "01/out/24",
                    "value": "01/out/24"
                  },
                  {
                    "type": "option",
                    "name": "01/nov/24",
                    "value": "01/nov/24"
                  },
                  {
                    "type": "option",
                    "name": "01/dez/24",
                    "value": "01/dez/24"
                  },
                  {
                    "type": "option",
                    "name": "01/jan/25",
                    "value": "01/jan/25"
                  },
                  {
                    "type": "option",
                    "name": "01/fev/25",
                    "value": "01/fev/25"
                  },
                  {
                    "type": "option",
                    "name": "01/mar/25",
                    "value": "01/mar/25"
                  },
                  {
                    "type": "option",
                    "name": "01/abr/25",
                    "value": "01/abr/25"
                  },
                  {
                    "type": "option",
                    "name": "01/mai/25",
                    "value": "01/mai/25"
                  },
                  {
                    "type": "option",
                    "name": "01/jun/25",
                    "value": "01/jun/25"
                  },
                  {
                    "type": "option",
                    "name": "01/jul/25",
                    "value": "01/jul/25"
                  },
                  {
                    "type": "option",
                    "name": "01/ago/25",
                    "value": "01/ago/25"
                  },
                  {
                    "type": "option",
                    "name": "01/set/25",
                    "value": "01/set/25"
                  },
                  {
                    "type": "option",
                    "name": "01/out/25",
                    "value": "01/out/25"
                  },
                  {
                    "type": "option",
                    "name": "01/nov/25",
                    "value": "01/nov/25"
                  },
                  {
                    "type": "option",
                    "name": "01/dez/25",
                    "value": "01/dez/25"
                  },
                  {
                    "type": "option",
                    "name": "01/jan/26",
                    "value": "01/jan/26"
                  },
                  {
                    "type": "option",
                    "name": "01/fev/26",
                    "value": "01/fev/26"
                  },
                  {
                    "type": "option",
                    "name": "01/mar/26",
                    "value": "01/mar/26"
                  },
                  {
                    "type": "option",
                    "name": "01/abr/26",
                    "value": "01/abr/26"
                  },
                  {
                    "type": "option",
                    "name": "01/mai/26",
                    "value": "01/mai/26"
                  },
                  {
                    "type": "option",
                    "name": "01/jun/26",
                    "value": "01/jun/26"
                  },
                  {
                    "type": "option",
                    "name": "01/jul/26",
                    "value": "01/jul/26"
                  },
                  {
                    "type": "option",
                    "name": "01/ago/26",
                    "value": "01/ago/26"
                  },
                  {
                    "type": "option",
                    "name": "01/set/26",
                    "value": "01/set/26"
                  },
                  {
                    "type": "option",
                    "name": "01/out/26",
                    "value": "01/out/26"
                  },
                  {
                    "type": "option",
                    "name": "01/nov/26",
                    "value": "01/nov/26"
                  },
                  {
                    "type": "option",
                    "name": "01/dez/26",
                    "value": "01/dez/26"
                  },
                  {
                    "type": "option",
                    "name": "01/jan/27",
                    "value": "01/jan/27"
                  },
                  {
                    "type": "option",
                    "name": "01/fev/27",
                    "value": "01/fev/27"
                  },
                  {
                    "type": "option",
                    "name": "01/mar/27",
                    "value": "01/mar/27"
                  },
                  {
                    "type": "option",
                    "name": "01/abr/27",
                    "value": "01/abr/27"
                  },
                  {
                    "type": "option",
                    "name": "01/mai/27",
                    "value": "01/mai/27"
                  },
                  {
                    "type": "option",
                    "name": "01/jun/27",
                    "value": "01/jun/27"
                  },
                  {
                    "type": "option",
                    "name": "01/jul/27",
                    "value": "01/jul/27"
                  },
                  {
                    "type": "option",
                    "name": "01/ago/27",
                    "value": "01/ago/27"
                  },
                  {
                    "type": "option",
                    "name": "01/set/27",
                    "value": "01/set/27"
                  },
                  {
                    "type": "option",
                    "name": "01/out/27",
                    "value": "01/out/27"
                  },
                  {
                    "type": "option",
                    "name": "01/nov/27",
                    "value": "01/nov/27"
                  },
                  {
                    "type": "option",
                    "name": "01/dez/27",
                    "value": "01/dez/27"
                  }
                ],
                "type": "select"
              },
              {
                "type": "text",
                "label": "Local de Trabalho futuro",
                "name": "local_de_trabalho_futuro",
                "validators": Validators.required
              },
              {
                "type": "radioHorizontal",
                "label": "Auxilio Transferência?",
                "name": "auxilio_transfer_ncia",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Sim",
                    "value": "Sim",
                    fields: [
                      {
                        "label": "Valor do Auxilio",
                        "name": "valor_do_auxilio",
                        "type": "currency",
                        "validators": Validators.required
                      }
                    ]
                  },
                  {
                    "type": "option",
                    "name": "Não",
                    "value": "Não"
                  }
                ]
              },
              {
                "label": "Anexo",
                "name": "anexo",
                "type": "attachment"
              },
              {
                "type": "textArea",
                "name": "coment_rios",
                "label": "Comentários"
              },
              {
                "type": "buttonSubmit",
                "name": "Enviar"
              }
            ]
          }
        ]
      }
    ]
  }

  enviarFormulario(event: any) {
    //
  }

}
