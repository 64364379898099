export const pipe_fields_baixa = [
  "sistema_t_tulo_em_aberto",
  "nome_do_cliente",
  "c_digo_do_cliente",
  "c_digo_do_cliente_sap",
  "n_mero_de_notas_fiscais_a_serem_baixadas",
  "n_mero_da_nota_fiscal_nota_1",
  "baixar_valor_total_da_nota_fiscal_nota_1",
  "valor_parcial_a_ser_baixado_nota_1",
  "desconto_concedido_nota_1",
  "valor_do_desconto_nota_1",
  "juros_aplicado_nota_1",
  "valor_dos_juros_nota_1",
  "n_mero_da_nota_fiscal_nota_2",
  "baixar_valor_total_da_nota_fiscal_nota_2",
  "valor_parcial_a_ser_baixado_nota_2",
  "desconto_concedido_nota_2",
  "valor_do_desconto_nota_2",
  "juros_aplicado_nota_2",
  "valor_dos_juros_nota_2",
  "n_mero_da_nota_fiscal_nota_3",
  "baixar_valor_total_da_nota_fiscal_nota_3",
  "valor_parcial_a_ser_baixado_nota_3",
  "desconto_concedido_nota_3",
  "valor_do_desconto_nota_3",
  "juros_aplicado_nota_3",
  "valor_dos_juros_nota_3",
  "n_mero_da_nota_fiscal_nota_4",
  "baixar_valor_total_da_nota_fiscal_nota_4",
  "valor_parcial_a_ser_baixado_nota_4",
  "desconto_concedido_nota_4",
  "valor_do_desconto_nota_4",
  "juros_aplicado_nota_4",
  "valor_dos_juros_nota_4",
  "n_mero_da_nota_fiscal_nota_5",
  "baixar_valor_total_da_nota_fiscal_nota_5",
  "valor_parcial_a_ser_baixado_nota_5",
  "desconto_concedido_nota_5",
  "valor_do_desconto_nota_5",
  "juros_aplicado_nota_5",
  "valor_dos_juros_nota_5",
  "n_mero_da_nota_fiscal_nota_6",
  "baixar_valor_total_da_nota_fiscal_nota_6",
  "valor_parcial_a_ser_baixado_nota_6",
  "desconto_concedido_nota_6",
  "valor_do_desconto_nota_6",
  "juros_aplicado_nota_6",
  "valor_dos_juros_nota_6",
  "n_mero_da_nota_fiscal_nota_7",
  "baixar_valor_total_da_nota_fiscal_nota_7",
  "valor_parcial_a_ser_baixado_nota_7",
  "desconto_concedido_nota_7",
  "valor_do_desconto_nota_7",
  "juros_aplicado_nota_7",
  "valor_dos_juros_nota_7",
  "n_mero_da_nota_fiscal_nota_8",
  "baixar_valor_total_da_nota_fiscal_nota_8",
  "valor_parcial_a_ser_baixado_nota_8",
  "desconto_concedido_nota_8",
  "valor_do_desconto_nota_8",
  "juros_aplicado_nota_8",
  "valor_dos_juros_nota_8",
  "n_mero_da_nota_fiscal_nota_9",
  "baixar_valor_total_da_nota_fiscal_nota_9",
  "valor_parcial_a_ser_baixado_nota_9",
  "desconto_concedido_nota_9",
  "valor_do_desconto_nota_9",
  "juros_aplicado_nota_9",
  "valor_dos_juros_nota_9",
  "n_mero_da_nota_fiscal_nota_10",
  "baixar_valor_total_da_nota_fiscal_nota_10",
  "valor_parcial_a_ser_baixado_nota_10",
  "desconto_concedido_nota_10",
  "valor_do_desconto_nota_10",
  "juros_aplicado_nota_10",
  "valor_dos_juros_nota_10",
  "cidade_loja_xp_nutrien",
  "estado_loja_xp_nutrien",
  "solicitante",
  "e_mail_solicitante",
  "e_mail_secund_rio",
  "observa_es",
  "anexo"
]
